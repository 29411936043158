<template>
  <div class="agendamiento d-flex" :style="{backgroundColor: color}">
    <div class="text-left">{{ texto }}</div><div class="ml-auto">{{ texto_consecutivo }}</div>
  <div class="text-left" style="color: red"> {{ texto1 }}</div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'ProgramadorAgendamiento',
    components: {
    },
    data () {
      return {
        
      }
    },
    props: {
      agendamiento : Object,
    },
    beforeCreate() {
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({
      }),
      ...mapMutations({
      }),
    },
    computed: {
      ...mapState({
      }),
      color(){
        let color = '#000'
        if(this.agendamiento){
          
          color='#fff'; 
          if(this.agendamiento.novedad && this.agendamiento.novedad=='sin_carpeta'){
            color='yellow';
          } 
        }
        return color
      },
      texto(){
        let texto = '...'
        let texto1 = '...'
        if(this.agendamiento){
          if(this.agendamiento.estudiante){
            texto = this.agendamiento.estudiante.primer_nombre +' '+this.agendamiento.estudiante.primer_apellido
            
            if(this.agendamiento.estudiante.segundo_apellido){
              texto += ' '+this.agendamiento.estudiante.segundo_apellido 
              
            }
          }
        }
        return texto
      },
      texto1(){
        let texto1 = '...'
           if(this.agendamiento){
             texto1 = this.agendamiento.estado
           
           }

        return texto1
      } 
      ,
      texto_consecutivo(){
        let texto = ''
        if(this.agendamiento){
          if(this.agendamiento.estudiante.agendamientos){
            let index = this.agendamiento.estudiante.agendamientos.findIndex((element)=>{
              return element.id == this.agendamiento.id
            })
            texto += (index+1)+'/'+this.agendamiento.estudiante.agendamientos.length
          }
        }
        return texto
      }

    }
  }
</script>

<style scope>
  .agendamiento{
    padding: 0 2px
  }
  .agendamiento .ml-auto{
    padding-left: 5px !important;
  }
</style>