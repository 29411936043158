<template>
  <div class="">

    <div class="container pt-3 m-auto text-left ">
      <h1>Tema Masivo</h1>
       <hr>
      <form
        autocomplete="off"
        class="form-horizontal"
        @submit.prevent="validateBeforeSubmit"
      >
        <div>
          <strong>Estructura:</strong><br>
          <span style="font-size: 10px">"block;pages;unidad;class_topic;class_structure;description;curso_id;nombre;orden;tipo_clase;tipo_salon;codigo;programable;activo;programacion_libre;motivacion;naturaleza;estructura;autorizaciones"</span>
          <textarea class="area-masivo" v-model="datos"></textarea>

        </div>
        <button class="btn btn-block btn-danger" type="submit">Procesar</button>
        <div>{{ resultado}}</div>
      </form>
    </div>a
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'TemaMasivo',
    data () {
      return {
          datos: null,
          resultado: null
      }
    },
    mounted () {
    },
    methods:{
      ...mapActions({
        procesar: 'cursos/crearTemasMasivo',
        setWarning: 'setWarning',
        
      }),
      ...mapMutations({
      }),
      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {

          let lines = this.datos.split("\n");
          let candidatos = []
          for(let i in lines){
            let line = lines[i];
            let parametros = line.split(";")
            let candidato = {
              block:parametros[0],
              pages:parametros[1],
              unidad:parametros[2],
              class_topic:parametros[3],
              class_structure:parametros[4],
              description:parametros[5],
              curso_id:parametros[6],
              nombre:parametros[7],
              orden:parametros[8],
              tipo_clase:parametros[9],
              tipo_salon:parametros[10],
              codigo:parametros[11],
              programable:parametros[12],
              activo:parametros[13],
              programacion_libre:parametros[14],
              motivacion:parametros[15],
              naturaleza:parametros[16],
              estructura:parametros[17],
              autorizaciones:parametros[18],
              
            }
            candidatos.push(candidato)
          }

          console.log(candidatos)
          if ( ! result) {
            //hay errores
          } else {
            
            this.procesar(candidatos).then((response)=>{
                this.resultado = response.message
                this.setWarning(response.message, { root: true }).then(()=>{
                //this.fetchLista().then(()=>{})
              })
            }).catch(error=>{
              
            })
            
          }
        })
      },

    },
    computed:{
      ...mapState({
        warning: state => state.warning
      }),
    }
  }
</script>

<style scope>
  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
  .area-masivo{
    width: 100%;
  }
</style>