<template>
  <div class="">
   <FuncionarioNavigation :funcionario="funcionario"/>
    <div class="container pt-3 m-auto text-left ">
      
      <div v-if="funcionario">
        <h2>Datos básicos</h2>
        
        <div class="row">
          <div class="col-3"><strong>Tipo Documento:</strong>
          </div><div class="col-9">{{ funcionario.tipo_documento }}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>Documento:</strong>
          </div><div class="col-9">{{ funcionario.numero_documento }}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>eMail:</strong></div>
          <div class="col-9">{{ funcionario.email }}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>Telefono:</strong></div>
          <div class="col-9">{{ funcionario.telefono }}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>Celular:</strong></div>
          <div class="col-9">{{ funcionario.movil }}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>Grupo:</strong></div>
          <div class="col-9" v-if="funcionario.grupo">{{ funcionario.grupo.nombre }}</div>
          <div class="col-9" v-else>Sin Asignar</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>Último ingreso:</strong></div>
          <div class="col-9" v-if="funcionario.ultimo_ingreso">{{ textoUltimoIngreso }}</div>
          <div class="col-9" v-else>No registra</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>Fecha válida de autenticación:</strong></div>
          <div class="col-9" v-if="funcionario.invalid_token_time">{{ textoInvalidarTokens }}</div>
          <div class="col-9" v-else>No registra</div>
        </div>
        <hr>

      </div>
      <div v-else>
        No hay información
      </div>
      <FuncionarioDetalleEventos :funcionario="funcionario"/>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import FuncionarioNavigation from '@/components/Funcionarios/FuncionarioNavigation';
  import FuncionarioDetalleEventos from '@/components/Funcionarios/FuncionarioDetalleEventos';

  export default {
    name: 'FuncionarioDetalle',
    components: {
      FuncionarioNavigation,
      FuncionarioDetalleEventos
    },
    data () {
      return {
          funcionario_id: null,
      }
    },    
    mounted () {
      this.funcionario_id = this.$route.params.id;
      if(!this.funcionario.id){
        this.actualizar()
      }
    },
    
    methods:{
      ...mapActions({
        fetchDetalle: 'funcionarios/fetchDetalle',
      }),
      ...mapMutations({
      }),
      actualizar(){
        this.fetchDetalle(this.funcionario_id).then(()=>{})
      },
      
    },
    computed:{
      ...mapState({
        detalle: state => state.funcionarios.detalle, 
      }),
      ...mapGetters({
        getDetalle: 'funcionarios/getDetalle', 
      }),
      funcionario(){
        if(this.funcionario_id){
          let detalle =  this.getDetalle(this.funcionario_id)
          return detalle
        }
        return null
      }, 
      textoUltimoIngreso(){
        let fechaUltimoIngreso = new Date(this.funcionario.ultimo_ingreso)
        return fechaUltimoIngreso.toLocaleString()
      },
      textoInvalidarTokens(){
        let fechaInvalidTokenTime = new Date(this.funcionario.invalid_token_time)
        return fechaInvalidTokenTime.toLocaleString()
      }
    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>