import Vue from 'vue';

const state = {
  agendamientos : {},
};

const actions = {
  
  fetchAgendamientos:({commit}) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('agendamientos').then(
        response =>{
          commit('setAgendamientos',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error.data, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchAgendamientosXMarca:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('agendamientos/xmarca',payload).then(
        response =>{
          commit('setAgendamientosXMarcaTiempo',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  //guarda la clase del agendamiento
  //payoload = 
  setClase:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve,reject) => {
      Vue.http.post('agendamiento/set_clase',payload).then(
        response =>{
          if(response.data.clase_id){
            commit('clases/setAgendamiento',response.data,{ root: true });
          }else{
            
            commit('setAgendamiento',response.data);
          }
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  desasignarDeClase:({commit},agendamiento) => {
    commit('clases/deleteAgendamiento',agendamiento,{ root: true });
  },
  desasignarDePendientes:({commit},agendamiento) => {
    commit('deleteAgendamiento',agendamiento);
  },
  //solicita asignar una clase automaticamente
  asignarAuto:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve,reject) => {
      Vue.http.post('agendamiento/asignar_clase_auto',payload).then(
        response =>{
          if(response.data.clase_id){
            commit('clases/setAgendamiento',response.data,{ root: true });
          }else{
            commit('setAgendamiento',response.data);
          }
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  //guarda la clase del agendamiento
  setTema:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve) => {
      Vue.http.post('agendamiento/set_tema',payload).then(
        response =>{
          if(response.data.clase_id){
            commit('clases/setAgendamiento',response.data,{ root: true });
          }else{
            commit('setAgendamiento',response.data);
          }
          resolve(response.data)
        }
      ).catch(error=>{
        console.log('error:'+error)
        console.log(error)
        commit('setError', error, { root: true });
        //reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  asignarTemaAuto:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve) => {
      Vue.http.post('agendamiento/asignar_tema_auto',payload).then(
        response =>{
          if(response.data.clase_id){
            commit('clases/setAgendamiento',response.data,{ root: true });
          }else{
            commit('setAgendamiento',response.data);
          }
          resolve(response.data)
        }
      ).catch(error=>{
        console.log('error:'+error)
        console.log(error)
        commit('setError', error, { root: true });
        //reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  anularFirma:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve) => {
      Vue.http.post('agendamiento/anular_firma',payload).then(
        response =>{
          if(response.data.clase_id){
            commit('clases/setAgendamiento',response.data,{ root: true });
          }else{
            commit('setAgendamiento',response.data);
          }
          resolve(response.data)
        }
      ).catch(error=>{
        console.log('error:'+error)
        console.log(error)
        commit('setError', error, { root: true });
        //reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  
  eliminarTema:({commit},payload) => {
    commit('startProcessing', null, { root: true });

    return new Promise((resolve) => {
      Vue.http.post('agendamiento/eliminar_tema',payload).then(
        response =>{
          if(response.data.clase_id){
            commit('clases/setAgendamiento',response.data,{ root: true });
          }else{
            commit('setAgendamiento',response.data);
          }
          resolve(response.data)
        }
      ).catch(error=>{
        console.log('error:'+error)
        console.log(error)
        commit('setError', error, { root: true });
        //reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  
  
};

const getters = {
  
};

const mutations = {
  setAgendamientos: (state, datos) => {
    state.agendamientos = datos
  },
  setAgendamiento: (state, datos) => {
    console.log('agendamientos/setAgendamiento')
    //agrego al arreglo de agendamientos pendientes
    if(!datos.clase_id){
      if(datos.marca){
        console.log('buscando marca:'+datos.marca)
        if(!state.agendamientos[datos.marca]){
          Vue.set(state.agendamientos, datos.marca, {})
        }
        let index = state.agendamientos[datos.marca].findIndex(element =>{
          return element.id == datos.id
        })
        console.log('setAgendamiento: index:'+index)
        if(index>=0){ 
          state.agendamientos[datos.marca].splice(index,1,datos)
        }else{
          state.agendamientos[datos.marca].push(datos)
        }
      }else{
        console.log('setAgendamiento: resultado sin marca')
      }
    }else{
      console.log('error intentando agregar un agendamiento asignado al arreglo de pendientes')
    }
  },
  setAgendamientosXMarca: (state, datos) => {
    let marca = datos.marca;
    if(!state.agendamientos[marca]){
      Vue.set(state.agendamientos, marca, {})  
    }
    state.agendamientos[marca] = datos.agendamientos
  },
  setAgendamientosXMarcaTiempo: (state, datos) => {
    for(let marca in datos){
      if(!state.agendamientos[marca]){
        Vue.set(state.agendamientos, marca, {})  
      }
      state.agendamientos[marca] = datos[marca]
    }
  },
  deleteAgendamiento: (state, datos) =>{
    let marca = datos.fecha+'T'+datos.hora+'T'+datos.tipo_clase
    console.log('deleteAgendamiento marca:'+marca)
    
    let index = state.agendamientos[marca].findIndex(element =>{
      return element.id == datos.id
    })
    console.log('index:'+index)
    if(index>=0){
      state.agendamientos[marca].splice(index,1)
    }  
    
  },
  restart: (state) => {
    state.agendamientos = {}
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
