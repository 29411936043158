<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_estudiantes"></b-navbar-toggle>
        <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Nuevo estudiante</h1>
        <b-collapse is-nav id="nav_collapse_estudiantes">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <a  :href="`#/estudiantes`" class="nav-link" title="Cancelar">Cancelar</a>
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <div class="container pt-3 m-auto text-left ">
      
      <form
        autocomplete="off"
        class="form-horizontal"
        @submit.prevent="validateBeforeSubmit"
      >
        <div>
          <InputTextSimple titulo="Primer Nombre" :modelo="estudiante.primer_nombre" 
            @change="estudiante.primer_nombre = $event"/>
          <InputTextSimple titulo="Segundo Nombre" :modelo="estudiante.segundo_nombre" 
            @change="estudiante.segundo_nombre = $event"/>
          <InputTextSimple titulo="Primer Apellido" :modelo="estudiante.primer_apellido" 
            @change="estudiante.primer_apellido = $event"/>
          <InputTextSimple titulo="Segundo Apellido" :modelo="estudiante.segundo_apellido" 
            @change="estudiante.segundo_apellido = $event"/>
          <SelectTipoDocumento titulo="Tipo de Documento" :modelo="estudiante.tipo_documento" 
            @change="estudiante.tipo_documento=$event"/>
          <InputNumeroDocumento titulo="Número de Documento" :modelo="estudiante.numero_documento" 
            @change="estudiante.numero_documento=$event"/>
          <InputEmailSimple titulo="Email" :modelo="estudiante.email" 
            @change="estudiante.email=$event"/>
          <InputMovil titulo="Telefono" :modelo="estudiante.telefono" 
            @change="estudiante.telefono=$event"/>
          <InputMovil titulo="Movil" :modelo="estudiante.movil" 
            @change="estudiante.movil=$event"/>
            
          <InputFecha titulo="Fecha de nacimiento" :modelo="estudiante.fecha_nacimiento" />

          
          <div class="form-group row">
            <label class="col-3 col-form-label">Método de Pago</label>
            <div class="col-3">
              <select v-model="estudiante.tipo_pago" class="form-control">
                <option disabled value="">Please select one</option>
                <option v-if="metodos_pago" v-for="opcion in metodos_pago" :value="opcion.codigo">{{ opcion.nombre }}</option>
              </select>
            </div>
          </div>


        </div>
        <button class="btn btn-block btn-danger" type="submit">Crear</button>
      </form>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  

  export default {
    name: 'EstudianteNuevo',
    components: {
  
    },
    data () {
      return {
          estudiante : {
            primer_nombre:null,
            segundo_nombre:null,
            primer_apellido:null,
            segundo_apellido:null,
            tipo_documento:null,
            numero_documento:null,
            email:null,
            movil:null,
            telefono:null,
            tipo_pago:null,
          }
      }
    },
    mounted () {
      if(!this.metodos_pago || (this.metodos_pago && !this.metodos_pago.length)){
        this.fetchMetodosPago()
      }
    },
    methods:{
      ...mapActions({
        crear: 'estudiantes/crear',
        setWarning: 'setWarning',
        fetchLista: 'estudiantes/fetchLista',
        fetchMetodosPago: 'asw/fetchMetodosPago',
      }),
      ...mapMutations({
      }),
      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {
          if ( ! result) {
            //hay errores
          } else {
            if(!this.estudiante.tipo_pago){
              this.setWarning('Debe seleccionar un tipo de pago')
              return
            }
            if (this.estudiante.fecha_nacimiento) {
              let fecha_nacimiento = this.estudiante.fecha_nacimiento.toISOString().split('T')[0]
              this.estudiante.fecha_nacimiento = fecha_nacimiento;
            }
            this.crear(this.estudiante).then(()=>{
                this.setWarning('Estudiante Creado', { root: true }).then(()=>{
                this.fetchLista().then(()=>{
                    this.$router.push('/estudiantes')
                })
              })
            }).catch(error=>{
              //todo probar si se puede quitar
            })
            
          }
        })
      },

    },
    computed:{
      ...mapState({
        warning: state => state.warning,
        metodos_pago: state  => state.asw.metodos_pago,
      }),
    }
  }
</script>

<style scope>
  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>