<template>
	<div class="form-group mb-0">		
    <div v-if="puedeEditar" >
			<select  v-model="docente_id" class="form-control" 
      style="font-size: 12px;height: 25px;">
        <option disabled value="-1">--Docente--</option>
        <option v-if="lista" v-for="opcion in lista" :value="opcion.id">{{ opcion.primer_nombre }} {{ opcion.primer_apellido }}</option>
      </select>
    </div>
    <div v-else class="text-center profesor">
<a target="_blank" :href="`#/docente/detalle/${ docente.id }`" class="" title="">
  <strong>{{ docente.primer_nombre }} {{ docente.primer_apellido }}</strong></a>
    </div>
	</div>
</template>


<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';

  export default {
    name: 'ProgramadorSeleccioneDocente',
    data () {
      return {
        _docente:null,
        error: null,
      }
    },
    props: {
      clase: Object,
      marca_tiempo: String,
      marca_relativa: String,
      editando: Boolean
    },
    mounted () {
      this.consultarDocente();
    }, 
    methods:{
      ...mapActions({
        getDocente: 'docentes/fetchConsultar',
      }),
      consultarDocente(){
        if (this.docentes) {
          let obj = this.docentes.find(element =>{
            return element.id == this.docente_id
          })
          if (!obj) {
            this.getDocente(this.docente_id).then((result) => {
              this.docentes.push(result)
              this._docente = result;
            });
          }
        }
      }
    },
    computed:{
      ...mapState({
        clases: state => state.clases.clases, 
        docentes: state  => state.docentes.lista
      }),
      ...mapGetters({
        getDocentesDisponibles: 'docentes/getDocentesDisponibles',
        getDocentesConClase: 'clases/getDocentesConClase', 
        permiso: 'auth/permiso', 
      }),
      puedeEditar(){

        if(this.clase.id && this.editando){
         if(this.permiso('956EB91C')){
          return true
         }
         
        }
        return false

      },
      docente_id: {
        get () {
          return this.clase.profesor_id
        },
        set (value) {
          this.$emit('change', value);
        }
      },
      docente() {
        if(this.docentes){
          let obj = this.docentes.find(element =>{
            return element.id == this.docente_id
          })
          if (!obj) {
            if (this._docente && this._docente.primer_nombre) {
              obj = this._docente;
            }
            console.log(this.docente_id);
          }
          if(obj){
            return obj
          }
        }
        return {primer_nombre:'cargando...', id:this.docente_id}
      },
      lista(){
        console.log('ProgramadorSeleccioneDocente lista')
        //Docentes que tienen la disponibilidad de horario
        let docentes_disponible = this.getDocentesDisponibles(this.marca_relativa)
        //Docentes que ya tienen clase en la marca de tiempo
        let docentes_con_clase = this.getDocentesConClase(this.marca_tiempo)
        //Cruzamos los dos para encontrar los que pueden agendar
        if(docentes_disponible && docentes_disponible.length>0){
          let respuesta = docentes_disponible.filter((docente)=>{
              if(docente.id == this.docente_id){
                return true
              }
              //VALIDAR SI NO TIENE CLASE
              if(!docentes_con_clase.includes(docente.id)){
                //VALIDAR TIPOS
                for(let i in this.clase.tipos){
                  let tipo = this.clase.tipos[i]
                  if(docente.tipos_permitidos && docente.tipos_permitidos.includes(tipo)){
                    return true
                  }
                }
              }
              return false
            })

          return respuesta
        }
        return []
      },
      
    }
    
  }
</script>

<style scope>
.profesor a strong{
  color: #fff;
}

</style>