<template>
	<div class="form-group mb-0">
      <div v-if="puedeEditar">
  			<select  v-model="salon_id" class="form-control" 
        style="font-size: 12px;height: 25px;">
          <option disabled value="-1">--Salón--</option>
          <option v-for="opcion in salones_disponibles" :key="opcion.id" :value="opcion.id">{{ opcion.nombre }}</option>
        </select>
      </div>
      <div v-else class="text-center salon"><strong>{{ salon.nombre }}</strong></div>
	</div>
</template>

<script>
  import {mapState,mapGetters} from 'vuex';
  export default {
    name: 'ProgramadorSeleccioneSalon',
    data () {
      return {
        error: null,
      }
    },
    props: {
      clase : Object,
      marca_tiempo: String,
      editando: Boolean
    },
    mounted () {
    },
    computed:{
      ...mapState({
        clases: state => state.clases.clases,
        salones: state  => state.salones.lista,
      }),
      ...mapGetters({
        getSalonesDisponibles: 'salones/getSalonesDisponibles', 
        permiso: 'auth/permiso', 
      }),
      

      puedeEditar(){
        if(this.clase.tipos && this.editando){
         if(this.permiso('EEB6ADA9')){
          return true
         }
         
        }
        return false
        
      },
      salon_id: {
        get () {
          return this.clase.salon_id
        },
        set (value) {
          this.$emit('change', value);
        }
      },
      salon() {
        if(this.salones){
          let obj = this.salones.find(element =>{
            return element.id == this.salon_id
          })
          if(obj){
            return obj
          }
        }
        return {nombre:'cargando...'}
      },
      salones_disponibles(){
        console.log('ProgramadorSeleccioneSalon salones_disponibles')
          let get_salones_disponibles = this.getSalonesDisponibles()
          if(get_salones_disponibles && get_salones_disponibles.length>0){
            let salones_sin_clase = get_salones_disponibles.filter((salon)=>{
              if(salon.id==this.salon_id){
                return true
              }
              let hay_clase = this.clases[this.marca_tiempo].find((clase)=>{
                return clase.salon_id == salon.id  
              })
              return !hay_clase
            })

            if(this.clase.tipos){

              let salones_sin_clase_con_tipo = salones_sin_clase.filter(salon=>{
                for(let i in this.clase.tipos){
                  let tipo = this.clase.tipos[i]
                  if(salon.tipos_permitidos && salon.tipos_permitidos.includes(tipo)){
                    return true
                  }
                }
                return false
              })

              return salones_sin_clase_con_tipo

            }else{
              return salones_sin_clase
            }
            
          }else{
            //this.fetchSalones()
          }

        return []
      },
     
    }
    
  }
</script>

<style scope>
  .salon{
    color: #fff;
  }
</style>