<template>

   
	<div class="form-group row">
		<label class="col-3 col-form-label">{{ titulo }}:</label>
    	<div class="col-9">
       
            <b-form-select v-model="_modelo" multiple class="select-tipo" :select-size="10"> 
          <!--      <option value="0">seleccione ...</option> -->
                <option v-for="sede in sedes" :key="sede.nombre" :value="sede.id" >{{ sede.nombre }}</option>
            </b-form-select> 

            </div>
	</div>
</template>


<script>

  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
 
  export default {
    name: 'SelectSedeAll',
    components:{

    },
    data () {
      return {
          error: null,
          curso_id:0,
          metodo_id:0,
          sede_id:0,
          selected:null,
         // _modelo: null
    
      }
    },
    props: {
		titulo: String,
		modelo: Array
    },
   
    mounted () {

           this.fetchLista()
    },
    methods:{
         ...mapActions({

                fetchLista: 'sedes/fetchLista'
              

              }),
    }
    ,
    computed:{
		_modelo: {
			get () {
       // console.log(this.modelo)
				return this.modelo
			},
			set (value) {
				this.$emit('change', value);
			}
        },
      ...mapState({

        sedes: state => state.sedes.lista,

      }),
    }
    
  }
</script>
