
<template>
  <b-container>
    <h1>Agendamientos Acumulados</h1>
    <div class="chart-container">
    <DashboardAgendamientosAcumuladosChart ref="chart" :chartdata="data" :options="options" :width="500" :height="200"></DashboardAgendamientosAcumuladosChart>
    </div>
    <b-button size="sm" variant="link" @click="actualizar">Actualizar</b-button>
  </b-container>
</template>


<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import DashboardAgendamientosAcumuladosChart from '@/components/Dashboard/DashboardAgendamientosAcumuladosChart';

  export default {
    name: 'DashboardAgendamientosAcumulados',
    components:{
DashboardAgendamientosAcumuladosChart
    },
    data(){
      return {
        data: {
            labels:["NORTE","CENTRO","ADMINISTRATIVO","SUBA","TITAN","DIVER PLAZA","CHICO","CALLE 72","CALLE 45","CORFERIAS","RESTREPO","TUNAL","BOSA","SOACHA","PLAZA DE LAS AMERICAS","VILLAVICENCIO","ARMENIA","PEREIRA","MOSQUERA","PREMIUM","FLORIDA","ENVIGADO","MANIZALES","CALI","TUNJA"],
            datasets: [
            {
              label: 'Agendamientos Acumulados',
              data:[73440,57619,0,1949,5,114,870,2796,58,1439,7782,7987,3668,2500,5865,0,0,0,1317,4733,4842,3459,0,0,0],
              backgroundColor: '#f87979',
            }
          ]
        },
        
        options: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }],
                xAxes: [{
                    gridLines: {
                        offsetGridLines: true
                    }
                }]
            }
        }
      }
    },
    mounted () {
    	
    },
    methods:{
      ...mapActions({
      	fetchAgendamientosAcumulados: 'indicadores/fetchAgendamientosAcumulados',
      }),
      ...mapMutations({
      }),
      actualizar(){
        this.fetchAgendamientosAcumulados()
        .then(()=>{
          this.data.labels=this.agendamientosAcumulados.labels
          this.data.datasets[0].data=this.agendamientosAcumulados.values
          //this.$data._chart.update()
          this.$refs.chart.update()
          
        })
      }
    },
    computed:{
      ...mapState({
      	agendamientosAcumulados: state => state.indicadores.agendamientosAcumulados, 
      }),
      ...mapGetters({   
      }),
    }
  }
</script>

<style scope>
.chart-container{
  text-align: center;
  margin: auto;
}
	
</style>