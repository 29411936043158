<template>
	<div class="form-group  mb-0">		
		  <select v-if="puedeEditar" v-model="tipo_clase" class="form-control" 
      style="font-size: 12px;height: 25px;">
        <option disabled value="">--Tipo Clase--</option>
        <option v-for="tipo in tipos_clase" :value="tipo.id">{{tipo.nombre}}</option>
        
      </select>
      <div v-else-if="tipo_clase!='regular'" class="text-center tipo-clase">{{ titulo }}</div>
	</div>
</template>


<script>
  import {mapState} from 'vuex';
  export default {
    name: 'ProgramadorSeleccioneTipoClase',
    data () {
      return {
          error: null
      }
    },
    props: {
      clase: Object,
      editando: Boolean
    },
    mounted () {
      
    },
    computed:{
      ...mapState({
        tipos_clase: state => state.asw.tipos_clase, 
      }),
      puedeEditar(){
        if(!this.clase.id){
         return true
        }
        if(this.clase.agendamientos && this.clase.agendamientos.length>0){
          return false
        }
        return this.editando
      },
      tipo_clase: {
        get () {
          return this.clase.tipo_clase
        },
        set (value) {
          this.$emit('change', value);
        }
      },
      tipoClase(){
        if(this.tipo_clase){
          return  this.tipos_clase.find((element)=>{
            return element.id == this.tipo_clase
          })
        }else{
          return null
        }
      },
      titulo(){
        if(this.tipoClase){
          return this.tipoClase.nombre
        }else{
          'No definido'
        }
      }
    }
    
  }
</script>

<style scope>
.tipo-clase{
  text-transform: uppercase;
  color:#fff;
  font-weight: bolder;
}
</style>