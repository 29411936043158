<template>
  <div class="">
    <b-row v-if="vista_ligera" class="progreso mv-0 pt-1" >
      <b-col cols="1">{{consecutivo+1}}</b-col>
      <b-col cols="3">
          <strong>{{textoTema}}</strong> <span> {{textoCurso}}</span>
      </b-col>
      <b-col cols="2" class="text-center">
        <icon v-if="this.progreso_fila.estado=='superado'" class="check-icon" scale="0.6" name="check"></icon>
        <strong v-else>{{textoEstado}}</strong><br>
      </b-col>
      
      <b-col cols="2" class="text-center">
        <a href="#!" @click="verCriterios" v-if="progreso_fila.nota">{{progreso_fila.nota}}/{{progreso_fila.rango_nota}}</a>
        <b-modal  v-model="criterios"  hide-footer id="modalcriterios" title="Criterios">
          <h6>Examen presentado: <strong>{{progreso_fila.examen}}</strong></h6>
          <div v-if="progreso_fila.estructura != null ">
            <div v-for="criterio in progreso_fila.estructura.criterios" class="row">
              <strong class="col-6">{{criterio.nombre}} :</strong> 
              <span v-if="progreso_fila.notas" class="col-6">
                <b-row>
                  <b-col cols="4">
                    <div class="viewNum">{{progreso_fila.notas.respuestas[criterio.codigo]}}</div>
                  </b-col>
                 <b-col cols="6" v-if="progreso_fila.estructura.self_assessment">
                  <i v-if="progreso_fila.notas.respuestas[criterio.codigo+'-self'] == 'bien'" class="viewIcon fa fa-thumbs-o-up" aria-hidden="true"></i>
                  <i v-if="progreso_fila.notas.respuestas[criterio.codigo+'-self'] == 'normal'" class="viewIcon fa fa-hand-paper-o"></i>
                  <i v-if="progreso_fila.notas.respuestas[criterio.codigo+'-self'] == 'mal'" class="viewIcon fa fa-thumbs-o-down" aria-hidden="true"></i>
                 </b-col>
                </b-row>
              </span>
            </div><br>
            <div class="row">
              <h5 class="col-8">
                Nota: {{progreso_fila.nota}}
              </h5>
              
              <div v-if="progreso_fila.notas && !progreso_fila.estructura.self_assessment" class="col-4">
                <span class="col-3">
                  <i v-if="progreso_fila.notas.respuestas['self-general'] == 'bien'" class="viewIcon fa fa-thumbs-o-up" aria-hidden="true"></i>
                  <i v-if="progreso_fila.notas.respuestas['self-general'] == 'normal'" class="viewIcon fa fa-hand-paper-o"></i>
                  <i v-if="progreso_fila.notas.respuestas['self-general'] == 'mal'" class="viewIcon fa fa-thumbs-o-down" aria-hidden="true"></i>
                </span>
              </div>
            
              <h5 v-if="progreso_fila.notas">
                Comentarios: {{progreso_fila.notas.docente_comentarios}}
              </h5>

            </div>
          </div>
        </b-modal>
      </b-col>
      <b-col cols="4" class="text-center">
        <b-button class="m-0 p-0" @click="vista_ligera=false" size="sm" variant="link" >Ver más</b-button>
      </b-col>
    </b-row>
    <b-row v-else class="progreso ">
      <b-col cols="3">
          <span>{{textoCurso}}</span><br>
          <strong>{{textoTema}}</strong><br>
          <span>{{textoSalon}}</span><br>
          <span>{{textoFechaClase}}</span>
      </b-col>
      <b-col cols="2" class="text-center">
        <strong>{{textoEstado}}</strong><br>
        <span>{{textoAsistenciaUsuario}}</span><br>
        <span>{{textoAsistenciaFecha}}</span>
        
       
        <div class="progreso_agendamientos" v-if="progreso_fila.agendamientos && progreso_fila.agendamientos.length>0">
          <strong>Agendamientos abiertos:</strong><br>
          <div class="progreso_agendamiento" v-for="agendamiento in progreso_fila.agendamientos" :key="agendamiento">
            <strong>{{agendamiento.estado}}</strong><br>
            <div v-if="agendamiento.clase && agendamiento.clase.salon">
              <strong>{{agendamiento.clase.salon.nombre}}</strong><br>
            </div>
            <span>{{agendamiento.fecha}} / </span><span>{{agendamiento.hora}}</span><br>
          </div>
          <div v-if="progreso_fila.agendamientos.length>1" class="alert alert-danger mt-2" role="alert">Inconsistencia. {{progreso_fila.agendamientos.length}} agendamientos para el mismo tema.</div>
        </div>

        <div v-if="permiso('8406FF9D') && puedeAnular">
          <button  @click="anulando=true" class="btn btn-danger btn-sm" >Anular Firma</button>
        </div>
        <div v-if="permiso('CE0C87D2') && puedeFirmar">
          <button  @click="firmando=true" class="btn btn-danger btn-sm">Firmar</button>
        </div>
        <div v-if="permiso('5FCCA3CB') && puedeDesbloquear">
          <button  @click="procesarDesbloquear" class="btn btn-danger btn-sm">Desbloquear</button>
        </div>
        <transition name="fade">
          <div class="alert alert-warning mt-2" role="alert" v-if="mensaje">{{mensaje}}</div>
        </transition>

      </b-col>
      
      <b-col cols="2" class="text-center">
        <div v-if="tieneNota && permiso('6B04B5EF')">
          <div v-if="editandoNota">
            <input type="text"
                v-model="nueva_nota"
                name="Nota"
                placeholder="0"
                class="form-control input-nota"
                size="5"
                >
            <strong class="m-3">{{instruccionesNota}}</strong>
            <b-button @click="editandoNota=false" size="sm" variant="danger" >Cancelar</b-button>
            <b-button v-if="puedeEditarNota" @click="procesarEditarNota" size="sm" variant="success" class="m-3" >Guardar</b-button>

          </div>
          <div v-else>
            <strong style="padding: 0px 10px">{{progreso_fila.nota}}/{{progreso_fila.rango_nota}}</strong>  
            <b-button @click="iniciarEditarNota" size="sm" variant="link" >Editar</b-button>
          </div> 
        </div>
      </b-col>
      <b-col cols="5">

        <p>
          {{docent(progreso_fila)}} <br>
          <span style="font-size: 9px;">{{progreso_fila.salon != null ? ubicacion(progreso_fila.salon, progreso_fila.clase_fecha ):""}}</span>
        </p>

        <p class="m-1" v-for="observacion in observaciones">
          {{ observacion.texto}}<span>  {{ observacion.usuario}} / {{ formatearFecha(observacion.fecha)}} </span>
        </p>
        <p v-if="progreso_fila.autorizado_fecha">
          <strong>{{textoAutorizadoUsuario}}</strong><br>
          <span>{{textoAutorizadoFecha}}</span>
        </p>
        <b-button  @click="vista_ligera=true" size="sm" variant="link" >Ver menos</b-button>
        <b-button class="m-0 p-0" @click="cargarObservaciones()" size="sm" variant="link" >Ver histórico</b-button>
      </b-col>
    </b-row>

    <b-modal @ok="procesarAnular" @cancel="motivoAnular=''" v-model="anulando"  id="modalAnular"
    :ok-disabled="motivoAnular.length<motivosMin"  title="Motivo de la anulación">
      <div class="mb-2">
        <b-form-textarea
          id="textarea"
          v-model="motivoAnular"
          placeholder="Cuéntanos, por qué es necesario hacer este proceso manual?"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <pre class="d-block text-right">{{ motivoAnular.length }}/{{ motivosMin }}</pre>
      </div>
    </b-modal>

    <b-modal @ok="procesarFirmar" @cancel="motivoFirmar=''" v-model="firmando"  id="modalFirmar"
    :ok-disabled="motivoFirmar.length<motivosMin"  title="Motivo de la firma">
      <div class="mb-2">
        <b-form-textarea
          id="textarea"
          v-model="motivoFirmar"
          placeholder="Cuéntanos, por qué es necesario hacer este proceso manual?"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <pre class="d-block text-right">{{ motivoFirmar.length }}/{{ motivosMin }}</pre>
      </div>
    </b-modal>
  </div>
</template>
<style>
  .viewIcon{
    padding: 0px 61px 0px;
    font-size:22px !important;
  }
  .viewNum{
    padding: 0px 61px 0px;
    font-size:18px !important;
  }
</style>
<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Icon from 'vue-awesome/components/Icon';
  import 'vue-awesome/icons/check';
  import Vue from 'vue';
  export default {
    name: 'ProgresoRegistro',
    components: {
      Icon
    },
    data () {
      return {
        vista_ligera : true,
        mensaje: null,
        editandoNota: false,
        nueva_nota: null,
        observaciones : null,
        motivosMin: 40,

        motivoAnular: '',
        anulando:false,
        motivoFirmar: '',
        firmando:false,
        criterios: false,
      }
    },
    props: {
      progreso_fila: Object,
      consecutivo: Number
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({
        fetchProgreso: 'progresos/fetchProgreso',
        anular: 'progresos/anular',
        firmar: 'progresos/firmar',
        bloquear: 'progresos/bloquear',
        desbloquear: 'progresos/desbloquear',
        editarNota: 'progresos/editarNota',
      }),
      ...mapMutations({
      }),
      iniciarEditarNota(){
        this.nueva_nota = this.progreso_fila.nota
        this.editandoNota = true
      },
      procesarEditarNota(){
        let data = {
          progreso_id:this.progreso_fila.id,
          nota:this.nueva_nota
        }
        this.editarNota(data).then(
          data =>{
            this.mensaje = data.mensaje
            setTimeout(() => {this.mensaje = null},3000)
            this.editandoNota = false
            this.fetchProgreso({estudiante_id:this.progreso_fila.estudiante_id})
          }
        )
      },
      procesarAnular(){
        let payload = {
          progreso_id:this.progreso_fila.id,
          motivo: this.motivoAnular
        }
        this.anular(payload).then(
          data =>{
            this.mensaje = data.mensaje
            setTimeout(() => {this.mensaje = null},3000)
            this.fetchProgreso({estudiante_id:this.progreso_fila.estudiante_id})
          }
        )
        this.motivoAnular = ''
      },
      procesarFirmar(){
        let payload = {
          progreso_id:this.progreso_fila.id,
          motivo: this.motivoFirmar
        }
        this.firmar(payload).then(
          data =>{
            this.mensaje = data.mensaje
            setTimeout(() => {this.mensaje = null},3000)
            this.fetchProgreso({estudiante_id:this.progreso_fila.estudiante_id})
          }
        )
        this.motivoFirmar = ''
      },
      procesarBloquear(){
        this.bloquear(this.progreso_fila.id).then(
          data =>{
            this.mensaje = data.mensaje
            setTimeout(() => {this.mensaje = null},3000)
            this.fetchProgreso({estudiante_id:this.progreso_fila.estudiante_id})
          }
        )
      },
      procesarDesbloquear(){
        this.desbloquear(this.progreso_fila.id).then(
          data =>{
            this.mensaje = data.mensaje
            setTimeout(() => {this.mensaje = null},3000)
            this.fetchProgreso({estudiante_id:this.progreso_fila.estudiante_id})
          }
        )
      },
      cargarObservaciones(){
        let payload = {
          progreso_id : this.progreso_fila.id
        }
        Vue.http.post('progreso/ver_observaciones',payload).then(
          response =>{
            this.observaciones = response.data
          }
        ).catch(response=>{
          
        }).finally(()=>{

        })
      },
      verCriterios(){
        this.criterios = true;
      },
      ubicacion(salon, fecha){
        var fec = ""
        var ho = "";
        var sede = ""
        if(fecha != null && fecha != ""){
          fec = fecha.split('T')[0]
          ho = new Date(fecha);
          fecha = fec.split("-")[2] + "/"+ fec.split("-")[1] + "/" + fec.split("-")[0] + " " + ho.getHours()+":"+ho.getMinutes()+":"+ho.getSeconds();
        }else{fecha=""}
        if(salon!=null)
          sede = JSON.parse(salon).sede
        else
          sede = ""
        return  sede +" "+ fecha;
      },
      docent(progreso){
        if(progreso && progreso.docente){
          if(progreso.docente != null){
            var docente = JSON.parse(progreso.docente)
            return docente.nombre
          }else{return "";}
        }
        else{return "";}
      },
      formatearFecha(fecha) {
          return this.$moment(fecha, 'MM/DD/YYYY, h:mm:ss a').format('DD/MM/YYYY HH:mm:ss');
      }

    },
    computed: {
      ...mapState({
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      
      textoEstudiante(){
        let texto = ''
        if(this.progreso){
          texto += this.progreso.primer_nombre?this.progreso.primer_nombre:''
          texto += this.progreso.segundo_nombre?' '+this.progreso.segundo_nombre:''
          texto += this.progreso.primer_apellido?' '+this.progreso.primer_apellido:''
          texto += this.progreso.segundo_apellido?' '+this.progreso.segundo_apellido:''
        }
        return texto
      },

      textoFechaClase(){
        if(this.progreso_fila && this.progreso_fila.clase_fecha){
          let clase_fecha = new Date(this.progreso_fila.clase_fecha).toLocaleString()
          return clase_fecha
        }
        return ''
      },
      textoSalon(){
        if(this.progreso_fila && this.progreso_fila.salon){
          let salon = JSON.parse(this.progreso_fila.salon)
          let texto = (salon.sede +' / '+salon.nombre)
          return texto
        }
        return ''
      },
      textoCurso(){
        if(this.progreso_fila && this.progreso_fila.curso){
          let texto = this.progreso_fila.curso
          return texto
        }
        return ''
      },
      textoTema(){
        if(this.progreso_fila && this.progreso_fila.tema){
          
          let texto = this.progreso_fila.tema.nombre
          return texto
        }
        return ''
      },
      textoAsistenciaFecha(){
        if(this.progreso_fila && this.progreso_fila.asistencia_fecha){
          let asistencia_fecha = new Date(this.progreso_fila.asistencia_fecha).toLocaleString()
          return asistencia_fecha
        }
        return ''
      },
      textoAsistenciaUsuario(){
        if(this.progreso_fila && this.progreso_fila.asistencia_usuario){
          let asistencia_usuario = JSON.parse(this.progreso_fila.asistencia_usuario)
          let texto = asistencia_usuario.nombre
          return texto
        }
        return ''
      },
      textoAutorizadoFecha(){
        if(this.progreso_fila && this.progreso_fila.autorizado_fecha){
          let autorizado_fecha = new Date(this.progreso_fila.autorizado_fecha).toLocaleString()
          return autorizado_fecha
        }
        return ''
      },
      textoAutorizadoUsuario(){
        if(this.progreso_fila && this.progreso_fila.autorizado_usuario){
          let autorizado_usuario = JSON.parse(this.progreso_fila.autorizado_usuario)
          let texto = autorizado_usuario.nombre
          return texto
        }
        return ''
      },
      textoEstado(){
        if(this.progreso_fila){
          if(this.progreso_fila.estado=='asistio'){
            return 'Asistió'
          }else if(this.progreso_fila.estado=='no_asistio'){
            return 'No asistió'
          }else if(this.progreso_fila.estado=='en_curso'){
            return 'En curso'
          }else if(this.progreso_fila.estado=='en_espera'){
            return 'En espera'
          }else if(this.progreso_fila.estado=='bloqueado'){
            return 'Bloqueado'
          }else if(this.progreso_fila.estado=='autorizado'){
            return 'Autorizado'
          }else if(this.progreso_fila.estado=='superado'){
            return 'Superado'
          }else if(this.progreso_fila.estado=='pendiente'){
            if(this.progreso_fila.agendamientos && this.progreso_fila.agendamientos.length>0){
              return 'Agendado'
            }else{
              return 'Pendiente'
            }
            
          }

        }
        return ''
      },
      
      textoConformidadFecha(){
        if(this.progreso_fila && this.progreso_fila.conformidad_fecha){
          let conformidad_fecha = new Date(this.progreso_fila.conformidad_fecha).toLocaleString()
          return conformidad_fecha
        }
        return 'Pendiente'
      },
      puedeAnular(){
        //TODO - VERIFICAR PERSMISO
        if(this.progreso_fila){
          if(this.progreso_fila.estado == 'superado' && this.progreso_fila.tipo != 'autonomo'){
            return true
          }
          
        }
        return false
      },
      puedeFirmar(){
        //TODO - VERIFICAR PERSMISO
        if(this.progreso_fila){
          if(this.progreso_fila.estado == 'pendiente' || this.progreso_fila.estado == 'bloqueado'){
            return true
          }
          
        }
        return false
      },
      puedeBloquear(){
        //TODO - VERIFICAR PERSMISO
        if(this.progreso_fila){
          if(this.progreso_fila.estado == 'pendiente'){
            return true
          }
          
        }
        return false
      },
      puedeDesbloquear(){
        //TODO - VERIFICAR PERSMISO
        if(this.progreso_fila){
          if(this.progreso_fila.estado == 'bloqueado'){
            return true
          }
          
        }
        return false
      },
      tieneNota(){
        if(this.progreso_fila && this.progreso_fila.rango_nota && this.progreso_fila.peso_nota){
          return true
        }
        return false
      },
      puedeEditarNota(){
        if(this.nueva_nota && this.nueva_nota >= 0 
          && this.nueva_nota != this.progreso_fila.nota
          && this.nueva_nota <= this.progreso_fila.rango_nota
          ){
          return true;
        }
        
        return false
      },
      instruccionesNota(){
        return '0-'+this.progreso_fila.rango_nota
      },
      
    }
  }
</script>

<style scope>
.progreso {
    border-top: 1px solid #bbb;
    font-size:14px;
    line-height: 14px;
    padding-top: 10px;
}
.progreso  strong{}
.progreso  span{
  font-size: 10px;
  line-height: 10px;
  color:#888;
  font-style: oblique;
  
}
.progreso .btn{
  margin-top:5px;
  margin-bottom:5px;
}
.progreso_agendamientos{}

.progreso_agendamiento{
  padding: 5px;
  margin-top: 5px;
  border-top: 1px solid #ddd;
}
</style>