<template>
    <div class="container  mt-3">
      <div class="m-auto">
          
        <div class="form-horizontal">
          
            <div class="form-group row">
              <label class="col-3 col-form-label">Nivel</label>
              <div class="col-3">
                <select v-model="nivel_id" class="form-control">
                  <option disabled value="">Please select one</option>
                  <option v-if="opciones" v-for="opcion in lista" :value="opcion.id">{{ opcion.descripcion }}</option>
                </select>
              </div>
              <div class="col-3">
                <button v-if="procesable" class="btn btn-block btn-danger" @click="procesar">
                  Activar Nivel
                </button> 
              </div>  
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
    export default {
      name: 'DetalleNivelActual',
      components: {
        
      },
      data () {
        return {
          nivel_id:null,
        }
      },
      props: {
        estudiante: Object
      },
      mounted () {
        if(!this.opciones || (this.opciones && this.opciones.length==0)){
          this.fetchCursos()
        }
        if(this.estudiante){
          this.nivel_id = this.estudiante.nivel_id  
        }else{
          console.log('Hay que asegurarse de tener un estudiante antes de utilizar el componente DetalleCursoActual')
        }
      },
      methods:{
        ...mapActions({
            activarNivel: 'estudiantes/activarNivel',
            fetchCursos: 'cursos/fetchLista',
            fetchDetalle: 'estudiantes/fetchDetalle',
            setWarning: 'setWarning',
        }),
        ...mapMutations({
          
        }),
        procesar(){
          
          this.activarNivel({
            id:this.estudiante.id,
            nivel_id:this.nivel_id,
          }).then((apiResponse)=>{
            
            this.limpiar()
            this.setWarning('Nivel Activo', { root: true }).then(()=>{
              this.fetchDetalle(this.estudiante.id).then(()=>{
                this.$router.push('/estudiante/detalle/'+this.estudiante.id)
              }) 
            })
            
            
          })
        },
        limpiar(){
          this.nivel_id = null
        }
      },
      computed:{
        ...mapState({
          opciones: state  => state.niveles.lista,
        }),
        ...mapGetters({
          
        }),
        procesable: {
          get () {
            return (this.nivel_id && this.nivel_id != this.estudiante.nivel_id)
          },
          set(value){}
        },
        lista() {
          if (this.estudiante && this.estudiante.niveles && this.estudiante.niveles.length) {
            return this.estudiante.niveles.sort((a,b) => a.id - b.id);
          } else  if (this.opciones) {
            return this.opciones.sort((a,b) => a.id - b.id);
          }
          return [];
        },
      }
    }
  </script>
  
  <style scope>
    .programar_wrapper .form-container{
        
    }
      .programar_wrapper{
      
      }
  </style>
  
  