<template>
  <div class="">

    <div class="container pt-3 m-auto text-left ">
      <h1>Nuevo Salon</h1>
       <hr>
      <form
        autocomplete="off"
        class="form-horizontal"
        @submit.prevent="validateBeforeSubmit"
      >
        <div>
          <InputTextSimple titulo="Nombre" :modelo="salon.nombre" 
            @change="salon.nombre = $event"/>
          <!-- //HFV - CAMPO OBSOLETO
          <InputTextSimple titulo="Numero" :modelo="salon.numero" 
            @change="salon.numero = $event"/>
          -->
          <InputNumero titulo="Capacidad" :modelo="salon.capacidad" 
            @change="salon.capacidad = $event"/>
         <!-- //HFV - CAMPO OBSOLETO
          <SelectTipoClase titulo="Tipo Salon" :modelo="salon.tipo_salon" 
            @change="salon.tipo_salon = $event"/>
          -->
          <SelectActivo titulo="Activo" :modelo="salon.activo ? salon.activo.toString() : '0'" 
            @change="salon.activo=$event"/>

        </div>
        <button class="btn btn-block btn-danger" type="submit">Crear</button>
      </form>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'SalonNuevo',
    data () {
      return {
          salon : {
            nombre:null,
            numero:null,
            capacidad:null,
            tipo_salon:null,
            activo:null,
          }
      }
    },
    mounted () {
    },
    methods:{
      ...mapActions({
        crear: 'salones/crear',
        setWarning: 'setWarning',
        fetchLista: 'salones/fetchLista',
      }),
      ...mapMutations({
      }),
      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {
          if ( ! result) {
            //hay errores
          } else {
            this.crear(this.salon).then(()=>{
                this.setWarning('Salon Creado', { root: true }).then(()=>{
                this.fetchLista().then(()=>{
                    this.$router.push('/salones')
                })
              })
            }).catch(error=>{
              //todo probar si se puede quitar
            })
            
          }
        })
      },

    },
    computed:{
      ...mapState({
        warning: state => state.warning
      }),
    }
  }
</script>

<style scope>
  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>