<template>
	<b-container fluid>
  	<div style="text-align: center;">
      <img class="logo-main" src="../../assets/blocks.jpeg">
    </div>
    <DashboardDisponibilidad></DashboardDisponibilidad>
  		<!--
      
      <DashboardAgendamientosAcumulados></DashboardAgendamientosAcumulados>
  		<DashboardAgendamientosEstudiantes></DashboardAgendamientosEstudiantes>
      <DashboardEstudiantesActivos></DashboardEstudiantesActivos>
    -->
	</b-container>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import DashboardDisponibilidad from '@/components/Dashboard/DashboardDisponibilidad';
  import DashboardEstudiantesActivos from '@/components/Dashboard/DashboardEstudiantesActivos';
  import DashboardAgendamientosAcumulados from '@/components/Dashboard/DashboardAgendamientosAcumulados';
  import DashboardAgendamientosEstudiantes from '@/components/Dashboard/DashboardAgendamientosEstudiantes';
  
  export default {
    name: 'DashboardContainer',
    components:{
      DashboardDisponibilidad,
		  DashboardEstudiantesActivos,
		  DashboardAgendamientosAcumulados,
      DashboardAgendamientosEstudiantes,
    },
    data(){
      return {

      }
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({
        
      }),
      ...mapMutations({
      }),
    },
    computed:{
      ...mapState({
        
      }),
      ...mapGetters({   
      }),
    }
  }
</script>

<style scope>

	.logo-main{
    width:500px;
    margin: 50px auto;
    text-align: center;
  }
</style>