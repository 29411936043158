<template>
  <span class="m-2">
      <b-badge class="resumen-badge" v-if="reporte" :variant="variant"><span>{{nombre}}:   </span><strong>{{totalOcupacion}}/{{totalCapacidad}}</strong>
<b-badge class="ml-1" v-if="posibles" variant="light">{{posibles}}</b-badge>
      </b-badge>
  </span>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';

  export default {
    name: 'ProgramadorInformeEstadoTipoResumen',
    components: {
      
    },
    data () {
      return {
        
      }
    },
    props : {
      reporte: Object,
      nombre: String
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({
      }),
      ...mapMutations({
      }),
      
      
    },
    computed: {
      ...mapState({
        
      }),
      totalCapacidad(){
        return (this.reporte.capacidad_salones_disponibles*1) + (this.reporte.capacidad_salones_ocupados*1)
      },
      totalOcupacion(){
        let ocupacion = (this.reporte.ocupacion_salones_ocupados*1)
        if(this.reporte.ocupacion_sin_asignar){
          ocupacion += (this.reporte.ocupacion_sin_asignar*1)
        }
        return ocupacion
      },
      totalDisponibilidad(){
      	return this.totalCapacidad-this.totalOcupacion
      },
      posibles(){
        if(this.reporte && this.reporte.salones_disponibles){
          return this.reporte.salones_disponibles.length
        }
       return null
      },
      classAlert(){
      	if(this.totalCapacidad<=0){
      		return 'alert-disponibilidad-error'
      	}else if(this.totalDisponibilidad<=0){
      		return 'alert-disponibilidad'
      	}
      	return ''
      },
      variant(){
        if(this.totalCapacidad==0){
          return 'danger'
        }else if(this.totalDisponibilidad<0){
          return 'warning'
        }else if(this.totalDisponibilidad==0){
          return 'success'
        }else if(this.totalDisponibilidad>0){
          return 'info'
        }
        return 'secondary'
      }
    }
  }
</script>

<style scope>
.alert-disponibilidad-error{
	background-color: red;
	padding: 2px;
}
.alert-disponibilidad{
	background-color: green;
	padding: 2px;
	color: #fff;
}
.resumen-badge{
  font-size: 80% !important;
}
.resumen-badge .resumen-posibles{
  font-size: 10px;
  font-style: italic;
}
</style>