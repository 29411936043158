<template>
  <div>
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_cursos"></b-navbar-toggle>
        <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Cursos</h1>
        <b-collapse is-nav id="nav_collapse_cursos">
          <!-- Right aligned nav items -->
          <!--
          <b-navbar-nav class="ml-auto">
            <router-link class="nav-link" to="/curso/nuevo" >Nuevo</router-link>
          </b-navbar-nav>
          -->
        </b-collapse>
      </div>
    </b-navbar>
    <div class="container cursos_wrapper mt-3">
      <div v-if="lista" class="tabla">
        <v-client-table name="cursosTable" :columns="columns" :data="lista" :options="options">
          <div slot="accion" slot-scope="lista"><a :href="`#/curso/detalle/${ lista.row.id }`" class="btn btn-secondary btn-sm" title="Ver">Ver</a></div>
        </v-client-table>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'CursoLista',
    data () {
      return {
        columns: ['id', 'codigo',  'nombre', 'activo', 'accion'],
        options: {
          filterable: true,
          filterByColumn: false,
          sortable:[],
          perPage: 10,
          perPageValues: [5, 10, 15, 20],
          headings: {
            id: 'Id',
            codigo: 'Codigo',
            nombre: 'Nombre',
            activo: 'Activo',
            accion: 'Accion'
          }
        }
      }
    },
    mounted () {
      if(!this.lista || (this.lista && this.lista.length==0)){
        this.fetchLista()
      }
    },
    methods:{
      ...mapActions({
        fetchLista: 'cursos/fetchLista',
      }),
      ...mapMutations({
        
      }),
      edit(){

      }
    },
    computed: {
      ...mapState({
        lista: state => state.cursos.lista, 
      }),
    }
  }
</script>

<style scope>

</style>
