<template>
	<div class="form-group row">
		<label class="col-3 col-form-label">{{ titulo }}:</label>
		<div class="col-9">
            <b-form-select  v-model="_modelo" multiple class="select-tipo">
             <!--   <option value="0">seleccione ...</option> -->
                <option v-for="curso in cursos" :key="curso.nombre" :value="curso.id">{{ curso.nombre }}</option>
            </b-form-select>
         

		</div>
	</div>
</template>


<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';

  export default {
    name: 'SelectCursoAll',
    data () {
      return {
          error: null,
          curso_id:0,
          metodo_id:0,
          sede_id:0
      }
    },
    props: {
		titulo: String,
		modelo: Array
    },
    mounted () {

            this.fetchCursos()
    },
    methods:{
         ...mapActions({

        fetchCursos: 'asw/fetchCursos',


              }),
    }
    ,
    computed:{
		_modelo: {
			get () {
				return this.modelo
			},
			set (value) {
				this.$emit('change', value);
			}
        },
      ...mapState({

        cursos:  state=> state.asw.cursos,

      }),
    }
    
  }
</script>
