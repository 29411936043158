<template>
	<div class="form-group row">
		<label class="col-3 col-form-label">{{ titulo }}:</label>
		<div class="col-9">
			<date-picker  class="date-picker" v-model="_modelo" lang="es" type="date" 
            :time-picker-options="timePickerOptions" :clearable="true"></date-picker>
		</div>
	</div>
</template>


<script>
import DatePicker from 'vue2-datepicker'
  export default {
	components: {
      DatePicker
    },
    name: 'InputFecha',
    data () {
      return {
          error: null,
		  timePickerOptions:{}
      }
    },
    props: {
		titulo: String,
		modelo: Date
    },
    
    computed:{
		_modelo: {
			get () {
				return this.modelo
			},
			set (value) {
				this.$emit('change', value);
			}
		},
    }
    
  }
</script>

<style scope>

</style>