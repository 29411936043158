<template>

    <div class="container programaciones_wrapper">
      <b-button v-b-toggle.collapseCambioNivel variant="link">Cambios de Niveles</b-button>

      <b-collapse id="collapseCambioNivel">
        
        <div class="text-center mb-3 mt-3 row">
          <div class="col-md-2"><small><strong>Creado por</strong></small></div>
          <div class="col-md-2"><small><strong>Respondido por</strong></small></div>
          <div class="col-md-3"><small><strong>Curso Anterior</strong></small></div>
          <div class="col-md-3"><small><strong>Curso Siguiente</strong></small></div>
          <div class="col-md-2"><small><strong>Avance Nivel</strong></small></div>
        </div>
        <DetalleCambioNivelesItem v-for="(item, index_2) in cambios_niveles" :key="'CN'+index_2"
      :item="item" :estudiante="estudiante"/>
      
    </b-collapse>
    </div>
  </template>
  
  <script>
    import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
    import DetalleCambioNivelesItem from '@/components/Estudiantes/DetalleCambioNivelesItem';
    export default {
      name: 'DetalleCambioNiveles',
      components: {
        DetalleCambioNivelesItem
      },
      data () {
        return {
          cambios_niveles:null
        }
      },
      props: {
        estudiante: Object
      },
      mounted () {
        console.log(this.estudiante.cambios_niveles);
        if(this.estudiante.cambios_niveles === undefined ){
          console.log('DetalleCambioNiveles undefined')
          this.fetchCambioNiveles(this.estudiante.id).then((result) => {
            this.cambios_niveles = result.cambios_niveles;
          })
        } else {
          this.cambios_niveles = this.estudiante.cambios_niveles;
        }
        
      },
      methods:{
        ...mapActions({
          fetchCambioNiveles: 'estudiantes/fetchCambioNiveles',
          fetchDetalle: 'estudiantes/fetchDetalle',
          cancelar: 'programacion/cancelar',
        }),
        ...mapMutations({
          
        })
  
      },
      computed: {
        ...mapState({
          
        }),
        ...mapGetters({
  
        }),
        
      }
    }
  </script>
  
  <style scope>
  
      .programaciones_wrapper .tabla{
        
      }
  </style>