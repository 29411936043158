<template>
	<div class="form-group mb-0">
    <div v-if="puedeMostrar">
			<select v-if="puedeEditar" v-model="curso_id" class="form-control" 
      style="font-size: 12px;height: 25px;">
        <option disabled value="-1">--Curso--</option>
        <option v-if="opciones" v-for="opcion in opciones" :value="opcion.id">{{ opcion.nombre }}</option>
      </select>
      <div v-else class="text-center p-0 curso">
        <strong>{{ curso.nombre }}</strong>
      </div>
    </div>
	</div>
</template>

<script>
  import {mapState} from 'vuex';
  export default {
    name: 'ProgramadorSeleccioneCurso',
    data () {
      return {
        error: null,
      }
    },
    props: {
      clase: Object,
      opciones: Array,
      editando: Boolean
    },
    mounted () {
    },
    methods:{
    },
    computed:{
      ...mapState({
        cursos: state  => state.asw.cursos,
      }),
      puedeEditar(){
        if(!this.clase.id){
         return true
        }
        if(this.clase.agendamientos && this.clase.agendamientos.length>0){
          return false
        }
        return this.editando
      },
      puedeMostrar(){
        if(this.clase.tipo_clase=='regular'){
          return true
        }
        return false
      },
      curso_id: {
        get () {
          return this.clase.curso_id
        },
        set (value) {
          this.$emit('change', value);
        }
      },
      curso() {
        if(this.cursos){
          let obj = this.cursos.find(element =>{
            return element.id == this.curso_id
          })
          return obj
        }
        return {nombre:'cargando...'}
      }
    }
  }
</script>

<style scope>
.curso{
  color: #fff;
}
</style>