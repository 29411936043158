<template>
  <div class="">
  	
    <div v-if="estado">
      
	  	<b-row  v-if="tipos">
	        <b-col cols="1" class="text-right">{{hora.nombre_corto}}</b-col>
	        <b-col class="celda text-center" v-for="(tipo, t_index) in tipos" :key="t_index">
	        	<!--
	        	<div :style="{color:'#fff',backgroundColor:'#'+tipo.color}" >
	        		{{estado[tipo.id].disponible}}
	        	</div>
	        -->

	       		<div class="celda-content" :style="{backgroundColor:variant(estado[tipo.id])}" v-if="estado[tipo.id]">
	        		{{estado[tipo.id].disponible}}/{{capacidad(estado[tipo.id])}}
	        	</div>
	        </b-col>
          <b-col class="celda text-center" v-if="indicadoresFranja">
            <div class="celda-content" style="background-color: #000" >
              {{indicadoresFranja}}
            </div>
          </b-col>
	         <!--
	        <b-col>
	         	<button class="pt-0 pb-0 mt-1 mr-1 btn btn-dark" 
            @click="procesarActualizar()" title="Actualizar"><icon  name="sync-alt"></icon></button>
        	</b-col>
        -->
	  	</b-row>
      
 	</div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';
  import Icon from 'vue-awesome/components/Icon'
  import 'vue-awesome/icons/sync-alt'

  export default {
    name: 'DashboardDisponibilidadFranja',
    components: {
    	Icon
      
    },
    data () {
      return {
        updateTime: null,
      }
    },
    props : {
      dia: Object,
      hora: Object, 
    },
    mounted () {
      this.procesarActualizar()
    },
    methods:{
      ...mapActions({
      	fetchDisponibilidad: 'estado/fetchDisponibilidad',
      }),
      ...mapMutations({
      }),
      procesarActualizar(){
      	this.fetchDisponibilidad({dia:this.dia.fecha,hora:this.hora.hora})
      	/*
        Vue.http.post('estado/disponibilidad_full',{dia:this.dia.fecha,hora:this.hora.hora})
        .then((response)=>{
          this.estado = response.data
          this.updateTime = new Date()
        })
        .catch(error=>{
          this.setWarning(error)
          console.log(error)
        })
        */
      },
      capacidad(estado){
      	return estado.capacidad_salones_disponibles + estado.capacidad_salones_ocupados
      },
      variant(estado){
        if(estado){
          let totalCapacidad = estado.capacidad_salones_disponibles + estado.capacidad_salones_ocupados
          if(totalCapacidad==0){
            return 'red'
          }else if(estado.disponible==0){
            return 'green'
          }else if(estado.disponible<0){
            return 'orange'
          }else if(estado.disponible>0){
            return 'blue'
          }
        }
      	
        return 'gray'
      }
      
    },
    computed: {
      ...mapState({
        tipos: state => state.asw.tipos,
        disponibilidad: state => state.estado.disponibilidad,
        user: state => state.auth.user,
      }),
      ...mapGetters({
        getDisponibilidadXFranja: 'estado/getDisponibilidadXFranja', 
      }),
      updateTimeFormated(){
        if(this.updateTime){
          return this.updateTime.toLocaleString('co-ES')  
        }
        return null
      },
      estado(){
        if(this.dia && this.hora && this.hora.hora){
        	let key = this.user.data.sede_id+':'+this.dia.fecha+':'+this.hora.hora
          //console.log(key)
          if(this.disponibilidad[key]){
            let respuesta =  this.disponibilidad[key]
            return respuesta  
          }
        	return false
        }
		return false
        
      },
      indicadoresFranja(){
        if(this.estado){
          let o = this.estado.ocupacion_salones_ocupados_franja
          let cs = this.estado.capacidad_salones_ocupados_franja
          let cd = this.estado.capacidad_docentes_disponibles_franja
          let ct = cs+cd
          let r = ct>0?(o/ct*100):0
          r = Math.round(r)
          return o+'/'+ct+ ' ('+r+'%)'
        }
        return false
      }

      
    }
  }
</script>

<style scope>
.pendientes-container{

}
.celda{
	font-size: 12px;
	color:#ddd;
	padding: 0px !important;
    margin: 1px;
    font-weight: bold;
}
.marcatiempo{
  font-size: 12px;
  color:#999;
}
.small{
  font-size: 10px !important;
}
.minibutton{
  font-size: 12px !important;
  padding: 0px;
}
</style>