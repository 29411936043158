import Vue from 'vue';

const state = {
  disponibilidad: {},
};

const actions = {
  fetchDisponibilidad:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('estado/disponibilidad_full',payload).then(
        response =>{
          commit('setDisponibilidad',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error.data, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  

};

const getters = {
	getDisponibilidadXFranja: (state) => (sede_id,fecha,hora) => {
		let key = sede_id+':'+fecha+':'+hora
		if(!state.disponibilidad[key]){
			Vue.set(state.disponibilidad, key, null)
		}
	    return state.disponibilidad[key]
	  },
};

const mutations = {
  setDisponibilidad: (state, data) => {
  	let key = data.key;
    Vue.set(state.disponibilidad, key, data)
  },
  restart: (state) => {
    state.disponibilidad = {}
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
