<template>
	<div class="form-group  mb-0">	
  
      <b-form-select v-if="puedeEditar" multiple :select-size="4" v-model="tipos" :options="options" class="select-tipo">
      </b-form-select>
<!--
      <div v-else class="text-center tipo-clase">{{ titulo }}</div>
    -->
	</div>
</template>


<script>
  import {mapState} from 'vuex';
  export default {
    name: 'ProgramadorSeleccioneTipos',
    data () {
      return {
          error: null
      }
    },
    props: {
      clase: Object,
      editando: Boolean
    },
    mounted () {
      
    },
    computed:{
      ...mapState({
        tipos_clase: state => state.asw.tipos_clase, 
        posibles_tipos: state => state.asw.tipos, 
      }),
      puedeEditar(){
        if(!this.clase.id){
         return true
        }
        if(this.clase.agendamientos && this.clase.agendamientos.length>0){
          return false
        }
        return this.editando
      },
      tipos: {
        get () {
        	if(this.clase.tipos){
        		return this.clase.tipos
        	}else{
        		return []
        	}
        },
        set (value) {
          this.$emit('change', value);
        }
      },
     
      titulo(){

        if(this.clase.nombre){
          return this.clase.nombre
        }else{
          'No definido'
        }
      },
      options(){
        let mapped = this.posibles_tipos.map((element)=>{
          return {
            value: element.id,
            text: element.nombre
          }
        })
        return mapped;
      }
    }
    
  }
</script>

<style scope>
.tipo-clase{
  text-transform: uppercase;
  color:#fff;
  font-weight: bolder;
}
.select-tipo{
  font-size: 12px !important;
  padding: 0px 5px !important;
}
</style>