<template>
  <div>
    <div class="container-fluid docentes_wrapper m-3 p-3">
    	<div class="row">
    		<h4>Resumen de docentes</h4>
    	</div>
      
      <strong>Filtro</strong>
      
      <div class="row pb-3">
        

        <b-row class="w-100">
            <b-col cols="1">
              <b-row style="height: 20px"></b-row>
              <b-row style="height: 20px" v-for="(h,index) in horas" :key="index">
                <b-col class="text-center">

                  <b-form-checkbox
                    :id="'seleccionarFilaFiltro'+index"
                    @change="seleccionarFilaFiltro(index+1)"
                    v-model="filtroFilas[index]"
                    :text="h"
                  >{{h}}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-col>


            <b-col cols="10">
              <b-row>
                  <b-col class="celda-head text-center" v-for="(n, index) in 5" :key="index">
                      {{ disponibilidad_general.dias[n].nombre }}
                  </b-col>
              </b-row>
              <b-row style="height: 20px" v-for="(hora,index) in disponibilidad_general.dias[1].horas" :key="index">
                <b-col class="text-center" v-for="(n,j) in 5" :key="j">
                  <b-form-checkbox
                    :id="'checkbox'+disponibilidad_general.dias[n].horas[index].id"
                    v-model="filtro[disponibilidad_general.dias[n].horas[index].id]"
                    name="checkbox-1"
                    :title="disponibilidad_general.dias[n].horas[index].id"
                  >
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col class="text-center" v-for="(text,index) in ['L','M','M','J','V']" :key="index">
                  <b-form-checkbox
                    :id="'checkboxSeleccionarColumna'+index"
                    @change="seleccionarColumnaFiltro(index+1)"
                    :title="text"
                    v-model="filtroColumnas[index]"
                  >
                  </b-form-checkbox>

                </b-col>  
              </b-row>
              
            </b-col>
            
            <b-col cols="1">
              <b-row>
                  <b-col class="celda-head text-center">
                      {{ disponibilidad_general.dias[6].nombre }}
                  </b-col>
              </b-row>
              <div v-for="(hora,index) in disponibilidad_general.dias[6].horas" :key="index">
                <b-row style="height: 20px">
                
                  <b-col class="text-center">
                    
                    <b-form-checkbox
                      :id="'checkbox'+disponibilidad_general.dias[6].horas[index].id"
                      v-model="filtro[disponibilidad_general.dias[6].horas[index].id]"
                      name="checkbox-1"
                      :title="hora.hora"
                    >
                      
                    </b-form-checkbox>

                  </b-col>
                </b-row>
              </div>

              <b-col class="text-center mt-2">
                    <b-form-checkbox
                      :id="'checkboxSeleccionarColumna6'"
                      @change="seleccionarColumnaFiltro(6)"
                      title="Sabados"
                      v-model="filtroColumnas[5]"
                    >
                    </b-form-checkbox>

              </b-col> 

            </b-col>

        </b-row>

        <b-button size="sm"  @click="aplicarFiltro">Aplicar Filtro</b-button>

      </div>

      <div class="row pb-3">
        <strong>Total docentes activos:{{docentesVisibles.length}}</strong>
      </div>

      <div v-for="(docente, index) in docentesVisibles" :key="index">
        <div  class="row">
        	<div class="tipo-titulo">
        		<a :href="`#/docente/detalle/${ docente.id }`" v-if="permiso('42C7BC3F')" >
        		{{docente.titulo}}</a>
          </div>

        	<div class="botones" v-for="(tipo, j) in tipos" :key="j">		
          		<button :title="tipo.nombre" class="pt-0 pb-0 mt-1 mr-1 btn btn-success" 
                    v-if="docente[tipo.id]">
                      {{ tipo.nombre_corto }}  
              </button>
              <button :title="tipo.nombre" class="pt-0 pb-0 mt-1 mr-1 btn btn-dark" v-else>
                	{{ tipo.nombre_corto }}  
              </button>  
        	</div>
      	  <div class="botones">
          		<button :class="{ 'btn-danger': docente.preasignaciones.length<docente.disponibilidad.length }"
              class="pt-0 pb-0 mt-1 mr-1 btn btn-info" title="Bloques semanales">
                {{ docente.preasignaciones.length }} / {{ docente.disponibilidad.length }}  
                ({{ docente.indice_preasignacion }}%)
              </button>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <h4>Indice de configuración:  {{ indicePreasignacionGeneral }}%</h4>
      </div>
    </div>
    
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue'

  export default {
    name: 'DocenteTipos',
    data () {
      return {
        filtro:{},
        filtroFilas:[true,true,true,true,true,true,true,true,true,true],
        filtroColumnas:[true,true,true,true,true,true],
        docentesVisibles:[],
        horas:['06:00:00','07:30:00','09:00:00','10:40:00','12:10:00','13:40:00','15:10:00','16:50:00','18:20:00','19:50:00'],
      }
    },
    mounted () {
      if(!this.docentes || (this.docentes && this.docentes.length==0)){
        this.fetchLista().then(()=>{
          this.docentesVisibles = this.docentesActivos
          console.log('inicializa docentes visibles')
        })
      }

      this.inicializarFiltro()

    },
    methods:{
      ...mapActions({
        fetchLista: 'docentes/fetchLista',
      }),
      ...mapMutations({
        
      }),
      edit(){

      },
      actualizar(){
        this.fetchLista()
      },
      aplicarFiltro(){
        let filtroActivo = []
        for(let property in this.filtro){
          if(this.filtro[property]==true){
            filtroActivo.push(property)
          }
        }
        this.docentesVisibles = this.docentesActivos.filter((docente)=>{
          for(let marca of filtroActivo){
            //console.log(marca)
            if(docente.disponibilidad.indexOf(marca)>=0){
              //console.log('docente:'+docente.id+' tiene:'+marca)
              return true
            }
          }
          return false
        })
      },
      seleccionarFilaFiltro(fila){
        console.log('seleccionarFilaFiltro:'+fila)
        switch(fila){
          case 1: this.borrarFiltroHora('06:00:00',fila); break;
          case 2: this.borrarFiltroHora('07:30:00',fila); break;
          case 3: this.borrarFiltroHora('09:00:00',fila); break;
          case 4: this.borrarFiltroHora('10:40:00',fila); break;
          case 5: this.borrarFiltroHora('12:10:00',fila); break;
          case 6: this.borrarFiltroHora('13:40:00',fila); break;
          case 7: this.borrarFiltroHora('15:10:00',fila); break;
          case 8: this.borrarFiltroHora('16:50:00',fila); break;
          case 9: this.borrarFiltroHora('18:20:00',fila); break;
          case 10: this.borrarFiltroHora('19:50:00',fila); break;
        }
      },
      borrarFiltroHora(hora,index){
        console.log('borrarFiltroHora:'+hora)
        for(let property in this.filtro){
          if(property.split('T')[1]==hora){
           Vue.set(this.filtro,property,!this.filtroFilas[index-1])
          }
        }
      },
      seleccionarColumnaFiltro(col){
        console.log('seleccionarColumnaFiltro:'+col)
        this.borrarFiltroDia(col)
      },
      borrarFiltroDia(dia){
        for(let property in this.filtro){
          if(property.split('T')[0]==dia){
            Vue.set(this.filtro,property,!this.filtroColumnas[dia-1])
          }
        }
      },
      inicializarFiltro(){

        for (var i = 1; i <= 6; i++) {
          this.disponibilidad_general.dias[i].horas.forEach(hora=>{
            Vue.set(this.filtro, hora.id, true)
          })
        }
        
      }


    },
    computed: {
      ...mapState({
        docentes: state => state.docentes.lista, 
        tipos: state => state.asw.tipos,
        disponibilidad_general: state => state.asw.disponibilidad_general,
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      docentesActivos(){
      	if(this.docentes){
      		let docentes_activos = this.docentes.filter(docente=>{
      			return docente.activo==1
      		})
      		
      		docentes_activos = docentes_activos.map((docente)=>{

            let disponibilidad = docente.disponibilidad?JSON.parse(docente.disponibilidad):[]
            let indice_preasignacion = disponibilidad.length>0?docente.preasignaciones.length/disponibilidad.length*100:0
	          let newDocente = {
	            id: docente.id,
	            activo: docente.activo,
	            titulo: docente.primer_nombre+' '+docente.primer_apellido,
	            tipos_permitidos: docente.tipos_permitidos,
	            disponibilidad: docente.disponibilidad?JSON.parse(docente.disponibilidad):[],
              salon: docente.salon,
	            preasignaciones: docente.preasignaciones,
              indice_preasignacion: Math.round(indice_preasignacion)
	          }
	          for(let i in this.tipos){
	          	let tipo = this.tipos[i]
	          	newDocente[tipo.id] = docente.tipos_permitidos.includes(tipo.id)
	          }
	          return newDocente
	        })
			
      		return docentes_activos
      	}else{
      		return []
      	}
      },
      /*
      docentesFiltrados(){
        let lista = this.docentesActivos.filter(docente=>{
          for(let property in this.filtro){
            console.log(property)
            if(docente.disponibilidad.indexOf(property)>=0){
              return true
            }
          }
          return false
        })
        return lista
      },
      */
      
      indicePreasignacionGeneral(){
        let suma = 0
        this.docentesActivos.forEach(element=>{
          suma += element.indice_preasignacion
        })
        suma = suma/this.docentesActivos.length
        return Math.round(suma)
      }
    }
  }
</script>

<style scope>
	.tipo-titulo{
		width: 160px;
    	font-size: 12px;
	}
	.botones .btn{
		font-size: 10px !important;
    	padding: 2px !important;
	}
  .alarm{
    color:#fff;
    background-color: red;
    padding: 2px;
  }
</style>