<template>
  <div class="container  mt-3">
    <div class="m-auto">
    	
      <div class="form-horizontal">
        
          <div class="form-group row">
            <label class="col-3 col-form-label">Vigencia:</label>
            <div class="col-3">
              <date-picker  class="date-picker" v-model="fechaVigencia" lang="es" type="date" 
            :time-picker-options="timePickerOptions" :clearable="true"></date-picker>
            </div>
            <div class="col-3">
              <button v-if="procesable" class="btn btn-block btn-danger" @click="procesar">
                Guardar Vigencia
              </button>   
            </div>
          </div>
        
        
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import DatePicker from 'vue2-datepicker'

  export default {
    name: 'DetalleVigencia',
    components: {
      DatePicker
    },
    data () {
      return {
        fechaVigencia: null,
        timePickerOptions:{}
      }
    },
    props: {
      estudiante: Object
    },
    mounted () {
      if(this.estudiante){
        this.fechaVigencia = this.estudiante.vigencia;
      }else{
        console.log('Hay que asegurarse de tener un estudiante antes de utilizar el componente DetalleVigencia')
      }
    },
    methods:{
      ...mapActions({
        editarVigencia: 'estudiantes/editarVigencia',
        fetchDetalle: 'estudiantes/fetchDetalle',
        setWarning: 'setWarning',
      }),
      ...mapMutations({
        
      }),
      procesar(){
        let fecha_vigencia = this.fechaVigencia.toISOString().split('T')[0]

        this.editarVigencia({
          id:this.estudiante.id,
          vigencia:fecha_vigencia,
        }).then((apiResponse)=>{
          
          this.limpiar()
          this.setWarning('Vigencia Guardada', { root: true }).then(()=>{
            this.fetchDetalle(this.estudiante.id).then(()=>{
              this.$router.push('/estudiante/detalle/'+this.estudiante.id)
            }) 
          })
          
          
        })
      },
      limpiar(){
        this.fechaVigencia= null
      },
      
    },
    computed:{
      ...mapState({
        
      }),
      ...mapGetters({
        
      }),
      procesable: {
        get () {
          return (this.fechaVigencia && this.fechaVigencia != this.estudiante.vigencia)
        },
        set(value){}
      },

    }
  }
</script>

<style scope>
  .programar_wrapper .form-container{
      
  }
	.programar_wrapper{
    
	}
</style>

