<template>
  <div class="">

    <div class="container pt-3 m-auto text-left ">
      <h1>Editar Curso</h1>
      <h2>Aún no se ha construido esta funcionalidad</h2>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'CursoEditar',
    components: {
      
    },
    data () {
      return {
           curso_id: null,
      }
    },
    mounted () {
     this.curso_id = this.$route.params.id;
      if(!this.detalle[this.curso_id]){
        this.actualizar()
      }
    },
    
    methods:{
      ...mapActions({
        fetchDetalle: 'cursos/fetchDetalle',
        editar: 'cursos/editar',
        setWarning: 'setWarning',
      }),
      ...mapMutations({
        
      }),
      actualizar(){
        this.fetchDetalle(this.curso_id)
      },
      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {
          console.log('validateBeforeSubmit')
          if ( ! result) {
            console.log('validateBeforeSubmit error')
            //hay errores
          } else {
            console.log('validateBeforeSubmit no error')
            this.editar(this.curso).then(()=>{
                this.setWarning('Curso Actualizado', { root: true }).then(()=>{
                this.fetchDetalle(this.curso_id).then(()=>{
                  this.$router.push('/curso/detalle/'+this.curso_id)
                })
              })
            }).catch(error=>{
              //todo probar si se puede quitar
            })
            
          }
        })
      },

    },
    computed:{
      ...mapState({
        detalle: state => state.cursos.detalle, 
        warning: state => state.warning
      }),
      curso(){
        return this.detalle[this.curso_id]
      },
      
      
    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>
