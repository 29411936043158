var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('b-navbar',{staticClass:"pt-0 pb-0",attrs:{"toggleable":"md","type":"dark","variant":"info"}},[_c('div',{staticClass:"container"},[_c('b-navbar-toggle',{attrs:{"target":"nav_collapse_cursos"}}),(_vm.banner)?_c('h1',{staticClass:"text-center navbar-brand pt-0 pb-0 mb-0"},[_vm._v("Banners Detalle ")]):_vm._e(),_c('b-collapse',{attrs:{"is-nav":"","id":"nav_collapse_cursos"}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[(_vm.banner)?_c('a',{staticClass:"nav-link",attrs:{"href":`#/banner/editar/${ _vm.banner.id }`,"title":"Ver"}},[_vm._v("Editar")]):_vm._e(),_c('a',{staticClass:"nav-link",attrs:{"href":`#/banners/lista`,"title":"Ver"}},[_vm._v("Volver")])])],1)],1)]),_c('div',{staticClass:"container pt-3 m-auto text-left"},[_c('div',[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.banner.user.primer_nombre)+" "+_vm._s(_vm.banner.user.primer_apellido))])]),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.banner.url))])]),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.banner.fecha_inicio))])]),_c('div',{staticClass:"row"},[_vm._m(3),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.banner.fecha_fin))])]),_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.banner.activo))])]),_c('div',{staticClass:"row"},[_vm._m(5),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.banner.metodosPago))])]),_c('div',{staticClass:"row"},[_vm._m(6),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.banner.sedes))])]),_c('div',{staticClass:"row"},[_vm._m(7),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.banner.cursos))])]),(_vm.banner.destination_url)?_c('div',{staticClass:"row"},[_vm._m(8),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.banner.destination_url))])]):_vm._e()])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Nombre y Apellido:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Url:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Fecha Inicio:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Fecha Fin:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Estado:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Tipo:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Sede:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Curso:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Enviar A (Link):")])])
}]

export { render, staticRenderFns }