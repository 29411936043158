<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_cursos"></b-navbar-toggle>
        <h1 v-if="curso" class="text-center navbar-brand pt-0 pb-0 mb-0">{{ curso.nombre }} </h1>
        <b-collapse is-nav id="nav_collapse_cursos">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <a :href="`#/cursos`" class="nav-link" title="Volver">Volver</a>
            <!--
            <a v-if="curso" :href="`#/curso/editar/${ curso.id }`" class="nav-link" title="Ver">Editar</a>
            -->
            <a v-if="curso && permiso('D4F5A51C')" :href="`#/curso/${ curso.id }/correos`" class="nav-link" title="Correos">Correos</a>
            <b-nav-item  href="#" @click="actualizar">Actualizar</b-nav-item>
            <a v-if="curso && permiso('A9EFE57')" :href="`#/tema/nuevo/${ curso.id }`" class="nav-link" title="Nuevo Tema">Nuevo Tema</a>
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <div class="container pt-3 m-auto text-left ">
      
      <div v-if="curso">
        
        <div class="row">
          <div class="col-3"><strong>Código:</strong>
          </div><div class="col-9">{{ curso.codigo }}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>Nombre:</strong>
          </div><div class="col-9">{{ curso.nombre }}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>Activo:</strong></div>
          <div class="col-9">{{ curso.activo }}</div>
        </div>
        
        
      </div>
      <div v-else>
        No hay información
      </div>
      <hr>
      <h2>Parcelador</h2>
        <div v-if="temas">
          <v-client-table name="temasTable" :columns="columns" :data="temas" :options="options">
            <div slot="accion" slot-scope="temas">
              <a :href="`#/tema/detalle/${ temas.row.id }`" 
              class="btn btn-secondary btn-sm" title="Ver">Ver</a>
            </div>
            <div slot="programable" slot-scope="temas">
              <div v-if="temas.row.programable==1">SI</div>
              <div v-if="temas.row.programable==0">NO</div>
            </div>
            <div slot="requiere_sede_original" slot-scope="temas">
              <div v-if="temas.row.requiere_sede_original==1">SEDE PROPIA</div>
              <div v-if="temas.row.requiere_sede_original==0">MULTISEDE</div>
            </div>
          </v-client-table>
        </div>
    </div>
    
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'CursoDetalle',
    components: {
    },
    data () {
      return {columns: ['nombre','orden','tipo', 'nombreExamen','requiere_sede_original', 'accion'],
        options: {
          filterable: true,
          filterByColumn: false,
          sortable:[],
          perPage: 50,
          perPageValues: [5, 10, 15, 20],
          headings: {
            block: 'Block',
            pages: 'Pages',
            unidad: 'Unit',
            class_topic: 'Class Topic',
            tipo: 'Tipo',
            class_structure: 'Class Structure',
            nombreExamen : 'Examen',
            requiere_sede_original: 'Sede'
          }
        },
          agendando: false,
          curso_id: null,
      }
    },
    beforeCreate() {
      this.$store.commit('cursos/incializarDetalle',this.$route.params.id)
    },
    
    mounted () {
      this.curso_id = this.$route.params.id;
      if(!this.detalle[this.curso_id]){
        console.log('Detalle mounted actualizar')
        this.actualizar()
      }
    },
    
    methods:{
      ...mapActions({
        fetchDetalle: 'cursos/fetchDetalle',
      }),
      ...mapMutations({
        setDetalle: 'cursos/setDetalle',
        incializarDetalle: 'cursos/incializarDetalle',
      }),
      actualizar(){
        this.fetchDetalle(this.curso_id).then(()=>{
          
        })
      },
      fechaFormated(value) {
        return value.split('T')[0]
      }   
    },
    computed:{
      ...mapState({
        detalle: state => state.cursos.detalle, 
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      curso(){
        return this.detalle[this.curso_id]
      },
      temas(){
        if(this.curso){
          for(var s = 0; s < this.curso.temas.length; s++){
            if(this.curso.temas[s].examen != null && this.curso.temas[s].examen.nombre != "")
              this.curso.temas[s].nombreExamen = this.curso.temas[s].examen.nombre
          }
          return this.curso.temas  
        }else{
          return []
        }
        
      }
    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>
