<template>
  <div>
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_salones"></b-navbar-toggle>
        <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Salones</h1>
        <b-collapse is-nav id="nav_collapse_salones">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <router-link v-if="permiso('3C1A985C')" class="nav-link" to="/salon/nuevo" >Nuevo</router-link>
            <router-link v-if="permiso('871292F9')" class="nav-link" to="/salon/masivo" >Masivo</router-link>
            <b-nav-item  href="#" @click="actualizar">Actualizar</b-nav-item>
          </b-navbar-nav>
          
        </b-collapse>
      </div>
    </b-navbar>
    <div class="container salones_wrapper mt-3">
      <div v-if="lista" class="tabla">
        <v-client-table name="salonesTable" :columns="columns" :data="lista" :options="options">
          <div v-if="permiso('AE7C3DD1')" slot="accion" slot-scope="lista"><a :href="`#/salon/detalle/${ lista.row.id }`" class="btn btn-primary btn-sm" title="Ver">Ver</a></div>
        </v-client-table>
      </div>
    </div>
    <SalonTipos  />
    <TiposConvenciones  />
  </div>
</template>

<script>
  import {mapState, mapGetters,mapActions, mapMutations} from 'vuex';
  import SalonTipos from '@/components/Salones/SalonTipos';
  import TiposConvenciones from '@/components/TiposConvenciones';

  export default {
    name: 'SalonLista',
    components: {
      SalonTipos,
      TiposConvenciones,
    },
    data () {
      return {
        columns: ['nombre',  'capacidad', 'activo', 'url', 'accion'],
        options: {
          filterable: true,
          filterByColumn: false,
          sortable:[],
          perPage: 10,
          perPageValues: [5, 10, 15, 20],
          headings: {
            id: 'Id',
            nombre: 'Nombre',
            numero: 'Numero',
            capacidad: 'Capacidad',
            tipo_salon: 'Tipo',
            activo: 'Habilitado',
            accion: 'Accion',
            url: 'Live room'
          }
        }
      }
    },
    mounted () {
      if(!this.lista || (this.lista && this.lista.length==0)){
        this.fetchLista()
      }
    },
    methods:{
      ...mapActions({
        fetchLista: 'salones/fetchLista',
      }),
      ...mapMutations({
        
      }),
      edit(){

      },
      actualizar(){
        this.fetchLista()
      }
    },
    computed: {
      ...mapState({
        lista: state => state.salones.lista, 
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
    }
  }
</script>

<style scope>

</style>