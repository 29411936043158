<template>
  <div class="container">
    <h1>Preasignaciones</h1>
    <!-- CONFIMARCION CALCULAR PREASIGNACIONES-->
    <b-modal  @ok="procesarBorrarPreasignaciones" @cancel="cancelarBorrarPreasignaciones" 

      no-close-on-backdrop   ok-title="Aceptar" cancel-title="Cancelar"
      v-model="iniciarBorrarPreasignaciones"   id="modalBorrarPreasignaciones"
      title="Borrar preasignaciones" >
      <p>
      Se eliminarán todas las preasignaciones de la sede para el día seleccionado
    </p>
      Seleccione el dia que quiere eliminar
      <select  v-model="diaSeleccionado" class="form-control">
          <option selected value="">--SELECCIONE--</option>
          <option :value="1">Lunes</option>
          <option :value="2">Martes</option>
          <option :value="3">Miercoles</option>
          <option :value="4">Jueves</option>
          <option :value="5">Viernes</option>
          <option :value="6">Sabado</option>
        </select>
      
    </b-modal>

    <b-button size="sm" variant="info" class="m-1" @click="iniciarBorrarPreasignaciones=true">Eliminar Preasignaciones</b-button>
    <div v-if="procesando">
      <BlockUI message="procesando..."></BlockUI>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';
  
  export default {
    name: 'Preasignaciones',
    components: {
      
    },
    data () {
      return {
        diaSeleccionado : null,
        iniciarBorrarPreasignaciones : false,
        procesando: false,
      }
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({
        setWarning: 'setWarning',
      }),
      ...mapMutations({
        
      }),
      
      procesarBorrarPreasignaciones(){
        if(!this.diaSeleccionado){
          console.log('Se requiere el día')
        }
        let payload = {
          dia: this.diaSeleccionado
        }
        Vue.http.post('preasignaciones/borrar',payload)
          .then(responseApi=>{
            this.setWarning('Proceso finalizado correctamente')
          })
          .catch(error=>{
            this.setWarning(error.data)
            console.log(error)
          }).finally(()=>{
            this.procesando = false
            this.reiniciarCandidato()
          })
      },
      cancelarBorrarPreasignaciones(){
        this.reiniciarCandidato()
      },
      reiniciarCandidato(){
        this.diaSeleccionado = false
      }
    },
    computed: {
      ...mapState({
        lista: state => state.docentes.lista, 
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
    }
  }
</script>

<style scope>

</style>