<template>
  <div class="">
   <GrupoNavigation :grupo="grupo"/>
    <div class="container pt-3 m-auto text-left ">
      <div v-if="grupo">
        
        <div class="permisos">
          <h2>Permisos</h2>
          <div class="mb-3 row" v-if="permisos">
            <div class="" v-for="permiso in permisos">
              <button class="pt-0 pb-0 mt-1 mr-1 btn btn-success" v-if="permisos_permitidos_ids.includes(permiso.id)"
              @click="procesarQuitarPermiso(permiso.id)"
              title="Click para quitar">
                {{ permiso.recurso }}  
              </button>
              <button class="pt-0 pb-0 mt-1 mr-1 btn btn-dark" v-else
              @click="procesarAgregarPermiso(permiso.id)"
              title="Click para agregar">
                {{ permiso.recurso }}  
              </button>
            </div>
          </div>

          
        </div>
      </div>
      <div v-else>
        No hay información
      </div>
      <button class="btn btn-small btn-info" @click="actualizar">Actualizar</button>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import GrupoNavigation from '@/components/Grupos/GrupoNavigation';

  export default {
    name: 'GrupoDetalle',
    components: {
      GrupoNavigation
    },
    data () {
      return {
          grupo_id: null,
          permisos_permitidos_ids:[],
          procesable: false
      }
    },    
    mounted () {
      this.grupo_id = this.$route.params.id;
      if(!this.permisos || (this.permisos && this.permisos.length==0)){
        this.fetchPermisos()
      }
      this.actualizar()
    },
    
    methods:{
      ...mapActions({
        fetchDetalle: 'grupos/fetchDetalle',
        agregarPermiso: 'grupos/agregarPermiso',
        quitarPermiso: 'grupos/quitarPermiso',
        fetchPermisos: 'permisos/fetchLista',
      }),
      ...mapMutations({
      }),
      actualizar(){
        this.fetchDetalle(this.grupo_id).then(()=>{
          this.calcularPermisos()
        })
      },
      calcularPermisos(){
        this.permisos_permitidos_ids.splice(0,this.permisos_permitidos_ids.length)
        this.grupo.permisos.forEach(element=>{
          this.permisos_permitidos_ids.push(element.id)
        })
      },
      procesarAgregarPermiso(value){
        let permiso = {
          grupo_id:this.grupo_id,
          permiso_id:value
        }
        this.agregarPermiso(permiso).then(()=>{
          this.calcularPermisos()
        })
      },
      procesarQuitarPermiso(value){
        let permiso = {
          grupo_id:this.grupo_id,
          permiso_id:value
        }
        this.quitarPermiso(permiso).then(()=>{
          this.calcularPermisos()
        })
      }
      
    },
    computed:{
      ...mapState({
        permisos: state => state.permisos.lista, 
      }),
      ...mapGetters({
        getDetalle: 'grupos/getDetalle', 
      }),
      grupo(){
        if(this.grupo_id){
          let detalle =  this.getDetalle(this.grupo_id)
          return detalle
        }
        return null
      }, 
    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>