<template>
    <div class="container  mt-3">
      <div class="m-auto">
          
        <div class="form-horizontal">
          <div class="form-group row" v-if="estudiante">
            <label class="col-3 col-form-label">Requiere Clase 0</label>
            <div class="col-3">
              <select v-model="requiere_clase_0" class="form-control">
                <option disabled value="">Please select one</option>
                <option  value="1">SI</option>
                <option  value="0">NO</option>
              </select>
            </div>
            <div class="col-3">
                <button v-if="procesable" class="btn btn-block btn-danger" @click="procesar">
                  Guardar
                </button>
              </div> 
          </div>
          <div v-else>
            No hay cursos inscritos
          </div>
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
    
    export default {
      name: 'DetalleClaseCero',
      data () {
        return {
            requiere_clase_0:null
        }
      },
      props: {
        estudiante: Object
      },
      mounted () {
        if(this.estudiante){
          this.requiere_clase_0 = this.estudiante.requiere_clase_0
        }
      },
      methods:{
        ...mapActions({
            editarClaseCero: 'estudiantes/editarClaseCero',
            fetchDetalle: 'estudiantes/fetchDetalle',
            setWarning: 'setWarning',
        }),
        ...mapMutations({
          
        }),
        procesar(){
          this.editarClaseCero({
            id:this.estudiante.id,
            requiere_clase_0:this.requiere_clase_0,
            
          }).then((apiResponse)=>{
            
            this.limpiar()
            this.setWarning('Información Guardada', { root: true }).then(()=>{
              this.fetchDetalle(this.estudiante.id).then(()=>{
                this.$router.push('/estudiante/detalle/'+this.estudiante.id)
              }) 
            })
            
          }).catch((apiResponse)=>{
            console.log("error procesando editar requiere clase 0.")
          })
        },
        limpiar(){
         this.requiere_clase_0  = null
        }
      },
      computed:{
        ...mapState({
          
        }),
        ...mapGetters({
          
        }),
        procesable: {
          get () {
            return (this.requiere_clase_0 != null && this.requiere_clase_0!=this.estudiante.requiere_clase_0)
          },
          set(value){}
        },
      }
    }
  </script>
  
  <style scope>
    .programar_wrapper .form-container{
        
    }
      .programar_wrapper{
      
      }
  </style>
  
  