<template>
  <div>
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_funcionarios"></b-navbar-toggle>
        <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Funcionario</h1>
        <b-collapse is-nav id="nav_collapse_funcionarios">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <router-link v-if="permiso('4049A232')" class="nav-link" to="/usuario/nuevo" >Nuevo</router-link>
            <b-nav-item  href="#" @click="actualizar">Actualizar</b-nav-item>
            <router-link v-if="permiso('2D648D9C')" class="nav-link" to="/usuario/masivo" >Masivo</router-link>
            <router-link v-if="permiso('E1020A89')" class="nav-link" to="/usuario/gestion_global" >Gestion Global</router-link>
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>

    <div class="container-fluid funcionarios_wrapper mt-3">
      <div  class="tabla">
        <v-client-table name="funcionariosTable" :columns="columns" :data="lista" :options="options">
          <div slot="grupo" slot-scope="lista">
            <span v-if="lista.row.grupo">{{lista.row.grupo.nombre}}</span>
            <span v-else>Sin Asignar</span>
              
          </div>

          <div slot="activo" slot-scope="lista">
            <span v-if="lista.row.activo==1">SI</span>
            <span v-else>NO</span>
          </div>
          <div slot="sede" slot-scope="lista">
              {{lista.row.sede_actual.nombre}}
          </div>
          <div slot="accion" slot-scope="lista"><a :href="`#/usuario/detalle/${ lista.row.id }`" class="btn btn-primary btn-sm" title="Ver">Ver</a></div>
        </v-client-table>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';

  export default {
    name: 'FuncionariosLista',
    data () {
      return {
        columns: ['primer_nombre','segundo_nombre',  'primer_apellido', 'segundo_apellido','numero_documento', 'email', 'grupo', 'activo','sede','accion'],
        options: {
          filterable: true,
          filterByColumn: false,
          sortable:[],
          perPage: 10,
          perPageValues: [5, 10, 15, 20],
          headings: {
            id: 'Id',
            primer_nombre: 'Primer Nombre',
            segundo_nombre: 'Segundo Nombre',
            primer_apellido: 'Primer Apellido',
            segundo_apellido: 'Segundo Apellido',
            numero_documento: 'Documento',
            grupo: 'Grupo',
            estado: 'Estado',
            sede: 'Sede',
            accion: 'Accion'
          }
        }
      }
    },
    mounted () {
      if(!this.lista || (this.lista && this.lista.length==0)){
        this.fetchLista()
      }
    },
    methods:{
      ...mapActions({
        fetchLista: 'funcionarios/fetchLista',
      }),
      ...mapMutations({
      }),
      actualizar(){
        this.fetchLista()
      },
    },
    computed: {
       ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      ...mapState({
        lista: state => state.funcionarios.lista, 
      }),
    }
  }
</script>

<style scope>

</style>