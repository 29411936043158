<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_cierres"></b-navbar-toggle>
        <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Cierres</h1>
        <b-collapse is-nav id="nav_collapse_cierres">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            
            <b-nav-item  href="#" @click="creando=true">Crear Cierre</b-nav-item>
            <b-nav-item  href="#" @click="actualizar">Actualizar</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
    <div class="container pt-3 m-auto text-left ">
      <div>

        <b-row>
          <b-col cols="1" class="celda-head text-center" >Fecha</b-col>
          <b-col cols="2" class="celda-head text-center" >Horas</b-col>
          <b-col cols="3" class="celda-head text-center" >Sedes</b-col>
          <b-col cols="2" class="celda-head text-center" >Usuario</b-col>
          <b-col cols="2" class="celda-head text-center" >Mensaje</b-col>
        </b-row>
        <b-row class="fila" v-for="(cierre, index) in cierresFormatted" :key="index">
          <b-col cols="1" >{{cierre.fecha}}</b-col>
          <b-col cols="2" >{{cierre.horas}}</b-col>
          <b-col cols="3" >{{cierre.sedes}}</b-col>
          <b-col cols="2" >{{cierre.usuario}}</b-col>
          <b-col cols="2" >{{cierre.mensaje}}</b-col>
        </b-row>
      </div>
      <hr>
    </div>
    <b-modal @ok="procesarCrear"  @cancel="reiniciarCandidato" v-model="creando"  id="modalCrearCierre"
    :ok-disabled="!puedeProcesarCrear"  title="Nuevo Cierre">

      <div class="mb-2">
        <h5 class="">Fecha:</h5>
        <date-picker :not-before="fechaActual" class="date-picker" v-model="candidato.fecha" lang="es" type="date" 
      :time-picker-options="timePickerOptions" :clearable="true"></date-picker>
      </div>

      <div class="mb-2" v-if="candidato.fecha">
        <h5 class="">Horas:</h5>
        <b-form-select v-model="candidato.horas" :options="posiblesHoras" multiple :select-size="10"></b-form-select>
      </div>

      <div class="mb-2" v-if="candidato.sedes">
        <h5 class="">Sedes:</h5>
        <b-form-select v-model="candidato.sedes" :options="posiblesSedes" multiple :select-size="10"></b-form-select>
      </div>

      <div class="mb-2" v-if="candidato.fecha && candidato.horas">
        <h5 class="my-1">Mensaje:</h5>

        <div >
          <b-form-textarea
            id="textarea"           
            v-model="candidato.mensaje"
            placeholder="Escriba el mensaje del cierre."
            rows="3"
            max-rows="6"
          ></b-form-textarea>

        </div>
      </div>

    </b-modal>
    <div v-if="procesando">
      <BlockUI message="procesando..."></BlockUI>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';
  import DatePicker from 'vue2-datepicker'
  import utils from '@/modules/utils';

  export default {
    name: 'Cierres',
    components: {
      DatePicker
    },
    data () {
      return {
        timePickerOptions:{},
        cierres:[],
        creando: false,
        procesando: false,
        
        candidato : {
          fecha:null,
          horas:[],
          sedes:[],
          mensaje:''
        }
      }
    },
    mounted () {
      if(this.cierres.length == 0 ){
        this.actualizar()
      }
      if(this.listaSedes.length == 0 ){
        this.fetchListaSedes()
      }
    },
    
    methods:{
      ...mapActions({
        setWarning: 'setWarning',
        fetchListaSedes: 'sedes/fetchLista',
      }),
      ...mapMutations({
        
      }),
      procesarCancelar(){
        
      },
      procesarCrear(){
        this.procesando = true

          Vue.http.post('cierres/crear',this.candidato).then()
          .catch(error=>{
            this.setWarning(error.data)
            console.log(error)
          }).finally(()=>{
            this.procesando = false
            this.actualizar()
            this.reiniciarCandidato()
          })
          
      },
      
      actualizar(){
        this.procesando = true
        Vue.http.post('cierres/lista').then(
          response =>{
            this.cierres = response.data
          }
        )
        .catch(error=>{
          console.log(error)
        }).finally(()=>{
          this.procesando = false
        })

      },
      reiniciarCandidato(){
        this.candidato = {
          fecha:null,
          horas:[],
          sedes:[],
          mensaje:null
        }
      },

    },
    computed:{
      ...mapState({
        warning: state => state.warning,
        disponibilidad_general: state => state.asw.disponibilidad_general, 
        listaSedes: state => state.sedes.lista, 
      }),

      puedeProcesarCrear(){
        if(this.candidato && this.candidato.fecha && this.candidato.horas.length>0 
          && this.candidato.sedes.length>0 
          && this.candidato.mensaje ){
          return true
        }
        return false
      },
      
      posiblesHoras(){
        if(this.candidato.fecha){
          let fecha = new Date(this.candidato.fecha)
          let dia = fecha.getDay()
          if([1,2,3,4,5,6].includes(dia)){
            let diaObj = this.disponibilidad_general.dias[dia]
            return diaObj.horas.map(element=>{
              return element.hora
            })
          }
        }

        return []
      },

      posiblesSedes(){
        if(this.listaSedes.length > 0 ){
          return this.listaSedes.map((sede)=>{
            return {value:sede.id,text:sede.nombre}
          })
        }
        return []
      },
      fechaActual(){
        let fecha = utils.getManana()
        console.log(fecha)
        return utils.getManana()
      },
      cierresFormatted(){
        return this.cierres.map((element)=>{
          let fecha_formatted = element.fecha.split('T')[0]
          let usuario_formatted = element.usuario.nombre+', '+element.usuario.identificacion+', '+element.usuario.email

          let sedesNombres = element.sedes.map(sede_id=>{
            let sedeEncontrada = this.listaSedes.find(sede=>{
              return sede.id == sede_id
            })
            return sedeEncontrada?sedeEncontrada.nombre:sede_id
          })

          return {
            fecha : fecha_formatted,
            horas : element.horas.join(' '),
            sedes : sedesNombres.join(', '),
            mensaje : element.mensaje,
            usuario : usuario_formatted
          }
        })
      }

      
    }
    
  }
</script>