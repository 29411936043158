<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_funcionarios"></b-navbar-toggle>
        <h1 v-if="funcionario" class="text-center navbar-brand pt-0 pb-0 mb-0">Editar Funcionario</h1>
        <b-collapse is-nav id="nav_collapse_docentes">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item  href="#" @click="procesarCancelar">Cancelar</b-nav-item>
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <div class="container pt-3 m-auto text-left ">
      <h1>Datos básicos</h1>
       <hr>
      <form
        autocomplete="off"
        class="form-horizontal"
        @submit.prevent="validateBeforeSubmit"
      >
        <div v-if="funcionario">
          <InputTextSimple titulo="Primer Nombre" :modelo="funcionario.primer_nombre" 
            @change="funcionario.primer_nombre = $event"/>
          <InputTextSimple titulo="Segundo Nombre" :modelo="funcionario.segundo_nombre" 
            @change="funcionario.segundo_nombre = $event"/>
          <InputTextSimple titulo="Primer Apellido" :modelo="funcionario.primer_apellido" 
            @change="funcionario.primer_apellido = $event"/>
          <InputTextSimple titulo="Segundo Apellido" :modelo="funcionario.segundo_apellido" 
            @change="funcionario.segundo_apellido = $event"/>
          <SelectTipoDocumento titulo="Tipo de Documento" :modelo="funcionario.tipo_documento" 
            @change="funcionario.tipo_documento=$event"/>
          <InputNumeroDocumento titulo="Número de Documento" :modelo="funcionario.numero_documento" 
            @change="funcionario.numero_documento=$event"/>
          <InputEmailSimple titulo="Email" :modelo="funcionario.email" 
            @change="funcionario.email=$event"/>
          <InputMovil titulo="Telefono" :modelo="funcionario.telefono" 
            @change="funcionario.telefono=$event"/>
            <InputMovil titulo="Movil" :modelo="funcionario.movil" 
            @change="funcionario.movil=$event"/>

        </div>
        <button class="btn btn-block btn-danger" type="submit">Guardar</button>
      </form>

      <div v-if="funcionario">
        <hr>
        <h2>Grupo</h2>
        <FuncionarioEditarGrupo v-if="permiso('1010A13E')" :funcionario="funcionario"></FuncionarioEditarGrupo>
        <hr>
        <FuncionarioEditarSede v-if="permiso('1010A13E')" :funcionario="funcionario"></FuncionarioEditarSede>
        <hr>
        <FuncionarioInvalidarTokens v-if="permiso('1010A13E')" :funcionario="funcionario"></FuncionarioInvalidarTokens>
      </div>

    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import FuncionarioEditarGrupo from '@/components/Funcionarios/FuncionarioEditarGrupo';
  import FuncionarioEditarSede from '@/components/Funcionarios/FuncionarioEditarSede';
  import FuncionarioInvalidarTokens from '@/components/Funcionarios/FuncionarioInvalidarTokens';

  export default {
    name: 'FuncionarioEditar',
    components: {
      FuncionarioEditarGrupo,
      FuncionarioEditarSede,
      FuncionarioInvalidarTokens,

    },
    data () {
      return {
          funcionario_id: null,
      }
    },
    mounted () {
      this.funcionario_id = this.$route.params.id;
      if(!this.detalle[this.funcionario_id]){
        this.actualizar()
      }
    },
    methods:{
      ...mapActions({
        fetchDetalle: 'funcionarios/fetchDetalle',
        editar: 'funcionarios/editar',
        setWarning: 'setWarning',
        fetchLista: 'funcionarios/fetchLista',
      }),
      ...mapMutations({
      }),
      procesarCancelar(){
        this.fetchDetalle(this.funcionario_id).then(()=>{
          this.$router.push('/usuario/detalle/'+this.funcionario_id)
        })
      },
      actualizar(){
        this.fetchDetalle(this.funcionario_id)
      },
      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {
          if ( ! result) {
            //hay errores
          } else {
            this.editar(this.funcionario).then(()=>{
                this.setWarning('Funcionario Actualizado', { root: true }).then(()=>{
                this.fetchLista().then(()=>{
                    this.$router.push('/usuarios')
                })
              })
            }).catch(error=>{
              //todo probar si se puede quitar
            })
            
          }
        })
      },

    },
    computed:{
      ...mapState({
        detalle: state => state.funcionarios.detalle, 
        warning: state => state.warning
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      funcionario(){
        return this.detalle[this.funcionario_id]
      },
    }
  }
</script>

<style scope>
  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>