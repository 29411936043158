
import Vue from 'vue';

const state = {
  preasignaciones: [],
};

const actions = {
  fetchPreasignacionesXDocente:({commit, state},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('preasignaciones/xdocente',payload).then(
        response =>{

          let currents =  state.preasignaciones.filter(preasignacion =>{
            return preasignacion.docente_id == payload.docente_id
          })
          let ids = currents.map(element=>{
            return element.id
          })
          if(ids && ids.length>0){
            commit('delPreasignaciones',ids);
          }

          commit('setPreasignaciones',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchPreasignacionesXSalon:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('preasignaciones/xsalon',payload).then(
        response =>{
          commit('setPreasignaciones',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  guardarPreasignacion:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('preasignacion/guardar',payload).then(
        response =>{
          if(response.data.deleted){
            commit('delPreasignaciones',response.data.ids);
          }else{
            commit('setPreasignaciones',[response.data]);  
          }
          
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  calcularPreasignaciones:({commit,state},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('preasignacion/calcular',payload).then(
        response =>{

          let currents =  state.preasignaciones.filter(preasignacion =>{
            return preasignacion.docente_id == payload.docente_id
          })
          let ids = currents.map(element=>{
            return element.id
          })
          if(ids && ids.length>0){
            commit('delPreasignaciones',ids);
          }

          commit('setPreasignaciones',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  

};

const getters = {
	getPreasignacionesXDocente: (state) => (docente_id) => {
		return state.preasignaciones.filter(preasignacion =>{
			return preasignacion.docente_id == docente_id
		})
  	},
  	getPreasignacionesXSalon: (state) => (salon_id) => {
		return state.preasignaciones.filter(preasignacion =>{
			return preasignacion.salon_id == salon_id
		})
  	},
};

const mutations = {
  setPreasignaciones: (state, data) => {
  	for(let i in data){
  		let preasignacion = data[i]
  		let index = state.preasignaciones.findIndex(element=>{
  			return element.id == preasignacion.id
  		})
  		if(index>=0){ 
        state.preasignaciones.splice(index,1,preasignacion)
      }else{
        state.preasignaciones.push(preasignacion)
      }
  	}
  },
  delPreasignaciones: (state, ids) => {
    for(let i in ids){
      let id = ids[i]
      let index = state.preasignaciones.findIndex(element=>{
        return element.id == id
      })
      if(index>=0){ 
        state.preasignaciones.splice(index,1)
      }
    }
  },
  restart: (state) => {
    state.preasignaciones = []
  },

};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
