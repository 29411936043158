<template>
  <div>
    <b-navbar toggleable="md" type="dark"  class="pt-0 pb-0 bg-blue">
        <b-navbar-toggle target="nav_collapse_actions"></b-navbar-toggle>
        <b-navbar-brand href="#" @click.prevent="actualizarTiempo"><img style="width:100px" src="../../assets/logo_small_white.png">
        </b-navbar-brand>
        <span style="color:#fff">{{ titulo }}</span>
    </b-navbar>
  	<div class="container-fluid principal" >
      <div style="font-size: 10px" class="text-center text-muted pt-0 pb-0 mb-0">
      Última actualización: {{ dia_actual_local }} {{ hora_actual_local }}
      </div>

      <div v-if="!dia_actual" class="text-center">
        <img  src="/img/icons/android-chrome-512x512.png">
      </div>

			<div v-if="iniciado">
				<div v-for="(dia, index_1) in agenda_disponible" :key="index_1">
					<ProgramadorXDiaXHora :activo="tabActivo" :rightPanel="rightPanel"
					v-for="(hora, index_2) in dia.horas" :key="index_2" :hora="hora" :dia="dia"/>
				</div>
			</div>
  	</div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import ProgramadorXDiaXHora from '@/components/Pantalla/ProgramadorXDiaXHora';

  export default {
    name: 'Pantalla',
    components: {
      ProgramadorXDiaXHora,
    },
    data () {
      return {
        rightPanel : false,
    		leftPanel : false,
        dia_actual : null,
        dia_actual_local : null,
        hora_actual : null,
        hora_actual_local : null,
        timestamp : null,
        interval: null
      }
    },
    mounted () {
      
      if(!this.agenda_disponible){
        this.fetchAgendaDisponible().then(()=>{
          this.actualizarTiempo()
        })
        this.fetchCursos().then(()=>{})
        this.fetchClases().then(()=>{})
        this.fetchProfesores().then(()=>{})
        this.fetchSalones().then(()=>{})
        
      }
      this.interval = setInterval(this.actualizarTiempo, 30000);
    },
    methods:{
      ...mapActions({
        fetchAgendaDisponible: 'asw/fetchAgendaDisponible',
        fetchCursos : 'asw/fetchCursos',
        fetchClases : 'clases/fetchClases',
        fetchClasesXMarca : 'clases/fetchClasesXMarca',
        fetchProfesores : 'docentes/fetchLista',
        fetchSalones : 'salones/fetchLista',
        fetchAgendamientos : 'agendamientos/fetchAgendamientos',
        generarParcial : 'clases/generarParcial'
      }),
      ...mapMutations({
        nuevaClase: 'clases/nuevaClase',
      }),
      actualizarTiempo(){
        let date = new Date()
        let dd = date.getDate();
        let mm = date.getMonth()+1; 
        let yyyy = date.getFullYear();
        if(dd<10) { dd=`0${dd}`; } 
        if(mm<10) { mm=`0${mm}`; } 
        this.dia_actual_local = `${yyyy}-${mm}-${dd}`;

        this.dia_actual = this.agenda_disponible.find((element)=>{
          return element.fecha == this.dia_actual_local
        })
        this.timestamp = date.getTime()
        let hora_actual_temporal = this.dia_actual.horas.find((element)=>{
          return (element.timestamp-300000) <= this.timestamp  && this.timestamp <= (element.timestamp+1200000)
          //return (element.timestamp) <= this.timestamp
        })
        this.hora_actual_local = date.toTimeString().split(' ')[0]
        if(hora_actual_temporal){
          this.hora_actual = hora_actual_temporal
          this.recargarClases()
        }else{
          this.hora_actual = null
          this.dia_actual = null
        }

      },
      toogleRightPanel(){
        this.rightPanel = !this.rightPanel
      },
      edit(){

      },
      cambiarDia(value){
        this.dia_actual = value
        if(this.hora_actual){
          var found = this.dia_actual.horas.find((element) => {
            return element.hora == this.hora_actual.hora;
          });
          if(found){
            this.hora_actual = found
          }else{
            this.hora_actual =  null
          }
        }
      },
      nueva(){
        let now = new Date()
        let candidato = {
          profesor_id:-1,
          curso_id: -1,
          salon_id: -1,
          fecha:this.hora_actual.marca_tiempo.split('T')[0],
          hora:this.hora_actual.marca_tiempo.split('T')[1],
          tipo_clase:'',
          id_temporal: now.getTime()
        }
        this.nuevaClase({marca:this.hora_actual.marca_tiempo,candidato:candidato})
        console.log('inicia el proceso')
      },
      generar(){
        console.log('Generando automaticamente....')

        this.generarParcial({
          marca_tiempo:this.hora_actual.marca_tiempo
        }).then(()=>{

        })
      },
      recargarClases(){
        this.fetchClasesXMarca({marcatiempo:this.hora_actual.marca_tiempo,silent:true}).then(()=>{})
        
      }
    },
    computed: {
      ...mapState({
        agenda_disponible: state => state.asw.agenda_disponible,
        cursos: state  => state.asw.cursos,
        clases: state => state.clases.clases,
        profesores: state => state.docentes.lista,
        salones: state => state.salones.lista,
        agendamientos: state => state.agendamientos.agendamientos,

      }),

      iniciado(){
    		console.log('iniciado')
    		if(this.agenda_disponible && this.cursos && this.clases && this.profesores && this.salones ){
    			return true
    		}
    		return false
      },
      titulo_dia(){
        if(this.dia_actual){
          return this.dia_actual.texto
        }else{
          return 'seleccione...'
        }
        
      },
      titulo_hora(){
        if(this.hora_actual){
          return this.hora_actual.nombre_corto
        }else{
          return 'seleccione...'
        }
        
      },
      titulo(){
        if(this.dia_actual &&  this.hora_actual){
          return this.dia_actual.texto+' '+this.hora_actual.nombre_corto
        }else{
          return ''
        }
        
      },
      tabActivo(){
        if(this.dia_actual &&  this.hora_actual){
          return this.dia_actual.fecha+'T'+this.hora_actual.hora
        }else{
          return null
        }
      },
    }
  }
</script>

<style scope>
.izquierda{
	padding-bottom: 30px
}
</style>