<template>
	<b-card>
		<h4>Convenciones</h4>
       	<div v-for="tipo in tipos">
       		<b-button :title="tipo.nombre" variant="success" size="sm" class="pt-0 pb-0 mt-1 mr-1">
                    {{ tipo.nombre_corto }}  
            </b-button>
            {{ tipo.nombre }}  
        </div>
    </b-card>
</template>


<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';


  export default {
    name: 'TiposConvenciones',
    data () {
      return {
        
      }
    },
    mounted () {
        
    },
    methods:{
      ...mapActions({
        
      }),
      ...mapMutations({
        
      }),  
    },
    computed:{
      ...mapState({
        tipos: state => state.asw.tipos, 
      }),
      ...mapGetters({
        
      }),
      
    }
    
  }
</script>

<style scope>

</style>