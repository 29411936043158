<template>
  <div class="container  mt-3">
    
      <div class="form-horizontal">

        <div class="form-group row">
          
            <button  class="btn btn-primary ml-3" @click="procesar">Invalidar Tokens</button>    
        </div> 
        
      </div>
    
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';
  export default {
    name: 'FuncionarioInvalidarTokens',
    data () {
      return {
        grupo : null,
      }
    },
    props: {
      funcionario: Object
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({
        fetchDetalle: 'funcionarios/fetchDetalle',
        setWarning: 'setWarning',
      }),
      ...mapMutations({
        
      }),
      
      procesar(){
        Vue.http.post('user/invalidar_tokens',{id:this.funcionario.id})
          .then()
          .catch(error=>{
            this.setWarning(error)
            console.log(error)
          }).finally(()=>{
            this.fetchDetalle(this.funcionario.id)
            .then(()=>{
              this.$router.push('/usuario/detalle/'+this.funcionario.id)
            })
          })
      },
      
      
    },
    computed:{
      ...mapState({
        
      }),
      ...mapGetters({
        
      }),
      
      
    }
  }
</script>

<style scope>
 
</style>

