<template>
	<div class="form-group row">
      <label class="col-3 col-form-label">{{ titulo }}:</label>
      <div class="col-9">
          <select v-model="_modelo" class="form-control">
              <option disabled value="">Please select one</option>
              <option v-for="td in tipos_documentos" :key="td.codigo" :value="td.codigo">{{ td.descripcion }}</option>
          </select>
      </div>
	</div>
</template>


<script>

import {mapState,  mapActions} from 'vuex';//mapGetters,, mapMutations
  export default {
    name: 'SelectTipoDocumento',
    data () {
        return {
            error: null,
        }
    },
    props: {
        titulo: String,
        modelo: String
    },
    mounted () {
        this.fetchLista()
    },
    methods:{
        ...mapActions({
            fetchLista: 'tipoDocumento/fetchLista'
        }),
    },
    
    computed:{
        _modelo: {
            get () {
              return this.modelo
            },
            set (value) {
              this.$emit('change', value);
            }
        },
        ...mapState({
            tipos_documentos: state => state.tipoDocumento.lista,
        })
    }
    
  }
</script>

<style scope>

</style>