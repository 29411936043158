<template>
	<div class="form-group row">
		<label class="col-3 col-form-label">{{ titulo }}:</label>
		<div class="col-9">
			<input type="email"
			v-model="_modelo"
			:name="titulo"
			:placeholder="titulo"
			class="form-control"
			v-validate
			data-vv-rules="required|email"
			>
		</div>
	</div>
</template>


<script>
  export default {
    name: 'InputEmailSimple',
    data () {
      return {
          error: null,
      }
    },
    props: {
		titulo: String,
		modelo: String
    },
    
    computed:{
		_modelo: {
			get () {
				return this.modelo
			},
			set (value) {
				this.$emit('change', value);
			}
		},
    }
    
  }
</script>

<style scope>

</style>