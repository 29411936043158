<template>
  <div class="">
    <div v-if="estado">
      
      
      <button class="pt-0 pb-0 mt-1 mr-1 btn btn-dark" 
            @click="procesarActualizar()" title="Actualizar">Actualizar</button>
            
      <h3 class="mt-3 mb-0">Reporte de ocupación {{dia.fecha}} / {{hora.hora}}</h3>
      <span class="marcatiempo" v-if="updateTime">{{updateTimeFormated}}</span>
      <hr>
      <div class="navbar">
        <h4 style="color:red">Resumen</h4>        
        <b-button v-b-toggle.collapseHelp class="ml-auto badge badge-pill sm" size="sm" variant="warning">?</b-button>
      </div>
      <b-collapse id="collapseHelp">
        <b-card>
           <div>
            <b-badge class="ml-1" variant="info">Cian</b-badge>
            : Indica que tenemos cupos para este tipo de clase. ABIERTO
           </div>

          <div>
            <b-badge class="ml-1" variant="success">Verde</b-badge>
            : Indica que tenemos tantos estudiantes como cupos. CERRADO
          </div>

          <div>
            <b-badge class="ml-1" variant="danger">Rojo</b-badge>
            : Indica que no ofrecemos cupo para este tipo de clase. CERRADO. Es necesario configurar por lo menos un docente disponible con un salón predeterminado.
          </div>

          <div>
            <b-badge class="ml-1" variant="warning">Naranja</b-badge>
            : Indica que tenemos sobreocupacion para este tipo de clase. CERRADO
          </div>

          <div>
            <b-badge class="ml-1" variant="info"><b-badge class="ml-1" variant="light">1</b-badge></b-badge>
            : El número indica la cantidad de clases posibles que la aplicación reconoce para este tipo de clase.
          </div>

         
          
        </b-card>
      </b-collapse>

      <div v-if="tipos">
        <b-container fluid>
          <span  v-for="tipo in tipos" >
              <ProgramadorInformeEstadoTipoResumen v-if="estado[tipo.id]"
              :reporte="estado[tipo.id]" :nombre="tipo.nombre_largo" />
          </span>
        </b-container>
      </div>

      
      <hr>
      <div >
        
        <b-button v-b-toggle.collapseDocentes variant="link"><h4 style="color:red">Docentes</h4></b-button>
        
      </div>
      <b-collapse id="collapseDocentes">
        
          <ProgramadorInformeEstadoDocentes :hora="hora" :consolidado="consolidadoDocentes" />
        
      </b-collapse>


      <div v-if="tipos" v-for="tipo in tipos">
        <hr>
        <div v-if="estado[tipo.id]">
          <div class="row">
              <div class="col-3"><h4 style="color:red">{{tipo.nombre_largo}}</h4></div>
          </div>
          
          <ProgramadorInformeEstadoTipo :reporte="estado[tipo.id]"/>
          
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';

import ProgramadorInformeEstadoTipo from '@/components/Programador/ProgramadorInformeEstadoTipo';
import ProgramadorInformeEstadoTipoResumen from '@/components/Programador/ProgramadorInformeEstadoTipoResumen';
import ProgramadorInformeEstadoDocentes from '@/components/Programador/ProgramadorInformeEstadoDocentes';
  export default {
    name: 'ProgramadorInformeEstado',
    components: {
      ProgramadorInformeEstadoTipo,
      ProgramadorInformeEstadoTipoResumen,
      ProgramadorInformeEstadoDocentes
    },
    data () {
      return {
        estado : false,
        updateTime: null,
        consolidadoDocentes: []
      }
    },
    props : {
      dia: Object,
      hora: Object, 
    },
    mounted () {
      this.procesarActualizar()
    },
    methods:{
      ...mapActions({
        setWarning: 'setWarning',
      }),
      ...mapMutations({
      }),
      procesarActualizar(){
        Vue.http.post('estado/disponibilidad_full',{dia:this.dia.fecha,hora:this.hora.hora})
        .then((response)=>{
          this.estado = response.data
          this.updateTime = new Date()
          this.consolidarDocentes()
        })
        .catch(error=>{
          this.setWarning(error)
          console.log(error)
        })
      },
      consolidarDocentes(){
        this.consolidadoDocentes = []
        for(const tipo in this.estado){
          //console.log('analizando...'+tipo)
          if(this.estado[tipo].salones_disponibles){

            for(let salon of this.estado[tipo].salones_disponibles){
              //console.log('   salon...'+salon.nombre)
              
              for(let docente of salon.docentes){
                
                let previo = this.consolidadoDocentes.find(element=>{
                  return element.id == docente.id
                })
                if(previo){
                  if(!previo.salones.find(el=>{return el.id==salon.id})){
                    previo.salones.push(salon)  
                  }
                  
                }else{
                  this.consolidadoDocentes.push({
                    id:docente.id,
                    salones:[salon]
                  })
                }

              }  
              
            }
          }
        }

      },
      
    },
    computed: {
      ...mapState({
        tipos: state => state.asw.tipos, 
      }),
      updateTimeFormated(){
        if(this.updateTime){
          return this.updateTime.toLocaleString('co-ES')  
        }
        return null
      },
      getEstado(tipo){
        if(this.estado){

        }
        
      }
      
    }
    
  }

</script>

<style scope>
.pendientes-container{

}
.marcatiempo{
  font-size: 12px;
  color:#999;
}
.small{
  font-size: 10px !important;
}
.minibutton{
  font-size: 12px !important;
  padding: 0px;
}
</style>