import Vue from 'vue';

const state = {

  metodos_pago :[],
  listaBanners : [],
  detalleBanner: {}
 
};

const actions = {

  fetchDetalleBanners:({commit},banner_id) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('banner/detalle',{id:banner_id}).then(
        response =>{
          commit('setDetalle',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  postGuardar:({commit},data) => {
    commit('startProcessing', null, { root: true });
    console.log(data);
    return new Promise((resolve, reject) => {
      Vue.http.post('banner/guardar',data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  fetchListaBanners:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('banner/lista', data).then(
        response =>{
          commit('setListaBanners',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  editar:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('banner/editar',data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },



};

const getters = {



};

const mutations = {

  setListaBanners: (state, listaBanners) => {
    state.listaBanners = listaBanners;
  },

  restart: (state) => {
    state.agenda_disponible = null
    state.cursos = null
    state.metodos_pago =null
  },
  setDetalle: (state, detalleBanner) => {
    let id = detalleBanner.id;
    Vue.set(state.detalleBanner, id, detalleBanner)
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
