<template>
    <div class="container pt-3 m-auto text-left ">
      <h1>Actualización Masiva</h1>
       <hr>
      <form
        autocomplete="off"
        class="form-horizontal"
      >
          <strong>Estructura:</strong><br>
          <span style="font-size: 12px"><b>Extensión:</b>"Archivo de extensión .csv"</span><br>
          <span style="font-size: 13px"><b>Cuerpo:</b><br>"Codigo del estudiante , metodo de pago</span><br>
          <span style="font-size: 13px">Codigo del estudiante , metodo de pago"</span><br>
        <div >
          <label class="text-reader">
            Archivo plano
            <input type="file" @change="readFile">
          </label><br>
          <label>Nombre archivo: {{nameFile}}</label>
        </div>
        <button v-if="users.length>0 || usersError.length>0" class="btn btn-block btn-danger" type="submit" @click="actualizarDatos()">Procesar</button>
      </form><br>
      <div v-if="usersError.length>0">
        <h4>Lista de errores</h4><hr>
        <b-table striped hover :items="usersError" :fields="fields"></b-table>
      </div>
    </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex';
  import Vue from 'vue';

  export default {
    name: 'Masivo',
    data () {
      return {
        fields: ['id', 'tipo', 'observaciones'],
        nameFile:"",
        file: "",
        methodPay: [],
        users:[],
        usersError:[]
      }
    },
    watch:{
      file(value){
        if(value != "")
          this.valMethodsPay(value);
      }
    },
    mounted () {
      this.methodsPay();
    },
    methods:{
    ...mapActions({
        setWarning: 'setWarning'
    }),
    methodsPay(){
      Vue.http.post('config/metodos_pago').then(
          response =>{
            for(var m in response.data){
              this.methodPay.push(response.data[m].codigo);
            }
          }
      )
      .catch(error=>{
          console.log(error)
      });
    },
    setError(obj, obv){
      obj.observaciones = obv;
      this.usersError.push(obj);
    },
    valMethodsPay(plain){
      this.users = [];
      this.usersError = [];
      var methods = plain.split("\n");
      for(var s = 0; s < methods.length; s++){
        if(methods[s]!=""){
          var user = methods[s].split(",");
          var obj = {
            "id":user[0],
            "tipo":user[1].toLowerCase()
          }
          if(this.valData(user, obj))
            this.users.push(obj);

        }
      }
    },
    valData(user, obj){
      var val = true;
      if(user.length>2 && !user.includes("")){
        this.setError(obj, "Estructura del registro incorrecta");
        val = false;
      }else if (!/^([0-9])*$/.test(user[0])){
        this.setError(obj, "El código no tiene el formato valido");
        val = false;
      }else if(!this.methodPay.includes(user[1].toLowerCase())){
        this.setError(obj, "Metodo de pago incorrecto");
        val = false;
      }
      return val;
    },
    readFile(ev) {
      const arc = ev.target.files[0];
      this.nameFile = arc.name;
      //Valida tipo de dato 
      var type = arc.type.split("/")[1];
      if(type == "csv"){
        const reader = new FileReader();
        reader.onload = e => this.file = e.target.result;
        reader.readAsText(arc);
      }else{
        this.nameFile = "";
        this.file = "";
        this.setWarning("El archivo debe ser de extensión .csv.")
      }
    },
    actualizarDatos () {
      var data = {
        "users" :  this.users,
        "usersErrors" : this.usersError
      }
      Vue.http.post('actualizar_medios_pago', data).then(
          response =>{
              this.file = "";
              this.nameFile = "";
              this.users = [];
              for(var s in response.data){
                this.usersError = response.data;
              }
              console.log(this.usersError);
          }
      )
      .catch(error=>{
          console.log(error)
      }).finally(()=>{
        this.setWarning("Se ejecutó con exito la acción.");
      })
    },

    },
    computed:{
      ...mapState({
        warning: state => state.warning
      }),
    }
  }
</script>

<style>
  .text-reader {
    position: relative;
    overflow: hidden;
    display: inline-block;

    /* Fancy button style 😎 */
    border: 2px solid black;
    border-radius: 5px;
    padding: 8px 12px;
    cursor: pointer;
  }
  .text-reader input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }
</style>