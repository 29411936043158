<template>
  <div class="">

    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_temas"></b-navbar-toggle>
        <h1 v-if="curso" class="text-center navbar-brand pt-0 pb-0 mb-0">{{ curso.nombre }} - Correo Nuevo </h1>
        <b-collapse is-nav id="nav_collapse_temas">
          <!-- Right aligned nav items -->
          
          <b-navbar-nav class="ml-auto">
            <b-nav-item  href="#" @click="cancelar">Cancelar</b-nav-item> 
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>

    <div class="container pt-3 m-auto text-left ">
       
      <form
        autocomplete="off"
        class="form-horizontal"
        @submit.prevent="validateBeforeSubmit"
      >
        <div v-if="correo">
          <div class="academico">

            <!-- <InputTextSimple titulo="Descripción" :modelo="correo.descripcion" @change="correo.descripcion = $event"/> -->
            
            <b-row class="my-1">
              <b-col sm="3">
                <label for="virtual">Tipo:</label>
              </b-col>
              <b-col sm="9">                
                <select id="virtual" v-if="tiposPosibles" v-model="correo.virtual" class="form-control">
                  <option disabled value="">---Seleccione una opción---</option>
                  <option v-for="opcion in tiposPosibles"
                  :value="opcion.id">{{ opcion.nombre}}</option>
                </select>
              </b-col>
            </b-row>

            <b-row class="my-1">
              <b-col sm="3">
                <label for="rango_nota_inicial">Rango Inicial (Mayor):</label>
              </b-col>
              <b-col sm="9">
                <b-form-input step="0.01" id="rango_nota_inicial" type="number" v-model="correo.rango_nota_inicial"></b-form-input>
              </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col sm="3">
                <label for="rango_nota_final">Rango Final (Menor o igual):</label>
              </b-col>
              <b-col sm="9">
                <b-form-input step="0.01" id="rango_nota_final" type="number" v-model="correo.rango_nota_final"></b-form-input>
              </b-col>
            </b-row>
            
            <InputTextSimple titulo="Asunto Correo" :modelo="correo.asunto" @change="correo.asunto = $event"/>
            
            <h5>Cuerpo de correo:</h5>
            <wysiwyg v-model="correo.plantilla_correo" />

            <br>

            <b-form-file 
              v-model="correo.archivo" 
              :state="Boolean(correo.archivo)"
              placeholder="Elige un archivo o suéltalo aquí..."
              drop-placeholder="Suelta el archivo aquí...">
            </b-form-file>
            <div class="mt-3">Evaluación cualitativa: {{ correo.archivo ? correo.archivo.name : '' }}</div>

          </div> 

          <hr>

        </div>
        <button class="btn btn-block btn-danger" type="submit">Guardar</button>
      </form>
      
    </div>
  </div>
  
</template>


<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'CorreoNuevo',
    components: {
      
    },
    data () {
      return {
          curso_id : null,
          correo : {},
          tiposPosibles:[{id:0,nombre:"Presencial"},{id:1,nombre:"Virtual"}]
      }
    },
    mounted () {
      this.correo = this.correoVacio
      this.curso_id = this.$route.params.curso_id;
      if(!this.detalle[this.curso_id]){
            this.actualizarCurso()
        }
    },
    
    methods:{
        ...mapActions({
            fetchCursoDetalle: 'cursos/fetchDetalle',
            crear: 'correos/crear',
            fetchDetalle: 'correos/fetchDetalle',
            setWarning: 'setWarning',
        }),
      ...mapMutations({
        
      }),
      
      cancelar(){
        this.correo = this.correoVacio
        this.$router.push(`/curso/${this.curso_id}/correos`)
      },
      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {
          console.log('validateBeforeSubmit')
          if ( ! result) {
            console.log('validateBeforeSubmit error')
            //hay errores
          } else {
            console.log('validateBeforeSubmit no error')
            this.correo.curso_id = this.$route.params.curso_id;
            const form = new FormData();
            form.append("curso_id", this.correo.curso_id)
            form.append("virtual", this.correo.virtual)
            form.append("rango_nota_inicial", this.correo.rango_nota_inicial)
            form.append("rango_nota_final", this.correo.rango_nota_final)
            if (this.correo.descripcion) {
              form.append("descripcion", this.correo.descripcion)
            }
            if (this.correo.asunto) {
              form.append("asunto", this.correo.asunto)
            }
            if (this.correo.plantilla_correo) {
              form.append("plantilla_correo", this.correo.plantilla_correo)
            }
            if (this.correo && this.correo.archivo) {
              form.append("archivo", this.correo.archivo, this.correo.archivo.name)
            }
            this.crear(form).then(result =>{
                this.setWarning('Plantilla de correo creado', { root: true }).then(()=>{
                  console.log("Correo craedo result:")
                  console.log(result)
                this.fetchDetalle({curso_id:result.curso_id, id:result.id}).then(()=>{
                  this.$router.push(`/curso/${result.curso_id}/correos/${result.id}/detalle`)
                })
              })
            }).catch(error=>{
              //todo probar si se puede quitar
            })
            
          }
        })
      },
      actualizarCurso(){
          this.fetchCursoDetalle(this.curso_id)
      }

    },
    computed:{
      ...mapState({
        tipos: state => state.asw.tipos, 
        examenes: state => state.examenes.lista, 
        detalle: state => state.temas.detalle, 
        warning: state => state.warning,
        detalle_curso: state => state.cursos.detalle, 
      }),

      curso(){
        return this.detalle_curso[this.curso_id]
      },

      correoVacio(){
        return {
          curso_id : null,
          descripcion : null,
          rango_nota_inicial : 0,
          rango_nota_final : 0,
          asunto : null,
          archivo : null,
          plantilla_correo : null,
        }
      },
      
      
    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>
