<template>
   <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_grupos"></b-navbar-toggle>
        <h1 v-if="grupo" class="text-center navbar-brand pt-0 pb-0 mb-0">
        {{ texto_grupo }}</h1>
        <b-collapse is-nav id="nav_collapse_grupos">
          <!-- Right aligned nav items -->
          <!--
          <b-navbar-nav class="ml-auto">
            <a v-if="grupo" :href="`#/grupo/detalle/${ grupo.id }`" class="nav-link" title="Ver">Datos Básicos</a>
            <a v-if="grupo" :href="`#/grupo/editar/${ grupo.id }`" class="nav-link" title="Ver">Editar</a>
          </b-navbar-nav>
          -->
        </b-collapse>
      </div>
    </b-navbar>
</template>

<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  
  export default {
    components: {},
    name: 'GrupoNavigation',
    props:{
      grupo:Object
    },
    methods: {
      ...mapActions({
      }),
    },
    computed:{
    ...mapState({
      }),
    ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      texto_grupo(){
        let texto = ''
        if(this.grupo){
          texto += this.grupo.nombre
          
        }
        return texto
      }
    }
  }
</script>
