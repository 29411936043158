<template>
  <div>
    <div v-if="hora.marca_tiempo==activo" class="programador_wrapper mt-2" :class="{ 'd-none': hora.marca_tiempo!=activo }">
      <div style="font-size: 10px" class="text-center text-muted pt-0 pb-0 mb-0">{{ titulo }}</div>
      <div v-if="cursos" class="row text-center">
        <div class="col" v-if="clases">
          <div class="clases-wrapper">
          <ProgramadorClase  v-for="(clase,index) in clases" :clase="clase" :hora="hora" 
           :marca_tiempo="marcaTiempo" :key="index"/>
          </div>
        </div>
        <div v-if="dia && hora" class="p-0 col-auto position-relative derecha shadow" :class="{ 'd-none': !rightPanel }"
          style="font-size: 12px">
            <strong>Sin Asignar</strong>
    <!--HFV
            <div v-for="curso in cursos" :key="'id'+dia.timestamp+hora.id+curso.id">
              <ProgramadorPendientes tipo_clase="regular" :curso="curso" :hora="hora" :dia="dia" :id="'id'+dia.timestamp+hora.id+curso.id"/>
            </div>
    -->      
            <!--
            <ProgramadorPendientes  tipo_clase="club" :hora="hora" :dia="dia" :id="'id'+dia.timestamp+hora.id"/>-->
            <!--
            <ProgramadorPendientes  tipo_clase="lab" :hora="hora" :dia="dia" :id="'id'+dia.timestamp+hora.id"/>
            <ProgramadorPendientes  tipo_clase="writing" :hora="hora" :dia="dia" :id="'id'+dia.timestamp+hora.id"/>
            <ProgramadorPendientes  tipo_clase="speakup_basico" :hora="hora" :dia="dia" 
            :id="'id'+dia.timestamp+hora.id"/>
            <ProgramadorPendientes  tipo_clase="speakup_avanzado" :hora="hora" :dia="dia" 
            :id="'id'+dia.timestamp+hora.id"/>
            <ProgramadorPendientes  tipo_clase="immersion_basico" :hora="hora" :dia="dia" 
            :id="'id'+dia.timestamp+hora.id"/>
            <ProgramadorPendientes  tipo_clase="immersion_avanzado" :hora="hora" :dia="dia" 
            :id="'id'+dia.timestamp+hora.id"/>
            <ProgramadorPendientes  tipo_clase="immersion_avanzado" :hora="hora" :dia="dia" 
            :id="'id'+dia.timestamp+hora.id"/>
            -->
            <ProgramadorPendientes  v-for="(tipo,index) in tipos" :tipo="tipo" :hora="hora" :dia="dia"  :key="index"
            :id="'id'+dia.timestamp+hora.id"/>
        </div>
      </div>
      <ProgramadorInformeEstado :hora="hora" :dia="dia"/>
    </div>
    
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
  import ProgramadorClase from '@/components/Programador/ProgramadorClase';
  import ProgramadorPendientes from '@/components/Programador/ProgramadorPendientes';
  import ProgramadorInformeEstado from '@/components/Programador/ProgramadorInformeEstado';
  export default {
    name: 'ProgramadorXDiaXHora',
    components: {
      ProgramadorClase,
      ProgramadorPendientes,
      ProgramadorInformeEstado
    },
    data () {
      return {
        
      }
    },
    props : {
      hora: Object,
      activo: String,
      dia: Object,
      rightPanel : Boolean,
    },
    mounted () {},
    methods:{
      ...mapActions({}),
      ...mapMutations({}),
     
    },
    computed: {
      ...mapState({
        cursos: state  => state.asw.cursos,
        tipos: state  => state.asw.tipos,
        todas_las_clases: state => state.clases.clases, 
        todos_los_agendamientos: state => state.agendamientos.agendamientos, 
        todos_los_salones: state => state.salones.lista, 
        todos_los_docentes: state => state.docentes.lista, 
      }),
      clases(){
        let franja = this.todas_las_clases[this.hora.marca_tiempo]
        if(franja){
          let franjaConOrden = franja.map(clase=>{
            if(!clase.tipos){
              clase.tipos = []
            }
            if(clase.tipos.length == 0){
              if(clase.tipo_clase == 'regular' && clase.curso_id){
                let codigo = clase.tipo_clase +'_'+ clase.curso_id
                clase.tipos.push(codigo)
              }else if(clase.tipo_clase){
                clase.tipos.push(clase.tipo_clase)
              }
            }

            let tipoObj =  this.tipos.find((tipo)=>{
                return tipo.id == clase.tipos[0]
              })
            if(tipoObj){
              clase.orden = tipoObj.orden  
            }else {
              clase.orden = 0
            }
            
            return clase
          })
          franjaConOrden.sort(function (a, b) {
            return a.orden - b.orden;
          });
          return franjaConOrden
        }
        return []
        
        //return this.todas_las_clases[this.hora.marca_tiempo]
      },
      ...mapGetters({}),
      titulo(){
        return this.dia.texto+' '+this.hora.nombre_corto
      },
      
      marcaTiempo(){
        return this.hora.marca_tiempo
      },
      
    }
  }
</script>

<style scope>

</style>