<template>
  <div class="">

    <div class="container pt-3 m-auto text-left ">
      <h1>Nuevo Curso</h1>
       <hr>
      <form
        autocomplete="off"
        class="form-horizontal"
        @submit.prevent="validateBeforeSubmit"
      >
        <div>
          <InputTextSimple titulo="Primer Nombre" :modelo="curso.primer_nombre" 
            @change="curso.primer_nombre = $event"/>
          <InputTextSimple titulo="Segundo Nombre" :modelo="curso.segundo_nombre" 
            @change="curso.segundo_nombre = $event"/>
          <InputTextSimple titulo="Primer Apellido" :modelo="curso.primer_apellido" 
            @change="curso.primer_apellido = $event"/>
          <InputTextSimple titulo="Segundo Apellido" :modelo="curso.segundo_apellido" 
            @change="curso.segundo_apellido = $event"/>
          <SelectTipoDocumento titulo="Tipo de Documento" :modelo="curso.tipo_documento" 
            @change="curso.tipo_documento=$event"/>
          <InputNumeroDocumento titulo="Número de Documento" :modelo="curso.numero_documento" 
            @change="curso.numero_documento=$event"/>
          <InputEmailSimple titulo="Email" :modelo="curso.email" 
            @change="curso.email=$event"/>
          <InputMovil titulo="Telefono" :modelo="curso.telefono" 
            @change="curso.telefono=$event"/>
            <InputMovil titulo="Movil" :modelo="curso.movil" 
            @change="curso.movil=$event"/>

        </div>
        <button class="btn btn-block btn-danger" type="submit">Crear</button>
      </form>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'CursoNuevo',
    data () {
      return {
          curso : {
            primer_nombre:null,
            segundo_nombre:null,
            primer_apellido:null,
            segundo_apellido:null,
            tipo_documento:null,
            numero_documento:null,
            email:null,
            movil:null,
            telefono:null,
          }
      }
    },
    mounted () {
    },
    methods:{
      ...mapActions({
        crear: 'cursos/crear',
        setWarning: 'setWarning',
        fetchLista: 'cursos/fetchLista',
      }),
      ...mapMutations({
      }),
      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {
          if ( ! result) {
            //hay errores
          } else {
            this.crear(this.curso).then(()=>{
                this.setWarning('Curso Creado', { root: true }).then(()=>{
                this.fetchLista().then(()=>{
                    this.$router.push('/cursos')
                })
              })
            }).catch(error=>{
              //todo probar si se puede quitar
            })
            
          }
        })
      },

    },
    computed:{
      ...mapState({
        warning: state => state.warning
      }),
    }
  }
</script>

<style scope>
  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>
