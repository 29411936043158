<template>
  <div class="">

    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_salones"></b-navbar-toggle>
        <h1  class="text-center navbar-brand pt-0 pb-0 mb-0">Editar Salon</h1>
        <b-collapse is-nav id="nav_collapse_salones">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item  href="#" @click="procesarCancelar">Cancelar</b-nav-item>
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <div class="container pt-3 m-auto text-left ">
       <hr>
       <h2>Datos básicos</h2>
      <form
        autocomplete="off"
        class="form-horizontal"
        @submit.prevent="validateBeforeSubmit"
      >
        <div v-if="salon">
          <InputTextSimple titulo="Nombre" :modelo="salon.nombre" 
            @change="salon.nombre = $event"/>
            <!--
          <InputTextSimple titulo="Numero" :modelo="salon.numero" 
            @change="salon.numero = $event"/>
          -->
          <InputNumero titulo="Capacidad" :modelo="salon.capacidad" 
            @change="salon.capacidad = $event"/>
            <!--
          <SelectTipoClase titulo="Tipo Salon" :modelo="salon.tipo_salon" 
            @change="salon.tipo_salon = $event"/>
          -->
          <SelectActivo titulo="Activo" :modelo="salon.activo ? salon.activo.toString() : '0'" 
            @change="salon.activo=$event"/>

          <InputTextSimple titulo="Url" :modelo="salon.url" 
            @change="salon.url = $event"/>
        </div>
        <button class="btn btn-block btn-danger" type="submit">Guardar</button>
      </form>
      <!--
      <div v-if="salon">
        <hr>
        <h2>Cursos Permitidos</h2>
        <SalonEditarCursos :salon="salon"></SalonEditarCursos>
      </div>
    -->
      <div v-if="salon">
        <hr>
        <h2>Tipos Permitidos</h2>
        <SalonEditarTipos :salon="salon"></SalonEditarTipos>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import SalonEditarTipos from '@/components/Salones/SalonEditarTipos';

  export default {
    name: 'SalonEditar',
    components: {
      SalonEditarTipos
    },
    data () {
      return {
           salon_id: null,
      }
    },
    mounted () {
     this.salon_id = this.$route.params.id;
      if(!this.detalle[this.salon_id]){
        this.actualizar()
      }
    },
    
    methods:{
      ...mapActions({
        fetchDetalle: 'salones/fetchDetalle',
        editar: 'salones/editar',
        setWarning: 'setWarning',
        fetchLista: 'salones/fetchLista',
      }),
      ...mapMutations({
        
      }),
      procesarCancelar(){
        this.fetchDetalle(this.salon_id).then(()=>{
          this.$router.push('/salon/detalle/'+this.salon_id)
        })
      },
      actualizar(){
        this.fetchDetalle(this.salon_id)
      },
      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {
          
          if ( ! result) {
            console.log('validateBeforeSubmit error')
            //hay errores
          } else {
            
            this.editar(this.salon).then(()=>{
                this.setWarning('Salon Actualizado', { root: true }).then(()=>{
                this.fetchDetalle(this.salon_id).then(()=>{
                  this.fetchLista()
                  this.$router.push('/salon/detalle/'+this.salon_id)
                })
              })
            }).catch(error=>{
              //todo probar si se puede quitar
            })
            
          }
        })
      },

    },
    computed:{
      ...mapState({
        detalle: state => state.salones.detalle, 
        warning: state => state.warning
      }),
      salon(){
        return this.detalle[this.salon_id]
      },
      
      
    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>