<template>
  <div class="">
    <div v-if="reporte">
          
          <hr style="border-color: #ddd !important">
          <h5>CLASES CREADAS</h5>
          <div class="row"> 
            <div class="col-3"><strong>Salón</strong></div>
            <div class="col-3"><strong>Docente</strong></div>
            <div class="col-1"><strong>Capacidad</strong></div>
            <div class="col-1"><strong>Ocupación</strong></div>
            <div class="col-1"><strong>Disponible</strong></div>
          </div>  
          <div class="row" v-for="salon in reporte.salones_ocupados">
              
              <div class="col-3">
                <a :href="`#/salon/detalle/${ salon.id }`" target="_blank" v-if="permiso('AE7C3DD1')" class="btn btn-link btn-sm" title="Ver">{{salon.nombre}} </a>
                <span class="text-mini" v-if="salon.clase && salon.clase.created_at">{{salon.clase.created_at}}</span>
              </div>
              <div class="col-3">
                <a :href="`#/docente/detalle/${ salon.clase.profesor.id }`" target="_blank" v-if="permiso('42C7BC3F')" class="btn btn-link btn-sm" title="Ver">{{salon.clase.profesor.primer_nombre}} {{salon.clase.profesor.primer_apellido}}, </a>
              
              </div>
              <div class="col-1">{{salon.capacidad}}</div>
              <div class="col-1">{{salon.clase.agendamientos.length}}</div>
              <div class="col-1">{{salon.capacidad-salon.clase.agendamientos.length}}</div>
              
          </div>
          <hr style="border-color: #ddd !important">
          <h5>CLASES POSIBLES</h5>
          <div class="row"> 
            <div class="col-3"><strong>Salón</strong></div>
            <div class="col-3"><strong>Docente</strong></div>
            <div class="col-1"><strong>Capacidad</strong></div>
            <div class="col-1"><strong>Ocupación</strong></div>
            <div class="col-1"><strong>Disponible</strong></div>
          </div>  
          <div class="row posibles" v-for="salon in reporte.salones_disponibles"> 
              <div class="col-3">
                <a :href="`#/salon/detalle/${ salon.id }`" target="_blank" v-if="permiso('AE7C3DD1')" class="btn btn-link btn-sm" title="Ver">{{salon.nombre}} </a>
              </div>
              <div class="col-3">
                <span v-for="docente in salon.docentes">
                  <a :href="`#/docente/detalle/${ docente.id }`" target="_blank" v-if="permiso('42C7BC3F')" class="btn btn-link btn-sm" title="Ver">{{docente.primer_nombre}} {{docente.primer_apellido}}, </a>
                </span>
              </div>
              <div class="col-1">{{salon.capacidad}}</div>
              <div class="col-1">0</div>
              <div class="col-1">{{salon.capacidad}}</div>
          </div>
          <hr style="border-color: #ddd !important">
          <div class="row" v-if="reporte.ocupacion_sin_asignar">
              
              <div class="col-3"><h5>ESTUDIANTES SIN ASIGNAR</h5></div>
              <div class="col-3"><strong></strong></div>
              <div class="col-1"><strong></strong></div>
              <div class="col-1"><strong>{{reporte.ocupacion_sin_asignar}}</strong></div>
              <div class="col-1"><strong></strong></div>
              
          </div>
          <hr style="border-color: #ddd !important">
          <div class="row" >
              
              <div class="col-3"><h5>TOTALES</h5></div>
              <div class="col-3"><strong></strong></div>
              <div class="col-1"><strong>{{totalCapacidad}}</strong></div>
              <div class="col-1"><strong>{{totalOcupacion}}</strong></div>
              <div class="col-1"><strong>{{totalCapacidad-totalOcupacion}}</strong></div>
              
          </div>

      
    </div>
    
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';

  export default {
    name: 'ProgramadorInformeEstadoTipo',
    components: {
      
    },
    data () {
      return {
        
      }
    },
    props : {
      reporte: Object,
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({
      }),
      ...mapMutations({
      }),
      
      
    },
    computed: {
      ...mapState({
        
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      totalCapacidad(){
        return (this.reporte.capacidad_salones_disponibles*1) + (this.reporte.capacidad_salones_ocupados*1)
      },
      totalOcupacion(){
        let ocupacion = (this.reporte.ocupacion_salones_ocupados*1)
        if(this.reporte.ocupacion_sin_asignar){
          ocupacion += (this.reporte.ocupacion_sin_asignar*1)
        }
        return ocupacion
      },

    }
  }
</script>

<style scope>
.pendientes-container{

}
.posibles span{
  font-size: 10px;

}
.text-mini{
  font-size: 8px;
  color:#777;
  font-style: italic;
}
</style>