<template>
  <div class="">
   
    <div class="container pt-3 m-auto text-left ">
      <div v-if="estudiante">
        <b-row>
          <b-col cols="2" class="celda-head text-center" >Fecha</b-col>
          <b-col cols="2" class="celda-head text-center" >Usuario</b-col>
          <b-col cols="" class="celda-head text-center" >Observacion</b-col>
          
        </b-row>
        <b-row class="fila" v-for="(observacion, index) in observacionesFormatted" :key="index">
          <b-col cols="2" >{{observacion.fecha}}</b-col>
          <b-col cols="2" >{{observacion.usuario}}</b-col>
          <b-col cols="" >{{observacion.observacion}}</b-col>
        </b-row>
      </div>
      <hr>
    </div>

    <b-button size="sm"  @click="creando=true">Crear Nueva</b-button>

    <b-modal @ok="procesarCrear"  @cancel="reiniciarCandidato" v-model="creando"  id="modalCrearObservacion"
    :ok-disabled="!puedeProcesarCrear"  title="Nueva Observacion">

      <div class="mb-2" >
        <h5 class="my-1">Observacion:</h5>

        <div >
          <b-form-textarea
            id="textarea"
            v-model="candidato.observacion"
            placeholder="Escriba su observación."
            rows="3"
            max-rows="6"
          ></b-form-textarea>

        </div>
      </div>

    </b-modal>
    <div v-if="procesando">
      <BlockUI message="procesando..."></BlockUI>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';

  import utils from '@/modules/utils';

  export default {
    name: 'DetalleObservaciones',
    components: {
      
    },
    data () {
      return {
      
        estudiante_id: null,
        observaciones:[],
        creando: false,
        procesando: false,
        motivoMin: 30,
        motivoSeleccionado: null,
        candidato : {
          estudiante_id:null,
          observacion:''
        }
      }
    },
    mounted () {
     this.estudiante_id = this.$route.params.id;
     if(!this.detalle[this.estudiante_id]){
        this.actualizarEstudiante()
      }
      if(this.observaciones.length == 0 ){
        this.actualizar()
      }
    },
    
    methods:{
      ...mapActions({
        fetchDetalle: 'estudiantes/fetchDetalle',
        setWarning: 'setWarning',
        fetchLista: 'estudiantes/fetchLista',
      }),
      ...mapMutations({
        
      }),
      procesarCancelar(){
        
      },
      procesarCrear(){
        this.procesando = true
          this.candidato.estudiante_id = this.estudiante_id

          Vue.http.post('estudiante_observaciones/crear',this.candidato).then()
          .catch(error=>{
            this.setWarning(error.data)
            console.log(error)
          }).finally(()=>{
            this.procesando = false
            this.actualizar()
            this.reiniciarCandidato()
          })
          
      },
      actualizarEstudiante(){
        this.fetchDetalle(this.estudiante_id)
      },
      actualizar(){
        this.procesando = true
        Vue.http.post('estudiante_observaciones/lista',{estudiante_id:this.estudiante_id}).then(
          response =>{
            this.observaciones = response.data
          }
        )
        .catch(error=>{
          console.log(error)
        }).finally(()=>{
          this.procesando = false
        })

      },
      reiniciarCandidato(){
        this.candidato = {
          estudiante_id:this.estudiante_id,
          observacion:''
        }
        this.motivoSeleccionado = null
      },

    },
    computed:{
      ...mapState({
        detalle: state => state.estudiantes.detalle, 
        warning: state => state.warning,
        disponibilidad_general: state => state.asw.disponibilidad_general, 
      }),
      estudiante(){
        return this.detalle[this.estudiante_id]
      },
      puedeProcesarCrear(){
        if(this.candidato && this.candidato.observacion){
          return true
        }
        return false
      },

      observacionesFormatted(){
        return this.observaciones.map((element)=>{
          
          let fechaDate = new Date(element.created_at)
          let fecha_formatted = fechaDate.toLocaleString()
          let usuario_formatted = element.usuario.nombre+', '+element.usuario.identificacion+', '+element.usuario.email

          return {
            fecha : fecha_formatted,
            observacion : element.observacion,
            usuario : usuario_formatted
          }
        })
      }
      
    }
    
  }
</script>
 