<template>
  <div class="perfil_wrapper mt-3">

    <div class="container col-lg-6 m-auto">
      <h1 class="text-center text-muted">Mi Cuenta</h1>
      <hr>
      <div class="row">
        <div class="col-3"><strong>Primer Nombre:</strong></div><div class="col-9">{{ user.data.primer_nombre }}</div>
      </div>
      <div class="row">
        <div class="col-3"><strong>Segundo Nombre:</strong></div><div class="col-9">{{ user.data.segundo_nombre }}</div>
      </div>
      <div class="row">
        <div class="col-3"><strong>Primer Apellido:</strong></div><div class="col-9">{{ user.data.primer_apellido }}</div>
      </div>
       <div class="row">
        <div class="col-3"><strong>Segundo Apellido:</strong></div><div class="col-9">{{ user.data.segundo_apellido }}</div>
      </div>
      <div class="row">
        <div class="col-3"><strong>Correo:</strong></div><div class="col-9">{{ user.data.email }}</div>
      </div>
      <div class="row">
        <div class="col-3"><strong>Documento:</strong></div><div class="col-9">{{ user.data.numero_documento }}</div>
      </div>
      <div class="row">
        <div class="col-3" >
          <strong>Sede:</strong>
        </div>
        <div class="col-9" v-if="sede && sede.nombre">{{ sede.nombre }}
          <b-button class="" size="sm" variant="success"  v-if="sedesPosibles" @click="iniciarCambiarSede()">
            Cambiar Sede
          </b-button>
        </div>
      </div>
      <hr>
      


      <b-modal  @ok="procesarCambiarSede" @cancel="cancelarCambiarSede" 
        @hide="cancelarCambiarSede" 
        no-close-on-backdrop   ok-title="Cambiar Sede" cancel-title="Cancelar"
        v-model="mostrarCambiarSede"   id="modalCambiarSede"
        title="Cambiar sede" >
        Nueva sede:
        <select v-if="sedesPosibles" v-model="nuevaSede" class="form-control">
          <option disabled value="">---SIN SALON---</option>
          <option v-for="opcion in sedesPosibles"
          :value="opcion.id">{{ opcion.nombre}}</option>
        </select>
    </b-modal>



    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';


  export default {
    name: 'perfil',
    data () {
      return {
        nuevaSede : null,
        mostrarCambiarSede: false
      }
    },
    mounted () {
        if(!this.todasLasSedes || (this.todasLasSedes && this.todasLasSedes.length==0)){
          this.fetchSedes()
        }
    },
    methods:{
      ...mapActions({
        fetchSedes: 'sedes/fetchLista',
        logout: 'auth/logout',
      }),
      ...mapMutations({
        startProcessing: 'startProcessing',
        stopProcessing: 'stopProcessing'
      }),
      iniciarCambiarSede(){
        this.mostrarCambiarSede = true;
      },
      procesarCambiarSede(){

        this.startProcessing()
        Vue.http.post('cambiarme_sede',{sede_id:this.nuevaSede})
          .then(response=>{
              //TODO . MOSTRAR ALERT
              this.logout().then(
                this.$router.push('/login')
              )
          })
          .catch(error=>{
            this.setWarning(error)
            console.log(error)
          }).finally(()=>{
            this.stopProcessing()
            
          })


      },
      cancelarCambiarSede(){
        this.nuevaSede = null;
        this.mostrarCambiarSede = false;
      }
        
    },
    computed:{
      ...mapState({
        user: state => state.auth.user, 
        sede: state => state.auth.sede,
        todasLasSedes: state  => state.sedes.lista,
        
      }),
      ...mapGetters({
        
      }),
      sedesPosibles(){
        if(this.user && this.user.data && this.user.data.sedes_permitidas && this.user.data.sedes_permitidas.length>0){
          return this.todasLasSedes.filter(element=>{
            return this.user.data.sedes_permitidas.includes(element.id) 
          })
        }
        return false
      }
    }
    
  }
</script>

<style scope>

	.perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
	}
</style>