<template>
  <div>
    <h4 v-if="dia" class="my-1">{{ dia.fecha }} {{ hora.hora }}</h4>
    <h4 v-if="estudiante" class="my-1">{{estudiante.primer_nombre}} {{estudiante.segundo_nombre}} {{estudiante.primer_apellido}} {{estudiante.segundo_apellido}}  /  {{estudiante.curso_actual.nombre}}
    </h4>
    <h6 v-if="tema_siguiente" class="my-1">{{temaSiguienteTexto}}
    </h6>

    <div class="form-group">
      <!--
     <b-btn :variant="btnNormalV" class="mx-1" size="sm" @click="setTipoSolicitud('normal')">
    Normal</b-btn>
  -->
    <!--
     <b-btn :variant="btnTutoriaV" class="mx-1" size="sm" @click="setTipoSolicitud('tutoria')">
     Tutoria</b-btn>
     <b-btn :variant="btnRefuerzoV" class="mx-1" size="sm" @click="setTipoSolicitud('refuerzo')">
     Refuerzo</b-btn>
   -->
    </div>

    <!-- ESTUDIANTE -->
    <div v-if="!estudiante" class="form-group">
      <input class="form-control" type="text" v-model="busqueda" name="el titulo" placeholder="Buscar estudiante" />
      <div v-if="resultados && resultados.length>0">
          <div class="btn-link" v-for="opcion in resultados" @click="seleccionarEstudiante(opcion)">
            <div v-if="opcion.curso_actual">
                {{opcion.primer_nombre}} {{opcion.segundo_nombre}} {{opcion.primer_apellido}} {{opcion.segundo_apellido}}  /  {{opcion.curso_actual.nombre}}
            </div> 
            
          </div>
        </div>
    </div>
    <div>
      <b-alert show variant="danger" v-if="error">{{ error }}</b-alert>
      <b-alert show variant="info" v-if="respuesta">{{ respuesta }}</b-alert>
    </div>
    <div class="mb-2" v-if="estudiante">
      <h4 class="my-1">Motivo del agendamiento:</h4>
      <b-form-textarea
      id="textarea"
      v-model="motivo"
      placeholder="Cuéntanos, por qué es necesario hacer esta programación manual?"
      rows="3"
      max-rows="6"
    ></b-form-textarea>
    <pre class="d-block text-right">{{ motivo.length }}/{{ motivoMin }}</pre>
    </div>
    <div class="d-block text-right">
      <b-btn v-if="puedeAgendar" class="mx-1" variant="primary" @click="procesarAgendar">Agendar</b-btn>
      <b-btn class="mx-1" variant="danger" @click="cancelar">Cancelar</b-btn>
      
    </div>
    
    <div v-if="procesando">
      <BlockUI message="procesando..."></BlockUI>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';
  export default {
    name: 'ProgramadorProgramador',
    components: {
      
    },
    data () {
      return {
       tipo_solicitud : 'normal',
       tema_siguiente : null,
       resultados: null,
       termino: null,
       estudiante : null,
       motivo: '',
       motivoMin: 40,
       error : null,
       respuesta : null,
       procesando : false
      }
    },
    props:{
      dia: Object,
      hora: Object,
    },
    mounted () {
      console.log('programador mounted')
    },
    methods:{
      ...mapActions({
        fetchDetalle: 'estudiantes/fetchDetalle',
        fetchHorarios: 'estudiantes/fetchHorarios',
        fetchAgendamientos: 'estudiantes/fetchAgendamientos',
        fetchAgendamientosAll : 'agendamientos/fetchAgendamientos',
        fetchAgendamientosXMarca : 'agendamientos/fetchAgendamientosXMarca',
        agendar: 'programacion/agendar',
        fetchClasesXMarca : 'clases/fetchClasesXMarca',
      }),
      ...mapMutations({
        nuevaClase: 'clases/nuevaClase',
      }),
      setTipoSolicitud(value){
        this.tipo_solicitud = value
      },
      

      cancelar(){
        
        this.resultados = null
        this.termino = null
        this.tema_siguiente = null
        this.estudiante = null
        this.error = null
        this.respuesta = null
        this.motivo = ''
        
        this.$root.$emit('bv::hide::modal','modalprogramador')
      },
      seleccionarEstudiante(value){
        this.resultados = null
        this.estudiante = value
        this.procesarVerificarTipoClase()
      },
      procesarAgendar(){
        this.procesando = true

        this.agendar({
          hora:this.hora.hora,
          dia:this.dia.fecha,
          id:this.estudiante.id,
          tipo_solicitud:this.tipo_solicitud,
          motivo:this.motivo,
          
        }).then((apiResponse)=>{

        }).catch((apiResponse)=>{
          if(apiResponse.data){
            if(apiResponse.data.error){
              this.error = apiResponse.data.error
            }else{
              this.error = apiResponse.data
            }
          }else{
              this.error = apiResponse
          }
          console.log(this.error)
        }).finally(()=>{
          this.fetchAgendamientosXMarca({marca_tiempo:this.hora.marca_tiempo}).then(()=>{})
          this.fetchClasesXMarca({marcatiempo:this.hora.marca_tiempo})
          this.cancelar()
          this.procesando = false
        })
      },

      procesarVerificarTipoClase(){
        this.procesando = true

        Vue.http.post('agendamiento/calcular_siguiente_tema',{estudiante_id:this.estudiante.id}).then(
          response =>{
            this.tema_siguiente = response.data
          }
        )
        .catch(error=>{
          console.log(error)
        }).finally(()=>{
          this.procesando = false
        })
      },
      
    },
    computed: {
      ...mapState({
        tipos_clase: state => state.asw.tipos_clase, 

      }),
      ...mapGetters({
        getNombreLargo: 'asw/getNombreLargo', 
        permiso: 'auth/permiso', 
      }),
      
      busqueda: {
        get () {
          return this.termino
        },
        set(value){
          this.termino = value 
          if(value && value.length >= 5){
            Vue.http.post('estudiante/buscar',{texto:value,curso_id:null,activos:'true'}).then(
              response =>{
                this.resultados = response.data
              }
            )
            .catch(error=>{
              console.log(error)
            }).finally(()=>{
              
            })
          }
        }
      },
      temaSiguienteTexto(){
        let texto = ''
        if(this.tema_siguiente){
          texto += 'Siguiente tema: '+ this.tema_siguiente.nombre +', '+ this.getNombreLargo(this.tema_siguiente.tipo)
        }
        return texto
      },
      btnNormalV(){
        if(this.tipo_solicitud == 'normal'){
          return 'success'
        }else{
          return 'secondary'
        }
      },
      btnTutoriaV(){
        if(this.tipo_solicitud == 'tutoria'){
          return 'success'
        }else{
          return 'secondary'
        }
      },
      btnRefuerzoV(){
        if(this.tipo_solicitud == 'refuerzo'){
          return 'success'
        }else{
          return 'secondary'
        }
      },
      btnLabV(){
        if(this.tipo_solicitud == 'lab'){
          return 'success'
        }else{
          return 'secondary'
        }
      },
      btnClubV(){
        if(this.tipo_solicitud == 'club'){
          return 'success'
        }else{
          return 'secondary'
        }
      },
      puedeAgendar(){
        if(this.tipo_solicitud && this.estudiante && this.tema_siguiente && this.motivo.length >= this.motivoMin){
          return true
        }
        return false
      }
      
    }
  }
</script>

<style scope>
.izquierda{
	padding-bottom: 30px
}
</style>