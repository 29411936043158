<template>
  <div>
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_grupos"></b-navbar-toggle>
        <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Grupos</h1>
        <b-collapse is-nav id="nav_collapse_grupos">
          <!-- Right aligned nav items -->
          <!-- NO TENEMOS POR AHORA OPCION PARA CREAR GRUPO POR PANTALLA
          <b-navbar-nav class="ml-auto">
            <router-link class="nav-link" to="/grupo/nuevo" >Nuevo</router-link>
          </b-navbar-nav>
          -->
        </b-collapse>
      </div>
    </b-navbar>

    <div class="container grupos_wrapper mt-3">
      <div  class="tabla">
        <v-client-table name="gruposTable" :columns="columns" :data="lista" :options="options">
          <div slot="accion" slot-scope="lista"><a :href="`#/grupo/detalle/${ lista.row.id }`" class="btn btn-secondary btn-sm" title="Ver">Ver</a></div>
        </v-client-table>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';

  export default {
    name: 'GruposLista',
    data () {
      return {
        columns: ['nombre','accion'],
        options: {
          filterable: true,
          filterByColumn: false,
          sortable:[],
          perPage: 10,
          perPageValues: [5, 10, 15, 20],
          headings: {
            id: 'Id',
            nombre: 'Grupo',
            accion: 'Accion'
          }
        }
      }
    },
    mounted () {
      if(!this.lista || (this.lista && this.lista.length==0)){
        this.fetchLista()
      }
    },
    methods:{
      ...mapActions({
        fetchLista: 'grupos/fetchLista',
      }),
      ...mapMutations({
      }),
    },
    computed: {
      ...mapState({
        lista: state => state.grupos.lista, 
      }),
    }
  }
</script>

<style scope>

</style>