
<template>
  <b-container>
    <h1>Estudiantes Activos</h1>
    <div class="chart-container">
    <DashboardEstudiantesActivosChart ref="chart" :chartdata="data" :options="options" :width="500" :height="200"></DashboardEstudiantesActivosChart>
    </div>
    <b-button size="sm" variant="link" @click="actualizar">Actualizar</b-button>
  </b-container>
</template>


<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import DashboardEstudiantesActivosChart from '@/components/Dashboard/DashboardEstudiantesActivosChart';

  export default {
    name: 'DashboardEstudiantesActivos',
    components:{
DashboardEstudiantesActivosChart
    },
    data(){
      return {
        data: {
            labels:["NORTE","CENTRO","ADMINISTRATIVO","SUBA","TITAN","DIVER PLAZA","CHICO","CALLE 72","CALLE 45","CORFERIAS","RESTREPO","TUNAL","BOSA","SOACHA","PLAZA DE LAS AMERICAS","VILLAVICENCIO","ARMENIA","PEREIRA","MOSQUERA","PREMIUM","FLORIDA","ENVIGADO","MANIZALES","CALI","TUNJA"],
            datasets: [
            {
              label: 'Estudiantes Activos',
              data:[3268,3943,7,1564,556,779,1046,1358,1916,875,1392,1495,1227,997,1579,156,33,108,816,1139,745,742,143,37,0],
              backgroundColor: '#f87979',
            }
          ]
        },
        
        options: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }],
                xAxes: [{
                    gridLines: {
                        offsetGridLines: true
                    }
                }]
            }
        }
      }
    },
    mounted () {
    	
    },
    methods:{
      ...mapActions({
      	fetchEstudiantesActivos: 'indicadores/fetchEstudiantesActivos',
      }),
      ...mapMutations({
      }),
      actualizar(){
        this.fetchEstudiantesActivos()
        .then(()=>{
          this.data.labels=this.estudiantesActivos.labels
          this.data.datasets[0].data=this.estudiantesActivos.values
          //this.$data._chart.update()
          this.$refs.chart.update()
          
        })
      }
    },
    computed:{
      ...mapState({
      	estudiantesActivos: state => state.indicadores.estudiantesActivos, 
      }),
      ...mapGetters({   
      }),
    }
  }
</script>

<style scope>
.chart-container{
  text-align: center;
  margin: auto;
}
	
</style>