<template>
  <div class="d-inline-flex  clase-light position-relative" :style="{backgroundColor:color}">
    <div class="w-100">
      <div class="w-100" v-if="clase">
        <ProgramadorSeleccioneTipoClase @change="setTipoClase($event)" :clase="clase" :editando="editando"/>
        <ProgramadorSeleccioneCurso @change="setCursoId($event)" 
        :clase="clase" :editando="editando" :opciones="cursos_disponibles"/>
        <ProgramadorSeleccioneSalon  @change="setSalonId($event)" 
        :clase="clase" :editando="editando" :marca_tiempo="marca_tiempo" />
        <ProgramadorSeleccioneDocente @change="setDocenteId($event)" 
        :clase="clase" :editando="editando" :marca_tiempo="marca_tiempo" :marca_relativa="marca_relativa" />
        
        <div v-if="marca_clase && clase.id">
          <div class=""  v-model="clase.agendamientos" :options="dragOptions"
            @add="add"
            @remove="remove" 
          >
              <div v-for="element in clase.agendamientos" :key="element.id" :accessKey="element.id"
              class="item">
                <ProgramadorAgendamiento :agendamiento='element'/>  
               <!-- <h5>{{ estado.agendamiento }}</h5> -->
              </div>   
              
          </div>
        </div>

      </div>
      
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import ProgramadorSeleccioneSalon from '@/components/Programador/ProgramadorSeleccioneSalon';
  import ProgramadorSeleccioneCurso from '@/components/Programador/ProgramadorSeleccioneCurso';
  import ProgramadorSeleccioneDocente from '@/components/Programador/ProgramadorSeleccioneDocente';
  import ProgramadorSeleccioneTipoClase from '@/components/Programador/ProgramadorSeleccioneTipoClase';
  import ProgramadorAgendamiento from '@/components/Logistica/ProgramadorAgendamiento';
  
  export default {
    name: 'ProgramadorClase',
    components: {
      ProgramadorSeleccioneSalon,
      ProgramadorSeleccioneCurso,
      ProgramadorSeleccioneDocente,
      ProgramadorSeleccioneTipoClase,
      ProgramadorAgendamiento
    },
    data () {
      return {
        cambio : false,
        marca_relativa : null,
        editando: false
      }
    },
    props: {
      clase:Object,
      hora:Object,
      marca_tiempo:String
    },
    beforeCreate() {
    },
    mounted () {
      let date = new Date(this.marca_tiempo)
      let dia = date.getDay()
      this.marca_relativa = dia+'T'+this.hora.hora
    },
    methods:{
      ...mapActions({
        fetchCursos : 'asw/fetchCursos',
        fetchSalones : 'salones/fetchLista',
        fetchDocentes : 'docentes/fetchLista',
        crear : 'clases/crear',
        eliminar : 'clases/eliminar',
        confirmar : 'clases/confirmar',
        actualizar : 'clases/fetchClase',
        setClase : 'agendamientos/setClase'
      }),
      ...mapMutations({
        cancelarNuevaClase: 'clases/cancelarNuevaClase',
      }),
      procesarConfirmar(){
        this.confirmar({clase_id:this.clase.id})
      },
      procesarEliminar(){
        this.eliminar(this.clase)
      },
      procesarActualizar(){
        this.actualizar(this.clase.id)
      },
      procesarCancelar(){
        if(this.clase.id){
          this.editando = false
          this.cambio = false
          this.actualizar(this.clase.id)
        }else{
          this.cancelarNuevaClase({marca:this.marca_tiempo,id_temporal:this.clase.id_temporal})
        }
      },
      procesarEditar(){
        this.editando = true
      },
      setCursoId(valor){
        console.log('setCurso:'+valor)
        this.clase.curso_id = valor
        this.cambio = true
      },
      setSalonId(valor){
        console.log('setSalonId:'+valor)
        this.clase.salon_id = valor
        this.cambio = true
      },
      setDocenteId(valor){
        console.log('setDocenteId:'+valor)
        this.clase.profesor_id = valor
        this.cambio = true
      },
      setTipoClase(valor){
        console.log('setTipoClase:'+valor)
        this.clase.tipo_clase = valor
        this.cambio = true
      },
      procesar(){
        console.log('procesar:')
        this.crear(this.clase).then((data)=>{
          console.log("Clase guardada")
          this.cambio=false;
          this.editando=false;
        }).catch((error)=>{
          console.log("error procesando guardar clase")
        })
      },
      add(event){
        console.log('add to clase')
        let key = event.item.accessKey
        this.setClase({clase_id:this.clase.id,agendamiento_id:key}).catch((error)=>{
          console.log("error add")
          console.log("error add:"+error)
        })
      },
      remove(event){
        console.log('remove to clase')
        let key = event.item.accessKey
        console.log(key)
      },
    },
    computed: {
      ...mapState({
        clases: state => state.clases.clases, 
        cursos_disponibles_state: state  => state.asw.cursos,
        salones_disponibles_state: state  => state.salones.lista,
        docentes_disponibles_state: state  => state.docentes.lista,
        tipos: state => state.asw.tipos, 
      }),
      puedeEditar(){
        if(!this.clase.id){
         return true
        }
        return this.editando
      },
      /*
      salon(){
        if(this.clase.salon){
          return this.clase.salon
        }else if(this.clase.salon_id>0){
          let _salon = this.salones_disponibles_state.find((salon)=>{
            return salon.id == this.clase.salon_id
          })
          return _salon
        } 
        return null
      },
      */
      docente(){
        if(this.clase.profesor){
          return this.clase.profesor
        }else if(this.clase.profesor_id>0){
          let _docente = this.docentes_disponibles_state.find((docente)=>{
            return docente.id == this.clase.profesor_id
          })
          return _docente
        } 
        return null
      },
      cursos_disponibles(){
        if(this.cursos_disponibles_state && this.cursos_disponibles_state.length>0){
          return this.cursos_disponibles_state
        }else{
          //this.fetchCursos()
          return null
        }
      },
      salones_disponibles(){
        console.log('ProgramadorClase salones_disponibles')
        if(this.salones_disponibles_state && this.salones_disponibles_state.length>0){
          let salones_sin_clase = this.salones_disponibles_state.filter((salon)=>{
            let hay_clase = this.clases[this.hora.marca_tiempo].find((clase)=>{
              return clase.salon_id == salon.id
            })
            return !hay_clase
          })
          return salones_sin_clase
        }else{
          //this.fetchSalones()
        }
      },
      
      
      dragOptions(){
        return {group:this.marca_clase,draggable:'.item'}
      },
      puedeGuardar(){
        if(this.cambio){
          return true
        }
        if(!this.clase.id && this.clase.curso_id>=0 && this.clase.salon_id>=0 && this.clase.profesor_id>=0 && this.clase.tipo_clase){  
          return true
        }
        return false
      },
      marca_clase(){
        if(!this.hora || !this.clase){
          return null
        }
        if(this.curso){
          return this.hora.marca_tiempo+'C'+this.curso.codigo  
        }else{
          return this.hora.marca_tiempo
        }
      },
      curso(){
        if(this.clase && this.clase.curso_id>0){
          return this.cursos_disponibles_state.find((element)=>{
            return element.id == this.clase.curso_id
          })
        }else{
          return null
        }
      },
      
      color(){
        if(this.clase && this.clase.tipos && this.clase.tipos.length>0){
          let tipoObj =  this.tipos.find((element)=>{
              return element.id == this.clase.tipos[0]
            })
          if(tipoObj){
            return '#'+tipoObj.color  
          }
        }
        return '#aaa'
      }
      

    }

  }
</script>

<style scope>
  .clase-light{
    font-size: 12px;
    max-width: 200px;
    min-width: 150px;
    border-right: 1px solid #ccc;
  }


</style>