<template>
    <div>
      <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
        <div class="container">
          <b-navbar-toggle target="nav_collapse_cursos"></b-navbar-toggle>
          <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">{{ curso.nombre }}</h1>
          <b-collapse is-nav id="nav_collapse_cursos">
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <router-link class="nav-link" :to="`/curso/detalle/${ curso.id }`" >Volver</router-link>
                <router-link class="nav-link" :to="`/curso/${ curso.id }/correos/nuevo`" >Nuevo</router-link>
              </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
      <div class="container cursos_wrapper mt-3">
        <div v-if="lista" class="tabla">
          <v-client-table name="cursosTable" :columns="columns" :data="lista" :options="options">
            <div slot="accion" slot-scope="lista"><a :href="`#/curso/${lista.row.curso_id}/correos/${ lista.row.id }/detalle`" class="btn btn-secondary btn-sm" title="Ver">Ver</a></div>
            
            <span slot="tipo" slot-scope="lista">{{lista.row.virtual ? 'Virtual' : 'Presencial'}}</span>

          </v-client-table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import {mapState, mapActions, mapMutations} from 'vuex';
    
    export default {
      name: 'CorreoLista',
      data () {
        return {
            curso_id: null,
            columns: ['index', 'tipo', 'descripcion',  'rango_nota_inicial', 'rango_nota_final', 'accion'],
            options: {
                filterable: true,
                filterByColumn: false,
                sortable:[],
                perPage: 10,
                perPageValues: [5, 10, 15, 20],
                headings: {
                    index: '#',
                    tipo: 'tipo',
                    codigo: 'Descripcion',
                    nombre: 'Rango Inicial',
                    activo: 'Rango Final',
                    accion: 'Accion'
                }
            }
        }
      },
      mounted () {
        this.curso_id = this.$route.params.curso_id;
        if(!this.detalle[this.curso_id]){
            this.actualizarCurso()
        }
        this.fetchLista(this.curso_id)
      },
      methods:{
        ...mapActions({
            fetchDetalle: 'cursos/fetchDetalle',
            fetchLista: 'correos/fetchLista',
        }),
        ...mapMutations({
          
        }),
        edit(){
  
        },
        actualizarCurso(){
            this.fetchDetalle(this.curso_id)
        },
      },
      computed: {
        ...mapState({
            lista: state => state.correos.lista, 
            detalle: state => state.cursos.detalle, 
        }),
        curso(){
            if(this.detalle && this.detalle[this.curso_id]){
                return this.detalle[this.curso_id]  
            }else{
                return {nombre:"Curso desconocido"}
            }
        },
      }
    }
  </script>
  
  <style scope>
  
  </style>
  