import Vue from 'vue';

const state = {
  progresos : {},
};

const actions = {
  
  fetchProgreso:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('estudiante/progreso',data).then(
        response =>{
          commit('setProgreso',response.data);
          resolve(response.data)
        }
      ).catch(response=>{
        commit('setError', response, { root: true });
        reject(response)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchNotas:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('estudiante/notas',data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(response=>{
        commit('setError', response, { root: true });
        reject(response)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  revisarProgreso:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('progreso/verificar',data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(response=>{
        commit('setError', response, { root: true });
        reject(response)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  anular:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('progreso/anular',payload).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  
  
  firmar:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('progreso/firmar',payload).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  bloquear:({commit},progresoId) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('progreso/bloquear',{
          progreso_id:progresoId,
        }).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  desbloquear:({commit},progresoId) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('progreso/desbloquear',{
          progreso_id:progresoId,
        }).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  editarNota:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('progreso/editar_nota',payload).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  actualizarNotas:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('progreso/actualizar_notas',payload).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  actualizarNivelacion:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('progreso/nota_nivelacion',payload).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  estadoCurso:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('progreso/estado',payload).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
};

const getters = {
  getProgreso: (state) => (estudiante_id) => {
    if(!state.progresos[estudiante_id]){
      Vue.set(state.progresos, estudiante_id, {})
    }
    return state.progresos[estudiante_id]
  },
};

const mutations = {
  setProgreso : (state, datos) => {
    let estudiante_id = datos.id
    if(!state.progresos[estudiante_id]){
      Vue.set(state.progresos, estudiante_id, {})  
    }
    state.progresos[estudiante_id] = datos
  },
  restart: (state) => {
    state.progresos = {}
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
