<template>
  <div>
    <div class="programador_wrapper mt-2" :class="{ 'd-none': hora.marca_tiempo!=activo }">
      <div v-if="cursos" class="row text-center">
        <div class="col" v-if="clases">
          <div class="clases-wrapper ">
            <div  class="">
              <ProgramadorClase  v-for="(clase, index_1) in clases" :key="index_1" :clase="clase" :hora="hora" 
           :marca_tiempo="marcaTiempo"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
  import ProgramadorClase from '@/components/Pantalla/ProgramadorClase';
  
  export default {
    name: 'ProgramadorXDiaXHora',
    components: {
      ProgramadorClase,
    },
    data () {
      return {
        
      }
    },
    props : {
      hora: Object,
      activo: String,
      dia: Object,
      rightPanel : Boolean,
    },
    mounted () {},
    methods:{
      ...mapActions({}),
      ...mapMutations({}),
     
    },
    computed: {
      ...mapState({
        cursos: state  => state.asw.cursos,
        todas_las_clases: state => state.clases.clases, 
        todos_los_agendamientos: state => state.agendamientos.agendamientos, 
        todos_los_salones: state => state.salones.lista, 
        todos_los_docentes: state => state.docentes.lista, 
        tipos: state => state.asw.tipos, 
      }),
      //clases(){
      //  return this.todas_las_clases[this.hora.marca_tiempo]
      //},

      clases(){
        let franja = this.todas_las_clases[this.hora.marca_tiempo]
        if(franja){
          let franjaConOrden = franja.map(clase=>{
            if(!clase.tipos){
              clase.tipos = []
            }
            if(clase.tipos.length == 0){
              if(clase.tipo_clase == 'regular' && clase.curso_id){
                let codigo = clase.tipo_clase +'_'+ clase.curso_id
                clase.tipos.push(codigo)
              }else if(clase.tipo_clase){
                clase.tipos.push(clase.tipo_clase)
              }
            }

            let tipoObj =  this.tipos.find((tipo)=>{
                return tipo.id == clase.tipos[0]
              })
            if(tipoObj){
              clase.orden = tipoObj.orden  
            }else {
              clase.orden = 0
            }
            
            return clase
          })
          franjaConOrden.sort(function (a, b) {
            return a.orden - b.orden;
          });

        return franjaConOrden
        }
        return []
        //return this.todas_las_clases[this.hora.marca_tiempo]
      },

      ...mapGetters({}),
      titulo(){
        return this.dia.texto+' '+this.hora.nombre_corto
      },
      
      marcaTiempo(){
        return this.hora.marca_tiempo
      },
      
    }
  }
</script>

<style scope>

</style>