import Vue from 'vue';

const state = {
  clases : {},
};

const actions = {
  
  fetchClases:({commit}) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('clases').then(
        response =>{
          commit('setClases',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error.data, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchClasesXMarca:({commit},payload) => {
    if(!payload.silent){
      commit('startProcessing', null, { root: true });  
    }
    return new Promise((resolve, reject) => {
      Vue.http.post('clasesxmarca',payload).then(
        response =>{
          commit('setClasesXMarca',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error.data, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchClase:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('clase',{id:data}).then(
        response =>{
          commit('setClase',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error.data, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  crear:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('clase/crear',data).then(
        response =>{
          commit('setClase',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error.data, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  eliminar:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('clase/eliminar',data).then(
        response =>{
          commit('deleteClase',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error.data, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  //CAMBIA EL ESTADO A CONFIRMADO DE LOS AGENDAMIENTOS ASIGANADOS A LA CLASE
  confirmar:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('clase/confirmar',data).then(
        //TODO Aprovechar el dato que devuelve para actualizar
        response =>{
          commit('setClase',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error.data, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  
  editar:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('clase/editar',data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error.data, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  generarParcial:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('clase/generar_parcial',data).then(
        response =>{
          commit('setClasesXMarca',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error.data, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  
};

const getters = {
  getDocentesConClase: (state) => (marca_tiempo) => {
    let profes = []
    if(state.clases[marca_tiempo]){
      state.clases[marca_tiempo].forEach((element)=>{
        if(element.profesor_id && element.profesor_id>=0){
          profes.push(element.profesor_id)
        }
      })  
    }
    return profes;
  },
};

const mutations = {
  setClases: (state, datos) => {
    state.clases = datos
  },
  setClase: (state, datos) => {
    if(datos.id_temporal){
      let marca = datos.fecha.split('T')[0]+'T'+datos.hora
      let index = state.clases[marca].findIndex(element =>{
        return element.id_temporal == datos.id_temporal
      })
      if(index>=0){ 
        delete datos.id_temporal
        state.clases[marca].splice(index,1,datos)
      }
    }else if(datos.id){
      let marca = datos.fecha.split('T')[0]+'T'+datos.hora
      let index = state.clases[marca].findIndex(element =>{
        return element.id == datos.id
      })
      if(index>=0){ 
        state.clases[marca].splice(index,1,datos)
      }else{
        state.clases[marca].splice(1,0,datos)
      }
    }
    
  },
  setAgendamiento: (state, datos) => {
    console.log('clases/setAgendamiento')
    if(datos.clase_id){
      console.log('agregando a la clase')
      //agrego a la clase
      let marca = datos.fecha.split('T')[0]+'T'+datos.hora
      console.log('marca:'+marca)
      if(!state.clases[marca]){
        Vue.set(state.clases, marca, [])
        console.log('marca agregada')
      }
        
      let clase = state.clases[marca].find(element =>{
        return element.id == datos.clase_id
      })

      if(clase){ 
        console.log('clase encontrada')
        let index = clase.agendamientos.findIndex(element =>{
          return element.id == datos.id
        })
        if(index>=0){
          console.log('agendamiento reemplazado')
          clase.agendamientos.splice(index,1,datos)  
        }else{
          console.log('agendamiento agregado')
          clase.agendamientos.push(datos)
        }
        
      }
    }else{ 
      console.log('resultado sin marca')
    }

  },
  deleteClase: (state, datos) => {
    let marca = datos.fecha.split('T')[0]+'T'+datos.hora
    let id = datos.id
    let index = state.clases[marca].findIndex(element =>{
      return element.id == datos.id
    })
    state.clases[marca].splice(index,1)
  },
  setClasesXMarca: (state, datos) => {
    let marca_tiempo = datos.marca;
    if(!state.clases[marca_tiempo]){
      Vue.set(state.clases, marca_tiempo, {})  
    }
    state.clases[marca_tiempo] = datos.clases
  },
  nuevaClase: (state, datos) => {
    state.clases[datos.marca].push(datos.candidato)
  },
  cancelarNuevaClase: (state, datos) => {
    let index = state.clases[datos.marca].findIndex(element =>{
      return element.id_temporal == datos.id_temporal
    })
    state.clases[datos.marca].splice(index,1)
  },
  deleteAgendamiento: (state, datos) =>{
    console.log('deleteAgendamiento')
    let marca_tiempo = datos.fecha+'T'+datos.hora
    console.log('deleteAgendamiento marca_tiempo:'+marca_tiempo)
    let clase = state.clases[marca_tiempo].find(element =>{
      return element.id == datos.clase_id
    })
    if(clase){
      console.log('clase:')
      console.log(clase)
      let index = clase.agendamientos.findIndex(element =>{
        return element.id == datos.id
      })
      console.log('index:'+index)
      if(index>=0){
        clase.agendamientos.splice(index,1)
      }
    }else{
      console.log('deleteAgendamiento No encontrada la clase')
    }
    console.log('finaliza deleteAgendamiento')
  },
  restart: (state) => {
    state.clases = {}
  },

};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
