<template>
    <div>
      <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0 bg-blue">
          <b-navbar-toggle target="nav_collapse_actions"></b-navbar-toggle>
          <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Encuesta de satisfacción</h1>
          <b-collapse is-nav id="nav_collapse_actions" >
            <b-navbar-nav class="ml-auto">
              <select v-if="sedesPosibles" v-model="sedes" class="form-control" multiple>
                <option disabled value="">---SIN SALON---</option>
                <option v-for="opcion in sedesPosibles"
                :value="opcion.id">{{ opcion.nombre}}</option>
              </select>

              <date-picker  class="date-picker" v-model="intervalo" lang="es" type="date" 
              :time-picker-options="timePickerOptions" range confirm :clearable="true"></date-picker>
            </b-navbar-nav>
            <b-navbar-nav  class="ml-auto">
              <b-nav-item  href="#" @click="generarReporte">Generar</b-nav-item>
              <download-excel :disabled="!registros || registros && !registros.length"
                  class="nav-link"
                  :data="registros"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="reporte.xls">
                  Descargar
                </download-excel>
            </b-navbar-nav>
          </b-collapse>
      </b-navbar>
  
        <div class="container-fluid" >
        <div class="reporte_dia table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Fecha</th>
                <th scope="col">Hora</th>
                <th scope="col">Sede Estudiante</th>
                <th scope="col">Estudiante</th>
                <th scope="col">Tipo de documento</th>
                <th scope="col">Número de documento</th>
                <th scope="col">Número de celular</th>
                <th scope="col">Correo electrónico</th>
                <th scope="col">Docente</th>
                <th scope="col">Sede Clase</th>
                <th scope="col">Salon</th>
                <th scope="col">Tema</th>
                <th scope="col">Calificación</th>
                <th scope="col">Comentarios</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in registros">
                <td>{{presentDate(item.fecha)}}</td>
                <td>{{item.hora}}</td>
                <td>{{item.estudiante.sede_original.nombre}}</td>
                <td>{{item.estudiante.primer_nombre+' '+item.estudiante.segundo_nombre+' '+item.estudiante.primer_apellido+' '+item.estudiante.segundo_apellido}}</td>
                <td>{{item.estudiante.tipo_documento}}</td>
                <td>{{item.estudiante.numero_documento}}</td>
                <td>{{item.estudiante.movil}}</td>
                <td>{{item.estudiante.email}}</td>
                <td>{{item.docente.primer_nombre+' '+item.docente.segundo_nombre+' '+item.docente.primer_apellido+' '+item.docente.segundo_apellido}}</td>
                <td>{{item.salon.sede.nombre}}</td>
                <td>{{item.salon.nombre}}</td>
                <td>{{item.tema_nombre}}</td>
                <td>{{item.calificacion}}</td>
                <td>{{item.comentarios}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
        
    </div>
  </template>
  
  <script>
    import {mapState, mapActions, mapMutations} from 'vuex';
    import Vue from 'vue';
    import DatePicker from 'vue2-datepicker'
  
    export default {
      name: 'ReporteEncuesta',
      components: {
        DatePicker
      },
      data () {
        return {
            intervalo:[],
            sedes:[],
            registros: [],
            timePickerOptions:{    
            },
            json_fields: {
              "Fecha":"fecha",
              "Hora":"hora",
              "Sede":"estudiante.sede_original.nombre",
              "Estudiante": {
                callback: (value) => {
                  return `${value.estudiante.primer_nombre} ${value.estudiante.segundo_nombre} ${value.estudiante.primer_apellido} ${value.estudiante.segundo_apellido}`;
                }
              },
              "Tipo de documento":"estudiante.tipo_documento",
              "Número de documento":"estudiante.numero_documento",
              "Número de celular":"estudiante.movil",
              "Correo electrónico":"estudiante.email",
              "Docente":{
                callback: (value) => {
                  return `${value.docente.primer_nombre} ${value.docente.segundo_nombre} ${value.docente.primer_apellido} ${value.docente.segundo_apellido}`;
                }
              },
              "Sede":"salon.sede.nombre",
              "Salon":"salon.nombre",
              "Tema":"tema_nombre",
              "Calificación":"calificacion",
              "Comentarios":"comentarios",
            }
        }
      },
      mounted () {
        if(!this.todasLasSedes || (this.todasLasSedes && this.todasLasSedes.length==0)){
          this.fetchSedes()
        }
      },
      methods:{
        ...mapActions({
          fetchSedes: 'sedes/fetchLista',
        }),
        ...mapMutations({
          startProcessing: 'startProcessing',
          stopProcessing: 'stopProcessing'
        }),
        
        generarReporte(){
          console.log('Generando reporte....')
          this.startProcessing()
          Vue.http.post('reportes/encuesta-satisfaccion',{
            fecha_inicio:this.$moment(this.intervalo[0]).format('YYYY-MM-DD'),
            fecha_fin:this.$moment(this.intervalo[1]).format('YYYY-MM-DD'),
            sedes:this.sedes
          }).then(
            response =>{
              this.registros = response.data;
            }
          ).catch(error=>{
            console.log(error)
          }).finally(()=>{
            this.stopProcessing()
          })
        },
        presentDate(value){
          if (value) {
            return this.$moment(value).format('DD/MM/YYYY')
          }
          return '';
        },
        getIdioma(item) {
          if (item.cursoAnterior) {
            return item.cursoAnterior ? (item.cursoAnterior.codigo.indexOf('EN') > -1 ? 'Inglés' : 'Frances') : '';
          } else if (item.cursoNuevo) {
            return item.cursoNuevo ? (item.cursoNuevo.codigo.indexOf('EN') > -1 ? 'Inglés' : 'Frances') : '';
          }
          return '';
        },
      },
      computed: {
        ...mapState({
          user: state => state.auth.user, 
          todasLasSedes: state  => state.sedes.lista,         
        }),
        sedesPosibles(){
          if(this.user && this.user.data && this.user.data.sedes_permitidas && this.user.data.sedes_permitidas.length>0){
            return this.todasLasSedes.filter(element=>{
              return this.user.data.sedes_permitidas.includes(element.id) 
            })
          }
          return false
        }

      }
    }
  </script>
  
  <style scope>
  
  .date-picker{
    
  }
  </style>