<template>
  <div>
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_estudiantes"></b-navbar-toggle>
        <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Estudiantes</h1>
        <b-collapse is-nav id="nav_collapse_estudiantes">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <router-link v-if="permiso('D12AEB51')" class="nav-link" to="/estudiante/nuevo" >Nuevo</router-link>
            <router-link v-if="permiso('F94EDE37')" class="nav-link" to="/estudiante/masivo" >Masivo</router-link>
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <div class="container estudiantes_wrapper mt-3">
      <div v-if="lista" class="tabla">
        <div>Buscar: <input type="text" v-model="getTermino" /></div>
        <v-client-table name="estudiantesTable" :columns="columns" :data="lista" :options="options">
          <div slot="activo" slot-scope="lista">
            <span v-if="lista.row.activo==1">SI</span>
            <span v-else>NO</span>
          </div>
          <div  v-if="lista.row.activo==1" slot="accion" slot-scope="lista"><a :href="`#/estudiante/detalle/${ lista.row.id }`" v-if="permiso('560B5B09')" class="btn btn-primary btn-sm" title="Ver">Ver</a></div>
          <div slot="sede_original" slot-scope="lista">
            {{lista.row.sede_original.nombre}}
          </div>
        </v-client-table>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';

  export default {
    name: 'estudiantes',
    data () {
      return {
        columns: ['primer_nombre','segundo_nombre',  'primer_apellido', 'segundo_apellido','numero_documento', 'email', 'activo','sede_original','accion'],
        options: {
          filterable: false,
          filterByColumn: false,
          sortable:[],
          perPage: 10,
          perPageValues: [5, 10, 15, 20],
          headings: {
            id: 'Id',
            primer_nombre: 'Primer Nombre',
            segundo_nombre: 'Segundo Nombre',
            primer_apellido: 'Primer Apellido',
            segundo_apellido: 'Segundo Apellido',
            numero_documento: 'Documento',
            sede_original: 'Sede',
            estado: 'Estado',
            accion: 'Accion'
          }
        },
        termino : null,
        lista : []
      }
    },
    mounted () {
      if(!this.lista || (this.lista && this.lista.length==0)){
        //this.fetchLista()
      }
    },
    methods:{
      ...mapActions({
        fetchLista: 'estudiantes/fetchLista',
      }),
      ...mapMutations({
        
      }),
      edit(){

      }
    },
    computed: {
      ...mapState({
        //lista: state => state.estudiantes.lista, 
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      getTermino: {
        get () {
          return this.termino
        },
        set(value){
          this.termino = value 
          if(value && value.length >= 5){
            console.log(value)
            Vue.http.post('estudiante/buscar',{texto:value}).then(
              response =>{
                this.lista = response.data
              }
            )
            .catch(error=>{
              console.log(error)
            }).finally(()=>{
              
            })
          }
        }
      },
    }
  }
</script>

<style scope>

</style>