import Vue from 'vue';

const state = {
  lista: [],
  cursos:[],
  detalle : {},
};

const actions = {
  fetchLista:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('nivel/lista',data).then(
        response =>{
          commit('setLista',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchCursos:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('nivel/cursos',data).then(
        response =>{
          commit('setListaCursos',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  }
  
};

const getters = {
  getNivel: (state) => (id) =>{
    return state.lista.find(element =>{
      return element.id == id
    })
  },
  getNivelDetalle: (state) => (id) =>{
    if(!state.detalle[id]){
      Vue.set(state.detalle, id, {})
    }
    return state.detalle[id]
  }
};

const mutations = {
  setLista: (state, lista) => {
    state.lista = lista;
  },
  setListaCursos: (state, lista) => {
    state.cursos = lista;
  },
  setDetalle: (state, detalle) => {
    let id = detalle.id;
    Vue.set(state.detalle, id, detalle)
  },
  
  incializarDetalle: (state, id) => {
    if(!state.detalle[id]){
      Vue.set(state.detalle, id, null)
    }
  }
  
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
