<template>
  <div class="container  mt-3">
    
      <div class="form-horizontal">

        <div class="form-group row">
          <label class="col-3 col-form-label">Salón:</label>  
          <div class="col-9" v-if="docente.salon_predeterminado && !editando">
            
            <a :href="`#/salon/detalle/${docente.salon_predeterminado}`" class="" title="Ver">{{ salonFormated }}</a>
            <button  class="btn btn-primary ml-3" @click="editando=true">Editar</button>  
          </div>
          
          <div v-else>
            <select v-model="salon" class="form-control">
              <option disabled value="">---SIN SALON---</option>
              <option v-for="salon in salonesPosibles"
              :value="salon.id">{{ salon.nombre}}</option>
              
            </select>
            <button v-if="procesable" class="btn btn-block btn-danger" @click="procesar">
              Guardar
            </button>  
          </div>
        </div> 
        
      </div>
    
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'DocenteEditarSalon',
    data () {
      return {
        salon : null,
        editando : false
      }
    },
    props: {
      docente: Object
    },
    mounted () {
      if(!this.salones || this.salones.length<1){
        this.fetchSalones()
      }
    },
    methods:{
      ...mapActions({
        fetchDetalle: 'docentes/fetchDetalle',
        fetchSalones: 'salones/fetchLista',
        setWarning: 'setWarning',
        editarSalon: 'docentes/editarSalon',
        fetchLista: 'docentes/fetchLista',
      }),
      ...mapMutations({
        
      }),
      procesar(){
        console.log("DocenteEditarSalon procesar:"+this.salon)
        this.editarSalon({
          id:this.docente.id,
          salon_predeterminado:this.salon,
        }).then((apiResponse)=>{
          
          this.setWarning('Información Guardada', { root: true }).then(()=>{
            this.fetchDetalle(this.docente.id).then(()=>{
              this.fetchLista()
              this.$router.push('/docente/detalle/'+this.docente.id)
            }) 
          })
          
        }).catch((apiResponse)=>{
          console.log("error procesando DocenteEditarSalon")
        })
      },
      limpiar(){
        
      }
    },
    computed:{
      ...mapState({
        salones: state => state.salones.lista, 
      }),
      ...mapGetters({
        getSalonesDisponibles: 'salones/getSalonesDisponibles', 
      }),
      procesable: {
        get () {
          
          return this.salon && (this.salon!=this.docente.salon_predeterminado)
          
        },
        set(value){}
      },
      salonesPosibles(){
        if(this.docente.tipos_permitidos){
          let get_salones_disponibles = this.getSalonesDisponibles()
          let salones_posibles = get_salones_disponibles.filter(salon=>{
            for(let x in salon.tipos_permitidos){
              let tipo = salon.tipos_permitidos[x]
              if(this.docente.tipos_permitidos.includes(tipo)){
                return true
              }
            }
            return false
          })
          return salones_posibles
        }else{
          return this.salones
        }
        
      },
      salonFormated(){
        let objeto = this.salones.find(element=>{
          return element.id == this.docente.salon_predeterminado
        })

        if(objeto){
          return objeto.nombre
        }else{
          return this.docente.salon_predeterminado
        }
        
      }
    }
  }
</script>

<style scope>
 
</style>

