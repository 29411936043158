<template>
  <div class="container  mt-3">
    <div class="col-lg-6 m-auto">
    	<h1 class="text-center mb-3 text-muted">Nueva Programación</h1>
      <hr>
      <form class="form-horizontal" v-if="cursoActual">
        <div class="form-group row">
          <label class="col-2">Curso:</label>
          <div class="col-10">
              <span>{{ cursoActual.nombre }}</span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-2">Tema:</label>
          <div class="col-10">
            <select v-model="tema" class="form-control">
             <option disabled value="">Please select one</option>
             <option value="null">Agendar sin tema</option>
              <option v-for="temaDisponible in temasDisponibles" :value="temaDisponible">{{ temaDisponible.nombre }}</option>
            </select>
          </div>
        </div>
    
        <div v-if="dias && dias.length > 0" class="form-group row">
          <label class="col-2">Dia:</label>
          <div class="col-10">
            <select v-model="dia" class="form-control">
             <option disabled value="">Please select one</option>
              <option v-for="dia in dias" :value="dia">{{ dia.texto }}</option>
            </select>
          </div>
        </div>

        <div v-if="posibles_horas && posibles_horas.length > 0" class="form-group row">
          <label class="col-2">Hora:</label>
          <div class="col-10">
            <select v-model="hora" class="form-control">
              <option disabled value="">Please select one</option>
              <option v-for="posible_hora in posibles_horas" :value="posible_hora">{{ posible_hora.nombre }}</option>
            </select>
          </div>
        </div>
    
        <hr>
        <button v-if="agendable" class="btn btn-block btn-danger" @click="reservar">
          Programar
        </button>
        <div v-if="mensaje" class="mt-1 alert alert-success" >{{ mensaje }}</div>
      </form>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'programar',
    data () {
      return {
        temaSeleccionado : null,
        diaSeleccionado : null,
        horarioSeleccionado : null,
        mensaje : null
      }
    },
    props: {
      estudiante: Object
    },
    mounted () {
      this.fetchHorarios({tema:null,id:this.estudiante.id})
    },
    methods:{
      ...mapActions({
        fetchHorarios: 'estudiantes/fetchHorarios',
        fetchAgendamientos: 'estudiantes/fetchAgendamientos',
        fetchDetalle: 'estudiantes/fetchDetalle',
        agendar: 'programacion/agendar',
        
      }),
      ...mapMutations({
        setHorariosDisponibles: 'estudiantes/setHorariosDisponibles',
      }),
      reservar(){
        this.agendar({
          tema_id:this.temaSeleccionado?this.temaSeleccionado.id:null,
          hora:this.horarioSeleccionado.hora,
          dia:this.diaSeleccionado.fecha,
          id:this.estudiante.id,
        }).then((apiResponse)=>{
          
          this.mensaje = "Programación exitosa para "+apiResponse.fecha+" "+apiResponse.hora
          this.horarioSeleccionado = null
          this.diaSeleccionado = null
          this.temaSeleccionado = null
          

          this.fetchDetalle(this.estudiante.id).then(()=>{
            this.fetchAgendamientos(this.estudiante.id)
            this.fetchHorarios({tema:null,id:this.estudiante.id})
          }) 
          
        })
      }
    },
    computed:{
      ...mapState({
        
      }),
      ...mapGetters({
        
      }),
      cursoActual(){
        return this.estudiante.curso_actual
      },
      temasDisponibles(){
        return this.estudiante.temas_disponibles
      },
      dias(){
        return this.estudiante.horarios_disponibles
      },
      tema: {
        get () {
          return this.temaSeleccionado;
        },
        set (value) {
          this.temaSeleccionado = value;
          this.fetchHorarios({tema:this.temaSeleccionado.id,id:this.estudiante.id})
        }
      },
      dia: {
        get () {
          return this.diaSeleccionado;
        },
        set (value) {
          this.diaSeleccionado = value;
        }
      },
      hora: {
        get () {
          return this.horarioSeleccionado;
        },
        set (value) {
          this.horarioSeleccionado = value;
        }
      },
      posibles_horas: {
        get () {
          if(this.diaSeleccionado){
            return this.diaSeleccionado.horas;
          }else{
            return null
          }
          
        },
        set (value) {
          
        }
      },
      agendable: {
        get () {
          return (this.diaSeleccionado && this.horarioSeleccionado)
        },
        set(value){}
      }
    }
  }
</script>

<style scope>
  .programar_wrapper .form-container{
      
  }
	.programar_wrapper{
    
	}
</style>

