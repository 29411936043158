<template>
  <div>
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0 bg-blue">
        <b-navbar-toggle target="nav_collapse_actions"></b-navbar-toggle>
        <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Consumo</h1>
        <b-collapse is-nav id="nav_collapse_actions" >
          <b-navbar-nav class="ml-auto">
            <date-picker  class="date-picker" v-model="intervalo" lang="es" type="date" 
            :time-picker-options="timePickerOptions" range confirm :clearable="true"></date-picker>
          </b-navbar-nav>
          <b-navbar-nav  class="ml-auto">
            <b-nav-item  href="#" @click="generarReporte">Generar</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
    </b-navbar>

  	<div class="container-fluid" >
      <div class="reporte_dia" v-for="dato in datos">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">
                {{dato.sede_nombre}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="total in dato.total">
              <td>{{total.fecha}}</td>
              <td>{{total['count(*)']}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
      
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';
  import DatePicker from 'vue2-datepicker'

  export default {
    name: 'ReporteConsumo',
    components: {
      DatePicker
    },
    data () {
      return {
        intervalo:[],
        dias : [],
        x_labels : {},
        y_labels : [],
        datos: [],
        timePickerOptions:{    
        }
      }
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({
      }),
      ...mapMutations({
        startProcessing: 'startProcessing',
        stopProcessing: 'stopProcessing'
      }),
      
      generarReporte(){
        console.log('Generando reporte....')
      
        let fecha_inicio = new Date(this.intervalo[0])
        fecha_inicio = fecha_inicio.toISOString().split('T')[0]

        let fecha_fin = new Date(this.intervalo[1])
        fecha_fin = fecha_fin.toISOString().split('T')[0]
        this.startProcessing()
        Vue.http.post('reportes/consumo',{fecha_inicio:fecha_inicio,fecha_fin:fecha_fin}).then(
          response =>{
            console.log(response)
            this.x_labels = response.data.x_labels
            this.y_labels = response.data.y_labels
            this.datos = response.data.datos
          }
        ).catch(error=>{
          console.log(error)
        }).finally(()=>{
          this.stopProcessing()
        })
        
      },
    },
    computed: {
      ...mapState({
       
      }),      
    }
  }
</script>

<style scope>

.date-picker{
  
}
</style>