import Vue from 'vue';

const state = {
   tipos : [
    {id:'regular_1',nombre_corto:'FN',nombre: 'Fundamentals',nombre_largo: 'Regular Fundamentals',solicitud:'normal', color:'4674C1', orden:10, grupo:'ASW starter A1'},
    {id:'regular_2',nombre_corto:'TN1',nombre: 'Top Notch 1',nombre_largo: 'Regular Top Notch 1',solicitud:'normal', color:'933836', orden:20, grupo: 'ASW developing  A2'},
    {id:'regular_3',nombre_corto:'TN2',nombre: 'Top Notch 2',nombre_largo: 'Regular Top Notch 2',solicitud:'normal', color:'4F602B', orden:30, grupo: 'ASW intermediary B1'},
    {id:'regular_4',nombre_corto:'TN3',nombre: 'Top Notch 3',nombre_largo: 'Regular Top Notch 3',solicitud:'normal', color:'5F4A79', orden:40, grupo: 'ASW competent B2'},
    {id:'regular_5',nombre_corto:'S1',nombre: 'Summit 1',nombre_largo: 'Regular Summit 1',solicitud:'normal', color:'7E5F11', orden:50, grupo: 'ASW skilled B2+'},
    {id:'regular_6',nombre_corto:'S2',nombre: 'Summit 2',nombre_largo: 'Regular Summit 2',solicitud:'normal', color:'7E5F11', orden:60, grupo: 'ASW exceptional C1'},
    {id:'regular_7',nombre_corto:'N1',nombre: 'Nickel 1',nombre_largo: 'Regular Nickel 1',solicitud:'normal', color:'E16C21', orden:70},
    {id:'regular_8',nombre_corto:'N2',nombre: 'Nickel 2',nombre_largo: 'Regular Nickel 2',solicitud:'normal', color:'E16C21', orden:80},
    {id:'regular_9',nombre_corto:'N3',nombre: 'Nickel 3',nombre_largo: 'Regular Nickel 3',solicitud:'normal', color:'39C4E1', orden:90},
    {id:'regular_a',nombre_corto:'TVB4',nombre: 'Tout Va Bien 4',nombre_largo: 'Regular Tout Va Bien 4',solicitud:'normal', color:'39C4E1', orden:100},
    {id:'lab',nombre_corto:'LAB',nombre: 'Laboratorio',nombre_largo: 'Laboratorio',solicitud: 'normal', color:'17a2b8', orden:110},
    {id:'writing',nombre_corto:'W',nombre: 'Writing',nombre_largo: 'Writing',solicitud: 'normal', color:'ffa8b8', orden:120},
    {id:'speakup_basico',nombre_corto:'SUB',nombre: 'Speakup Basico',nombre_largo: 'Speakup Basico',solicitud: 'normal', color:'FC28FC', orden:130},
    {id:'speakup_avanzado',nombre_corto:'SUA',nombre: 'Speakup Avanzado',nombre_largo: 'Speakup Avanzado',solicitud: 'normal', color:'FC28FC', orden:140},
    {id:'immersion_basico',nombre_corto:'IB',nombre: 'Immersion Basico',nombre_largo: 'Immersion Basico',solicitud: 'normal', color:'6CA754', orden:150},
    {id:'immersion_avanzado',nombre_corto:'IA',nombre: 'Immersion Avanzado',nombre_largo: 'Immersion Avanzado',solicitud: 'normal', color:'6CA754', orden:160}, 
    {id:'clase_0',nombre_corto:'CERO',nombre: 'Clase 0',nombre_largo: 'Clase 0',solicitud: 'normal', color:'4674C1', orden:170}, 
    {id:'conversation',nombre_corto:'CT',nombre: 'Conversation Time',nombre_largo: 'Conversation Time',solicitud: 'normal', color:'A80C07', orden:180},
    // NUEVOS MODULOS
    {id:'regular_16',nombre_corto:'CF',nombre: 'Connectivity foundations',nombre_largo: 'Connectivity Foundations',solicitud:'normal', color:'4674C1', orden:190, grupo:'ASW starter A1'},
    {id:'regular_17',nombre_corto:'C1',nombre: 'Connectivity 1',nombre_largo: 'Connectivity 1',solicitud:'normal', color:'933836', orden:200, grupo: 'ASW developing  A2'},
    {id:'regular_18',nombre_corto:'C2',nombre: 'Connectivity 2',nombre_largo: 'Connectivity 2',solicitud:'normal', color:'4F602B', orden:210,grupo: 'ASW intermediary B1'},
    {id:'regular_19',nombre_corto:'C3',nombre: 'Connectivity 3',nombre_largo: 'Connectivity 3',solicitud:'normal', color:'5F4A79', orden:220, grupo: 'ASW competent B2'},
    {id:'regular_20',nombre_corto:'C4',nombre: 'Connectivity 4',nombre_largo: 'Connectivity 4',solicitud:'normal', color:'7E5F11', orden:230, grupo: 'ASW skilled B2+'},
    {id:'regular_21',nombre_corto:'C5',nombre: 'Connectivity 5',nombre_largo: 'Connectivity 5',solicitud:'normal', color:'7E5F11', orden:240, grupo: 'ASW exceptional C1'},
    
    {id:'regular_22',nombre_corto:'CPF',nombre: 'Premium Connectivity foundations',nombre_largo: 'Premium Connectivity Foundations',solicitud:'normal', color:'4674C1', orden:190, grupo:'ASW starter A1'},
    {id:'regular_23',nombre_corto:'CP1',nombre: 'Premium Connectivity 1',nombre_largo: 'Premium Connectivity 1',solicitud:'normal', color:'933836', orden:200, grupo: 'ASW developing  A2'},
    {id:'regular_24',nombre_corto:'CP2',nombre: 'Premium Connectivity 2',nombre_largo: 'Premium Connectivity 2',solicitud:'normal', color:'4F602B', orden:210,grupo: 'ASW intermediary B1'},
    {id:'regular_25',nombre_corto:'CP3',nombre: 'Premium Connectivity 3',nombre_largo: 'Premium Connectivity 3',solicitud:'normal', color:'5F4A79', orden:220, grupo: 'ASW competent B2'},
    {id:'regular_26',nombre_corto:'CP4',nombre: 'Premium Connectivity 4',nombre_largo: 'Premium Connectivity 4',solicitud:'normal', color:'7E5F11', orden:230, grupo: 'ASW skilled B2+'},
    {id:'regular_27',nombre_corto:'CP5',nombre: 'Premium Connectivity 5',nombre_largo: 'Premium Connectivity 5',solicitud:'normal', color:'7E5F11', orden:240, grupo: 'ASW exceptional C1'},
    // NUEVOS MODULOS
    
  ],
  combinacionesPosibles : [
    ["speakup_avanzado","speakup_basico"],
    ['immersion_avanzado','immersion_basico'],
    ['regular_5','regular_6'],
    ['regular_7','regular_8'],
    ['regular_9','regular_a'],
  ],
  tipos_salon :[
    {
      id:'regular',
      nombre: 'Regular',
      solicitud:'normal'
    },
    {
      id:'lab',
      nombre: 'Laboratorio',
      solicitud: 'normal'
    },
    {
      id:'writing',
      nombre: 'Writing',
      solicitud: 'normal'
    },
    {
      id:'speakup_basico',
      nombre: 'Speakup Basico',
      solicitud: 'normal'
    },
    {
      id:'speakup_avanzado',
      nombre: 'Speakup Avanzado',
      solicitud: 'normal'
    },
    {
      id:'immersion_basico',
      nombre: 'Immersion Basico',
      solicitud: 'normal'
    },
    {
      id:'immersion_avanzado',
      nombre: 'Immersion Avanzado',
      solicitud: 'normal'
    },
    {
      id:'conversation',
      nombre: 'Conversation Time',
      solicitud: 'normal'
    }, 
  ],

  tipos_clase :[
    {
      id:'regular',
      nombre: 'Regular',
      solicitud:'normal'
    },
    {
      id:'lab',
      nombre: 'Laboratorio',
      solicitud: 'normal'
    },
    {
      id:'writing',
      nombre: 'Writing',
      solicitud: 'normal'
    },
    {
      id:'speakup_basico',
      nombre: 'Speakup Basico',
      solicitud: 'normal'
    },
    {
      id:'speakup_avanzado',
      nombre: 'Speakup Avanzado',
      solicitud: 'normal'
    },
    {
      id:'immersion_basico',
      nombre: 'Immersion Basico',
      solicitud: 'normal'
    },
    {
      id:'immersion_avanzado',
      nombre: 'Immersion Avanzado',
      solicitud: 'normal'
    }, 
  ],
  disponibilidad_general: {
    dias: {
      '0': {'id': '0', 'nombre': 'Domingo', 'horas':[]},
      '1': {'id': '1', 'nombre': 'Lunes', 'horas':[
          {'id' : '1T06:00:00','nombre' : '6:00 am - 7:30 am','hora' : '06:00:00',},
          {'id' : '1T07:30:00','nombre' : '7:30 am - 9:00 am','hora' : '07:30:00'},
          {'id' : '1T09:00:00','nombre' : '9:00 am - 10:30 am','hora' : '09:00:00'},
          {'id' : '1T10:40:00','nombre' : '10:40 am - 12:10 am','hora' : '10:40:00'},
          {'id' : '1T12:10:00','nombre' : '12:10 pm - 1:40 pm','hora' : '12:10:00'},
          {'id' : '1T13:40:00','nombre' : '1:40 pm - 3:10 pm','hora' : '13:40:00'},
          {'id' : '1T15:10:00','nombre' : '3:10 pm - 4:40 pm','hora' : '15:10:00'},
          {'id' : '1T16:50:00','nombre' : '4:50 pm - 6:20 pm','hora' : '16:50:00'},
          {'id' : '1T18:20:00','nombre' : '6:20 pm - 7:50 pm','hora' : '18:20:00'},
          {'id' : '1T19:50:00','nombre' : '7:50 pm - 9:20 pm','hora' : '19:50:00'},
        ]},
      '2': {'id': '2', 'nombre': 'Martes', 'horas':[
          {'id' : '2T06:00:00','nombre' : '6:00 am - 7:30 am','hora' : '06:00:00',},
          {'id' : '2T07:30:00','nombre' : '7:30 am - 9:00 am','hora' : '07:30:00'},
          {'id' : '2T09:00:00','nombre' : '9:00 am - 10:30 am','hora' : '09:00:00'},
          {'id' : '2T10:40:00','nombre' : '10:40 am - 12:10 am','hora' : '10:40:00'},
          {'id' : '2T12:10:00','nombre' : '12:10 pm - 1:40 pm','hora' : '12:10:00'},
          {'id' : '2T13:40:00','nombre' : '1:40 pm - 3:10 pm','hora' : '13:40:00'},
          {'id' : '2T15:10:00','nombre' : '3:10 pm - 4:40 pm','hora' : '15:10:00'},
          {'id' : '2T16:50:00','nombre' : '4:50 pm - 6:20 pm','hora' : '16:50:00'},
          {'id' : '2T18:20:00','nombre' : '6:20 pm - 7:50 pm','hora' : '18:20:00'},
          {'id' : '2T19:50:00','nombre' : '7:50 pm - 9:20 pm','hora' : '19:50:00'},

        ]},
      '3': {'id': '3', 'nombre': 'Miercoles', 'horas':[
          {'id' : '3T06:00:00','nombre' : '6:00 am - 7:30 am','hora' : '06:00:00',},
          {'id' : '3T07:30:00','nombre' : '7:30 am - 9:00 am','hora' : '07:30:00'},
          {'id' : '3T09:00:00','nombre' : '9:00 am - 10:30 am','hora' : '09:00:00'},
          {'id' : '3T10:40:00','nombre' : '10:40 am - 12:10 am','hora' : '10:40:00'},
          {'id' : '3T12:10:00','nombre' : '12:10 pm - 1:40 pm','hora' : '12:10:00'},
          {'id' : '3T13:40:00','nombre' : '1:40 pm - 3:10 pm','hora' : '13:40:00'},
          {'id' : '3T15:10:00','nombre' : '3:10 pm - 4:40 pm','hora' : '15:10:00'},
          {'id' : '3T16:50:00','nombre' : '4:50 pm - 6:20 pm','hora' : '16:50:00'},
          {'id' : '3T18:20:00','nombre' : '6:20 pm - 7:50 pm','hora' : '18:20:00'},
          {'id' : '3T19:50:00','nombre' : '7:50 pm - 9:20 pm','hora' : '19:50:00'},
        ]},
        '4': {'id': '4', 'nombre': 'Jueves', 'horas':[
          {'id' : '4T06:00:00','nombre' : '6:00 am - 7:30 am','hora' : '06:00:00',},
          {'id' : '4T07:30:00','nombre' : '7:30 am - 9:00 am','hora' : '07:30:00'},
          {'id' : '4T09:00:00','nombre' : '9:00 am - 10:30 am','hora' : '09:00:00'},
          {'id' : '4T10:40:00','nombre' : '10:40 am - 12:10 am','hora' : '10:40:00'},
          {'id' : '4T12:10:00','nombre' : '12:10 pm - 1:40 pm','hora' : '12:10:00'},
          {'id' : '4T13:40:00','nombre' : '1:40 pm - 3:10 pm','hora' : '13:40:00'},
          {'id' : '4T15:10:00','nombre' : '3:10 pm - 4:40 pm','hora' : '15:10:00'},
          {'id' : '4T16:50:00','nombre' : '4:50 pm - 6:20 pm','hora' : '16:50:00'},
          {'id' : '4T18:20:00','nombre' : '6:20 pm - 7:50 pm','hora' : '18:20:00'},
          {'id' : '4T19:50:00','nombre' : '7:50 pm - 9:20 pm','hora' : '19:50:00'},
        ]},
        '5': {'id': '5', 'nombre': 'Viernes', 'horas':[
          {'id' : '5T06:00:00','nombre' : '6:00 am - 7:30 am','hora' : '06:00:00',},
          {'id' : '5T07:30:00','nombre' : '7:30 am - 9:00 am','hora' : '07:30:00'},
          {'id' : '5T09:00:00','nombre' : '9:00 am - 10:30 am','hora' : '09:00:00'},
          {'id' : '5T10:40:00','nombre' : '10:40 am - 12:10 am','hora' : '10:40:00'},
          {'id' : '5T12:10:00','nombre' : '12:10 pm - 1:40 pm','hora' : '12:10:00'},
          {'id' : '5T13:40:00','nombre' : '1:40 pm - 3:10 pm','hora' : '13:40:00'},
          {'id' : '5T15:10:00','nombre' : '3:10 pm - 4:40 pm','hora' : '15:10:00'},
          {'id' : '5T16:50:00','nombre' : '4:50 pm - 6:20 pm','hora' : '16:50:00'},
          {'id' : '5T18:20:00','nombre' : '6:20 pm - 7:50 pm','hora' : '18:20:00'},
          {'id' : '5T19:50:00','nombre' : '7:50 pm - 9:20 pm','hora' : '19:50:00'},
        ]},
        '6': {'id': '6', 'nombre': 'Sabado', 'horas':[
          {'id' : '6T08:00:00','nombre' : '8:00 am - 9:30 am','hora' : '08:00:00'},
          {'id' : '6T09:30:00','nombre' : '9:30 am - 11:00 am','hora' : '09:30:00'},
          {'id' : '6T11:00:00','nombre' : '11:00 am - 12:30 am','hora' : '11:00:00'},
          {'id' : '6T13:00:00','nombre' : '1:00 pm - 2:30 pm','hora' : '13:00:00' },
          {'id' : '6T14:30:00','nombre' : '2:30 pm - 4:00 pm','hora' : '14:30:00' },
        ]},
    }
  },
  cursos: null,
  agenda_disponible: null,
  metodos_pago :[],
  listaBanners : [],
  detalleBanner: {}
 
};

const actions = {
  fetchCursos:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('curso/lista',data).then(
        response =>{
          commit('setCursos',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchAgendaDisponible:({commit}) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('agenda_disponible').then(
        response =>{
          commit('setAgendaDisponible',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchMetodosPago:({commit},data) => {
   
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('config/metodos_pago',data).then(
        response =>{
        //  console.log("esto lo impimo yo: "+response.data)
          commit('setMetodosPago',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchDetalleBanners:({commit},banner_id) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('banner/detalle',{id:banner_id}).then(
        response =>{
          commit('setDetalle',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  cambiarPassword:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('setpassword',data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  postGuardar:({commit},data) => {
    commit('startProcessing', null, { root: true });
    console.log(data + 'likuhegrfwahkjlsfdagjs');
    return new Promise((resolve, reject) => {
      Vue.http.post('banner/guardar',data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  fetchListaBanners:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('banner/lista', data).then(
        response =>{
          commit('setListaBanners',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },



};

const getters = {
  getNombreTipo: (state) => (id) => {
    let tipo = state.tipos.find((element)=>{
      return element.id == id
    })
    if(tipo){
      return tipo.nombre
    }else{
      return 'TIPO NO ENCONTRADO'
    }
  },
  getNombreLargo: (state) => (id) => {
    let tipo = state.tipos.find((element)=>{
      return element.id == id
    })
    if(tipo){
      return tipo.nombre_largo
    }else{
      return 'TIPO NO ENCONTRADO'
    }
  },
  getTipoNombreCorto: (state) => (id) => {
    let tipo = state.tipos.find((element)=>{
      return element.id == id
    })
    if(tipo){
      return tipo.nombre_corto
    }else{
      return 'TIPO NO ENCONTRADO'
    }
  },
  esCombinacionValida: (state) => (combinacion) => {

    combinacion.sort()
    let combinacionTexto = JSON.stringify(combinacion)
    
    let combinacionesPosiblesTextos = state.combinacionesPosibles.map(element=>{
      element.sort()
      return JSON.stringify(element)
    })

    return combinacionesPosiblesTextos.includes(combinacionTexto)
  },
};

const mutations = {
  setCursos: (state, lista) => {
    state.cursos = lista;
  },
  setListaBanners: (state, listaBanners) => {
    state.listaBanners = listaBanners;
  },
  setAgendaDisponible: (state, lista) => {
    state.agenda_disponible = lista;
  },
  setMetodosPago:(state, data)=>{
    state.metodos_pago = data;
  },
  restart: (state) => {
    state.agenda_disponible = null
    state.cursos = null
    state.metodos_pago =null
  },
  setDetalle: (state, detalleBanner) => {
    let id = detalleBanner.id;
    Vue.set(state.detalleBanner, id, detalleBanner)
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
