var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('FuncionarioNavigation',{attrs:{"funcionario":_vm.funcionario}}),_c('div',{staticClass:"container pt-3 m-auto text-left"},[(_vm.funcionario)?_c('div',[_c('h2',[_vm._v("Datos básicos")]),_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.funcionario.tipo_documento))])]),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.funcionario.numero_documento))])]),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.funcionario.email))])]),_c('div',{staticClass:"row"},[_vm._m(3),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.funcionario.telefono))])]),_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.funcionario.movil))])]),_c('div',{staticClass:"row"},[_vm._m(5),(_vm.funcionario.grupo)?_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.funcionario.grupo.nombre))]):_c('div',{staticClass:"col-9"},[_vm._v("Sin Asignar")])]),_c('div',{staticClass:"row"},[_vm._m(6),(_vm.funcionario.ultimo_ingreso)?_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.textoUltimoIngreso))]):_c('div',{staticClass:"col-9"},[_vm._v("No registra")])]),_c('div',{staticClass:"row"},[_vm._m(7),(_vm.funcionario.invalid_token_time)?_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.textoInvalidarTokens))]):_c('div',{staticClass:"col-9"},[_vm._v("No registra")])]),_c('hr')]):_c('div',[_vm._v(" No hay información ")]),_c('FuncionarioDetalleEventos',{attrs:{"funcionario":_vm.funcionario}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Tipo Documento:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Documento:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("eMail:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Telefono:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Celular:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Grupo:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Último ingreso:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Fecha válida de autenticación:")])])
}]

export { render, staticRenderFns }