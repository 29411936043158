import { render, staticRenderFns } from "./SalonPreasignacion.vue?vue&type=template&id=398b1d5f&"
import script from "./SalonPreasignacion.vue?vue&type=script&lang=js&"
export * from "./SalonPreasignacion.vue?vue&type=script&lang=js&"
import style0 from "./SalonPreasignacion.vue?vue&type=style&index=0&id=398b1d5f&prod&scope=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports