import Vue from 'vue';

const state = {
  agendamientosAcumulados: {},
  agendamientosEstudiantes: {},
  estudiantesActivos: {},
};

const actions = {
  fetchAgendamientosAcumulados:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('indicadores/agendamientos_acumulados',data).then(
        response =>{
          commit('setAgendamientosAcumulados',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchAgendamientosEstudiantes:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('indicadores/agendamientos_estudiantes',data).then(
        response =>{
          commit('setAgendamientosEstudiantes',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchEstudiantesActivos:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('indicadores/estudiantes_activos',data).then(
        response =>{
          commit('setEstudiantesActivos',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

};

const getters = {
};

const mutations = {
  setAgendamientosAcumulados: (state, data) => {
    state.agendamientosAcumulados = data;
  },
  setAgendamientosEstudiantes: (state, data) => {
    state.agendamientosEstudiantes = data;
  },
  setEstudiantesActivos: (state, data) => {
    state.estudiantesActivos = data;
  },
  restart: (state) => {
    state.agendamientosAcumulados = {}
    state.agendamientosEstudiantes = {}
    state.estudiantesActivos = {}
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
