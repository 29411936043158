<template>
  <div class="container  mt-3">
    <div class="m-auto form-horizontal">
      
        <div class="mb-3 row" v-if="tipos">
          <div class="" v-for="tipo in tipos">

                <button class="pt-0 pb-0 mt-1 mr-1 btn btn-success" 
                v-if="tipos_permitidos.includes(tipo.id)"
                @click="tipos_permitidos.splice(tipos_permitidos.indexOf(tipo.id),1)"
                title="Click para quitar">
                  {{ tipo.nombre }}  
                </button>
                <button class="pt-0 pb-0 mt-1 mr-1 btn btn-dark" v-else
                @click="tipos_permitidos.push(tipo.id)"
                title="Click para agregar">
                  {{ tipo.nombre }}  
                </button>
            
          </div>
        </div>

        <button v-if="procesable" class="btn btn-block btn-danger" @click="procesar">
          Guardar
        </button>   
      
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'SalonEditarTipos',
    data () {
      return {
        tipos_permitidos:[]
      }
    },
    props: {
      salon: Object
    },
    mounted () {
      if(this.salon.tipos_permitidos){
        this.tipos_permitidos = this.salon.tipos_permitidos
      }
    },
    methods:{
      ...mapActions({
        fetchDetalle: 'salones/fetchDetalle',
        setWarning: 'setWarning',
        editarTipos: 'salones/editarTipos',
        fetchLista: 'salones/fetchLista',
      }),
      ...mapMutations({
        
      }),
      procesar(){
        console.log("SalonEditarTipos procesar")
        
        this.editarTipos({
          id:this.salon.id,
          tipos_permitidos:this.tipos_permitidos,
        }).then((apiResponse)=>{
          
          this.setWarning('Información Guardada', { root: true }).then(()=>{
            this.fetchDetalle(this.salon.id).then(()=>{
              this.fetchLista()
              this.$router.push('/salon/detalle/'+this.salon.id)
            }) 
          })
          
        }).catch((apiResponse)=>{
          console.log("error procesando SalonEditarTipos")
        })
        
      },
      
    },
    computed:{
      ...mapState({
        tipos: state => state.asw.tipos, 
      }),
      ...mapGetters({
        
      }),
      procesable: {
        get () {
          return true
        },
        set(value){}
      },
    }
  }
</script>

<style scope>
 
</style>

