<template>
    <div class="container pt-3 m-auto text-left ">
      <h1>Cancelación masiva</h1>
       <hr>
          <strong>Estructura:</strong><br>
        <div>
            <date-picker  class="date-picker" v-model="fecha" lang="es" type="date" 
            :time-picker-options="timePickerOptions"></date-picker><br><br>
            <select  v-model="hora" class="form-control">
                <option value="null">Seleccione una hora...</option>
                <option v-for="hora in horas"
                :value="hora">{{ hora}}</option>
            </select><br>
            <select  v-model="sede" class="form-control">
                <option value="null">Seleccione una sede...</option>
                <option v-for="sede in sedes"
                :value="sede.id">{{ sede.nombre}}</option>
            </select><br>
        </div>
        <button v-if="fecha && hora && sede" :disabled="proccess" class="btn btn-block btn-danger" @click="cancelarAgendamientos()">Procesar</button><br>
        <div v-if="cancelados.length>0">
          <h4>Lista de agendamientos cancelados</h4><hr>
          <b-table striped hover :items="cancelados" :fields="fields"></b-table>
        </div>
    </div>
</template>

<script>
  import Vue from 'vue';
  import {mapState, mapActions} from 'vuex';
  import DatePicker from 'vue2-datepicker'

  export default {
    name: 'CancelacionMasiva',
    components: {
      DatePicker
    },
    data () {
      return {
        fields: ['estudiante', 'fecha', 'hora'],
        fecha:"",
        hora:"",
        sede:null,
        timePickerOptions:{},
        cancelados:[],
        proccess: false
      }
    },
    mounted () {
        this.fetchLista();
    },
    methods:{
    ...mapActions({
        setWarning: 'setWarning',
        fetchLista: 'sedes/fetchLista'
    }),
    cancelarAgendamientos () {
      this.proccess = true;
      Vue.http.post('cancelacion_agendamientos', {"fecha":this.fecha, "hora": this.hora, "sede": this.sede}).then(
          response =>{
            this.proccess = false;
            this.cancelados = response.data;
            this.fecha = "";
            this.hora = "";
            this.sede = "";
          }
      )
      .catch(error=>{
          console.log(error)
      }).finally(()=>{
        if(this.cancelados.length == 0)
          this.setWarning("No se encontraron agendamientos a cancelar.");
        else
          this.setWarning("Se cancelaron "+this.cancelados.length+" agendamientos.");
      })
    },

    },
    computed:{
      ...mapState({
        warning: state => state.warning,
        sedes: state => state.sedes.lista, 
        disponibilidad_general: state => state.asw.disponibilidad_general, 
      }),
      horas(){
        if(this.fecha){
          let fecha = new Date(this.fecha)
          let dia = fecha.getDay()
          if([1,2,3,4,5,6].includes(dia)){
            let diaObj = this.disponibilidad_general.dias[dia]
            return diaObj.horas.map(element=>{
              return element.hora
            })
          }
        }

        return []
      },
    }
  }
</script>