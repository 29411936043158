<template>
   <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_funcionarios"></b-navbar-toggle>
        <h1 v-if="funcionario" class="text-center navbar-brand pt-0 pb-0 mb-0">
        {{ texto_funcionario }}</h1>
        <b-collapse is-nav id="nav_collapse_funcionarios">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <a :href="`#/usuarios`" class="nav-link" title="Lista">Lista</a>
            <a v-if="funcionario" :href="`#/usuario/detalle/${ funcionario.id }`" class="nav-link" title="Ver">Datos Básicos</a>
            <a v-if="funcionario && permiso('DEF5A092')" :href="`#/usuario/editar/${ funcionario.id }`" class="nav-link" title="Ver">Editar</a>
            <a v-if="funcionario && permiso('1CE32AC5')" :href="`#/usuario/cambiarpassword/${ funcionario.id }`" class="nav-link" title="Ver">Password</a>
            <b-nav-item  href="#" @click="actualizar">Actualizar</b-nav-item>
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
</template>

<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  
  export default {
    components: {},
    name: 'FuncionarioNavigation',
    props:{
      funcionario:Object
    },
    methods: {
      ...mapActions({
        fetchDetalle: 'funcionarios/fetchDetalle',
      }),
      actualizar(){
        this.fetchDetalle(this.funcionario.id).then(()=>{})
      },
    },
    computed:{
    ...mapState({
      }),
    ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      texto_funcionario(){
        let texto = ''
        if(this.funcionario){
          texto += this.funcionario.primer_nombre?this.funcionario.primer_nombre:''
          texto += this.funcionario.segundo_nombre?' '+this.funcionario.segundo_nombre:''
          texto += this.funcionario.primer_apellido?' '+this.funcionario.primer_apellido:''
          texto += this.funcionario.segundo_apellido?' '+this.funcionario.segundo_apellido:''
        }
        return texto
      }
    }
  }
</script>
