import Vue from 'vue';

const state = {
  lista: [],
  detalle : {},
};

const actions = {
  fetchLista:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('salon/lista',data).then(
        response =>{
          commit('setLista',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchDetalle:({commit},salon_id) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('salon/detalle',{id:salon_id}).then(
        response =>{
          commit('setDetalle',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  crear:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('salon/crear',data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  crearMasivo:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('salon/crear_masivo',data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  editar:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('salon/editar',data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  editarCursos:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('salon/editar_cursos',data).then(
        response =>{
          commit('setCursosPermitidos',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  editarTipos:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('salon/editar_tipos',data).then(
        response =>{
          commit('setTiposPermitidos',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  
};

const getters = {
  getSalonesDisponibles: (state) => () => {
    return state.lista.filter((element)=>{
      return element.activo == 1
    })
  },
};

const mutations = {
  setLista: (state, lista) => {
    state.lista = lista;
  },
  setDetalle: (state, detalle) => {
    let id = detalle.id;
    Vue.set(state.detalle, id, detalle)
  },
  setCursosPermitidos: (state, datos) => {
    let id = datos.id;
    state.detalle[id].cursos_permitidos = datos.cursos_permitidos
  },
  setTiposPermitidos: (state, datos) => {
    let id = datos.id;
    state.detalle[id].tipos_permitidos = datos.tipos_permitidos
  },
  incializarDetalle: (state, id) => {
    if(!state.detalle[id]){
      Vue.set(state.detalle, id, null)
    }
  },
  restart: (state) => {
    state.lista = []
    state.detalle = {}
  },
  
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
