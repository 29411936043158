<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_temas"></b-navbar-toggle>
        <h1 v-if="curso" class="text-center navbar-brand pt-0 pb-0 mb-0">{{ curso.nombre }} - {{correo.descripcion}} </h1>
        <b-collapse is-nav id="nav_collapse_temas">
          <!-- Right aligned nav items -->
          
          <b-navbar-nav class="ml-auto">
            <a v-if="correo" :href="`#/curso/${ correo.curso_id }/correos/`" class="nav-link" title="Volver">Volver</a>
            <a v-if="correo && permiso('DC61F6A2')" :href="`#/curso/${correo.curso_id}/correos/${ correo.id }/editar`" class="nav-link" title="Ver">Editar</a>
            
            <b-nav-item v-if="correo && permiso('83D463D2')" href="#" @click="eliminarPlantilla = true">Eliminar</b-nav-item>
            <b-nav-item  href="#" @click="actualizar">Actualizar</b-nav-item>
            
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <div class="container pt-3 m-auto text-left ">
       
        <div v-if="correo">
          <h3>Academico:</h3>
          <div class="row" >
            <div class="col-md-3">Tipo:</div><div class="col-md-9">{{correo.virtual ? 'Virtual' : 'Presencial'}}</div>
            <div class="col-md-3">Descripcion:</div><div class="col-md-9">{{correo.descripcion ? correo.descripcion : ''}}</div>
            <div class="col-md-3">Rango - Nota Minima (Mayor):</div><div class="col-md-9">{{correo.rango_nota_inicial}}</div>
            <div class="col-md-3">Rango - Nota Max (Menor o igual):</div><div class="col-md-9">{{correo.rango_nota_final}}</div>
            <div class="col-md-3">Asunto:</div><div class="col-md-9">{{correo.asunto ? correo.asunto : ''}}</div>
            <div class="col-md-3">Cuerpo de correo:</div><div class="col-md-9"><span v-html="correo.plantilla_correo ? correo.plantilla_correo : '&nbsp;'"></span></div>
            <div class="col-md-3">Evaluación cualitativa:</div>
            <div class="col-md-9">
              <b-row class="text-center m-3">
                <b-button :href="rutaArchivo" target="_blank" variant="success" >Preview</b-button>
              </b-row>
              <b-row>
                <embed v-if="correo.ruta_archivos" :src="rutaArchivo" width="500" height="375" type="application/pdf">
              </b-row>
            </div>
          </div>
          <hr>
          
        </div>
     
    </div>
    
    <b-modal v-model="eliminarPlantilla" no-close-on-backdrop hide-header-close id="modal-center" @ok="procesarEliminar" centered title="Eliminar plantilla de correo">
      <p class="my-4">¿Estas seguro de eliminar la plantilla?</p>
    </b-modal> 
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';  
  import Vue from 'vue';

  
  export default {
    name: 'CorreoDetalle',
    components: {
    },
    data () {
      return {
        tema:null,
        correo_id: null,
        curso_id:null,
        pageCount:null,
        currentPage:null,
        urlApi:null,
        eliminarPlantilla:false

      }
    },
    mounted () {
     this.curso_id = this.$route.params.curso_id;
     this.correo_id = this.$route.params.id;
      if(!this.detalle[this.correo_id]){
        this.actualizar()
      }
      if(!this.detalle_curso[this.curso_id]){
        this.actualizarCurso()
      }
      this.urlApi = Vue.http.options.root;
    },
    
    methods:{
      ...mapActions({
            fetchCursoDetalle: 'cursos/fetchDetalle',
        fetchDetalle: 'correos/fetchDetalle',
        fetchLista: 'correos/fetchLista',
        eliminar: 'correos/eliminar',
        setWarning: 'setWarning',
      }),
      ...mapMutations({
        
      }),
      actualizarCurso(){
          this.fetchCursoDetalle(this.curso_id)
      },
      actualizar(){
        this.fetchDetalle({curso_id:this.curso_id,id:this.correo_id}).then((result) => {
        }).catch((error) => {
          this.$router.push(`/curso/${ this.curso_id }/correos/`)
        })
      },
      procesarEliminar(){
        this.eliminar({curso_id:this.curso_id, id:this.correo_id}).then(()=>{
          this.fetchLista(this.curso_id).then(() => {
            this.$router.push(`/curso/${ this.curso_id }/correos/`)
          })
        })
      }

    },
    computed:{
      ...mapState({
        detalle: state => state.correos.detalle, 
        warning: state => state.warning,
        examenes: state => state.examenes.lista,
        detalle_curso: state => state.cursos.detalle
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      correo(){
        if (this.detalle[this.correo_id]) {
          return this.detalle[this.correo_id]
        } else {
          return {descripcion:"Correo desconocido"}
        }
      },
      curso(){
        if (this.detalle_curso[this.curso_id]) {
          return this.detalle_curso[this.curso_id]
        } else if(this.correo && this.correo.curso) {
          return this.correo.curso;
        } else {
          return {nombre:"Curso desconocido"}
        }
        
      },
      rutaArchivo() {
      // `/api/admin/file/email-course/${this.correo.id}`
        return `${this.urlApi}file/email-course/${this.correo.id}?&embedded=true`;
      }
      
    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>
