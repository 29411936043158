<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_examennuevo"></b-navbar-toggle>
        <h1  class="text-center navbar-brand pt-0 pb-0 mb-0">Nuevo Banner</h1>
        <b-collapse is-nav id="nav_collapse_examennuevo">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <a :href="`#/banners/lista`" class="nav-link" title="Ver">Volver</a> 
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <br>
    <div class="container pt-3 m-auto text-left ">
       
      <div>
        <SelectCursoAll titulo="Curso" :modelo="guardarBanners.segmento.cursos" @change="guardarBanners.segmento.cursos=$event"/>  
        <SelectSedeAll titulo="Sede" :modelo="guardarBanners.segmento.sedes" @change="guardarBanners.segmento.sedes=$event"/>      
        <InputTextSimple titulo="Url" :modelo="guardarBanners.urlGuardar" @change="guardarBanners.urlGuardar=$event"/>
        <SelectTipoPago titulo="Tipo de Pago" :modelo="guardarBanners.segmento.metodoPago" @change="guardarBanners.segmento.metodoPago=$event"/>
        
        <div class="form-group row">
            <label class="col-3 col-form-label">Fecha implementación:</label>
            <div class="col-4">
              <date-picker  class="date-picker" lang="es" v-model="new_fecha1" type="date" 
            :time-picker-options="timePickerOptions" :clearable="true"></date-picker>
            </div>
            <div class="col-4">
             <date-picker  class="date-picker" lang="es" v-model="new_fecha2" type="date" 
            :time-picker-options="timePickerOptions" :clearable="true"></date-picker>
            </div>
        </div>
        
        <InputTextSimple titulo="Enviar A (Link)" :modelo="guardarBanners.destination_url" @change="guardarBanners.destination_url=$event"/>

      </div>  
      <button class="btn btn-block btn-primary" @click="procesarGuardar" >Guardar</button>
      
      
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import SelectSedeAll from '@/components/Forms/SelectSedeAll'
  import SelectCursoAll from '@/components/Forms/SelectCursoAll'
  import SelectTipoPago from '@/components/Forms/SelectTipoPago'
  import DatePicker from 'vue2-datepicker'

  export default {
    name: 'BannersNuevo',
    components: {
      DatePicker,
      SelectSedeAll,
      SelectCursoAll,
      SelectTipoPago

    },
    data () {
      return {
          timePickerOptions:{},
          curso_id:0,
          metodo_id:0,
          sede_id:0,
          guardarBanners: {
            segmento:{}
          },

          fechaAco: null,
          fecha1dd: null,
          fecha1mm: null,
          fecha1yy: null,
          fecha1total: null,
          new_fecha1: null,
          new_fecha2: null

          
      }
    },
    mounted () {
      if(!this.cursos){
            this.fetchCursos()
      }
           
           this.fetchMetodosPago()
           this.fetchLista()
       
    },
    
    methods:{
      ...mapActions({
        fetchCursos: 'asw/fetchCursos',
        fetchMetodosPago: 'asw/fetchMetodosPago',
        fetchLista: 'sedes/fetchLista',
        postGuardar: 'banner/postGuardar',
        setWarning: 'setWarning'
      }),
 ...mapMutations({
        startProcessing: 'startProcessing',
        stopProcessing: 'stopProcessing'
      })
    ,
    procesarGuardar(){
        
          this.fechaAco = new Date(this.new_fecha1)
          this.fecha1mm= this.fechaAco.getMonth()+1
          this.fecha1dd= this.fechaAco.getDate()
          this.fecha1yy=this.fechaAco.getFullYear()
          this.fecha1total = this.fecha1yy + '-' +  this.fecha1mm + '-' + this.fecha1dd

          this.fechaAco2 = new Date(this.new_fecha2)
          this.fecha2mm= this.fechaAco2.getMonth()+1
          this.fecha2dd= this.fechaAco2.getDate()
          this.fecha2yy=this.fechaAco2.getFullYear()
          this.fecha2total = this.fecha2yy + '-' +  this.fecha2mm + '-' + this.fecha2dd
        //  console.log(this.fecha2total)
        console.log(this.guardarBanners.urlGuardar)

      
        this.guardarBanners.fecha1= this.fecha1total
        this.guardarBanners.fecha2= this.fecha2total
        this.guardarBanners.user_id= this.user.data.id

        if(this.new_fecha1 == null || this.new_fecha2== null || this.guardarBanners.segmento.cursos == undefined || this.guardarBanners.segmento.sedes == undefined || this.guardarBanners.urlGuardar == undefined){
           this.setWarning('Debe rellenar todos los campos')
           console.log('error')
        }else{
       this.postGuardar(this.guardarBanners).then(data=>{

            //console.log(this.guardarBanners)

            this.setWarning('Se ha guardado el Banner', { root: true }).then(()=>{
              this.$router.push('/banners/lista')
            })
        }).catch(error=>{
          this.setWarning(error, { root: true }).then(()=>{})
        })
        //

        this.guardarBanners = {}
        this.new_fecha1= null
        this.new_fecha2= null
                
        }
        }
 

    },
    computed:{
      ...mapState({
        cursos:  state=> state.asw.cursos,
        metodos_pago : state => state.asw.metodos_pago,
        sedes: state => state.sedes.lista,
        warning: state => state.warning,
        user: state => state.auth.user, //

      }),
      puedeGuardar(){
        if(this.candidato.codigo){
          return true  
        }
        return false
      }
      
    }
    
  }
</script>