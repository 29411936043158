<template>
  <div class="">

    <div class="container pt-3 m-auto text-left ">
      <h1>Gestion Global</h1>
      <hr>
      <div  class="form-group">
        <input class="form-control" type="text" v-model="busqueda"  placeholder="Buscar estudiante" />
        <div v-if="resultados">
          <div class="btn-link" v-for="res in resultados" @click="seleccionarUsuario(res)"> 
            {{res.primer_nombre}} {{res.segundo_nombre}} {{res.primer_apellido}} {{res.segundo_apellido}} / {{res.numero_documento}} / {{res.email}} / {{res.sede_actual.nombre}} <span v-if="res.ad && res.grupo">/ {{res.grupo.nombre}}</span> 
            
          </div>
          <div v-if="resultados.length === 0">
            <p>
              No se encontraron registros
            </p>
          </div>
        </div>
        <div class="seleccion" v-if="usuario">
          <div>{{usuario.primer_nombre}} {{usuario.segundo_nombre}} {{usuario.primer_apellido}} {{usuario.segundo_apellido}}</div>
          <div>{{usuario.numero_documento}} / {{usuario.email}}</div>
          <div >
            <strong>Estado:</strong>

            <button v-if="usuario.activo" class="pt-0 pb-0 mt-1 mr-1 btn btn-success" 
            @click="procesarCambiarEstado(usuario.id,0)" title="Click para quitar">Activo</button>
            <button v-else class="pt-0 pb-0 mt-1 mr-1 btn btn-dark" 
            @click="procesarCambiarEstado(usuario.id,1)" title="Click para agregar">No Activo</button>

          </div>

          <div>
            <strong>Sede programacion:</strong>
            <span v-if="usuario.sede_actual">{{usuario.sede_actual.nombre}}</span>
            <span v-else>Sin Asignar</span>
          </div>

          <div>
            <strong>Sede Matricula:</strong>
            <span v-if="usuario.sede_original">{{usuario.sede_original.nombre}}</span>
            <span v-else>Sin Asignar</span>

            <select v-if="sede_editing && permiso('4610DB00')" v-model="nueva_sede" class="form-control">
              <option disabled value="">Please select one</option>
              <option v-for="s in sedes"
              :value="s">{{ s.nombre}}</option>
            </select>

            <b-button v-if="!sede_editing && permiso('4610DB00')" size="sm" variant="link" 
            @click="iniciarEdicionSede()" title="Click para quitar">Cambiar</b-button>

            <b-button v-if="sede_editing" size="sm" variant="link" 
            @click="sede_editing=false;nueva_sede=null" title="Click para quitar">Cancelar</b-button>

            <b-button v-if="nueva_sede!=null && permiso('4610DB00')" size="sm" variant="link" 
            @click="procesarCambiarSede" title="Click para guardar">Guardar</b-button>

            

          </div>
          <div v-if="usuario.ad">
            <strong>Grupo:</strong>
            <span v-if="usuario.grupo">{{usuario.grupo.nombre}}</span>
            <span v-else class="btn-danger">Sin Asignar</span>
          </div>
          <div v-if="usuario.es">
            <strong>Curso Actual:</strong>
            <span v-if="usuario.curso_actual">{{usuario.curso_actual.nombre}}</span>
            <span v-else class="btn-danger">Sin Asignar</span>
          </div>
          <div>
            <button v-if="usuario.ad" class="pt-0 pb-0 mt-1 mr-1 btn btn-success" 
            @click="procesarDesactivarRol(usuario.id,'ad')" title="Click para quitar">ADMIN</button>
            <button v-else class="pt-0 pb-0 mt-1 mr-1 btn btn-dark" 
            @click="procesarActivarRol(usuario.id,'ad')" title="Click para agregar">ADMIN</button>
            <button v-if="usuario.pr" class="pt-0 pb-0 mt-1 mr-1 btn btn-success" 
            @click="procesarDesactivarRol(usuario.id,'pr')" title="Click para quitar">DOCENTE</button>
            <button v-else class="pt-0 pb-0 mt-1 mr-1 btn btn-dark" 
            @click="procesarActivarRol(usuario.id,'pr')" title="Click para agregar">DOCENTE</button>

            <button v-if="usuario.es" class="pt-0 pb-0 mt-1 mr-1 btn btn-success" 
            @click="procesarDesactivarRol(usuario.id,'es')" title="Click para quitar">ESTUDIANTE</button>
            <button v-else class="pt-0 pb-0 mt-1 mr-1 btn btn-dark" 
            @click="procesarActivarRol(usuario.id,'es')" title="Click para agregar">ESTUDIANTE</button>
          </div>
          <div v-if="usuario.deleted_at">
            <strong>Eliminado el:</strong>
            <span >{{dateFormat(usuario.deleted_at)}}</span>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';

  export default {
    name: 'FuncionarioMultirol',
    data () {
      return {
          resultados: null,
          termino: null,
          usuario: null,
          sede_editing: false,
          nueva_sede: null,
          sedes: null
      }
    },
    mounted () {
    },
    methods:{
      ...mapActions({
        setWarning: 'setWarning',
      }),
      ...mapMutations({
      }),
      procesarActivarRol(id,value){
        if(this.permiso('13FF6EC4')){
          Vue.http.post('user/activar_rol',{id:id,rol:value})
            .then((response)=>{
              console.log(response)
              this.usuario = response.data.usuario
              this.setWarning(response.data.mensaje)
            })
            .catch(error=>{
              this.setWarning(error)
              console.log(error)
            })
        }else{
          this.setWarning('No tiene permiso para esta operación')
          return
        }
      },
      procesarDesactivarRol(id,value){
        if(this.permiso('71A798F')){
          Vue.http.post('user/desactivar_rol',{id:id,rol:value})
            .then((response)=>{
              console.log(response)
              this.usuario = response.data.usuario
              this.setWarning(response.data.mensaje)
            })
            .catch(error=>{
              this.setWarning(error)
              console.log(error)
            })
        }else{
          this.setWarning('No tiene permiso para esta operación')
          return
        }
      },
      procesarCambiarEstado(id,value){
        if(this.permiso('71A798F')){
          Vue.http.post('user/cambiar-activo',{id:id,activo:value})
            .then((response)=>{
              console.log(response)
              this.usuario = response.data.usuario
              this.setWarning(response.data.mensaje)
            })
            .catch(error=>{
              if (error && error.body && error.body.error) {
                this.setWarning(error.body.error)
              } else {
                this.setWarning(error)
              }
              console.log(error)
            })
        }else{
          this.setWarning('No tiene permiso para esta operación')
          return
        }
      },
      seleccionarUsuario(value){
        this.resultados = null
        this.usuario = value
      },
      iniciarEdicionSede(){
        this.sede_editing=true
        if(this.sedes == null){
          Vue.http.post('sedes/lista')
          .then((response)=>{
            this.sedes = response.data
          })
          .catch(error=>{
            this.setWarning(error)
            console.log(error)
          })
        }
      },
      procesarCambiarSede(){
        Vue.http.post('user/cambiar_sede',{id:this.usuario.id,sede_id:this.nueva_sede.id})
          .then((response)=>{
            this.sede_editing=false
            this.nueva_sede = null
            this.usuario = response.data.usuario
            this.setWarning(response.data.mensaje)
          })
          .catch(error=>{
            this.setWarning(error)
            console.log(error)
          })
      },
      dateFormat(date) {
          return this.$moment(date).format("DD/MM/YYYY HH:mm:ss");
        },
    },
    computed:{
      ...mapState({
        warning: state => state.warning
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      busqueda: {
        get () {
          return this.termino
        },
        set(value){
          this.termino = value 
          if(value && value.length >= 5){
            this.usuario = null
            Vue.http.post('user/buscar',{texto:value}).then(
              response =>{
                this.resultados = response.data
              }
            )
            .catch(error=>{
              console.log(error)
            }).finally(()=>{
              
            })
          }
        }
      },

    }
  }
</script>

<style scope>
  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
  .resultado{
    padding: 5px; 
  }
  .seleccion{
    padding: 10px; 
  }
</style>