<template>
  <div>
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0 bg-blue">
        <b-navbar-toggle target="nav_collapse_actions"></b-navbar-toggle>
        <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Actividad Académica</h1>
        <b-collapse is-nav id="nav_collapse_actions" >
          <b-navbar-nav class="ml-auto">
            <date-picker  class="date-picker" v-model="fecha" lang="es" type="date" 
            :time-picker-options="timePickerOptions"  confirm :clearable="true"></date-picker>
          </b-navbar-nav>
          <b-navbar-nav  class="ml-auto">
            <b-nav-item  href="#" @click="generarReporte">Generar</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
    </b-navbar>

  	<div class="container-fluid" >
       <v-client-table name="actividadTable" :columns="columns" :data="lista" :options="options">
          
        </v-client-table>
    </div>
      
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';
  import DatePicker from 'vue2-datepicker'

  export default {
    name: 'ReporteActividad',
    components: {
      DatePicker
    },
    data () {
      return {
        columns: ['bloque','estudiante','numero_documento','estado'],
        options: {
          filterable: true,
          filterByColumn: true,
          sortable:['bloque','estado'],
          perPage: 10,
          perPageValues: [10, 50, 100, 200,400],
          headings: {
            id: 'Id',
            bloque: 'Bloque',
            estudiante: 'Estudiante',
            numero_documento: 'Documento',
            estado: 'Estado',
          }
        },
        lista:[],
        fecha:null,
        timePickerOptions:{    
        }
      }
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({
      }),
      ...mapMutations({
        startProcessing: 'startProcessing',
        stopProcessing: 'stopProcessing'
      }),
      
      generarReporte(){
        console.log('Generando reporte....')
      
        let fecha = new Date(this.fecha)
        console.log(fecha)
        
        this.startProcessing()
        Vue.http.post('reportes/actividad',{fecha:fecha}).then(
          response =>{
            this.lista = response.data
          }
        ).catch(error=>{
          console.log(error)
        }).finally(()=>{
          this.stopProcessing()
        })
        
      },
    },
    computed: {
      ...mapState({
       
      }),      
    }
  }
</script>

<style scope>

.date-picker{
  
}
</style>