<template>
  <div class="" :style="{backgroundColor: color}">
    <span>{{ texto }}</span>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'ProgramadorAgendamiento',
    components: {
    },
    data () {
      return {
        
      }
    },
    props: {
      agendamiento : Object,
    },
    beforeCreate() {
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({
      }),
      ...mapMutations({
      }),
    },
    computed: {
      ...mapState({
      }),
      color(){
        return '#fff'
      },
      texto(){
        let texto = '...'
        if(this.agendamiento){
          if(this.agendamiento.estudiante){
            texto = this.agendamiento.estudiante.primer_nombre+' '+this.agendamiento.estudiante.primer_apellido
            texto += this.agendamiento.estudiante.segundo_apellido?' '+this.agendamiento.estudiante.segundo_apellido.substring(0,1):''
          }

          if(this.agendamiento.tema){
            //texto = texto+this.agendamiento.tema.nombre//TO DO - HACERE OTRA PANTALLA QUE MUESTRE LOS TEMAS
          }
        }
        return texto
      },
      
    }
  }
</script>

<style scope>
  
</style>