<template>
  <div class="">

    <div class="container pt-3 m-auto text-left ">
      <h1>Estudiante Masivo</h1>
       <hr>
      <form
        autocomplete="off"
        class="form-horizontal"
        @submit.prevent="validateBeforeSubmit"
      >
        <div>
          <strong>Estructura:</strong><br>
          <span style="font-size: 10px">"primer_nombre,segundo_nombre,primer_apellido,segundo_apellido,tipo_documento,numero_documento,telefono,movil,email,sede_id,curso_actual_id"</span>
          <textarea class="area-masivo" v-model="datos"></textarea>

        </div>
        <button class="btn btn-block btn-danger" type="submit">Procesar</button>

        <div>{{ resultado}}</div>
      </form>

      
      <button class="btn btn-block btn-danger mt-3" @click="procesarRegistros">Procesar Registro de actualización</button>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';
  
  export default {
    name: 'EstudianteMasivo',
    data () {
      return {
          datos: null,
          resultado: null
      }
    },
    mounted () {
    },
    methods:{
      ...mapActions({
        procesar: 'estudiantes/crearMasivo',
        setWarning: 'setWarning',
        fetchLista: 'estudiantes/fetchLista',
      }),
      ...mapMutations({
      }),

      procesarRegistros () {
          Vue.http.post('estudiante/procesar_re_ac',{}).then(
              response =>{
                this.resultado = response.data
              }
            )
            .catch(error=>{
              console.log(error)
            }).finally(()=>{
              
            })
      },

      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {

          let lines = this.datos.split("\n");
          let candidatos = []
          for(let i in lines){
            let line = lines[i];
            let parametros = line.split(",")
            let candidato = {
              primer_nombre:parametros[0],
              segundo_nombre:parametros[1],
              primer_apellido:parametros[2],
              segundo_apellido:parametros[3],
              tipo_documento:parametros[4],
              numero_documento:parametros[5],
              telefono:parametros[6],
              movil:parametros[7],
              email:parametros[8],
              sede_id:parametros[9],
              curso_actual_id:parametros[10],
              
            }
            candidatos.push(candidato)
          }

          console.log(candidatos)
          if ( ! result) {
            //hay errores
          } else {
            
            this.procesar(candidatos).then((response)=>{
                this.resultado = response.message
                this.setWarning(response.message, { root: true }).then(()=>{
                this.fetchLista().then(()=>{
                    //this.$router.push('/estudiantes')
                })
              })
            }).catch(error=>{
              
            })
            
          }
        })
      },

    },
    computed:{
      ...mapState({
        warning: state => state.warning
      }),
    }
  }
</script>

<style scope>
  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
  .area-masivo{
    width: 100%;
  }
</style>