<template>
  <div class="">
    <div class="pt-3 m-auto text-left ">
      <div v-if="funcionario">
        <h2>Últimos Eventos</h2>
      </div>
      <div v-else>
        No hay información
      </div>
      
       <v-client-table @row-click="rowClick" name="eventosFuncionarioTable" :columns="columns" :data="data" :options="options">
        </v-client-table>
      <b-modal  v-model="showRow"  hide-footer title="Detalle del Evento">
          <div v-for="(value, key) in selectedRow">
            {{ key }}: {{ value }}
          </div>
      </b-modal>
      <b-button v-if="funcionario!=null && haymas" size="sm" variant="link" 
            @click="cargar" title="Ver más">Ver más</b-button>
        <b-button v-if="funcionario!=null" size="sm" variant="link" 
            @click="reiniciar" title="Reiniciar">Reiniciar</b-button>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue'
  import axios from 'axios'
  import config from '@/config'

  export default {
    name: 'FuncionarioDetalleEventos',
    components: {
    },
    props:{
      funcionario:Object
    },
    data () {
      return {
        columns: ['universo','tipo',  'evento', 'contenido','created_at'],
        options: {
          filterable: false,
          filterByColumn: true,
          sortable:[],
          perPage: 10,
          perPageValues: [5, 10, 15, 20],
          headings: {
            id: 'Id',
            universo: 'App',
            tipo: 'Tipo',
            evento: 'Evento',
            contenido: 'Descripcion',
            created_at: 'Fecha',
          }
        },
          
          data:[],
          page:1,
          haymas: true,
          showRow: false,
          selectedRow: null
      }
    },    
    mounted () {
      
    },
    
    methods:{
      ...mapActions({
        
      }),
      ...mapMutations({
      }),
      reiniciar(){
        this.page = 1
        this.haymas = true
        this.data = []
      },
      cargar(){
        let api = config.EVENTS_API+'event/search'
        let filters = {funcionario_id:this.funcionario.id}
        axios.defaults.headers.common['Authorization'] = 'Bearer '+config.BITACORA_TOKEN;
        axios.post(api,{paginate:this.page,filters:filters}).then((response) => {
          this.data = response.data.datos.data
          if(response.data.datos.lastPage>response.data.datos.lastPage){
            this.page = (response.data.datos.page*1)+1
          }else{
            this.haymas = false
          }
          
          
        })
      },
      rowClick(event){
        this.showRow = true
        this.selectedRow = event.row
      }
      
    },
    computed:{
      ...mapState({
        
      }),
      ...mapGetters({
        
      }),
    }
    
  }
</script>

<style scope>

</style>