<template>

  <div class="container programaciones_wrapper mt-3">
    <div v-if="estudiante.agendamientos" class="tabla">
      <div class="text-center mb-3 mt-3 row">
        <div class="col-md-3"><strong>TEMA</strong></div>
        <div class="col-md-3"><strong>ESTADO</strong></div>
        <div class="col-md-6"><strong>OBSERVACIONES</strong></div>
      </div>
      <DetalleAgendamiento v-for="(agendamiento, index_1) in estudiante.agendamientos" :key="index_1"
      :agendamiento="agendamiento" :estudiante="estudiante"/>
      
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import DetalleAgendamiento from '@/components/Estudiantes/DetalleAgendamiento';
  export default {
    name: 'programaciones',
    components: {
      DetalleAgendamiento
    },
    data () {
      return {
        
      }
    },
    props: {
      estudiante: Object
    },
    mounted () {
      if(this.estudiante.agendamientos == 'undefined'  ){
        console.log('DetalleProgramaciones undefined')
        this.fetchAgendamientos(this.estudiante.id)
      }
      
    },
    methods:{
      ...mapActions({
        fetchAgendamientos: 'estudiantes/fetchAgendamientos',
        fetchDetalle: 'estudiantes/fetchDetalle',
        cancelar: 'programacion/cancelar',
      }),
      ...mapMutations({
        
      }),
      procesar_cancelar(id){
        this.cancelar(id).then((apiResponse)=>{
          if(apiResponse.error){
            this.error = apiResponse.error
          }else{
            this.fetchDetalle(this.estudiante.id).then(()=>{
              this.fetchAgendamientos(this.estudiante.id)
            }) 
          }
        })
      }

    },
    computed: {
      ...mapState({
        
      }),
      ...mapGetters({

      }),
      
    }
  }
</script>

<style scope>

	.programaciones_wrapper .tabla{
      
	}
</style>