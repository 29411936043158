import Vue from 'vue'
import App from './App.vue'
import config from './config'
import store from './store'
import router from './router'
import './registerServiceWorker'

Vue.config.productionTip = false


//vue resource
import VueResource from 'vue-resource';
Vue.use(VueResource);
Vue.http.options.root = config.apiUrl;
Vue.http.interceptors.push((request, next) => {
  request.headers.set('Authorization', `Bearer ${window.localStorage.getItem('_token')}`);
  next();
});
//.vue resource

import VueMoment from 'vue-moment'
require('moment/locale/es')
Vue.use(VueMoment)

//vue cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
//.vue cookies

//blockui
import BlockUI from 'vue-blockui';
Vue.use(BlockUI);
//.blockui

//vue bootstrap
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//.vue bootstrap

//vee-validate
import VeeValidate, {Validator} from 'vee-validate';
import validatorEs from '@/validator/es';
//import validatorEn from '@/validator/en';
Validator.localize('es', validatorEs);
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });
//.vee-validate

//vue-tables-2
import {ClientTable} from 'vue-tables-2';
Vue.use(ClientTable, {}, false, 'bootstrap4', 'default');
//.vue-tables-2

import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {}); // config is optional. more below
import "vue-wysiwyg/dist/vueWysiwyg.css";

//datepicker
import 'vue2-datepicker/index.css';
//datepicker

import InputEmailSimple from '@/components/Forms/InputEmailSimple';
import InputTextSimple from '@/components/Forms/InputTextSimple';
import InputTextArea from '@/components/Forms/InputTextArea';
import SelectTipoDocumento from '@/components/Forms/SelectTipoDocumento';
import SelectTipoClase from '@/components/Forms/SelectTipoClase';
import SelectActivo from '@/components/Forms/SelectActivo';
import InputNumeroDocumento from '@/components/Forms/InputNumeroDocumento';
import InputNumero from '@/components/Forms/InputNumero';
import InputMovil from '@/components/Forms/InputMovil';
import InputFecha from '@/components/Forms/InputFecha';
import draggable from 'vuedraggable'
import JsonExcel from "vue-json-excel";

Vue.component('InputEmailSimple',InputEmailSimple)
Vue.component('InputTextSimple',InputTextSimple)
Vue.component('InputTextArea',InputTextArea)
Vue.component('SelectTipoDocumento',SelectTipoDocumento)
Vue.component('InputNumeroDocumento',InputNumeroDocumento)
Vue.component('InputNumero',InputNumero)
Vue.component('InputMovil',InputMovil)
Vue.component('InputFecha',InputFecha)
Vue.component('SelectTipoClase',SelectTipoClase)
Vue.component('SelectActivo',SelectActivo)
Vue.component('draggable',draggable)
Vue.component("downloadExcel", JsonExcel);

new Vue({
	store,
	router,
	render: h => h(App),
	
}).$mount('#app')
