<template>
  <div class="">
    <div class="pt-3 m-auto text-left ">
      <div v-for="dato in data">
        {{dato.evento}} / {{dato.usuario}} / {{dato.created_at}} / {{dato.docente_id}} / {{dato.salon_id}} / {{dato.motivo}}
      </div>
      <b-button size="sm" variant="link" 
        @click="cargar" title="Actualizar">Actualizar</b-button>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue'
  import axios from 'axios'
  import config from '@/config'

  export default {
    name: 'ProgramadorClaseDetalle',
    components: {
    },
    props:{
      clase_id:Number
    },
    data () {
      return {  
          data:[],
      }
    },    
    mounted () {
      
    },
    
    methods:{
      ...mapActions({
        
      }),
      ...mapMutations({
      }),
      reiniciar(){
        this.data = []
      },
      cargar(){
        let api = config.EVENTS_API+'event/search'
        let filters = {clase_id:this.clase_id}
        axios.defaults.headers.common['Authorization'] = 'Bearer '+config.BITACORA_TOKEN;
        axios.post(api,{paginate:this.page,filters:filters}).then((response) => {
          if(response.data.datos.total==0){
            this.data.push({evento:'No hay información'})
          }else{
            this.data = response.data.datos.data
          }
        })
      },
      rowClick(event){
        this.showRow = true
        this.selectedRow = event.row
      }
      
    },
    computed:{
      ...mapState({
        
      }),
      ...mapGetters({
        
      }),
    }
    
  }
</script>

<style scope>

</style>