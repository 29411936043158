<template>
  <div class="">

    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_temas"></b-navbar-toggle>
        <h1 v-if="tema" class="text-center navbar-brand pt-0 pb-0 mb-0">{{ curso.nombre }} - {{ tema.nombre }} </h1>
        <b-collapse is-nav id="nav_collapse_temas">
          <!-- Right aligned nav items -->
          
          <b-navbar-nav class="ml-auto">
            <b-nav-item  href="#" @click="cancelar">Cancelar</b-nav-item> 
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>

    <div class="container pt-3 m-auto text-left ">
      <h1>Editar Tema</h1>
       
      <form
        autocomplete="off"
        class="form-horizontal"
        @submit.prevent="validateBeforeSubmit"
      >
        <div v-if="tema">
          <div class="academico">

            <InputTextSimple titulo="Block" :modelo="tema.block" 
            @change="tema.block = $event" v-show="true" ></InputTextSimple>
            <InputTextSimple titulo="Pages" :modelo="tema.pages" 
            @change="tema.pages=$event"/>
            <InputTextSimple titulo="Unidad" :modelo="tema.unidad" 
            @change="tema.unidad=$event"/>
            <InputTextSimple titulo="Class Topic" :modelo="tema.class_topic" 
            @change="tema.class_topic=$event"/>
            <InputTextSimple titulo="Class Structure" :modelo="tema.class_structure" 
            @change="tema.class_structure=$event"/>
            <InputTextSimple titulo="Description" :modelo="tema.description" 
            @change="tema.description=$event"/>

            <div class="form-group row">
              <label class="col-3 col-form-label">Examen:</label>
              <div class="col-9">
                <select  v-model="tema.examen_id" class="form-control">
                  <option value="null">Seleccione un examen...</option>
                  <option v-for="examen in examenes"
                  :value="examen.id">{{ examen.nombre}}</option>
                </select>
              </div>
            </div>

          </div> 
          <div class="tecnico">
            <hr>
            <InputTextSimple titulo="Orden" :modelo="tema.orden ? tema.orden.toString() : ''" 
            @change="tema.orden = $event"/>
            <!--
            <InputTextSimple titulo="Activo" :modelo="tema.activo" 
            @change="tema.activo=$event"/>
            -->
            <SelectActivo titulo="Activo" :modelo="tema.activo ? tema.activo.toString() : '0'" 
            @change="tema.activo=$event"/>
            <!--
            <InputTextSimple titulo="Tipo Clase" :modelo="tema.tipo_clase" 
            @change="tema.tipo_clase = $event"/>
            -->
            <!--
            <InputTextSimple titulo="Tipo" :modelo="tema.tipo" 
            @change="tema.tipo = $event"/>
            -->
            <div class="form-group row">
              <label class="col-3 col-form-label">Tipo:</label>
              <div class="col-9">
                <select  v-model="tema.tipo" class="form-control">
                <option v-for="tipo in tiposComputed"
                :value="tipo.id">{{ tipo.nombre}}</option>
              </select>
              </div>
            </div>
             
            <!--
            <InputTextSimple titulo="Tipo de Salon" :modelo="tema.tipo_salon" 
            @change="tema.tipo_salon=$event"/>
            -->
            <!--
            <InputTextSimple titulo="Programable" :modelo="tema.programable" 
            @change="tema.programable=$event"/>
            <InputTextSimple titulo="Autorizaciones" :modelo="tema.autorizaciones" 
            @change="tema.autorizaciones=$event"/>
            -->
            <InputNumero titulo="Rango Nota" :modelo="tema.rango_nota" 
            @change="tema.rango_nota=$event"/>
            <InputTextSimple titulo="Peso Nota" :modelo="tema.peso_nota" 
            @change="tema.peso_nota=$event"/>

            <div class="form-group row">
              <label class="col-3 col-form-label">Sede:</label>
              <div class="col-9">
                <select  v-model="tema.requiere_sede_original" class="form-control">
                  <option value="null">Seleccione una opción...</option>
                  <option value="0">MULTISEDE</option>
                  <option value="1">SEDE PROPIA</option>
                </select>
              </div>
            </div>

          </div>
          <hr>
          <div class="notificacion">
            <div class="form-group row">

              <div class="col">
                <b-form-checkbox v-model="tema.notificacion_mostrar" name="notificacion_mostrar" id="notificacion_mostrar" switch>
                  Mostrar notificación
                </b-form-checkbox>
              </div>
            </div>
            <InputTextArea titulo="Notificación" :modelo="tema.notificacion_texto" v-if="tema.notificacion_mostrar"
            @change="tema.notificacion_texto=$event"/>

            <InputTextSimple titulo="Enlace" :modelo="tema.notificacion_enlace" v-if="tema.notificacion_mostrar"
            @change="tema.notificacion_enlace=$event"/>

          </div>
        </div>
        <button class="btn btn-block btn-danger" type="submit">Guardar</button>
      </form>
      
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'TemaEditar',
    components: {
      
    },
    data () {
      return {
           tema_id: null,
      }
    },
    mounted () {
     this.tema_id = this.$route.params.id;
      if(!this.detalle[this.tema_id]){
        this.actualizar()
      }
    },
    
    methods:{
      ...mapActions({
        fetchDetalle: 'temas/fetchDetalle',
        editar: 'temas/editar',
        setWarning: 'setWarning',
        fetchLista: 'examenes/fetchLista',
      }),
      ...mapMutations({
        
      }),
      actualizar(){
        this.fetchDetalle(this.tema_id)
        this.fetchLista()
      },
      cancelar(){
        this.fetchDetalle(this.tema_id)
        this.fetchLista()
        this.$router.push('/tema/detalle/'+this.tema_id)
      },
      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {
          console.log('validateBeforeSubmit')
          if ( ! result) {
            console.log('validateBeforeSubmit error')
            //hay errores
          } else {
            console.log('validateBeforeSubmit no error')
            this.editar(this.tema).then(()=>{
                this.setWarning('Tema Actualizado', { root: true }).then(()=>{
                this.fetchDetalle(this.tema_id).then(()=>{
                  this.$router.push('/tema/detalle/'+this.tema_id)
                })
              })
            }).catch(error=>{
              //todo probar si se puede quitar
            })
            
          }
        })
      },

    },
    computed:{
      ...mapState({
        tipos: state => state.asw.tipos, 
        examenes: state => state.examenes.lista, 
        detalle: state => state.temas.detalle, 
        warning: state => state.warning,
        detalle_curso: state => state.cursos.detalle, 
      }),
      curso(){
        if(this.tema && this.tema.curso_id){
          return this.detalle_curso[this.tema.curso_id]  
        }else{
          return {nombre:"Curso desconocido"}
        }
        
      },

      tiposComputed() {
        var tipos = [];
        if (!this.tipos) return tipos;
        if (!this.tipos.find(x => x.id == 'autonomo')) {
          tipos = [...this.tipos];
          tipos.push({id:'autonomo', nombre:"Trabajo Autonomo"});
        } 
        return tipos;
      },

      tema(){
        return this.detalle[this.tema_id]
      },
      
      
    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>
