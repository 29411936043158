<template>
  <div class="pendientes-container">
    <div class="pendientes-titulo" @click="toogleView" :style="{backgroundColor:color}">{{ titulo }}</div>
    <div v-if="estado">
      <draggable  style="min-height: 60px" class="card" v-model="agendamientos" :options="dragOptions" 
        @add="add"
        @remove="remove"
      >
            <ProgramadorAgendamiento v-for="element in agendamientos" :key="element.id" 
          :accessKey="element.id" :agendamiento='element' @procesarActualizar="procesarActualizar"/>    
        
      </draggable>
      <!--
      <ProgramadorAgendamientoNuevo :tipo="tipo" :curso="curso" :hora="hora" :dia="dia"/>
    -->
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import ProgramadorAgendamiento from '@/components/Programador/ProgramadorAgendamiento';
  import ProgramadorAgendamientoNuevo from '@/components/Programador/ProgramadorAgendamientoNuevo';

  export default {
    name: 'ProgramadorPendientes',
    components: {
      ProgramadorAgendamiento,
      ProgramadorAgendamientoNuevo
    },
    data () {
      return {
        estado : false,
      }
    },
    props : {
      curso: Object,
      hora: Object, 
      dia: Object,
      id: String,
      tipo: Object,
    },
    mounted () {},
    methods:{
      ...mapActions({
        setClase : 'agendamientos/setClase',
        fetchAgendamientos : 'agendamientos/fetchAgendamientos',
        fetchAgendamientosXMarca : 'agendamientos/fetchAgendamientosXMarca',
      }),
      ...mapMutations({
        setAgendamientosXMarca: 'agendamientos/setAgendamientosXMarca'
      }),
      procesarActualizar(){
        //OPTIMIZAR this.fetchAgendamientos()
        this.fetchAgendamientosXMarca({marca_tiempo:this.hora.marca_tiempo}).then(()=>{})
      },
      add(event){
        console.log('add to pendientes')
        let key = event.item.accessKey
        console.log(key)
        this.setClase({clase_id:null,agendamiento_id:key})
      },
      remove(event){
        console.log('remove from pendientes')
        let key = event.item.accessKey
        console.log(key)
      },
      toogleView(){
        this.estado = !this.estado
      },
      
    },
    computed: {
      ...mapState({
        todos_los_agendamientos: state => state.agendamientos.agendamientos, 
        tipos_clase: state => state.asw.tipos_clase, 
      }),
      titulo(){
        let titulo = ''
        if(this.agendamientos.length){
          titulo = ' ('+this.agendamientos.length+')'
        }
        
        titulo = this.tipo.nombre + titulo
        
        return titulo
      },
      color(){
        if(this.tipo){
          return '#'+this.tipo.color    
        }else{
          console.log('aquí el error:')
          console.log(this.tipo)
        }
        
      },
      
      marcaClase(){
        return this.hora.marca_tiempo+'T'+this.tipo.id
      },
      
      agendamientos:{
        get () {
          if(this.todos_los_agendamientos[this.marcaClase]){
            return this.todos_los_agendamientos[this.marcaClase]    
          }else{
            return []
          }
          
        },
        set(value){
          this.setAgendamientosXMarca({marca:this.marcaClase,agendamientos:value})
        }
      },
      dragOptions(){
        return {group:this.marcaClase,draggable:'.item'}
      },
    }
  }
</script>

<style scope>
.pendientes-container{
  width: 200px;
}
 .pendientes-titulo{
  cursor: pointer;
  padding: 1px 5px;
  width: 100%;
  color:#fff;
 }
 .link-inferior{

 }
</style>