<template>
	<div class="form-group row">
		<label class="col-3 col-form-label">Activo</label>
		<div class="col-9">
			<select v-model="_modelo" class="form-control">
        <option disabled value="">Please select one</option>
        <option value="1">Activo</option>
        <option value="0">No Activo</option>
      </select>
		</div>
	</div>
</template>


<script>
  export default {
    name: 'SelectActivo',
    data () {
      return {
          error: null,
      }
    },
    props: {
      modelo: String
    },
    
    computed:{
		_modelo: {
			get () {
				return this.modelo
			},
			set (value) {
				this.$emit('change', value);
			}
		},
    }
    
  }
</script>

<style scope>

</style>