<template>
  <div>
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0 bg-blue">
        <b-navbar-toggle target="nav_collapse_actions"></b-navbar-toggle>
        
        <b-navbar-nav >
          <b-nav-item-dropdown v-if="agenda_disponible_local" :text="titulo_dia" >
            <b-dropdown-item v-for="dia in agenda_disponible_local" :key="dia.timestamp" href="#" 
            @click="cambiarDia(dia)">
            {{ dia.texto }}
          </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav >
          <b-nav-item-dropdown v-if="dia_actual" :text="titulo_hora" >
            <b-dropdown-item v-for="hora in dia_actual.horas" :key="hora.id" href="#" @click="cambiarHora(hora)">
            {{ hora.nombre_corto }}
          </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav >
          <date-picker @change="agregarFecha" class="date-picker" v-model="new_fecha" lang="es" type="date" :time-picker-options="timePickerOptions" :clearable="false"></date-picker>
        </b-navbar-nav>
        <b-collapse is-nav id="nav_collapse_actions" >
          <b-navbar-nav  class="ml-auto">
            <b-nav-item  href="#" @click="recargarClases">Actualizar</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
    </b-navbar>
  	<div class="container-fluid principal" >
      <div style="font-size: 10px" class="text-center text-muted pt-0 pb-0 mb-0">
      Última actualización: {{ dia_actual_local }} {{ hora_actual_local }}
      </div>

      <div v-if="!dia_actual" class="text-center">
        <img  src="/img/icons/android-chrome-512x512.png">
      </div>

			<div v-if="iniciado">
				<div v-for="(dia, index_1) in agenda_disponible_local" :key="index_1">
					<ProgramadorXDiaXHora :activo="tabActivo" :rightPanel="rightPanel"
					v-for="(hora, index_2) in dia.horas" :key="index_2" :hora="hora" :dia="dia"/>
				</div>
			</div>
  	</div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import ProgramadorXDiaXHora from '@/components/Logistica/ProgramadorXDiaXHora';
  import Vue from 'vue';
  import DatePicker from 'vue2-datepicker'

  export default {
    name: 'Logistica',
    components: {
      ProgramadorXDiaXHora,
      DatePicker
    },
    data () {
      return {
        rightPanel : false,
    		leftPanel : false,
        dia_actual : null,
        dia_actual_local : null,
        hora_actual : null,
        hora_actual_local : null,
        timestamp : null,
        interval: null,
        new_fecha: null,
        agenda_disponible_local : [],
        timePickerOptions:{
          
        }
      }
    },
    mounted () {
      
      
      if(!this.agenda_disponible){
        this.fetchAgendaDisponible().then(()=>{
          this.agenda_disponible_local = this.agenda_disponible
          this.fetchCursos().then(()=>{})
          this.fetchProfesores().then(()=>{})
          this.fetchSalones().then(()=>{})
        })
        /*
        
        this.fetchClases().then(()=>{})
        this.fetchAgendamientos().then(()=>{})
        */
      }

      this.interval = setInterval(()=>{
        if(this.$router.currentRoute.name=='logistica'){
          this.recargarClases()  
        }
      }, 30000);
      
    },
    methods:{
      ...mapActions({
        fetchAgendaDisponible: 'asw/fetchAgendaDisponible',
        fetchCursos : 'asw/fetchCursos',
        fetchClases : 'clases/fetchClases',
        fetchClasesXMarca : 'clases/fetchClasesXMarca',
        fetchProfesores : 'docentes/fetchLista',
        fetchSalones : 'salones/fetchLista',
        fetchAgendamientos : 'agendamientos/fetchAgendamientos',
        generarParcial : 'clases/generarParcial'
      }),
      ...mapMutations({
        nuevaClase: 'clases/nuevaClase',
      }),
      
      toogleRightPanel(){
        this.rightPanel = !this.rightPanel
      },
      edit(){

      },
      cambiarDia(value){
        this.dia_actual = value
        if(this.hora_actual){
          var found = this.dia_actual.horas.find((element) => {
            return element.hora == this.hora_actual.hora;
          });
          if(found){
            this.hora_actual = found
            this.recargarClases()
          }else{
            this.hora_actual =  null
          }
        }
      },
      cambiarHora(value){
        this.hora_actual=value
        this.recargarClases()
      },
      nueva(){
        let now = new Date()
        let candidato = {
          profesor_id:-1,
          curso_id: -1,
          salon_id: -1,
          fecha:this.hora_actual.marca_tiempo.split('T')[0],
          hora:this.hora_actual.marca_tiempo.split('T')[1],
          tipo_clase:'',
          id_temporal: now.getTime()
        }
        this.nuevaClase({marca:this.hora_actual.marca_tiempo,candidato:candidato})
        console.log('inicia el proceso')
      },
      generar(){
        console.log('Generando automaticamente....')

        this.generarParcial({
          marca_tiempo:this.hora_actual.marca_tiempo
        }).then(()=>{

        })
      },
      recargarClases(){
        if(this.hora_actual){
          this.hora_actual_local = new Date().toLocaleString()
          this.fetchClasesXMarca({marcatiempo:this.hora_actual.marca_tiempo,con_consecutivo:true,silent:true})
          .then(()=>{})  
        }
        
      },
      agregarFecha(){
        let value = new Date(this.new_fecha)
        let timestamp = value.getTime()
        value = value.toISOString().split('T')[0]

        if(timestamp>0 && value && value!=''){
          let dia = this.agenda_disponible_local.find(element=>{
            return element.fecha == value
          })
          if(dia)
          {
            console.log('La fecha ya esta en la lista. Cambiando...')
            this.cambiarDia(dia)
          }else{
            console.log('Cargando fecha:'+value)
            Vue.http.post('agenda_especifica',{fecha:value}).then(
              response =>{
                let datos = response.data
                this.agenda_disponible_local.push(datos[0])
                let nuevo_dia = this.agenda_disponible_local.find(element=>{
                  return element.fecha == value
                })
                if(value){this.cambiarDia(nuevo_dia)}
              }
            ).catch(error=>{
              console.log(error)
            }).finally(()=>{
              
            })
          }

          
        }
       
      }
    },
    computed: {
      ...mapState({
        agenda_disponible: state => state.asw.agenda_disponible,
        cursos: state  => state.asw.cursos,
        clases: state => state.clases.clases,
        profesores: state => state.docentes.lista,
        salones: state => state.salones.lista,
        agendamientos: state => state.agendamientos.agendamientos,

      }),

      iniciado(){
    		console.log('iniciado')
    		if(this.agenda_disponible && this.cursos && this.profesores && this.salones){
    			return true
    		}
    		return false
      },
      titulo_dia(){
        if(this.dia_actual){
          return this.dia_actual.texto
        }else{
          return 'seleccione...'
        }
        
      },
      titulo_hora(){
        if(this.hora_actual){
          return this.hora_actual.nombre_corto
        }else{
          return 'seleccione...'
        }
        
      },
      titulo(){
        if(this.dia_actual &&  this.hora_actual){
          return this.dia_actual.texto+' '+this.hora_actual.nombre_corto
        }else{
          return ''
        }
        
      },
      tabActivo(){
        if(this.dia_actual &&  this.hora_actual){
          return this.dia_actual.fecha+'T'+this.hora_actual.hora
        }else{
          return null
        }
      },
    }
  }
</script>

<style scope>
.izquierda{
	padding-bottom: 30px
}
.date-picker{
  
}
</style>