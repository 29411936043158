<template>
    <div class="container">
  
      <div class="agendamiento_fila row" v-if="item">
        <div class="col-md-2">
          <strong class="cap">{{ textoSolicitadoDocumento }} </strong><br><small>{{ textoSolicitado }}</small>
        </div>
        <div class="col-md-2">
          <strong class="cap">{{ textoRespondidoDocumento }} </strong><br><small>{{ textoRespondido }}</small>
        </div>
          <div class="col-md-3">
            <strong class="cap">{{textoCursoAnterior}} <b-link :href="`${urlApi}pdf/cambio-nivel?id=${item.id}`" target="_blank"><b-icon variant="info" icon="file-pdf" ></b-icon></b-link> </strong><br>
            <span>{{textoFechaFinalizarNivel}}</span>
          </div>
          <div class="col-md-3">
            <strong class="cap">{{textoCursoNuevo}}</strong><br>
            <span>{{textoSede}} - {{textoFechaEntregaLibro}}</span><br>
          </div>
          <div class="col-md-2">
            <strong class="cap">{{textoFinalizado}}</strong><br>
            <span v-if="textoFechaAvanceNivel">{{textoFechaAvanceNivel}}</span>
          </div>
      </div>
      
  
    </div>
  </template>
  
  <script>
    import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
    import Vue from 'vue';
  
    export default {
      name: 'DetalleCambioNivelesItem',
      components: {
      },
      props:{
        item: Object,
        estudiante: Object
      },
      data () {
        return {
          cambiando_tema : false,
          urlApi:'',
        }
      },
      
      mounted () {
        var url = Vue.http.options.root;
        // var str = url.split("api")
        this.urlApi = url;
        console.log(this.item)
      },
      methods:{
        ...mapActions({

        }),
        ...mapMutations({
  
        }),
        recargar(){
          
        },
  
      },
      computed: {
        ...mapState({

        }),
        ...mapGetters({
          permiso: 'auth/permiso', 
          getSede: 'sedes/getSede',
        }),
        textoSede(){
          if(this.item && this.item.sede){
            let texto = this.item.sede ? this.item.sede.nombre : "";
            return texto
          }
          return ''
        },
        textoCursoAnterior(){
          if(this.item.cursoAnterior && this.item.cursoAnterior.nombre){
            let texto = this.item.cursoAnterior.nivel.codigo + ": " +this.item.cursoAnterior.nombre
            return texto
          }
          return ''
        },
        textoCursoNuevo(){
          if(this.item.cursoNuevo && this.item.cursoNuevo.nombre){
            let texto = this.item.cursoNuevo.nivel.codigo + ": " +this.item.cursoNuevo.nombre
            return texto
          }
          return ''
        },
        textoFinalizado(){
          if(this.item){
            let texto = this.item.finalizado ? "SI" : "NO";
            return texto
          }
          return ''
        },
        textoSolicitado(){
          if(this.item && this.item.solicitante){
            let texto = this.item.solicitante.primer_nombre+" "+this.item.solicitante.primer_apellido;
            return texto
          }
          return ''
        },
        textoSolicitadoDocumento(){
          if(this.item && this.item.solicitante){
            let texto = this.item.solicitante.numero_documento;
            return texto
          }
          return ''
        },
        textoRespondido(){
          if(this.item && this.item.administrador && this.item.curso_nuevo_id){
            let texto = this.item.administrador.primer_nombre+" "+this.item.administrador.primer_apellido;
            return texto
          }
          return ''
        },
        textoRespondidoDocumento(){
          if(this.item && this.item.administrador && this.item.curso_nuevo_id){
            let texto = this.item.administrador.numero_documento;
            return texto
          }
          return ''
        },
        textoFechaFinalizarNivel(){
          if(this.item && this.item.fecha_finalizar_nivel){
            let fecha_finalizar_nivel = new Date(this.item.fecha_finalizar_nivel).toLocaleString()
            return fecha_finalizar_nivel
          }
          return ''
        },
        textoFechaAvanceNivel(){
          if(this.item && this.item.fecha_avance_nivel){
            let fecha_avance_nivel = new Date(this.item.fecha_avance_nivel).toLocaleString()
            return fecha_avance_nivel
          }
          return ''
        },
        textoFechaEntregaLibro(){
          if(this.item && this.item.fecha_entrega_libro){
            let fecha_entrega_libro = new Date(this.item.fecha_entrega_libro).toLocaleString()
            return fecha_entrega_libro
          }
          return ''
        },
        
      }
    }
  </script>
  
  <style scope>
  .agendamiento_fila {
      border-top: 1px solid #bbb;
      font-size:14px;
      line-height: 14px;
      padding-top: 10px !important;
  }
  .agendamiento_fila  strong{}
  .agendamiento_fila  span{
    font-size: 10px;
    line-height: 10px;
    color:#888;
    font-style: oblique;
  }
  .agendamiento_fila  p{
        margin-bottom: 5px !important;
  }
  .agendamiento_fila  button{
        margin: 5px !important;
  }
  .cambiar-tema{
    padding: 10px;
      border: 1px solid grey;
  }
  .cap{
  text-transform: capitalize;  
  }
  </style>