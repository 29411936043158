<template>
   <div class="agendamiento d-flex" :class="{item:puedeMover}" :style="{backgroundColor: color,textDecoration: decoration}">
    <div class="invisible">
      <span v-if="puedeDesasignar && permiso('E329996D')" class="btn-link" @click="desasignando=true">-</span>
    </div>
    <div class="invisible">
      <span v-if="puedeAsignarAuto && permiso('D7DC46E')" class="btn-link" @click="asignando=true">+</span>
    </div>
    <div class="text-left">
      <a target="_blank" :title="agendamiento.tema?(agendamiento.tema.curso ? agendamiento.tema.curso.nombre+': ' : '')+agendamiento.tema.nombre:''" :href="`#/estudiante/detalle/${ agendamiento.estudiante.id }`" class="">
        {{ texto }}
      </a>
    </div>
    <!-- FEBRERO 19 2019
    <div class="invisible ml-auto">
      <span v-if="puedeCancelar" class="btn-link" @click="procesarCancelar">x</span>
    </div>
    -->
    <!--
    <icon v-if="agendamiento.estado=='asistido'"class="check-icon" scale="0.6" name="check"></icon>
    <icon v-if="agendamiento.estado=='ausencia'"class="frown-icon" scale="0.7" name="frown"></icon>
    <icon v-if="agendamiento.estado=='asignado'"class="question-icon" scale="0.6" name="question"></icon>
    -->
    <b-modal @ok="procesarDesasignar" @cancel="motivoDesasignar=''" v-model="desasignando"  id="modalDesasignar"
    :ok-disabled="motivoDesasignar.length<motivosMin"  title="Motivo">
      <div class="mb-2">
        <b-form-textarea
          id="textarea"
          v-model="motivoDesasignar"
          placeholder="Cuéntanos, por qué es necesario desasignar manual?"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <pre class="d-block text-right">{{ motivoDesasignar.length }}/{{ motivosMin }}</pre>
      </div>
    </b-modal>

    <b-modal @ok="procesarAsignarAuto" @cancel="motivoAsignar=''" v-model="asignando"  id="modalDesasignar"
    :ok-disabled="motivoAsignar.length<motivosMin"  title="Motivo">
      <div class="mb-2">
        <b-form-textarea
          id="textarea"
          v-model="motivoAsignar"
          placeholder="Cuéntanos, por qué es necesario asignar manual?"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <pre class="d-block text-right">{{ motivoAsignar.length }}/{{ motivosMin }}</pre>
      </div>
    </b-modal>
  </div>
  
</template>

<script>
  import {mapState,mapGetters, mapActions, mapMutations} from 'vuex';
  import Icon from 'vue-awesome/components/Icon'
  import 'vue-awesome/icons/check'
  import 'vue-awesome/icons/frown'
  import 'vue-awesome/icons/check-double'
  import 'vue-awesome/icons/question'

  export default {
    name: 'ProgramadorAgendamiento',
    components: {
      Icon
    },
    data () {
      return {
        motivosMin:40,
        desasignando: false,
        motivoDesasignar:'',
        asignando: false,
        motivoAsignar:'',

      }
    },
    props: {
      agendamiento : Object,
    },
    beforeCreate() {
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({
        cancelar: 'programacion/cancelar',
        setClase : 'agendamientos/setClase',
        asignarAuto : 'agendamientos/asignarAuto',
        desasignarDeClase : 'agendamientos/desasignarDeClase',
        desasignarDePendientes : 'agendamientos/desasignarDePendientes',
      }),
      ...mapMutations({
      }),
      procesarCancelar(){
        this.cancelar(this.agendamiento.id).then((apiResponse)=>{
          if(apiResponse.error){
            this.error = apiResponse.error
          }else{
            this.$emit('procesarActualizar');
          }
        })
      },
      procesarDesasignar(){
          let payload = {
            clase_id:null,
            agendamiento_id:this.agendamiento.id,
            motivo:this.motivoDesasignar
          }
          this.setClase(payload)
          .then(()=>{
            this.desasignarDeClase(this.agendamiento)
          }).catch(response=>{
            this.$emit('procesarActualizar');
          })
      },
      procesarAsignarAuto(){
          let payload = {
            agendamiento_id:this.agendamiento.id,
            motivo:this.motivoAsignar
          }
          this.asignarAuto(payload)
          .then(()=>{
            this.desasignarDePendientes(this.agendamiento)
          }).catch(response=>{
            this.$emit('procesarActualizar');
          })
      }
    },
    computed: {
      ...mapState({
      }),
      ...mapGetters({
        getCurso: 'cursos/getCurso', 
        permiso: 'auth/permiso',
      }),
      puedeCancelar(){
        if(this.agendamiento){
          if(this.agendamiento.estado == 'solicitado' 
            || this.agendamiento.estado == 'asignado'
            ){
            return true
          }
        }
        return false
      },
      puedeDesasignar(){
        if(this.agendamiento){
          if(this.agendamiento.estado == 'asignado'){
            return true
          }
        }
        return false
      },
      puedeAsignarAuto(){
        if(this.agendamiento){
          if(this.agendamiento.estado == 'solicitado'){
            return true
          }
        }
        return false
      },
      puedeMover(){
        if(this.agendamiento){
          if(this.agendamiento.estado == 'solicitado' 
            || this.agendamiento.estado == 'asignado'){
            return true
          }
        }
        return false
      },
      color(){
        let color = '#000'
        if(this.agendamiento){
          switch(this.agendamiento.estado){
            case 'solicitado': color='fff'; break;
            case 'notificado': color='#fff' ; break;
            case 'asignado': color='#fff'; break;
            case 'ausencia': color='#fff'; break;
            case 'cancelado': color='gray'; break;
            case 'asistente': color='orange'; break;
            case 'asistido': color='#fff'; break;
            case 'bloqueado': color='gray'; break;
            default: color='#fff';
          }
          if(this.agendamiento.novedad && this.agendamiento.novedad=='error_tema'){
            color='red';
          }else if(this.agendamiento.novedad && this.agendamiento.novedad=='sin_carpeta'){
            color='yellow';
          }  
        }
        return color
      },
      decoration(){
        if(this.agendamiento){
          if(this.agendamiento.estado=='bloqueado'){
            return 'line-through'
          }
        }
        return 'none'
      },
      cursoTexto(){
       if(this.agendamiento && this.agendamiento.estudiante && this.agendamiento.estudiante.curso_actual_id){
          let curso = this.getCurso(this.agendamiento.estudiante.curso_actual_id)
          if(curso){
            return curso.nombre
          }else{
            return ''
          }
       }
      },
      texto(){
        let texto = ''
        if(this.agendamiento){
          if(this.agendamiento.estudiante){
            texto += this.agendamiento.estudiante.primer_nombre?this.agendamiento.estudiante.primer_nombre:''
            texto += this.agendamiento.estudiante.segundo_nombre?' '+this.agendamiento.estudiante.segundo_nombre:''
            texto += this.agendamiento.estudiante.primer_apellido?' '+this.agendamiento.estudiante.primer_apellido:''
            texto += this.agendamiento.estudiante.segundo_apellido?' '+this.agendamiento.estudiante.segundo_apellido.substring(0,1):''
          }
          
        }
        return texto
      },
      
    }
  }
</script>

<style scope>
  .agendamiento:hover .invisible{
    visibility: visible !important;
  }
  .agendamiento .invisible .btn-link{
    color: #000 !important;
    font-weight: bold;
    padding: 0px 3px;
  }
  .check-icon{
    color:rgb(108, 167, 84);
    margin: 3px 0;
  }
  .frown-icon{
    color:red;
    margin: 2px 0;
  }
  .question-icon{
    color:orange;
    margin: 2px 0;
  }
</style>