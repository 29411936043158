<template>
  <div class="container  mt-3">
    
      <div class="form-horizontal">

        <div class="form-group row">
          <div class="col-9" v-if="!editando">
            <strong>{{ textoGrupo }}</strong>
            <!--
            <strong v-if="grupo">{{ grupo.nombre }}</strong>
            <strong v-else="grupo">{{ funcionario.grupo_id }}</strong>
          -->
            <button  class="btn btn-primary ml-3" @click="iniciarEdicion">Editar</button>  
          </div>
          
          <div v-else>
            <select v-model="grupo" class="form-control">
              <option disabled value="">Please select one</option>
              <option v-for="gr in grupos"
              :value="gr">{{ gr.nombre}}</option>
              
            </select>
            <button v-if="procesable" class="btn btn-block btn-danger" @click="procesar">
              Guardar
            </button>  
          </div>
        </div> 
        
      </div>
    
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';
  export default {
    name: 'FuncionarioEditarGrupo',
    data () {
      return {
        grupo : null,
        editando : false
      }
    },
    props: {
      funcionario: Object
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({
        fetchDetalle: 'funcionarios/fetchDetalle',
        fetchGrupos: 'grupos/fetchLista',
        setWarning: 'setWarning',
      }),
      ...mapMutations({
        
      }),
      
      procesar(){
        Vue.http.post('funcionario/editar_grupo',{id:this.funcionario.id,grupo_id:this.grupo.id})
          .then()
          .catch(error=>{
            this.setWarning(error)
            console.log(error)
          }).finally(()=>{
            this.fetchDetalle(this.funcionario.id)
            .then(()=>{
              this.$router.push('/usuario/detalle/'+this.funcionario.id)
            })
          })
      },
      iniciarEdicion(){
        this.editando=true
      }
      
    },
    computed:{
      ...mapState({
        grupos: state => state.grupos.lista, 
      }),
      ...mapGetters({
        
      }),
      procesable: {
        get () {
          return this.grupo && (this.grupo.id!=this.funcionario.grupo_id)
        },
        set(value){}
      },
      textoGrupo(){
        console.log('textoGrupo')
        let texto = ''
        if(!this.grupos || this.grupos.length<1){
          this.fetchGrupos().then(()=>{
            if(this.funcionario.grupo_id>0){
              this.grupo = this.grupos.find(element=>{
                return element.id == this.funcionario.grupo_id
              })  
            }
          })
          texto = 'Buscando...'
        }else{
          if(this.funcionario.grupo_id>0){
            this.grupo = this.grupos.find(element=>{
              return element.id == this.funcionario.grupo_id
            })
            texto = this.grupo.nombre
          }else{
            texto = 'No hay grupo'
          }
        }
        return texto
      }
      
    }
  }
</script>

<style scope>
 
</style>

