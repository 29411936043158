<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_temas"></b-navbar-toggle>
        <h1 v-if="tema && tema.nombre" class="text-center navbar-brand pt-0 pb-0 mb-0">{{ tema.nombre }} </h1>
        <b-collapse is-nav id="nav_collapse_temas">
          <!-- Right aligned nav items -->
          
          <b-navbar-nav class="ml-auto">
            <a v-if="tema" :href="`#/curso/detalle/${ tema.curso_id }`" class="nav-link" title="Volver">Volver</a>
            <a v-if="tema && permiso('799AAD9D')" :href="`#/tema/editar/${ tema.id }`" class="nav-link" title="Ver">Editar</a>
            <b-nav-item  href="#" @click="actualizar">Actualizar</b-nav-item>
            
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <div class="container pt-3 m-auto text-left ">
       
        <div v-if="tema">
          <h3>Academico:</h3>
          <div class="row" >
            <div class="col-md-3">Nombre:</div><div class="col-md-9">{{tema.nombre}}</div>
            <div class="col-md-3">Block:</div><div class="col-md-9">{{tema.block}}</div>
            <div class="col-md-3">Pages:</div><div class="col-md-9">{{tema.pages}}</div>
            <div class="col-md-3">Unidad:</div><div class="col-md-9">{{tema.unidad}}</div>
            <div class="col-md-3">Class Topic:</div><div class="col-md-9">{{tema.class_topic}}</div>
            <div class="col-md-3">Class Structure:</div><div class="col-md-9">{{tema.class_structure}}</div>
            <div class="col-md-3">Description:</div><div class="col-md-9">{{tema.description}}</div>
            <div class="col-md-3">Examen:</div><div class="col-md-9" v-for="ex in examenes" v-if="ex.id == tema.examen_id">{{ex.nombre}}</div>
          </div>
          <hr>
          <h3>Técnico:</h3>
          <div class="row">
            <div class="col-md-3">Orden:</div><div class="col-md-9">{{tema.orden}}</div>
            <div class="col-md-3">Activo:</div><div class="col-md-9">{{tema.activo}}</div>
            <div class="col-md-3">Tipo de clase:</div><div class="col-md-9">{{tema.tipo}}</div>
            <!--
            <div class="col-md-3">Programable:</div><div class="col-md-9">{{tema.programable}}</div>
            <div class="col-md-3">Programación Libre:</div><div class="col-md-9">{{tema.programacion_libre}}</div>
            <div class="col-md-3">Autorizaciones:</div><div class="col-md-9">{{tema.autorizaciones}}</div>
          -->
            <div class="col-md-3">Rango Nota:</div><div class="col-md-9">{{tema.rango_nota}}</div>
            <div class="col-md-3">Peso Nota:</div><div class="col-md-9">{{tema.peso_nota}}</div>
            <div class="col-md-3">Sede:</div>
            <div class="col-md-9">
              <span v-if="tema.requiere_sede_original==1">SEDE PROPIA</span>
              <span v-if="tema.requiere_sede_original==0">MULTISEDE</span>
            </div>
          </div>
          <hr>
          <h3>Notificación:</h3>
          <div class="row">
            <div class="col-md-3">Show:</div><div class="col-md-9">{{tema.notificacion_mostrar ? 'SI' : 'NO'}}</div>
            <div class="col-md-3" v-id="tema.notificacion_mostrar">Text:</div><div class="col-md-9" v-id="tema.notificacion_mostrar">{{tema.notificacion_texto}}</div>
            <div class="col-md-3" v-id="tema.notificacion_mostrar">Link:</div><div class="col-md-9" v-id="tema.notificacion_mostrar">{{tema.notificacion_enlace}}</div>
          </div>

        </div>
     
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'TemaDetalle',
    components: {
      
    },
    data () {
      return {
           tema_id: null,
      }
    },
    mounted () {
     this.tema_id = this.$route.params.id;
      if(!this.detalle[this.tema_id]){
        this.actualizar()
      }
    },
    
    methods:{
      ...mapActions({
        fetchDetalle: 'temas/fetchDetalle',
        setWarning: 'setWarning',
        fetchLista: 'examenes/fetchLista',
      }),
      ...mapMutations({
        
      }),
      actualizar(){
        this.fetchDetalle(this.tema_id)
        this.fetchLista()
      },

    },
    computed:{
      ...mapState({
        detalle: state => state.temas.detalle, 
        warning: state => state.warning,
        examenes: state => state.examenes.lista
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      tema(){
        return this.detalle[this.tema_id]
      },
      
      
    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>
