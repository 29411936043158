<template>
  <div class="container container-login mt-3">
    <div class="row">
      <div class="justify-content-center col-lg-6 m-auto">
        <h1 class="text-center text-muted">
          <span>Ingresar</span>
        </h1>
        <hr />
        <user-form
          :isLogin="true"
          :user="user"
          btnText="Enviar"
          v-on:processUserForm="processLogin($event)"
        ></user-form>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions,mapMutations} from 'vuex';
  import UserForm from "./UserForm";
  export default {
    components: {UserForm},
    name: 'login',
    data () {
      return {
        user: {
          email: '',
          password: '',
        },
      }
    },
    methods: {
      ...mapActions({
        login: 'auth/login',
        fetchSede: 'auth/fetchSede'
      }),
      ...mapMutations({

      }),
      processLogin (user) {
        this.login({
          email: user.email,
          password: user.password
        }).then(
          user => {
            this.fetchSede()
            this.$router.push('/');
          },
          error => {
            
          })
      }
    }
  }
</script>
