<template>
  <div>
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_cursos"></b-navbar-toggle>
        <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Banners</h1>
        <b-collapse is-nav id="nav_collapse_cursos">
          <!-- Right aligned nav items -->
          
          <b-navbar-nav class="ml-auto">
            <router-link class="nav-link" to="/banners/nuevo" >Nuevo</router-link>
          </b-navbar-nav>
          
        </b-collapse>
      </div>
    </b-navbar>
    <div class="container cursos_wrapper mt-3">
      <div v-if="listaBanners" class="tabla">
        <v-client-table name="cursosTable" :columns="columns" :data="listaBanners" :options="options">
          <div slot="accion" slot-scope="listaBanners"><a :href="`#/banners/detalle/${ listaBanners.row.id }`" class="btn btn-secondary btn-sm" title="Ver">Ver</a></div>
        </v-client-table>
      </div>
    </div>
  </div>
</template>


<script>
  import {mapState, mapActions } from 'vuex';

  export default {
    name: 'BannersTodos',
    data () {
      return {
        columns: ['id', 'url', 'activo', 'fecha_inicio', 'fecha_fin', 'accion'],
        options: {
          filterable: true,
          filterByColumn: false,
          sortable:[],
          perPage: 10,
          perPageValues: [5, 10, 15, 20],
          headings: {
         //   tipo_pago: 'Tipo de Pago',
         //   segmento : 'Sede'
          }
        },
      }
    },
    watch:{
        listaBanners(val){
            for (let s = 0; s < val.length; s++) {
                val[s].fecha_inicio = this.formato(val[s].fecha_inicio)
                val[s].url = val[s].url.substring(0,30)
                val[s].fecha_fin = this.formato(val[s].fecha_fin)
            }
        }
    },
    mounted () {
       // if(this.listaBanners && this.listaBanners.length==0){
        this.fetchListaBanners()
      // }
     //    this.formato()

    },
    methods:{
      ...mapActions({
        fetchListaBanners: 'banner/fetchListaBanners',
      }),
     
     formato(fec){
        // console.log(fec)
        var fecha1 = new Date(fec) 
        var fecha1dd = fecha1.getDate() < 10 ? "0"+(fecha1.getDate()+1) : (fecha1.getDate()+1);
        var fecha1mm = (fecha1.getMonth()+1) < 10 ? "0"+(fecha1.getMonth()+1) : (fecha1.getMonth()+1);
        var fecha1yy = fecha1.getFullYear()

        var fecha1Total = fecha1yy + '/' + fecha1mm + '/' + fecha1dd
        return fecha1Total;

    },

      edit(){

      }
    },
    computed: {
      ...mapState({
        listaBanners: state => state.banner.listaBanners, 
      }),
    }
  }
</script>