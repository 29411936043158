<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_docentes"></b-navbar-toggle>
        <h1 v-if="docente" class="text-center navbar-brand pt-0 pb-0 mb-0">{{ docente.primer_nombre }} {{ docente.primer_apellido }}</h1>
        <b-collapse is-nav id="nav_collapse_docentes">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <a :href="`#/docentes`" class="nav-link" title="Lista">Lista</a>
            <a v-if="docente && permiso('3329CE3B')" :href="`#/docente/editar/${ docente.id }`" class="nav-link" title="Ver">Editar</a>
            <a v-if="docente && permiso('2FD9B828')" :href="`#/docente/cambiarpassword/${ docente.id }`" class="nav-link" title="Ver">Password</a>
            <a v-if="docente && permiso('EFE8AFBA')" :href="`#/docente/novedades/${ docente.id }`" class="nav-link" title="Novedades">Novedades</a>
            <b-nav-item  href="#" @click="recargar">Actualizar</b-nav-item>
            
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <div class="container pt-3 m-auto text-left ">
      
      <div v-if="docente">
        <h2>Datos básicos</h2>
        
        <div class="row">
          <div class="col-3"><strong>Tipo Documento:</strong>
          </div><div class="col-9">{{ docente.tipo_documento }}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>Documento:</strong>
          </div><div class="col-9">{{ docente.numero_documento }}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>eMail:</strong></div>
          <div class="col-9">{{ docente.email }}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>Telefono:</strong></div>
          <div class="col-9">{{ docente.telefono }}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>Celular:</strong></div>
          <div class="col-9">{{ docente.movil }}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>Activo:</strong></div>
          <div class="col-9">{{ docente.activo?'Si':'No' }}</div>
        </div>

        <div class="row">
          <div class="col-3"><strong>Split:</strong></div>
          <div class="col-9">{{ docente.split?'Si':'No' }}</div>
        </div>

        <div class="row">
          <div class="col-3"><strong>Tipos Permitidos:</strong></div>
          <div class="col-9">{{ tiposTexto }}</div>
        </div>
<!--
        <div class="row">
          <div class="col-3"><strong>Salon predeterminado:</strong></div>
          <div class="col-9">{{ salonTexto }}</div>
        </div>
  -->      
      </div>
      <div v-else>
        No hay información
      </div>

    </div>
    <div v-if="docente && docente.id">
      <DocentePreasignacion :docente="docente"></DocentePreasignacion>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import DocentePreasignacion from '@/components/Docentes/DocentePreasignacion';
  
  export default {
    name: 'DocenteDetalle',
    components: {
      DocentePreasignacion
    },
    data () {
      return {
          agendando: false,
          docente_id: null,
      }
    },
    beforeCreate() {
      this.$store.commit('docentes/incializarDetalle',this.$route.params.id)
    },
    
    mounted () {
      this.docente_id = this.$route.params.id;
      if(!this.detalle[this.docente_id]){
        console.log('Detalle mounted recargar')
        this.recargar()
      }
    },
    
    methods:{
      ...mapActions({
        fetchDetalle: 'docentes/fetchDetalle',
      }),
      ...mapMutations({
        setDetalle: 'docentes/setDetalle',
        incializarDetalle: 'docentes/incializarDetalle',
      }),
      recargar(){
        this.fetchDetalle(this.docente_id).then(()=>{
          
        })
      },
      fechaFormated(value) {
        return value.split('T')[0]
      }   
    },
    computed:{
      ...mapState({
        detalle: state => state.docentes.detalle, 
        tipos: state => state.asw.tipos, 
        salones: state => state.salones.lista, 
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      docente(){
        return this.detalle[this.docente_id]
      }, 
      tiposTexto(){
        if(this.docente.tipos_permitidos && this.docente.tipos_permitidos.length > 0 && this.tipos){
            let tiposObj = this.tipos.filter((element)=>{
              return this.docente.tipos_permitidos.includes(element.id)  
            })
            let texto = ''
            tiposObj = tiposObj.map(element=>{
              return element.nombre
            })
            texto = tiposObj.join(',')
            
            return texto
        }else{
          return ''
        }
        
      },
      salonTexto(){
        if(this.docente.salon_predeterminado){
            let salonObj = this.salones.find((element)=>{
              return element.id == this.docente.salon_predeterminado  
            })
            if(salonObj){
              return salonObj.nombre
            }else{
              return 'NO SE ENCUENTRA EL SALÓN'
            }
            
            return texto
        }else{
          return 'NO TIENE SALÓN ASIGNADO'
        }
        
      }

    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>