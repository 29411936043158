<template>
	<div class="form-group row">
		<label class="col-3 col-form-label">{{ titulo }}:</label>
		<div class="col-9">
            <b-form-select  v-model="_modelo" class="select-tipo" multiple :select-size="10">
              <!--  <option value="0">seleccione ...</option> -->
                <option v-for="metodopago in metodos_pago" :key="metodopago.id" :value="metodopago.codigo">{{ metodopago.codigo }}</option>
            </b-form-select>
         

		</div>
	</div>
</template>


<script>
  import {mapState, mapGetters, mapActions} from 'vuex';

  export default {
    name: 'SelectTipoPago',
    data () {
      return {
          error: null,
          curso_id:0,
          metodo_id:0,
          sede_id:0
      }
    },
    props: {
		titulo: String,
		modelo: String
    },
    mounted () {

           this.fetchMetodosPago()
    },
    methods:{
         ...mapActions({

        fetchMetodosPago: 'asw/fetchMetodosPago',
        


              }),
    }
    ,
    computed:{
		_modelo: {
			get () {
				return this.modelo
			},
			set (value) {
				this.$emit('change', value);
			}
        },
      ...mapState({

        metodos_pago : state => state.asw.metodos_pago,

      }),
    }
    
  }
</script>
