<template>
  <div class="d-inline-flex  clase position-relative" :style="{backgroundColor:color}">
    <div class="w-100">
      <div class="w-100" v-if="clase">
        <!--
        <ProgramadorSeleccioneTipoClase @change="setTipoClase($event)" :clase="clase" :editando="editando"/>
         -->
        <div class="titulo-clase" v-for="titulo in titulos">
          {{titulo}} <span >({{infoTemas.cantidad}})</span></div>
        <!--
        <ProgramadorSeleccioneTipos @change="setTiposClase($event)" :clase="clase" :editando="editando"/>
        -->
        <!--
        <ProgramadorSeleccioneCurso @change="setCursoId($event)" 
        :clase="clase" :editando="editando" :opciones="cursos_disponibles"/>
        -->
        
        <ProgramadorSeleccioneSalon @change="setSalonId($event)" 
        :clase="clase" :editando="editando" :marca_tiempo="marca_tiempo" />
        <ProgramadorSeleccioneDocente @change="setDocenteId($event)" 
        :clase="clase" :editando="editando" :marca_tiempo="marca_tiempo" :marca_relativa="marca_relativa" />
        <div class="mini">
        {{infoTemas.nombres}}
        </div>

        <div v-if="puedeEditar" class="w-100 text-center" >
          <button class="btn btn-warning btn-block btn-sm" @click="procesarCancelar">Cancelar</button>
        </div>
        <button v-if="puedeGuardar" class="btn btn-danger btn-block btn-sm" @click="procesar">Guardar</button>

        <div v-if="marca_clase && clase.id">
          <draggable class="card" style="min-height: 120px" v-model="clase.agendamientos" :options="dragOptions"
            @add="add"
            @remove="remove" 
          >
              <ProgramadorAgendamiento v-for="element in clase.agendamientos" 
              :key="element.id" :accessKey="element.id"
              :agendamiento='element' @procesarActualizar="procesarActualizar"/>  
             
              <!--
              <div v-for="element in clase.agendamientos" :key="element.id" :accessKey="element.id"
              :class="{item:puedeMover}">
                <ProgramadorAgendamiento :agendamiento='element' @procesarActualizar="procesarActualizar"/>  
              </div> 
              --> 
          </draggable>
        </div>

        

      </div>
      <div v-if="clase.id" class="position-absolute w-100 btn-container">
        <div class="invisible">
          <span class="btn-link" @click="eliminando=true" v-if="permiso('57315736')">eliminar</span>
          <span class="btn-link" @click="procesarActualizar">actualizar</span>
          <span class="btn-link" @click="procesarEditar" v-if="permiso('956EB91C') || permiso('EEB6ADA9')">editar</span>
          <span class="btn-link" @click="modalClaseInfo=true" v-if="clase.id && permiso('956EB91C') || permiso('EEB6ADA9')">i</span>
        </div>
      </div>
      
    </div>

    <b-modal @ok="procesarEliminar" @cancel="motivoEliminar=''" v-model="eliminando"  id="modalFirmar"
    :ok-disabled="motivoEliminar.length<motivosMin"  title="Motivo para eliminar la clase">
      <div class="mb-2">
        <b-form-textarea
          id="textarea"
          v-model="motivoEliminar"
          placeholder="Cuéntanos, por qué es necesario hacer este proceso manual?"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <pre class="d-block text-right">{{ motivoEliminar.length }}/{{ motivosMin }}</pre>
      </div>
    </b-modal>
     <b-modal  @ok="modalClaseInfo=false"  ok-only
      v-model="modalClaseInfo"   id="modalClaseInfoId"
      title="Detalle de la clase" >
      <ProgramadorClaseDetalle :clase_id="clase.id"></ProgramadorClaseDetalle>
      
    </b-modal>

  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import ProgramadorSeleccioneSalon from '@/components/Programador/ProgramadorSeleccioneSalon';
  import ProgramadorSeleccioneCurso from '@/components/Programador/ProgramadorSeleccioneCurso';
  import ProgramadorSeleccioneDocente from '@/components/Programador/ProgramadorSeleccioneDocente';
  import ProgramadorSeleccioneTipoClase from '@/components/Programador/ProgramadorSeleccioneTipoClase';
  import ProgramadorSeleccioneTipos from '@/components/Programador/ProgramadorSeleccioneTipos';
  import ProgramadorAgendamiento from '@/components/Programador/ProgramadorAgendamiento';
  import ProgramadorClaseDetalle from '@/components/Programador/ProgramadorClaseDetalle';
  
  export default {
    name: 'ProgramadorClase',
    components: {
      ProgramadorSeleccioneSalon,
      ProgramadorSeleccioneCurso,
      ProgramadorSeleccioneDocente,
      ProgramadorSeleccioneTipoClase,
      ProgramadorSeleccioneTipos,
      ProgramadorAgendamiento,
      ProgramadorClaseDetalle,
    },
    data () {
      return {
        cambio : false,
        marca_relativa : null,
        editando: false,
        eliminando: false,
        motivosMin: 30,
        motivoEliminar: '',
        modalClaseInfo: false,
      }
    },
    props: {
      clase:Object,
      hora:Object,
      marca_tiempo:String
    },
    beforeCreate() {
    },
    mounted () {
      let date = new Date(this.marca_tiempo)
      let dia = date.getDay()
      this.marca_relativa = dia+'T'+this.hora.hora
      if(!this.clase.id){
        this.editando = true;
      }
    },
    methods:{
      ...mapActions({
        fetchCursos : 'asw/fetchCursos',
        fetchSalones : 'salones/fetchLista',
        fetchDocentes : 'docentes/fetchLista',
        crear : 'clases/crear',
        eliminar : 'clases/eliminar',
        confirmar : 'clases/confirmar',
        actualizar : 'clases/fetchClase',
        setClase : 'agendamientos/setClase',
        fetchAgendamientosXMarca : 'agendamientos/fetchAgendamientosXMarca',
        fetchClasesXMarca : 'clases/fetchClasesXMarca',
      }),
      ...mapMutations({
        cancelarNuevaClase: 'clases/cancelarNuevaClase',
      }),
      procesarConfirmar(){
        this.confirmar({clase_id:this.clase.id})
      },
      procesarEliminar(){
        let payload = {
          id: this.clase.id,
          motivo: this.motivoEliminar
        }
        this.eliminar(payload)
        this.motivoEliminar = ''
      },
      procesarActualizar(){
        this.actualizar(this.clase.id).then(response=>{
          if(response.data==null){
            this.procesarActualizarTodo()
          }
        })
      },
      procesarActualizarTodo(){
        this.fetchAgendamientosXMarca({marca_tiempo:this.marca_tiempo})
        this.fetchClasesXMarca({marcatiempo:this.marca_tiempo})
      },
      procesarCancelar(){
        if(this.clase.id){
          this.editando = false
          this.cambio = false
          this.actualizar(this.clase.id)
        }else{
          this.cancelarNuevaClase({marca:this.marca_tiempo,id_temporal:this.clase.id_temporal})
        }
      },
      procesarEditar(){
        this.editando = true
      },
      setCursoId(valor){
        console.log('setCurso:'+valor)
        this.clase.curso_id = valor
        this.cambio = true
      },
      setSalonId(valor){
        console.log('setSalonId:'+valor)
        this.clase.salon_id = valor
        this.cambio = true
      },
      setDocenteId(valor){
        console.log('setDocenteId:'+valor)
        this.clase.profesor_id = valor
        this.cambio = true
      },
      setTipoClase(valor){
        console.log('setTipoClase:'+valor)
        this.clase.tipo_clase = valor
        this.cambio = true
        if(valor!='regular'){
          this.clase.curso_id = -1
        }
        
      },
      setTiposClase(valor){
        console.log('setTipsoClase:'+valor)
        this.clase.tipos = valor
        this.cambio = true
        
      },
      procesar(){
        console.log('procesar:')
        this.crear(this.clase).then((data)=>{
          console.log("Clase guardada")
        }).catch((error)=>{
          this.procesarActualizar()
          console.log("error procesando guardar clase")
        }).finally(()=>{
          this.cambio=false;
          this.editando=false;
        })
      },
      add(event){
        console.log('add to clase')
        let key = event.item.accessKey
        this.setClase({clase_id:this.clase.id,agendamiento_id:key}).catch((error)=>{
          console.log("error add:"+error)
          this.actualizar(this.clase.id)
          this.fetchAgendamientosXMarca({marca_tiempo:this.marca_tiempo})
        })
      },
      remove(event){
        console.log('remove to clase')
        let key = event.item.accessKey
        console.log(key)
      },

      
    },
    computed: {
      ...mapState({
        clases: state => state.clases.clases, 
        cursos_disponibles_state: state  => state.asw.cursos,
        salones_disponibles_state: state  => state.salones.lista,
        docentes_disponibles_state: state  => state.docentes.lista,
        tipos_clase: state => state.asw.tipos_clase, 
        tipos: state => state.asw.tipos, 
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      puedeEditar(){
        if(!this.clase.id){
         return true
        }
        return this.editando
      },
      titulos(){
        if(this.clase.tipos){
          let mapped = this.tipos.filter(element=>{
            return this.clase.tipos.includes(element.id)
          })
          
          var groupBy = function(xs, key) {
            return xs.reduce(function(rv, x) {
              (rv[x[key]] = rv[x[key]] || []).push(x);
              return rv;
            }, {});
          };
          var groups = groupBy(mapped, 'grupo');
          var keys = Object.keys(groups);
          if (keys.length == 1) {
            if (keys[0] != 'undefined') {
              return [keys[0]]
            }
          }
          return mapped.map(x => x.nombre);
        }
        return ['SIN NOMBRE']
        
      },
      /*
      salon(){
        if(this.clase.salon){
          return this.clase.salon
        }else if(this.clase.salon_id>0){
          let _salon = this.salones_disponibles_state.find((salon)=>{
            return salon.id == this.clase.salon_id
          })
          return _salon
        } 
        return null
      },
      */
      docente(){
        if(this.clase.profesor){
          return this.clase.profesor
        }else if(this.clase.profesor_id>0){
          let _docente = this.docentes_disponibles_state.find((docente)=>{
            return docente.id == this.clase.profesor_id
          })
          return _docente
        } 
        return null
      },
      cursos_disponibles(){
        if(this.cursos_disponibles_state && this.cursos_disponibles_state.length>0){
          return this.cursos_disponibles_state
        }else{
          //this.fetchCursos()
          return null
        }
      },
      salones_disponibles(){
        console.log('ProgramadorClase salones_disponibles')
        if(this.salones_disponibles_state && this.salones_disponibles_state.length>0){
          let salones_sin_clase = this.salones_disponibles_state.filter((salon)=>{
            let hay_clase = this.clases[this.hora.marca_tiempo].find((clase)=>{
              return clase.salon_id == salon.id
            })
            return !hay_clase
          })
          return salones_sin_clase
        }else{
          //this.fetchSalones()
        }
      },
      dragOptions(){
        if(this.marcas_tipos && this.marcas_tipos.length>0){
          return {group:{name:'...',
          pull:true,
          put:this.marcas_tipos},draggable:'.item'}
        }else{
          return {group:this.marca_clase,draggable:'.item'}  
        }
        
        //ASI ES POSIBLE RECIBIR DE VARIOS TIPOS
        /*
        
        */
      },
      puedeGuardar(){
        /*
        if(this.cambio && this.clase.tipo_clase && this.clase.tipo_clase=='regular'
          && this.clase.curso_id>=0 && this.clase.salon_id>=0 && this.clase.profesor_id>=0 ){
          return true
        }
        if(this.cambio && this.clase.tipo_clase && this.clase.tipo_clase!='regular'
          && this.clase.salon_id>=0 && this.clase.profesor_id>=0 ){
          return true
        }
        if(!this.clase.id && this.clase.curso_id>=0 && this.clase.salon_id>=0 && this.clase.profesor_id>=0 && this.clase.tipo_clase){  
          return true
        }
        */

        if(this.cambio && this.clase.salon_id>=0 && this.clase.profesor_id>=0 ){
          return true
        }

        return false
      },
      marcas_tipos(){
        if(this.clase.tipos){
          let tiposMapped = this.clase.tipos.map((element)=>{
            return this.hora.marca_tiempo+'T'+element
          })
          return tiposMapped
        }else{
          return null
        }
      },
      marca_clase(){
        if(!this.hora || !this.clase){
          return null
        }
        if(this.curso){
          return this.hora.marca_tiempo+'C'+this.curso.id
        }else if(this.clase.tipo_clase){
          return this.hora.marca_tiempo+'T'+this.clase.tipo_clase
        }else{
          return this.hora.marca_tiempo
        }
      },
      curso(){
        if(this.clase && this.clase.curso_id>0){
          return this.cursos_disponibles_state.find((element)=>{
            return element.id == this.clase.curso_id
          })
        }else{
          return null
        }
      },
      tipoClase(){
        if(this.tipos_clase){

        }
        return null
      },
      color(){
        if(this.clase && this.clase.tipos && this.clase.tipos.length>0){
          let tipoObj =  this.tipos.find((element)=>{
              return element.id == this.clase.tipos[0]
            })
          if(tipoObj){
            return '#'+tipoObj.color  
          }
        }
        return '#aaa'
      },
      infoTemas(){
        if(this.clase.agendamientos){
          let temas = []
          let temasNombre = []
          
          this.clase.agendamientos.forEach(element=>{
              if(!temas.includes(element.tema_id)){
                if(element.tema && element.tema.nombre){
                  temasNombre.push(element.tema.nombre)
                }else{
                  temasNombre.push(element.tema_id)
                }
                temas.push(element.tema_id)
              }
          })

          return {nombres:temasNombre,cantidad:temas.length}
        }
        return {}
      }

      

    }

  }
</script>

<style scope>
  .clase{
    font-size: 12px;
    max-width: 200px;
    min-width: 150px;
    min-height: 140px;
    padding-bottom: 14px;
    border: 1px solid #aaa;
  }
  .titulo-clase{
    text-transform: uppercase;
    color:#fff;
    font-weight: bolder;
  }
  .invisible{
    visibility: hidden;
  }
  .invisible span{
    padding: 0px 4px;
  }
  .invisible .btn-link{
    cursor: pointer;
    color: #fff !important;
  }
  .btn-container{
    bottom: 0px; font-size: 8px; color:#aaa;
  }
  .btn-container:hover .invisible{
    visibility: visible !important;
  }
  .btn-link{
    cursor: pointer;
  }
  .mini{
    font-size: 8px;color:#eee;
  }
</style>