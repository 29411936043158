<template>
    <div>
      <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0 bg-blue">
          <b-navbar-toggle target="nav_collapse_actions"></b-navbar-toggle>
          <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Avance de Nivel</h1>
          <b-collapse is-nav id="nav_collapse_actions" >
            <b-navbar-nav class="ml-auto">
              <date-picker  class="date-picker" v-model="intervalo" lang="es" type="date" 
              :time-picker-options="timePickerOptions" range confirm :clearable="true"></date-picker>
            </b-navbar-nav>
            <b-navbar-nav  class="ml-auto">

              <b-nav-item  href="#" @click="generarReporte">Consultar</b-nav-item>
              <download-excel :disabled="!registros || registros && !registros.length"
                  class="nav-link"
                  :data="registros"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="reporte.xls">
                  Descargar
              </download-excel>
              
            </b-navbar-nav>
          </b-collapse>
      </b-navbar>
      <div class="container-fluid" >
        <div class="reporte_dia table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Nombres del estudiante</th>
                <th scope="col">Apellidos del estudiante</th>
                <th scope="col">Tipo de documento</th>
                <th scope="col">Número de documento</th>
                <th scope="col">Número de celular</th>
                <th scope="col">Correo electrónico</th>
                <th scope="col">Idioma</th>
                <th scope="col">Nivel actual</th>
                <th scope="col">Nivel siguiente</th>
                
                <th scope="col">Fecha avance de nivel</th>
                <th scope="col">Fecha inicio de nivel</th>
                <th scope="col">Sede avance</th>
                <th scope="col">Sede original </th>

                <th scope="col">Resultado</th>
                <th scope="col">Nota IFE</th>
                <th scope="col">Nota final módulo</th>
                <th scope="col">Módulos adquiridos</th>
                <th scope="col">Folio</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in registros">
                <td>{{item.estudiante.primer_nombre}} {{item.estudiante.segundo_nombre}}</td>
                <td>{{item.estudiante.primer_apellido}} {{item.estudiante.segundo_apellido}}</td>
                <td>{{item.estudiante.tipo_documento}}</td>
                <td>{{item.estudiante.numero_documento}}</td>
                <td>{{item.estudiante.movil}}</td>
                <td>{{item.estudiante.email}}</td>
                <td>{{getIdioma(item)}}</td>
                <td>{{item.cursoAnterior ? item.cursoAnterior.nombre : ''}}</td>
                <td>{{item.cursoNuevo ? item.cursoNuevo.nombre : ''}}</td>
                
                <td>{{presentDate(item.fecha_avance_nivel)}}</td><!-- GUARDAR DE AVANCE-->
                <td>{{presentDate(item.fecha_entrega_libro)}}</td><!-- GUARDAR DE INICIO-->
                <td>{{item.sede ? item.sede.nombre : (item.estudiante.sede_original.nombre)}}</td><!-- GUARDAR SEDE EN QUE SE GENERA AVANCE-->
                <td>{{item.estudiante.sede_original.nombre}}</td><!-- GUARDAR SEDE EN QUE SE GENERA AVANCE-->

                <td>{{item.resultado}}</td>
                <td>{{item.nota_ife}}</td>
                <td>{{item.nota}}</td>
                <td>{{item.estudiante.modulos_adquiridos}}</td>
                <td>{{item.folio}}</td>
                <td>
                  <b-button size="sm" variant="link" @click="consultarNotas(item)">
                    <b-icon icon="exclamation-circle-fill" variant="info"></b-icon>
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <b-modal size="lg" @ok="procesarNotas"  @cancel="cancelar" v-model="showNotas"  id="modalNotas"
        :ok-disabled="!puedeProcesarNotas"  :title="estudiante && estudiante.estudiante ? estudiante.estudiante.primer_nombre+' '+estudiante.estudiante.segundo_nombre+' '+estudiante.estudiante.primer_apellido+' '+estudiante.estudiante.segundo_apellido : ''">

        <div class="mb-2" >
          <div class="container row" v-if="estudiante && estudiante.estudiante && estudiante.estudiante.nivel_actual">
            <label class="col-3 col-form-label">Nivel</label>
            <div class="col-6">
              {{estudiante.estudiante.nivel_actual.descripcion}}
            </div>  
          </div>

          <DetalleCursoActual  v-if="puedeEditarCurso && estudiante && estudiante.estudiante" :estudiante="estudiante.estudiante" :refrescar="false"></DetalleCursoActual>
        </div>

      </b-modal>

    
    <transition name="fade">
      <div class="alert alert-warning mt-2" role="alert" v-if="mensaje">{{mensaje}}</div>
    </transition>
        
    </div>
  </template>
  
  <script>
    import {mapState, mapActions, mapMutations, mapGetters} from 'vuex';
    import Vue from 'vue';
    import DatePicker from 'vue2-datepicker'
    import DetalleNivelActual from '@/components/Estudiantes/DetalleNivelActual';
    import DetalleCursoActual from '@/components/Estudiantes/DetalleCursoActual';
  
    export default {
      name: 'ReporteSolicitudAvance',
      components: {
        DatePicker,
        DetalleCursoActual,
        DetalleNivelActual
      },
      data () {
        return {
            intervalo:[],
            registros: [],
            temas:[],
            timePickerOptions:{    
            },
            showNotas:false,
            locale:'es-CO',
            mensaje:null,
            estudiante:null,
            json_fields: {
              "Nombres del estudiante": {
                callback: (value) => {
                  return `${value.estudiante.primer_nombre} ${value.estudiante.segundo_nombre}`;
                }
              },
              "Apellidos del estudiante": {
                callback: (value) => {
                  return `${value.estudiante.primer_apellido} ${value.estudiante.segundo_apellido}`;
                }
              },
              "Tipo de documento":"estudiante.tipo_documento",
              "Número de documento":"estudiante.numero_documento",
              "Número de celular":"estudiante.movil",
              "Correo electrónico":"estudiante.email",
              "Idioma":{
                callback: (value) => {
                  return this.getIdioma(value);
                }
              },
              "Nivel actual":"cursoAnterior.nombre",
              "Nivel siguiente":"cursoNuevo.nombre",

              
              "Fecha avance de nivel":{
                field: "fecha_avance_nivel",
                callback: (value) => {
                  return this.presentDate(value);
                },
              },

              "Fecha inicio de nivel":{
                field: "fecha_entrega_libro",
                callback: (value) => {
                  return this.presentDate(value);
                },
              },
              "Sede avance":"sede.nombre",
              "Sede original":"estudiante.sede_original.nombre",
              "Resultado":"resultado",
              "Nota IFE":"nota_ife",
              "Nota final módulo":"nota",
              "Módulos adquiridos":"estudiante.modulos_adquiridos",
              "Folio":"folio"
            }
        }
      },
      mounted () {
        
      },
      methods:{
        ...mapActions({
          fetchNotas: 'progresos/fetchNotas',
          actualizarNotas: 'progresos/actualizarNotas'
        }),
        ...mapMutations({
          startProcessing: 'startProcessing',
          stopProcessing: 'stopProcessing'
        }),
        
        
        generarReporte(){
          console.log('Generando reporte....')
          this.startProcessing()
          Vue.http.post('reportes/solicitud-avance',{
            fecha_inicio:this.$moment(this.intervalo[0]).format('YYYY-MM-DD'),
            fecha_fin:this.$moment(this.intervalo[1]).format('YYYY-MM-DD')
          }).then(
            response =>{
              this.registros = response.data;
            }
          ).catch(error=>{
            console.log(error)
          }).finally(()=>{
            this.stopProcessing()
          })
        },
        presentDate(value){
          if (value) {
            return this.$moment(value).format('DD/MM/YYYY')
          }
          return '';
        },
        getIdioma(item) {
          if (item.cursoAnterior) {
            return item.cursoAnterior ? (item.cursoAnterior.codigo.indexOf('EN') > -1 ? 'Inglés' : 'Frances') : '';
          } else if (item.cursoNuevo) {
            return item.cursoNuevo ? (item.cursoNuevo.codigo.indexOf('EN') > -1 ? 'Inglés' : 'Frances') : '';
          }
          return '';
        }, 
        consultarNotas(item) {
          this.estudiante = item;
          this.fetchNotas({estudiante_id:item.estudiante_id}).then((result) => {
            this.showNotas = true;
            this.temas = result;
          }).catch((error) => {
            console.error(error)
          })
        },
        procesarNotas() {
          var data = {estudiante_id:this.estudiante.estudiante_id,temas:this.temas}
          this.actualizarNotas(data).then((data) => {
            this.mensaje = data.mensaje;
            this.generarReporte();
            setTimeout(() => {this.mensaje = null},3000)

          }).catch((error) => {
            console.error(error)
          })
        },
        cancelar() {
          console.log("cancelar");
        },
        maxValue(tema) {
          if (tema.nota > tema.rango_nota) {
            tema.nota = tema.rango_nota;
          }
        }
      },
      computed: {
        ...mapState({
         
        }),
        ...mapGetters({
          permiso: 'auth/permiso', 
        }),
        notaFinal(){
          if(this.temas && this.temas){
            let temasConNotas = this.temas.filter(element=>{
              return element.rango_nota && element.peso_nota
            })
            let notaF = 0;
            for (let i in temasConNotas){
              let tema = temasConNotas[i]
              let notaParcial = (tema.nota / tema.rango_nota) * tema.peso_nota
              notaParcial = notaParcial.toPrecision(3)*1
              notaParcial = notaParcial*5/100
              notaF += notaParcial
              
            }

            return notaF.toPrecision(3);	
          }
          
          return null
        },
      
        puedeProcesarNotas(){
          if(this.temas.filter(x => x.nota && x.tipo == 'autonomo').length == this.temas.filter(x => x.tipo == 'autonomo').length) {
            return true;
          }
          return false
        },

        puedeEditarCurso(){
          if(this.estudiante && this.estudiante.estudiante && this.estudiante.estudiante.curso_actual_id == null){
            return this.permiso('C448968B') || this.permiso('C0F0653E')
          }else{
            return this.permiso('C448968B')//PERMISO DE EDITAR
          }
        }

      }
    }
  </script>
  
  <style scope>
  
  .date-picker{
    
  }
  </style>