<template>
    <div>


 

  <b-modal no-close-on-backdrop hide-header-close id="modal-center" centered title="Reiniciar Nivel">
    <p class="my-4">¿Estas seguro de reiniciar el nivel?</p>
    
  </b-modal> 

 
    </div>
</template>
 <script>
 export default {
     name:'ProgresoReinicio'
 }
 </script>