<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_docentes"></b-navbar-toggle>
        <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Nuevo Funcionario</h1>
        <b-collapse is-nav id="nav_collapse_docentes">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <a  :href="`#/usuarios`" class="nav-link" title="Cancelar">Cancelar</a>
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <div class="container pt-3 m-auto text-left ">
      <h1>Nuevo Usuario</h1>
       <hr>
      <form
        autocomplete="off"
        class="form-horizontal"
        @submit.prevent="validateBeforeSubmit"
      >
        <div>
          <InputTextSimple titulo="Primer Nombre" :modelo="funcionario.primer_nombre" 
            @change="funcionario.primer_nombre = $event"/>
          <InputTextSimple titulo="Segundo Nombre" :modelo="funcionario.segundo_nombre" 
            @change="funcionario.segundo_nombre = $event"/>
          <InputTextSimple titulo="Primer Apellido" :modelo="funcionario.primer_apellido" 
            @change="funcionario.primer_apellido = $event"/>
          <InputTextSimple titulo="Segundo Apellido" :modelo="funcionario.segundo_apellido" 
            @change="funcionario.segundo_apellido = $event"/>
          <SelectTipoDocumento titulo="Tipo de Documento" :modelo="funcionario.tipo_documento" 
            @change="funcionario.tipo_documento=$event"/>
          <InputNumeroDocumento titulo="Número de Documento" :modelo="funcionario.numero_documento" 
            @change="funcionario.numero_documento=$event"/>
          <InputEmailSimple titulo="Email" :modelo="funcionario.email" 
            @change="funcionario.email=$event"/>
          <InputMovil titulo="Telefono" :modelo="funcionario.telefono" 
            @change="funcionario.telefono=$event"/>
            <InputMovil titulo="Movil" :modelo="funcionario.movil" 
            @change="funcionario.movil=$event"/>

        </div>
        <button class="btn btn-block btn-danger" type="submit">Crear</button>
      </form>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'FuncionarioNuevo',
    data () {
      return {
          funcionario : {
            primer_nombre:null,
            segundo_nombre:null,
            primer_apellido:null,
            segundo_apellido:null,
            tipo_documento:null,
            numero_documento:null,
            email:null,
            movil:null,
            telefono:null,
          }
      }
    },
    mounted () {
    },
    methods:{
      ...mapActions({
        crear: 'funcionarios/crear',
        setWarning: 'setWarning',
        fetchLista: 'funcionarios/fetchLista',
      }),
      ...mapMutations({
      }),
      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {
          if ( ! result) {
            //hay errores
          } else {
            this.crear(this.funcionario).then(()=>{
                this.setWarning('funcionario Creado', { root: true }).then(()=>{
                this.fetchLista().then(()=>{
                    this.$router.push('/usuarios')
                })
              })
            }).catch(error=>{
              //todo probar si se puede quitar
            })
            
          }
        })
      },

    },
    computed:{
      ...mapState({
        warning: state => state.warning
      }),
    }
  }
</script>

<style scope>
  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>