<template>
  <div class="container  mt-3">
    <div class="m-auto form-horizontal">
      
        <div class="mb-3 row" v-if="cursos">
          <div class="" v-for="curso in cursos">

                <button class="pt-0 pb-0 mt-1 mr-1 btn btn-success" v-if="cursos_permitidos.includes(curso.id)"
                @click="cursos_permitidos.splice(cursos_permitidos.indexOf(curso.id),1)"
                title="Click para quitar">
                  {{ curso.nombre }}  
                </button>
                <button class="pt-0 pb-0 mt-1 mr-1 btn btn-dark" v-else
                @click="cursos_permitidos.push(curso.id)"
                title="Click para agregar">
                  {{ curso.nombre }}  
                </button>
            
          </div>
        </div>

        <button v-if="procesable" class="btn btn-block btn-danger" @click="procesar">
          Guardar
        </button>   
        
      
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'DocenteEditarCursos',
    data () {
      return {
        cursos_permitidos:[]
      }
    },
    props: {
      docente: Object
    },
    mounted () {
      if(this.docente.cursos_permitidos){
        this.cursos_permitidos = JSON.parse(this.docente.cursos_permitidos)
      }
      if(!this.cursos || this.cursos.length<1){
        this.fetchCursos()
      }
    },
    methods:{
      ...mapActions({
        fetchDetalle: 'docentes/fetchDetalle',
        setWarning: 'setWarning',
        editarCursos: 'docentes/editarCursos',
        fetchCursos: 'asw/fetchCursos'
      }),
      ...mapMutations({
        
      }),
      procesar(){
        console.log("DocenteEditarCursos procesar")
        
        this.editarCursos({
          id:this.docente.id,
          cursos_permitidos:this.cursos_permitidos,
        }).then((apiResponse)=>{
          
          this.setWarning('Información Guardada', { root: true }).then(()=>{
            this.fetchDetalle(this.docente.id).then(()=>{
              this.$router.push('/docente/detalle/'+this.docente.id)
            }) 
          })
          
        }).catch((apiResponse)=>{
          console.log("error procesando DocenteEditarCursos")
        })
        
      },
      
    },
    computed:{
      ...mapState({
        cursos: state => state.asw.cursos, 
      }),
      ...mapGetters({
        
      }),
      procesable: {
        get () {
          return true
        },
        set(value){}
      },
    }
  }
</script>

<style scope>
 
</style>

