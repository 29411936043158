const utils = {
  getTextoHora:(value)=>{
  	switch(value){
  		case '06:00:00': return '6:00 am'; break; 
      case '07:30:00': return '7:30 am'; break; 
      case '08:00:00': return '8:00 am'; break;
      case '09:00:00': return '9:00 am'; break; 
      case '09:30:00': return '9:30 am'; break;
      case '10:40:00': return '10:40 am'; break; 
      case '11:00:00': return '11:00 am'; break;
      case '12:10:00': return '12:10 pm'; break; 
      case '13:00:00': return '1:00 pm'; break;
      case '13:40:00': return '1:40 pm'; break; 
      case '15:10:00': return '3:10 pm'; break; 
      case '16:50:00': return '4:50 pm'; break; 
      case '18:20:00': return '6:20 pm'; break; 
      case '19:50:00': return '7:50 pm'; break; 
  	}
  	return value
  },
  getHoy:()=>{
    let date = new Date()
    let dd = date.getDate();
    let mm = date.getMonth()+1; 
    let yyyy = date.getFullYear();
    if(dd<10) { dd=`0${dd}`; } 
    if(mm<10) { mm=`0${mm}`; } 
    let fecha_actual = `${yyyy}-${mm}-${dd}`;
    return fecha_actual
  },
  getManana:()=>{
    let date = new Date()
    date = new Date(date.getTime() + 24*60*60*1000)
    let dd = date.getDate();
    let mm = date.getMonth()+1; 
    let yyyy = date.getFullYear();
    if(dd<10) { dd=`0${dd}`; } 
    if(mm<10) { mm=`0${mm}`; } 
    let fecha_actual = `${yyyy}-${mm}-${dd}`;
    return fecha_actual
  },
  LETTER_REGEX: /^[a-zA-ZÀ-ÿ+()., -]+$/,
  VERSION : '0.21.1'
};

export default utils;