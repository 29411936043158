
<template>
  <b-container>
    <h1>Agendamientos realizados por estudiantes</h1>
    <div class="chart-container">
    <DashboardAgendamientosEstudiantesChart ref="chart" :chartdata="data" :options="options" :width="500" :height="200"></DashboardAgendamientosEstudiantesChart>
    </div>
    <b-button size="sm" variant="link" @click="actualizar">Actualizar</b-button>
    <b-button size="sm" variant="link" @click="revisar">Revisar</b-button>
  </b-container>
</template>


<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import DashboardAgendamientosEstudiantesChart from '@/components/Dashboard/DashboardAgendamientosEstudiantesChart';

  export default {
    name: 'DashboardAgendamientosEstudiantes',
    components:{
DashboardAgendamientosEstudiantesChart
    },
    data(){
      return {
        data: {
            labels:["NORTE","CENTRO","ADMINISTRATIVO","SUBA","TITAN","DIVER PLAZA","CHICO","CALLE 72","CALLE 45","CORFERIAS","RESTREPO","TUNAL","BOSA","SOACHA","PLAZA DE LAS AMERICAS","VILLAVICENCIO","ARMENIA","PEREIRA","MOSQUERA","PREMIUM","FLORIDA","ENVIGADO","MANIZALES","CALI","TUNJA"],
            datasets: [
            {
              label: 'Agendamientos por Estudiantes',
              data:[73440,57619,0,1949,5,114,870,2796,58,1439,7782,7987,3668,2500,5865,0,0,0,1317,4733,4842,3459,0,0,0],
              backgroundColor: '#f87979',
            }
          ]
        },
        
        options: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }],
                xAxes: [{
                    gridLines: {
                        offsetGridLines: true
                    }
                }]
            }
        }
      }
    },
    mounted () {
    	
    },
    methods:{
      ...mapActions({
      	fetchAgendamientosEstudiantes: 'indicadores/fetchAgendamientosEstudiantes',
      }),
      ...mapMutations({
      }),
      revisar(){
console.log(this.$refs.chart.defaults)
      },
      actualizar(){
        this.fetchAgendamientosEstudiantes()
        .then(()=>{
          this.data.labels=this.agendamientosEstudiantes.labels
          this.data.datasets[0].data=this.agendamientosEstudiantes.values
          //this.$data._chart.update()
          this.$refs.chart.update()
          
        })
      }
    },
    computed:{
      ...mapState({
      	agendamientosEstudiantes: state => state.indicadores.agendamientosEstudiantes, 
      }),
      ...mapGetters({   
      }),
    }
  }
</script>

<style scope>
.chart-container{
  text-align: center;
  margin: auto;
}
	
</style>