import Vue from 'vue';

const state = {
  lista : [],
};

const actions = {
  fetchLista:({commit}) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('examen/lista').then(
        response =>{
          commit('setLista',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  crear:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('examen/crear',data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  
  editar:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('examen/editar',data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  
};

const getters = {
  getExamen: (state) => (id) =>{
    let examen = state.lista.find(element=>{
      return element.id == id
    }) 
    return examen
  }
};

const mutations = {

  setLista: (state, lista) => {
    state.lista = lista
  },
  restart: (state) => {
    state.lista = []
  },

  
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
