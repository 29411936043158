<template>
	<div class="form-group row">
		<label class="col-3 col-form-label">{{ titulo }}:</label>
		<div class="col-9">
            <b-form-textarea
            
            v-model="_modelo"
            :name="titulo"
            :placeholder="titulo"
            rows="3"
            max-rows="6">
        </b-form-textarea>
			<!--input type="text"
			v-model="_modelo"
			:name="titulo"
			:placeholder="titulo"
			class="form-control"
			v-validate
			data-vv-rules="required"

            :id="_modelo"
    -->
		</div>
	</div>
</template>


<script>
  export default {
    name: 'InputTextArea',
    data () {
      return {
          error: null,
      }
    },
    props: {
		titulo: String,
		modelo: String
    },
    
    computed:{
		_modelo: {
			get () {
				return this.modelo
			},
			set (value) {
				this.$emit('change', value);
			}
		},
    }
    
  }
</script>

<style scope>

</style>