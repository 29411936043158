

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'DashboardEstudiantesActivosChart',
  //  extends: Bar,
    props: {
      chartdata: {
        type: Object,
        default: null
      },
      options: {
        type: Object,
        default: null
      }
    },
    mounted () {
      //this.renderChart(this.chartdata, this.options)
    },
    methods:{
      ...mapActions({
        
      }),
      ...mapMutations({
      }),
    },
    computed:{
      ...mapState({
        
      }),
      ...mapGetters({   
      }),
    }
  }
</script>

<style scope>

  
</style>