<template>
  <div class="">

    <div class="container pt-3 m-auto text-left ">
      <h1>Salon Masivo</h1>
       <hr>
      <form
        autocomplete="off"
        class="form-horizontal"
        @submit.prevent="validateBeforeSubmit"
      >
        <div>
          <strong>Estructura:</strong><br>
          <span style="font-size: 10px">"nombre,numero,capacidad,tipo_salon,sede_id"</span>
          <textarea class="area-masivo" v-model="datos"></textarea>

        </div>
        <button class="btn btn-block btn-danger" type="submit">Procesar</button>
        <div>{{ resultado}}</div>
      </form>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'SalonMasivo',
    data () {
      return {
          datos: null,
          resultado: null
      }
    },
    mounted () {
    },
    methods:{
      ...mapActions({
        procesar: 'salones/crearMasivo',
        setWarning: 'setWarning',
        fetchLista: 'salones/fetchLista',
      }),
      ...mapMutations({
      }),
      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {

          let lines = this.datos.split("\n");
          let candidatos = []
          for(let i in lines){
            let line = lines[i];
            let parametros = line.split(",")
            let candidato = {
              nombre:parametros[0],
              numero:parametros[1],
              capacidad:parametros[2],
              tipo_salon:parametros[3],
              sede_id:parametros[4],
            }
            candidatos.push(candidato)
          }

          console.log(candidatos)
          if ( ! result) {
            //hay errores
          } else {
            
            this.procesar(candidatos).then((response)=>{
                this.resultado = response.message
                this.setWarning(response.message, { root: true }).then(()=>{
                this.fetchLista().then(()=>{
                    //this.$router.push('/salones')
                })
              })
            }).catch(error=>{
              
            })
            
          }
        })
      },

    },
    computed:{
      ...mapState({
        warning: state => state.warning
      }),
    }
  }
</script>

<style scope>
  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
  .area-masivo{
    width: 100%;
  }
</style>