<template>
	<div class="form-group row">
		<label class="col-3 col-form-label">Tipo Predeterminado:</label>
		<div class="col-9">
      <select  v-model="_modelo" class="form-control">
        <option disabled value="">--Tipo Salón--</option>
        <option v-for="tipo in tipos_salon" :value="tipo.id">{{tipo.nombre}}</option>
        
      </select>
		</div>
	</div>
</template>


<script>
  import {mapState} from 'vuex';
  export default {
    name: 'SelectTipoClase',
    data () {
      return {
          error: null,
      }
    },
    props: {
      modelo: String
    },
    
    computed:{
      ...mapState({
        tipos_salon: state => state.asw.tipos, 
      }),
		_modelo: {
			get () {
				return this.modelo
			},
			set (value) {
				this.$emit('change', value);
			}
		},
    }
    
  }
</script>

<style scope>

</style>