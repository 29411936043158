import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Auth/Login';
// import Home from '@/components/Home';
import DashboardContainer from '@/components/Dashboard/DashboardContainer';

import Estudiantes from '@/components/Estudiantes/Lista';
import EstudianteDetalle from '@/components/Estudiantes/Detalle';
import EstudianteNuevo from '@/components/Estudiantes/Nuevo';
import EstudianteEditar from '@/components/Estudiantes/Editar';
import EstudianteMasivo from '@/components/Estudiantes/Masivo';
import EstudianteCambiarPassword from '@/components/Estudiantes/EstudianteCambiarPassword';
import Progreso from '@/components/Estudiantes/Progreso/Progreso';

import DocenteLista from '@/components/Docentes/DocenteLista';
import DocenteDetalle from '@/components/Docentes/DocenteDetalle';
import DocenteNuevo from '@/components/Docentes/DocenteNuevo';
import DocenteMasivo from '@/components/Docentes/DocenteMasivo';
import DocenteEditar from '@/components/Docentes/DocenteEditar';
import DocenteNovedades from '@/components/Docentes/DocenteNovedades';
import DocenteCambiarPassword from '@/components/Docentes/DocenteCambiarPassword';

import FuncionariosLista from '@/components/Funcionarios/FuncionariosLista';
import FuncionarioDetalle from '@/components/Funcionarios/FuncionarioDetalle';
import FuncionarioNuevo from '@/components/Funcionarios/FuncionarioNuevo';
import FuncionarioEditar from '@/components/Funcionarios/FuncionarioEditar';
import FuncionarioMasivo from '@/components/Funcionarios/FuncionarioMasivo';
import FuncionarioGestionGlobal from '@/components/Funcionarios/FuncionarioGestionGlobal';
import FuncionarioCambiarPassword from '@/components/Funcionarios/FuncionarioCambiarPassword';

import GruposLista from '@/components/Grupos/GruposLista';
import GrupoDetalle from '@/components/Grupos/GrupoDetalle';

import SalonLista from '@/components/Salones/SalonLista';
import SalonDetalle from '@/components/Salones/SalonDetalle';
import SalonNuevo from '@/components/Salones/SalonNuevo';
import SalonMasivo from '@/components/Salones/SalonMasivo';
import SalonEditar from '@/components/Salones/SalonEditar';

import CursoLista from '@/components/Cursos/CursoLista';
import CursoDetalle from '@/components/Cursos/CursoDetalle';
import CursoNuevo from '@/components/Cursos/CursoNuevo';
import CursoEditar from '@/components/Cursos/CursoEditar';
import TemaMasivo from '@/components/Cursos/Temas/TemaMasivo';
import TemaEditar from '@/components/Cursos/Temas/TemaEditar';
import TemaNuevo from '@/components/Cursos/Temas/TemaNuevo';
import TemaDetalle from '@/components/Cursos/Temas/TemaDetalle';

import CursoCorreoLista from '@/components/Cursos/Correos/CorreoLista';
import CursoCorreoNuevo from '@/components/Cursos/Correos/CorreoNuevo';
import CursoCorreoDetalle from '@/components/Cursos/Correos/CorreoDetalle';
import CursoCorreoEditar from '@/components/Cursos/Correos/CorreoEditar';


import Programador from '@/components/Programador/Programador';
import Pantalla from '@/components/Pantalla/Pantalla';
import Logistica from '@/components/Logistica/Logistica';
import Profile from '@/components/Profile';

import ReporteOcupacion from '@/components/Reportes/ReporteOcupacion';
import ReporteActividad from '@/components/Reportes/ReporteActividad';
import ReporteConsumo from '@/components/Reportes/ReporteConsumo';
import ReporteSIET from '@/components/Reportes/ReporteSIET';
import ReporteSolicitudAvance from '@/components/Reportes/ReporteSolicitudAvance';
import ReporteEncuesta from '@/components/Reportes/ReporteEncuesta';

import Preasignaciones from '@/components/Preasignaciones/Preasignaciones';
import ExamenesLista from '@/components/Cursos/Examenes/ExamenesLista';
import ExamenNuevo from '@/components/Cursos/Examenes/ExamenNuevo';
import ExamenEditar from '@/components/Cursos/Examenes/ExamenEditar';

import Cierres from '@/components/Cierres/Cierres';

import BannersNuevo from '@/components/Banners/BannersNuevo';
import BannersTodos from '@/components/Banners/BannersTodos';
import BannersDetalle from '@/components/Banners/BannersDetalle';
import BannersEditar from '@/components/Banners/BannersEditar'; 

import Masivo from '@/components/Masivos/Masivo';
import CancelacionMasiva from '@/components/Masivos/CancelacionMasiva';

import Unauthorized from '@/components/Unauthorized';

import store from './store'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/index.html',
      name: 'indexhome',
      component: DashboardContainer,
      meta: { Auth: true, title: 'Inicio' },
    },
    {
      path: '/',
      name: 'home',
      component: DashboardContainer,
      meta: { Auth: true, title: 'Inicio' },
    },
    {
      path: '/no-autorizado',
      name: 'no-autorizado',
      component: Unauthorized,
      meta: { Auth: true, title: 'No Autorizado' },
    },
    {
      path: '/estudiantes',
      name: 'estudiantes',
      component: Estudiantes,
      meta: { Auth: true, title: 'Estudiantes' , requerido: '4586B73F'},
    },
    {
      path: '/estudiante/detalle/:id',
      name: 'estudiante',
      component: EstudianteDetalle,
      meta: { Auth: true, title: 'Estudiante' , requerido: '560B5B09'},
    },
    {
      path: '/estudiante/nuevo',
      name: 'estudiante/nuevo',
      component: EstudianteNuevo,
      meta: { Auth: true, title: 'Estudiante Nuevo', requerido: 'D12AEB51' },
    },
    {
      path: '/estudiante/masivo',
      name: 'estudiante/masivo',
      component: EstudianteMasivo,
      meta: { Auth: true, title: 'Estudiante Masivo' , requerido: 'F94EDE37'},
    },
    {
      path: '/estudiante/editar/:id',
      name: 'estudiante/editar',
      component: EstudianteEditar,
      meta: { Auth: true, title: 'Estudiante Editar'},
    },
    {
      path: '/estudiante/progreso/:id',
      name: 'estudiante/progreso',
      component: Progreso,
      meta: { Auth: true, title: 'Progreso Academico' , requerido: '4EBC511D'},
    },
    {
      path: '/estudiante/cambiarpassword/:id',
      name: 'estudiante/cambiarpassword',
      component: EstudianteCambiarPassword,
      meta: { Auth: true, title: 'Cambiar Password' , requerido: '31BEFF98'},
    },
    {
      path: '/docentes',
      name: 'docentes',
      component: DocenteLista,
      meta: { Auth: true, title: 'Docentes', requerido: '8A28EE41' },
    },
    {
      path: '/docente/detalle/:id',
      name: 'docente',
      component: DocenteDetalle,
      meta: { Auth: true, title: 'Docente', requerido: '42C7BC3F' },
    },
    {
      path: '/docente/nuevo',
      name: 'docente/nuevo',
      component: DocenteNuevo,
      meta: { Auth: true, title: 'Docente Nuevo', requerido: '66581933' },
    },
    {
      path: '/docente/masivo',
      name: 'docente/masivo',
      component: DocenteMasivo,
      meta: { Auth: true, title: 'Docente Masivo', requerido: '5A45ACB1' },
    },
    {
      path: '/docente/editar/:id',
      name: 'docente/editar',
      component: DocenteEditar,
      meta: { Auth: true, title: 'Docente Editar', requerido: '3329CE3B' },
    },
    {
      path: '/docente/novedades/:id',
      name: 'docente/novedades',
      component: DocenteNovedades,
      meta: { Auth: true, title: 'Docente Novedades', requerido: 'EFE8AFBA' },
    },
    {
      path: '/docente/cambiarpassword/:id',
      name: 'docente/cambiarpassword',
      component: DocenteCambiarPassword,
      meta: { Auth: true, title: 'Cambiar Password' , requerido: '2FD9B828'},
    },

    {
      path: '/cursos',
      name: 'cursos',
      component: CursoLista,
      meta: { Auth: true, title: 'Cursos' , requerido: '19E70E2C'},
    },
    {
      path: '/curso/detalle/:id',
      name: 'curso',
      component: CursoDetalle,
      meta: { Auth: true, title: 'Curso' , requerido: '84D84CB2'},
    },
    {
      path: '/curso/nuevo',
      name: 'curso/nuevo',
      component: CursoNuevo,
      meta: { Auth: true, title: 'Curso Nuevo' , requerido: '55C8CB36'},
    },
    
    {
      path: '/curso/editar/:id',
      name: 'curso/editar',
      component: CursoEditar,
      meta: { Auth: true, title: 'Curso Editar' , requerido: '4370AA66'},
    },
    {
      path: '/curso/:curso_id/correos',
      name: 'correo/lista',
      component: CursoCorreoLista,
      meta: { Auth: true, title: 'Correos' , requerido: 'D4F5A51C'},
    },
    {
      path: '/curso/:curso_id/correos/nuevo',
      name: 'correo/nuevo',
      component: CursoCorreoNuevo,
      meta: { Auth: true, title: 'Correos Nuevo' , requerido: '6213A164'},
    },
    {
      path: '/curso/:curso_id/correos/:id/detalle',
      name: 'correos/detalle',
      component: CursoCorreoDetalle,
      meta: { Auth: true, title: 'Correos Detalle' , requerido: '91361DBD'},
    },
    {
      path: '/curso/:curso_id/correos/:id/editar',
      name: 'correos/editar',
      component: CursoCorreoEditar,
      meta: { Auth: true, title: 'Correos Editar' , requerido: 'DC61F6A2'},
    },
    {
      path: '/tema/detalle/:id',
      name: 'tema/detalle',
      component: TemaDetalle,
      meta: { Auth: true, title: 'Tema Detalle' , requerido: '8A224540'},
    },
    {
      path: '/tema/editar/:id',
      name: 'tema/editar',
      component: TemaEditar,
      meta: { Auth: true, title: 'Tema Editar' , requerido: '799AAD9D'},
    },
    {
      path: '/tema/nuevo/:id', //El id es el id del curso 
      name: 'tema/nuevo',
      component: TemaNuevo,
      meta: { Auth: true, title: 'Tema Nuevo' , requerido: 'A9EFE57'},
    },
    {
      path: '/tema/masivo',
      name: 'tema/masivo',
      component: TemaMasivo,
      meta: { Auth: true, title: 'Tema Masivo' , requerido: '10F6CF17'},
    },
    
    {
      path: '/salones',
      name: 'salones',
      component: SalonLista,
      meta: { Auth: true, title: 'Salones', requerido: 'B2903DC1' },
    },
    {
      path: '/salon/detalle/:id',
      name: 'salon',
      component: SalonDetalle,
      meta: { Auth: true, title: 'Salon' , requerido: 'AE7C3DD1'},
    },
    {
      path: '/salon/nuevo',
      name: 'salon/nuevo',
      component: SalonNuevo,
      meta: { Auth: true, title: 'Salon Nuevo' , requerido: '3C1A985C'},
    },
    {
      path: '/salon/masivo',
      name: 'salon/masivo',
      component: SalonMasivo,
      meta: { Auth: true, title: 'Salon Masivo' , requerido: '871292F9'},
    },
    {
      path: '/salon/editar/:id',
      name: 'salon/editar',
      component: SalonEditar,
      meta: { Auth: true, title: 'Salon Editar' , requerido: 'EE7EF073'},
    },

    {
      path: '/programador',
      name: 'programador',
      component: Programador, 
      meta: { Auth: true, title: 'Programador', requerido: '8D24244B'},
    },
    {
      path: '/pantalla',
      name: 'pantalla',
      component: Pantalla,
      meta: { Auth: true, title: 'Pantalla', limpio: true, requerido: '293B9B69' },
    },
    {
      path: '/logistica',
      name: 'logistica',
      component: Logistica,
      meta: { Auth: true, title: 'Logistica', requerido: '4E621462'},
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: { Auth: true, title: 'Perfil' },
    },
    {
      path: '/usuarios',
      name: 'usuarios',
      component: FuncionariosLista,
      meta: { Auth: true, title: 'Usuarios' , requerido: '42A34371'},
    },
    {
      path: '/usuario/detalle/:id',
      name: 'usuario',
      component: FuncionarioDetalle,
      meta: { Auth: true, title: 'Funcionario' , requerido: '1E3B0C0D'},
    },
    {
      path: '/usuario/nuevo',
      name: 'usuario/nuevo',
      component: FuncionarioNuevo,
      meta: { Auth: true, title: 'Funcionario Nuevo' , requerido: '4049A232'},
    },
    {
      path: '/usuario/editar/:id',
      name: 'usuario/editar',
      component: FuncionarioEditar,
      meta: { Auth: true, title: 'Funcionario Editar' , requerido: 'DEF5A092'},
    },
    {
      path: '/usuario/masivo',
      name: 'usuario/masivo',
      component: FuncionarioMasivo,
      meta: { Auth: true, title: 'Funcionario Masivo', requerido: '2D648D9C' },
    },
    {
      path: '/usuario/gestion_global',
      name: 'usuario/gestion_global',
      component: FuncionarioGestionGlobal,
      meta: { Auth: true, title: 'Funcionario Gestion Global', requerido: 'E1020A89' },
    },
    {
      path: '/usuario/cambiarpassword/:id',
      name: 'usuario/cambiarpassword',
      component: FuncionarioCambiarPassword,
      meta: { Auth: true, title: 'Cambiar Password' , requerido: '1CE32AC5'},
    },
    {
      path: '/grupos',
      name: 'grupos',
      component: GruposLista,
      meta: { Auth: true, title: 'Grupos' , requerido: '4F40D01B'},
    },
    {
      path: '/grupo/detalle/:id',
      name: 'grupo',
      component: GrupoDetalle,
      meta: { Auth: true, title: 'Grupo' , requerido: '90C25887' },
    },

    {
      path: '/reportes/ocupacion',
      name: 'ReporteOcupacion',
      component: ReporteOcupacion,
      meta: { Auth: true, title: 'Reporte de Ocupación' , requerido: '17737EC6'},
    },
    
    {
      path: '/reportes/siet',
      name: 'ReporteSIET',
      component: ReporteSIET,
      meta: { Auth: true, title: 'Reporte SIET', requerido: '11FC3E77' }, 
    },

    {
      path: '/reportes/solicitud-avance',
      name: 'ReporteSolicitudAvance',
      component: ReporteSolicitudAvance,
      meta: { Auth: true, title: 'Reporte Solicitud Avance', requerido: 'E96EB087' }, 
    },

    {
      path: '/reportes/encuesta-satisfaccion',
      name: 'ReporteEncuesta',
      component: ReporteEncuesta,
      meta: { Auth: true, title: 'Reporte de Satisfacción', requerido: 'F83DCEB2' }, 
    },
    
    
    {
      path: '/reportes/consumo',
      name: 'ReporteConsumo',
      component: ReporteConsumo,
      meta: { Auth: true, title: 'Reporte Consumo' , requerido: '4F40D01B'},//FALTA PERMISO
    },

    {
      path: '/reportes/actividad',
      name: 'ReporteActividad',
      component: ReporteActividad,
      meta: { Auth: true, title: 'Reporte Actividad' , requerido: '17737EC6'},
    },

    {
      path: '/preasignaciones',
      name: 'preasignaciones',
      component: Preasignaciones,
      meta: { Auth: true, title: 'Preasignaciones', requerido: '3329CE3B' },//FALTA PERMISO
    },

    {
      path: '/examenes',
      name: 'examenes',
      component: ExamenesLista,
      meta: { Auth: true, title: 'Examenes', requerido: 'B7A9DE95' },
    },
    {
      path: '/examen/nuevo',
      name: 'examen_nuevo',
      component: ExamenNuevo,
      meta: { Auth: true, title: 'Examen Nuevo', requerido: '39237B08' },
    },

    {
      path: '/examen/editar/:id',
      name: 'examen',
      component: ExamenEditar,
      meta: { Auth: true, title: 'Examen Editar', requerido: '827D5C7D' },
    },

    {
      path: '/cierres',
      name: 'cierres',
      component: Cierres,
      meta: { Auth: true, title: 'Cierres', requerido: 'E4D2DDE5' },
    },
    {
      path: '/banners/nuevo',
      name: 'BannersNuevo',
      component: BannersNuevo,
      meta: { Auth: true, title: 'Banners Nuevo', requerido: 'F0F8F93B' },
    }, 
    {
      path: '/banners/lista',
      name: 'BannersTodos',
      component: BannersTodos,
      meta: { Auth: true, title: 'Banners Todos', requerido: '2360680B' },
    }, 
    {
      path: '/banners/detalle/:id',
      name: 'BannersDetalle',
      component: BannersDetalle,
      meta: { Auth: true, title: 'Banners Detalle' , requerido: 'B6EFA5D3'},
    },
   {
      path: '/banner/editar/:id',
      name: 'BannersEditar',
      component: BannersEditar,
      meta: { Auth: true, title: 'Banner Editar' , requerido: '3D64E6E9'},
    },

    {
      path: '/masivos',
      name: 'Masivos',
      component: Masivo,
      meta: { Auth: true, title: 'Masivo', requerido: 'EACA7E4' }, 
    },
    {
      path: '/cacelacionMasiva',
      name: 'CacelacionMasiva',
      component: CancelacionMasiva,
      meta: { Auth: true, title: 'Cancelaciones', requerido: 'E1D33839' }, 
    },
    
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { Auth: false, title: 'Iniciar sesión' },
      beforeEnter: (to, from, next) => {
        if(store.state.auth.logged) {
          next({ path: '/' });
        } else {
          next();
        }
      }
    }
  ]
})


//.configurar el router
//para cada cambio de ruta

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.meta.Auth && !store.state.auth.logged) {
    next({path: '/login'});
  }else {
    if (store.state.auth.logged) {
      store.commit('auth/setUser');
    }
    if (to.meta.requerido && !store.getters['auth/permiso'](to.meta.requerido)) {
      console.log('No tiene permiso')
      next({path: '/no-autorizado'});
    }else{
      next();
    }
    
  }
});

//.para cada cambio de ruta


export default router;