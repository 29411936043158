
<template>
  <div>
    <div class="container-fluid salones_wrapper m-3 p-3">
    	<div class="row">
      		<h4>Resumen de salones</h4>
      	</div>
      	<div class="row pb-3">
      		<strong>Total salones activos:{{salonesActivos.length}}</strong>
      	</div>

      <div v-for="salon in salonesActivos" class="row">
      	<div class="tipo-titulo">
      		<a :href="`#/salon/detalle/${ salon.id }`" v-if="permiso('AE7C3DD1')" >
      		{{salon.titulo}}</a>
        </div>
      	<div class="botones" v-for="tipo in tipos">
      		
      		<button class="pt-0 pb-0 mt-1 mr-1 btn btn-success" 
                v-if="salon[tipo.id]">
                  {{ tipo.nombre_corto }}  
            </button>
            <button class="pt-0 pb-0 mt-1 mr-1 btn btn-dark" v-else>
              	{{ tipo.nombre_corto }}  
            </button>
            
      	</div>
        <div class="botones">
          <button v-if="salon.capacidad" class="pt-0 pb-0 mt-1 mr-1 btn btn-info" >{{salon.capacidad}}</button>
          
        </div>
        <div class="botones">
      		<button :class="{ 'btn-danger': salon.preasignaciones.length<capacidadMaxima }"
            class="pt-0 pb-0 mt-1 mr-1 btn btn-info" title="Bloques semanales">
              {{ salon.preasignaciones.length }} / {{ capacidadMaxima }}  
              ({{ salon.indice_preasignacion }}%)
            </button>

        </div>
      </div>
      <div class="row mt-3">
        <h4>Indice de configuración:  {{ indicePreasignacionGeneral }}%</h4>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'SalonTipos',
    data () {
      return {
        capacidadMaxima : 55
      }
    },
    mounted () {
      if(!this.salones || (this.salones && this.salones.length==0)){
        this.fetchLista()
      }
    },
    methods:{
      ...mapActions({
        fetchLista: 'salones/fetchLista',
      }),
      ...mapMutations({
        
      }),
      edit(){

      },
      actualizar(){
        this.fetchLista()
      }
    },
    computed: {
      ...mapState({
        salones: state => state.salones.lista, 
        tipos: state => state.asw.tipos, 
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      salonesActivos(){
      	if(this.salones){
      		let salones_activos = this.salones.filter(salon=>{
      			return salon.activo==1
      		})
      		
          salones_activos = salones_activos.map((salon)=>{

            let indice_preasignacion = salon.preasignaciones.length/this.capacidadMaxima*100

	          let newSalon = {
	            id: salon.id,
	            activo: salon.activo,
	            titulo: salon.nombre,
              tipos_permitidos: salon.tipos_permitidos,
	            capacidad: salon.capacidad,
              preasignaciones: salon.preasignaciones,
              indice_preasignacion: Math.round(indice_preasignacion)
	          }
	          for(let i in this.tipos){
	          	let tipo = this.tipos[i]
	          	newSalon[tipo.id] = salon.tipos_permitidos.includes(tipo.id)
	          }
	          return newSalon
	        })
			
      		return salones_activos
      	}else{
      		return []
      	}
      },
      indicePreasignacionGeneral(){
        let suma = 0
        this.salonesActivos.forEach(element=>{
          suma += element.indice_preasignacion
        })
        suma = suma/this.salonesActivos.length
        return Math.round(suma)
      }
    }
  }
</script>

<style scope>
	.tipo-titulo{
		width: 160px;
    	font-size: 12px;
	}
	.botones .btn{
		font-size: 10px !important;
    	padding: 2px !important;
	}
</style>