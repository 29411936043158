<template>
  <b-container fluid v-if="permiso('235AE1F')">
    <hr>
    <div v-if="salon">
    <h4>Preasignaciones</h4>	
    	<div v-if="disponibilidad_general">

    		<b-row class="tabla-preasignaciones">
    			<!-- ENTRE SEMANA -->
    			<b-col cols="9">
		    		<b-row>
						<b-col class="celda-head text-center" >Franja</b-col>
						<b-col class="celda-head text-center" v-for="(n, index_1) in 5" :key="index_1">
				            {{ disponibilidad_general.dias[n].nombre }}
				        </b-col>
					</b-row>
					<div v-for="(hora, index_2) in disponibilidad_general.dias[1].horas" :key="index_2">		
						<b-row class="fila">
							<b-col class="text-center">{{ hora.hora }}</b-col>
							<b-col class="text-center" v-for="(n, index_3) in 5" :key="index_3">
            
						
	                  		<div v-if="getPreasignacion(n,hora.hora)">
	                  			<b-button class="btnFlat m-1" size="sm" variant="light" 
	                  			>
			                {{getPreasignacionTextoDocente(n,hora.hora)}}<br>
			                  		<div v-html="getPreasignacionTextoTipos(n,hora.hora)">
			                  		</div>
		                		</b-button>
	                  		</div>
	                  		<div v-else>
	                  			LIBRE
	                  		</div>

    					</b-col>
    				</b-row>
                </div>
          	</b-col>
          	<!-- SABADOS -->
    			<b-col cols="3">
	        		<b-row>
	    				<b-col class="celda-head text-center" >Franja</b-col>
	    				<b-col class="celda-head text-center">
	    		            {{ disponibilidad_general.dias[6].nombre }}
	    		        </b-col>
	    			</b-row>
	    			<div v-for="(hora, index_4) in disponibilidad_general.dias[6].horas" :key="index_4">		
	    				<b-row class="fila">
	    					<b-col class="text-center">{{ hora.hora }}</b-col>
	    					<b-col class="text-center">
		                    	<div v-if="getPreasignacion(6,hora.hora)">
		                      		<b-button class="btnFlat m-1" size="sm" variant="light" @click="iniciarEditarPreasignacion(6,hora.hora)">
		                      {{getPreasignacionTextoDocente(6,hora.hora)}}<br>
		                    			<div v-html="getPreasignacionTextoTipos(6,hora.hora)"></div>
		                      		</b-button>
		                    	</div>
			                    <div v-else>
			                      LIBRE
			                    </div>
							</b-col>
						</b-row>
		            </div>
	        	</b-col>
			</b-row>
        </div>
    </div>
    

  </b-container>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';

  export default {
    name: 'SalonPreasignacion',
    data () {
      return {

      }
    },
    props: {
      salon: Object
    },
    mounted () {
     	if(this.docentes && this.docentes.length<=0){
     		this.fetchDocentes()
     	}
    },
    methods:{
      ...mapActions({
        fetchSalones: 'salones/fetchLista',
        fetchDocentes: 'docentes/fetchLista',
      }),
      ...mapMutations({
      }),
      getPreasignacion(dia,hora){
      	if(this.salon.preasignaciones){
      		return this.salon.preasignaciones.find(element=>{
      			return element.dia == dia && element.hora==hora
      		})
      	}
      },

      getPreasignacionTextoDocente(dia,hora){
      	if(this.salon.preasignaciones){
      		let preasignacion = this.getPreasignacion(dia,hora)
      		let texto = preasignacion.docente.primer_nombre+' '+preasignacion.docente.primer_apellido
      		return texto
      	}
      	return null
      },

      getPreasignacionTextoTipos(dia,hora){
      	if(this.salon.preasignaciones){
      		let preasignacion = this.getPreasignacion(dia,hora)
      		let texto = ''
      		if(preasignacion.tipos && preasignacion.tipos.tipos){
      			let lista = preasignacion.tipos.tipos.map(element=>{
      				let tipoObj = this.tipos.find(tipo=>{
      					return tipo.id == element
      				})
      				let html = tipoObj?tipoObj.nombre_corto:'indefinido';
      				html = '<div class="badge" style="color:#fff;background-color:#'+tipoObj.color+';">'+html+'</div>';
      				return html
      			})
      			if(preasignacion.tipos.modo && preasignacion.tipos.modo == 'and'){
      				texto = lista.join(' y ')		
      			}else{
      				texto = lista.join('')
      			}
      		}
      		
      		return texto
      	}
      	return null
      },

      
    },
    computed: {
      ...mapState({
        tipos: state => state.asw.tipos, 
        combinacionesPosibles: state => state.asw.combinacionesPosibles, 
        disponibilidad_general: state => state.asw.disponibilidad_general,
        salones: state => state.salones.lista, 
        docentes: state => state.docentes.lista, 
      }),
      ...mapGetters({
        permiso: 'auth/permiso',
        preasignaciones: 'preasignaciones/getPreasignacionesXDocente', 
        getTipoNombreCorto: 'asw/getTipoNombreCorto', 
        getNombreLargo: 'asw/getNombreLargo', 
        esCombinacionValida: 'asw/esCombinacionValida', 
      }),
        
    }
  }
</script>

<style scope>
	.btnFlat{
	    font-size: 12px !important;
    	padding: 1px 3px !important;
	}
	.fila{
		border-top: 1px solid #ccc;
	}
	.tabla-preasignaciones .col{
		padding: 0px;
	}
	.tabla-preasignaciones .col button{
		max-width: 140px;
	}
</style>