<template>
  <div class="pendientes-nuevo">
    <a href="#" class="btn-link link-inferior" v-if="!programando" @click.prevent="iniciarProgramar">Programar</a>
    <a href="#" class="btn-link link-inferior" v-if="programando" @click.prevent="cancelar">Cancelar</a> 
    <div v-if="programando">
      <div v-if="estudiante_id && estudiante">
        <div>
        {{estudiante.primer_nombre}} {{estudiante.segundo_nombre}} {{estudiante.primer_apellido}} {{estudiante.segundo_apellido}}
        </div>
       
        <button v-if="agendable" class="btn btn-block btn-danger" @click="procesarAgendar">
          Programar
        </button>
      </div>
      <div v-else>
        <input type="text"
          v-model="getTexto"
          :name="titulo"
          :placeholder="titulo"
          >
        <div v-if="opciones">
          <div class="btn-link" v-for="opcion in opciones" @click="seleccionarEstudiante(opcion)">
            
            {{opcion.primer_nombre}} {{opcion.segundo_nombre}} {{opcion.primer_apellido}} {{opcion.segundo_apellido}}
            
          </div>
        </div>
      </div>
    </div>
    <div v-if="errorMessage" style="color:red;border: 1px solid red; padding:2px">{{ errorMessage }}</div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';
  
  export default {
    name: 'ProgramadorAgendamientoNuevo',
    components: {
      
    },
    data () {
      return {
        programando : false,
        texto : null,
        opciones : null,
        estudiante_id: null,
        errorMessage: null,
        tipoClase : null
      }
    },
    props : {
      curso: Object,
      hora: Object, 
      dia: Object,
      tipo : Object
    },
    mounted () {},
    methods:{
      ...mapActions({
        fetchDetalle: 'estudiantes/fetchDetalle',
        fetchHorarios: 'estudiantes/fetchHorarios',
        fetchAgendamientos: 'estudiantes/fetchAgendamientos',
        fetchAgendamientosAll : 'agendamientos/fetchAgendamientos',
        fetchAgendamientosXMarca : 'agendamientos/fetchAgendamientosXMarca',
        agendar: 'programacion/agendar',
      }),
      ...mapMutations({
        
      }),
      iniciarProgramar(){
        this.texto = null
        this.opciones = null
        this.estudiante_id = null
        this.tipoClase = null
        this.errorMessage = null
        this.programando = !this.programando
      },
      procesarAgendar(){
        
        this.agendar({
          hora:this.hora.hora,
          dia:this.dia.fecha,
          id:this.estudiante_id,
          tipo_clase:this.tipo.id,
          tipo_solicitud:this.tipo.solicitud,
        }).then((apiResponse)=>{
          this.fetchDetalle(this.estudiante_id).then(()=>{
            //OPTIMIZACION this.fetchAgendamientosAll().then(()=>{})
            this.fetchAgendamientosXMarca({marca_tiempo:this.hora.marca_tiempo}).then(()=>{})
            this.fetchHorarios({tema:null,id:this.estudiante_id})
          })
        }).catch((apiResponse)=>{
            console.log(apiResponse.data.error)
            this.errorMessage = apiResponse.data.error
          }).finally(()=>{
            this.cancelar()
          })
      },
      cancelar(){
        this.programando = !this.programando
      },
      seleccionarEstudiante(value){
        this.estudiante_id = value.id
        this.procesarAgendar()
      }
      
    },
    computed: {
      ...mapState({
        detalle: state => state.estudiantes.detalle, 
      }),
      estudiante(){
        return this.detalle[this.estudiante_id]
      },
      
      titulo(){
        return 'Busca Estudiantes'
      },
      getTexto: {
        get () {
          return this.texto
        },
        set(value){
          this.texto = value 
          if(value && value.length >= 5){
            console.log(value)
            Vue.http.post('estudiante/buscar',{texto:value,curso_id:(this.curso?this.curso.id:null),activos:'true'}).then(
              response =>{
                this.opciones = response.data
              }
            )
            .catch(error=>{
              console.log(error)
            }).finally(()=>{
              
            })
          }
        }
      },
      agendable: {
        get () {
          return (this.estudiante_id && this.estudiante)
        },
        set(value){}
      }
    }
  }
</script>

<style scope>
.pendientes-nuevo{
  
}
 
</style>