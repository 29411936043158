<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_exameneditar"></b-navbar-toggle>
        <h1  class="text-center navbar-brand pt-0 pb-0 mb-0">Editar Examen</h1>
        <b-collapse is-nav id="nav_collapse_exameneditar">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <a :href="`#/examenes`" class="nav-link" title="Ver">Volver</a>
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>

    <div class="container pt-3 m-auto text-left ">
       
      <div v-if="examen">

        <InputTextSimple titulo="Codigo" :modelo="examen.codigo" 
            @change="examen.codigo = $event"/>
        <InputTextSimple titulo="Version" :modelo="examen.version" 
            @change="examen.version = $event"/>
        <InputTextSimple titulo="Nombre" :modelo="examen.nombre" 
            @change="examen.nombre = $event"/>
        
        <div class="form-group row">
          <label class="col-3 col-form-label">Objetivo:</label>
          <div class="col-9">
            <b-form-textarea
              id="textarea"
              v-model="examen.objetivo"
              placeholder="Objetivo"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </div>

        <div class="form-group row">
            <label class="col-3 col-form-label">Fecha implementación:</label>
            <div class="col-9">
              <date-picker  class="date-picker" v-model="examen.fecha_implementacion" lang="es" type="date" 
            :time-picker-options="timePickerOptions" :clearable="true"></date-picker>
            </div>
        </div>

        <div class="form-group row">
          <label class="col-3 col-form-label">Estructura:</label>
          <b-card tag="article" class="col-md-9">
            <div class="row">
              <div class="col-md-4">
                <label class="col-form-label">Unidades:</label>
                <b-form-input v-model="struct.unidades" placeholder="Unidades"></b-form-input><br>
                <div class="custom-control custom-switch">
                  <input type="checkbox" v-model="struct.comentarios_estudiante" class="custom-control-input" id="studentSwitch">
                  <label class="custom-control-label" for="studentSwitch">Comentario estudiantes</label>
                </div><br>
                <div class="custom-control custom-switch">
                  <input type="checkbox" v-model="struct.comentarios_docente" class="custom-control-input" id="docentSwitch">
                  <label class="custom-control-label" for="docentSwitch">Comentario docentes</label>
                </div><br>
                <div class="custom-control custom-switch">
                  <input type="checkbox" v-model="struct.self_assessment" class="custom-control-input" id="selfSwitch">
                  <label class="custom-control-label" for="selfSwitch">Self Assessment</label>
                </div>
              </div>
              <div class="col-md-8">
                <h2>Criterios</h2>
                <div class="row">
                  <div class="col-md-4" v-for="(cr, index) in struct.criterios">
                    <b-card tag="div" style="height: 85%;width: 115%;">
                      <label class="col-form-label">Nombre: {{cr.nombre}}</label><br>
                      <label class="col-form-label">Máxima calificación: {{cr.opciones.length}}</label><br>
                      <label class="col-form-label">Tipo: {{cr.tipo.charAt(0).toUpperCase() + cr.tipo.slice(1)}}</label><br>
                      <b-button pill variant="outline-danger" @click="removeCriterion(index)">Eliminar</b-button>
                    </b-card><br>
                  </div>
                </div>
              </div>
              <div class="col-4"></div>
              <div class="col-6">
                <h2>Nuevo criterio</h2>
                <b-form-input v-model="criterion.nombre" placeholder="Nombre"></b-form-input><br>
                <b-form-input v-model="criterion.opciones" placeholder="Maxima Calificación"></b-form-input><br>
                <b-form-select v-model="criterion.tipo" :options="types"></b-form-select><br><br>
                <b-button pill variant="primary" style="float:right;" @click="addCriterion()">Agregar criterio</b-button>
              </div>
            </div>
          </b-card>
        </div>

        
<!--
        <select  v-model="candidato" class="form-control">
          <option selected value="">--SELECCIONE--</option>
          <option :value="1">Lunes</option>
          <option :value="2">Martes</option>
          <option :value="3">Miercoles</option>
          <option :value="4">Jueves</option>
          <option :value="5">Viernes</option>
          <option :value="6">Sabado</option>
        </select>

      -->
      </div>  
      <button v-if="puedeGuardar" class="btn btn-block btn-danger" @click="procesarGuardar">Guardar</button>
      
      
    </div>
  </div>
</template>

<script>
  import {mapState,mapGetters, mapActions, mapMutations} from 'vuex';
  import DatePicker from 'vue2-datepicker'

  export default {
    name: 'ExamenEditar',
    components: {
      DatePicker
    },
    data () {
      return {
          examen_id:null,
          timePickerOptions:{},
          /**Gestion estructura**/
          struct : {},
          criterion: {},
          criteria : [],
          types: [
            {text: 'Discreto', value:'discreto'},
            {text: 'Continuo', value:'continuo'}
          ]
      }
    },
    mounted () {
     this.examen_id = this.$route.params.id;
      if(!this.detalle[this.tema_id]){
      //  this.actualizar()
      }
    },
    watch:{
      examen (exam){
        this.struct.comentarios_estudiante= exam.estructura.comentarios_estudiante
        this.struct.comentarios_docente=exam.estructura.comentarios_docente
        this.struct.self_assessment=exam.estructura.self_assessment
        this.struct.unidades = exam.estructura.unidades
        this.struct.criterios = exam.estructura.criterios
        this.criteria = exam.estructura.criterios
      }
    },
    methods:{
      ...mapActions({
        editar: 'examenes/editar',
        setWarning: 'setWarning',
      }),
      ...mapMutations({
        
      }),
      actualizar(){
        //this.fetchDetalle(this.examen_id)
      },
      removeCriterion(idx){
        this.criteria.splice(idx,1);
        this.criterion = {};
      },
      addCriterion(){
        this.criterion.codigo = this.criterion.nombre.toLowerCase().split(" ").join("_");
        let opc = [];
        for(var s=1; s <= this.criterion.opciones; s++){
          opc.push(s);
        }
        this.criterion.opciones = opc;
        this.criteria.push(this.criterion);
        this.criterion = {};
      },
      buildEstructure(){
        this.struct.criterios = this.criteria;
        return this.struct;
      },
      procesarGuardar () {
        this.examen.estructura = this.buildEstructure();
        this.editar(this.examen).then(data=>{
            this.setWarning(data.mensaje, { root: true }).then(()=>{
              this.$router.push('/examenes/')
            })
        }).catch(error=>{
          this.setWarning(error, { root: true }).then(()=>{})
        })
        
      },
      
    },
    computed:{
      ...mapState({
        warning: state => state.warning
      }),
      ...mapGetters({
        detalle: 'examenes/getExamen', 
      }),
      examen(){
        if(this.examen_id){
          return this.detalle(this.examen_id)
        }
        return false
        
      },
      puedeGuardar(){
        if(this.examen){
          return true  
        }
        return false
      }
      
      
    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>
