import Vue from 'vue';

const state = {
  lista: [],
  detalle : {},
};

const actions = {
  fetchLista:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('grupo/lista',data).then(
        response =>{
          commit('setLista',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchDetalle:({commit},id) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('grupo/detalle',{id:id}).then(
        response =>{
          commit('setDetalle',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
   agregarPermiso:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('grupo/agregar_permiso',payload).then(
        response =>{
          commit('setDetalle',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  quitarPermiso:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('grupo/quitar_permiso',payload).then(
        response =>{
          commit('setDetalle',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  crear:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('grupo/crear',data).then(
        response =>{
          commit('setDetalle',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  
};

const getters = {
  getDetalle: (state) => (id) =>{
    if(!state.detalle[id]){
      let encontrado = state.lista.find(element=>{
        return element.id == id
      })
      if(encontrado){
        Vue.set(state.detalle, id, encontrado)  
      }else{
        Vue.set(state.detalle, id, {})  
      }
    }
    return state.detalle[id]
  }
};

const mutations = {
  setLista: (state, lista) => {
    state.lista = lista;
  },
  setDetalle: (state, detalle) => {
    let id = detalle.id;
    Vue.set(state.detalle, id, detalle)
  },
  restart: (state) => {
    state.lista = []
    state.detalle = {}
  },
  
  
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
