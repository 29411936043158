<template>
  <div class="container  mt-3">
    <div class="m-auto">
      <div class="form-horizontal">
        <div class="mb-3 row" v-if="disponibilidad_general">
          <div class="col-2" v-for="n in 6">
            <div><strong>{{ disponibilidad_general.dias[n].nombre }}</strong></div>
            <div>
              <div v-for="hora in disponibilidad_general.dias[n].horas">
                <button class="pt-0 pb-0 mt-1 btn btn-success" v-if="disponibilidad.includes(hora.id)"
                @click="disponibilidad.splice(disponibilidad.indexOf(hora.id),1)"
                title="Click para quitar">
                  {{ hora.hora }}  
                </button>
                <button class="pt-0 pb-0 mt-1 btn btn-dark" v-else
                @click="disponibilidad.push(hora.id)"
                title="Click para agregar">
                  {{ hora.hora }}  
                </button>
              </div>
            </div>
          </div>
        </div>
        <button class="btn btn-secondary btn-sm mr-1 mb-1 pt-0 pb-0" @click="mananas">Mañanas</button>  
        <button class="btn btn-secondary btn-sm mr-1 mb-1 pt-0 pb-0" @click="tardes">Tardes</button>  
        <button class="btn btn-secondary btn-sm mr-1 mb-1 pt-0 pb-0 " @click="ninguno">Ninguno</button>  
        <button v-if="procesable" class="btn btn-block btn-danger" @click="procesar">
          Guardar
        </button>   
        
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'DocenteEditarDisponibilidad',
    data () {
      return {
        disponibilidad:[]
      }
    },
    props: {
      docente: Object
    },
    mounted () {
      if(this.docente.disponibilidad){
          this.disponibilidad = JSON.parse(this.docente.disponibilidad)
          this.disponibilidad = this.disponibilidad.filter(element=>{
            return this.disponibilidadGeneralLista.includes(element)
          })
          
      }
    },
    methods:{
      ...mapActions({
        fetchDetalle: 'docentes/fetchDetalle',
        setWarning: 'setWarning',
        editarDisponibilidad: 'docentes/editarDisponibilidad',
        fetchLista: 'docentes/fetchLista',
      }),
      ...mapMutations({
        
      }),
      procesar(){
        
        this.editarDisponibilidad({
          id:this.docente.id,
          disponibilidad:this.disponibilidad,
        }).then((apiResponse)=>{
          
          this.setWarning('Información Guardada', { root: true }).then(()=>{
            this.fetchDetalle(this.docente.id).then(()=>{
              this.fetchLista()
              this.$router.push('/docente/detalle/'+this.docente.id)
            }) 
          })
          
        }).catch((apiResponse)=>{
          console.log("error procesando editar disponibilidad")
        })
        
      },
      mananas(){
        this.disponibilidad.splice(0,this.disponibilidad.length)
        for (var i = 1; i <= 5; i++) {
          let dia = this.disponibilidad_general.dias[i]
          for (var j = 0; j <= 4; j++) {
            let hora = dia.horas[j]
            this.disponibilidad.push(hora.id)
          }
        }
      },
      tardes(){
        this.disponibilidad.splice(0,this.disponibilidad.length)
        for (var i = 1; i <= 5; i++) {
          let dia = this.disponibilidad_general.dias[i]
          for (var j = 5; j <= 9; j++) {
            let hora = dia.horas[j]
            this.disponibilidad.push(hora.id)
          }
        }
      },
      ninguno(){
        this.disponibilidad.splice(0,this.disponibilidad.length)
      }
      
    },
    computed:{
      ...mapState({
        disponibilidad_general: state => state.asw.disponibilidad_general, 
      }),
      ...mapGetters({
        
      }),
      procesable: {
        get () {
          return true
        },
        set(value){}
      },
      disponibilidadGeneralLista(){
        let lista = []
        for(let d in this.disponibilidad_general.dias){
          let dia = this.disponibilidad_general.dias[d]
          for(let hora of dia.horas){
            lista.push(hora.id)  
          }
          
        }
        return lista;
      }
    }
  }
</script>

<style scope>
 
</style>

