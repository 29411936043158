
<template>
  <div>
    <div class="container-fluid docentes_wrapper m-3 p-3">
    	<div class="row pb-3">
    		<strong>Total docentes activos:{{docentesActivos.length}}</strong>
    	</div>

      <b-row>
        <b-col class="celda-head text-left" >Docente</b-col>
        <b-col cols="5" class="celda-head text-center" >Preasignación</b-col>
        <b-col cols="5" class="celda-head text-center" >Asignación</b-col>
      </b-row>
      <b-row class="fila" v-for="(docente,index) in docentesActivos" :key="index">
      	<b-col class="tipo-titulo">
      		<a :href="`#/docente/detalle/${ docente.id }`" target="_blank" v-if="permiso('42C7BC3F')" >
      		{{docente.titulo}}</a>
<icon v-if="docenteTieneClase(docente.id)" class="ok-docente" scale="0.6" name="check"></icon>
          
        </b-col>
        <b-col class="text-right">
          <span v-if="docente.preasignacion && docente.preasignacion.salon">
            <a :href="`#/salon/detalle/${ docente.preasignacion.salon.id }`" target="_blank" v-if="permiso('AE7C3DD1')" >
                {{docente.preasignacion.salon.nombre}}</a>
            
          </span>
        </b-col>

        <b-col class="text-left">
          <span class="botones" v-if="docente.preasignacion && docente.preasignacion.tipos && docente.preasignacion.tipos.tipos">
            <button v-for="(tipo,index) in docente.preasignacion.tipos.tipos" :key="index"
              class="pt-0 pb-0 mt-1 mr-1 btn btn-success">
                    {{ getTipoNombreCorto(tipo) }}  
            </button>
          </span> 
        </b-col>
        
        <b-col class="text-right">
          <div v-if="docente.clase">
              <a :href="`#/salon/detalle/${ docente.clase.salon.id }`" target="_blank" v-if="permiso('AE7C3DD1')" >
                {{docente.clase.salon.nombre}}</a>
          </div>
        </b-col>

        <b-col class="text-left">
          <div class="botones" v-if="docente.clase && docente.clase.tipos">
            <button v-for="(tipo,index) in docente.clase.tipos" :key="index"
              class="pt-0 pb-0 mt-1 mr-1 btn btn-success">
                    {{ getTipoNombreCorto(tipo) }}  
            </button>
          </div>
        </b-col>

          <!--
          <icon v-if="salonesAsignadosPredeterminados[docente.salon.id]>1" class="frown-icon" scale="0.7" name="frown"></icon>
        -->

      </b-row>
      
<hr>

      <div class="row pb-3">
        <strong>Total docentes libres:{{docentesSinClase.length}}</strong>
      </div>
      <b-row>
        <b-col class="celda-head text-left" >Docente</b-col>
      </b-row>

      <b-row  v-for="(docente,index) in docentesSinClase" :key="`docente-sin-clase-${index}`">
        <b-col >
          <a :href="`#/docente/detalle/${ docente.id }`" target="_blank" v-if="permiso('42C7BC3F')" >
            {{docente.titulo}}

            {{ getNovedad(docente.id) ? ' (NOVEDAD)' : '' }}
          </a>
          
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Icon from 'vue-awesome/components/Icon'
  import 'vue-awesome/icons/check'
  import 'vue-awesome/icons/frown'

  export default {
    name: 'ProgramadorInformeEstadoDocentes',
    components: {
      Icon
    },
    data () {
      return {
        marca_relativa : null,
        dia:null
      }
    },
    props : {
      hora:Object,
      consolidado: Array,
    },
    mounted () {
    	let date = new Date(this.hora.marca_tiempo)
      	this.dia = date.getDay()
      	this.marca_relativa = this.dia+'T'+this.hora.hora

      if(!this.docentes || (this.docentes && this.docentes.length==0)){
        this.fetchLista()
      }
    },

    methods:{
      ...mapActions({
        fetchLista: 'docentes/fetchLista',
      }),
      ...mapMutations({
        
      }),
      edit(){

      },
      actualizar(){
        this.fetchLista()
      },
      docenteTieneClase(docente_id){
        if(this.docentesConClase && this.docentesConClase.length>0){
          return this.docentesConClase.includes(docente_id)
        }
        return false
      },
      consolidadoXdocente(docente_id){
        return this.consolidado.find(element=>{
          return element.id == docente_id
        })
      },
     

    },
    computed: {
      ...mapState({
        docentes: state => state.docentes.lista, 
        tipos: state => state.asw.tipos, 
        clases: state => state.clases.clases,
      }),
      ...mapGetters({
        getDocentesDisponibles: 'docentes/getDocentesDisponibles', 
      	getDocentesConClase: 'clases/getDocentesConClase', 
        getNombreTipo: 'asw/getNombreTipo', 
      	getTipoNombreCorto: 'asw/getTipoNombreCorto', 
        permiso: 'auth/permiso', 
        getNovedad: 'docentes/getNovedad'
      }),
      docentesActivos(){
      	if(this.docentes){
      		let docentes_disponible = this.getDocentesDisponibles(this.marca_relativa)
      		
      		docentes_disponible = docentes_disponible.map((docente)=>{

            let preasignacionActual = docente.preasignaciones.find(element=>{
              return element.dia==this.dia && element.hora == this.hora.hora
            })
            let clase = null
            if(this.clases[this.hora.marca_tiempo]){
              clase = this.clases[this.hora.marca_tiempo].find(element=>{
                return element.profesor_id == docente.id
              })  
            }

	          let newDocente = {
	            id: docente.id,
	            activo: docente.activo,
	            titulo: docente.primer_nombre+' '+docente.primer_apellido,
	            tipos_permitidos: docente.tipos_permitidos,
	            disponibilidad: docente.disponibilidad?JSON.parse(docente.disponibilidad):[],
	            salon: docente.salon,
              preasignacion: preasignacionActual,
              clase: clase,
	          }
	          for(let i in this.tipos){
	          	let tipo = this.tipos[i]
	          	newDocente[tipo.id] = docente.tipos_permitidos.includes(tipo.id)
	          }
	          return newDocente
	        })
			
      		return docentes_disponible
      	}else{
      		return []
      	}
      },
      docentesConClase(){
        return this.getDocentesConClase(this.hora.marca_tiempo)
      },
      docentesSinClase(){
        let docentes_activos = this.docentesActivos
        let docentes_con_clase = this.docentesConClase
        let respuesta = docentes_activos.filter(element=>{
          return !docentes_con_clase.includes(element.id)
        })
        return respuesta
      }

      
      /*
      salonesAsignadosPredeterminados(){
        let salones = {}
        this.docentesActivos.forEach(element=>{
          if(salones[element.salon.id]==undefined){
            salones[element.salon.id] = 1
          }else{
            salones[element.salon.id]++  
          }
          
        })
        return salones
      }
      */
    }
  }
</script>

<style scope>
	.tipo-titulo{
		width: 160px;
    	font-size: 12px;
	}
	.botones .btn{
		font-size: 10px !important;
    	padding: 2px !important;
	}
  .alarm{
    color:#fff;
    background-color: red;
    padding: 2px;
  }
  .ok-docente{
    color: green;
    margin-left: 4px;
  }
</style>