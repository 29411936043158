<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_docentes"></b-navbar-toggle>
        <h1  class="text-center navbar-brand pt-0 pb-0 mb-0">Cambiar Password</h1>
        <b-collapse is-nav id="nav_collapse_docentes">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item  href="#" @click="procesarCancelar">Cancelar</b-nav-item>
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <div class="container pt-3 m-auto text-left ">
      <!--
      <div class="form-group row">
        <label class="col-3 col-form-label">Nuevo password::</label>
        <div class="col-9">
          <input type="text"
          v-model="password"
          name="password"
          placeholder="password"
          class="form-control"
          >
          <span>Debe tener mínimo 8 caracteres.</span>
        </div>
      -->
      <div class="">
        <h5>Se le enviará un nuevo password al correo electrónico {{docente.email}}</h5>
        <button class="btn btn-danger mt-3" @click="procesarCambiarPassword">Cambiar Password</button>
      </div>

      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';

  export default {
    name: 'DocenteCambiarPassword',
    components: {
    },
    data () {
      return {
           docente_id: null,
           password: ''
      }
    },
    mounted () {
     this.docente_id = this.$route.params.id;
      if(!this.detalle[this.docente_id]){
        this.actualizar()
      }
    },
    
    methods:{
      ...mapActions({
        fetchDetalle: 'docentes/fetchDetalle',
        editar: 'docentes/editar',
        setWarning: 'setWarning',
        cambiarPassword: 'asw/cambiarPassword'
      }),
      ...mapMutations({
        
      }),
      procesarCancelar(){
        this.fetchDetalle(this.docente_id).then(()=>{
          this.$router.push('/docente/detalle/'+this.docente_id)
        })
      },
      actualizar(){
        this.fetchDetalle(this.docente_id)
      },
      procesarGuardar () {
        let value = null
        if(this.password && this.password!=''){
          if(this.password.length>=8){
            value = this.password
          }else{
            this.setWarning('Debe tener mínimo 8 caracteres.', { root: true })
            return
          }
        }
        this.cambiarPassword({userid:this.docente_id,password:value}).then(data=>{
            this.setWarning(data.mensaje, { root: true }).then(()=>{
              this.$router.push('/docente/detalle/'+this.docente_id)
            })
        }).catch(error=>{
          this.setWarning(error, { root: true }).then(()=>{})
        })
        
      },

      procesarCambiarPassword () {
        let value = null

        this.cambiarPassword({userid:this.docente_id,action:'autogenerate'}).then(data=>{
            this.setWarning(data.mensaje, { root: true }).then(()=>{
              this.$router.push('/docente/detalle/'+this.docente_id)
            })
        }).catch(error=>{
          this.setWarning(error, { root: true }).then(()=>{})
        })
      },


    },
    computed:{
      ...mapState({
        detalle: state => state.docentes.detalle, 
        warning: state => state.warning
      }),
      docente(){
        return this.detalle[this.docente_id]
      },
      
      
    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>